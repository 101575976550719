import React, { useState } from "react";

import InfoBar from "./InfoBar";
import { useApi } from "../../../api";
import ConfirmQuery from "./ConfirmQuery";
import { useAuth, PERMISSIONS } from "../../../auth";
import { DEVICE_TYPE, useDevice } from "../../../domain/device";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const InfoBarContainer = () => {
  const refreshConfig = {
    everySeconds: 10,
    max: 12
  };

  const { device, reload } = useDevice();
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const { hasPermission } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isQuerying, setQuerying] = useState(false);

  const queryDevice = () => {
    setSubmitting(true);

    api
      .post("/v2/queries", {
        deviceId: device.id
      })
      .then(response => {
        setQuerying(true);
        refreshQueryStatus(response.headers.location);
      })
      .catch(() => {})
      .then(() => {
        setSubmitting(false);
        setShowConfirmation(false);
      });
  };

  const refreshQueryStatus = (endpoint, refreshCount = 0) => {
    if (refreshCount >= refreshConfig.max) {
      setQuerying(false);

      return;
    }

    api.get(endpoint).then(response => {
      if (response.data.status === "processed") {
        setQuerying(false);

        enqueueSnackbar(
          "devices.infoBar.query.responses.processed",
          {
            variant: "success"
          },
          { device: device.id }
        );

        reload();

        return;
      }

      setTimeout(() => {
        refreshQueryStatus(endpoint, refreshCount + 1);
      }, refreshConfig.everySeconds * 1000);
    });
  };

  const handleConfirmQuery = () => {
    if (device.type == DEVICE_TYPE.LX_IOT) {
      queryDevice();

      return;
    }

    setShowConfirmation(true);
  };

  return (
    <>
      {showConfirmation && (
        <ConfirmQuery
          isSubmitting={isSubmitting}
          onConfirm={queryDevice}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
      <InfoBar
        allowedToQuery={hasPermission(device.permissions, PERMISSIONS.QUERY)}
        device={device}
        isQuerying={isQuerying}
        onQueryDevice={handleConfirmQuery}
      />
    </>
  );
};

export default InfoBarContainer;
