import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  makeStyles,
  Button,
  useMediaQuery,
  useTheme,
  Box
} from "@material-ui/core";

import Title from "./Title";
import I18nText from "./I18nText";

const SectionHeader = ({ children, onEdit, disableEdit = false }) => {
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles(theme => ({
    header: {
      height: 29,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: 2
    },
    hr: {
      //width: fullWidth ? "100%" : "calc(100% - 80px)",
      width: fullWidth ? "100%" : "100%",
      margin: 0,
      border: 0,
      height: 1,
      backgroundColor: theme.palette.grey.A400,
      marginBottom: 12
    },
    editContainer: {
      //transform: !fullWidth ? "translateX(-80px)" : null
    },
    editIcon: {
      "&&": {
        fontSize: 12
      }
    },
    button: {
      marginBottom: "-3px",
      fontSize: 10,
      padding: "3px 12px",
      borderRadius: "4px 4px 0 0"
    }
  }));

  const classes = useStyles();

  const Icon = <EditIcon className={classes.editIcon}></EditIcon>;

  return (
    <>
      <div className={classes.header}>
        <Title>{children}</Title>
        {onEdit && !disableEdit && (
          <Box className={classes.editContainer}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={Icon}
              onClick={onEdit}
            >
              <I18nText>actions.edit</I18nText>
            </Button>
          </Box>
        )}
      </div>
      <hr className={classes.hr} />
    </>
  );
};

export default SectionHeader;
