import React from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import { format } from "date-fns";
import {
  ALIAS,
  CHARGING_PERIOD_TYPES,
  TIMES_OF_INVOICING
} from "../../../../../domain/billingTerms";
import TermsForm from "../Form/TermsForm";

const CreateContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {
      price: values.price,
      chargingPeriodType: values.chargingPeriodType,
      timeOfInvoicing: values.timeOfInvoicing,
      name: values.custom.name,
      validFrom: values.validFrom
        ? format(new Date(values.validFrom), "yyyy-MM-dd")
        : null
    };

    api
      .post(`/v2/devices/${device.id}/billing-terms`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.billing.responses.created", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const values = {
    alias: ALIAS.CUSTOM,
    price: 0,
    chargingPeriodType: CHARGING_PERIOD_TYPES.YEARLY,
    timeOfInvoicing: TIMES_OF_INVOICING.END,
    custom: {
      name: ""
    },
    validFrom: new Date(),
    validUntil: null
  };

  return (
    <Dialog>
      <TermsForm values={values} onClose={onClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};

export default CreateContainer;
