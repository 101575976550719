import React, { useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";

import AuthContext from "../context/AuthContext";
import { useApi } from "../api";

export const ROLES = {
  DEMO: "ROLE_DEMO",
  APP_USER: "ROLE_APP_USER",
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
  SUPERADMIN: "ROLE_SUPERADMIN",
  MASTER: "ROLE_MASTER"
};

export const PERMISSIONS = {
  VIEW_STATUS: "VIEW_STATUS",
  VIEW_CONSUMPTION: "VIEW_CONSUMPTION",
  VIEW_PROCESS: "VIEW_PROCESS",
  VIEW_MESSAGING: "VIEW_MESSAGING",
  VIEW_CONFIGURATION: "VIEW_CONFIGURATION",
  CONFIGURATION: "CONFIGURATION",
  EDIT: "EDIT",
  DELETE: "DELETE",
  QUERY: "QUERY",
  EXPORT: "EXPORT",
  REF_DATE_QUERY: "REF_DATE_QUERY"
};

let ROLE_HIERARCHY = {
  [ROLES.APP_USER]: [ROLES.APP_USER],
  [ROLES.DEMO]: [ROLES.DEMO]
};

ROLE_HIERARCHY[ROLES.USER] = [
  ROLES.USER,
  ...ROLE_HIERARCHY[ROLES.APP_USER],
  ...ROLE_HIERARCHY[ROLES.DEMO]
];

ROLE_HIERARCHY[ROLES.ADMIN] = [ROLES.ADMIN, ...ROLE_HIERARCHY[ROLES.USER]];
ROLE_HIERARCHY[ROLES.SUPERADMIN] = [
  ROLES.SUPERADMIN,
  ...ROLE_HIERARCHY[ROLES.ADMIN]
];

ROLE_HIERARCHY[ROLES.MASTER] = [
  ROLES.MASTER,
  ...ROLE_HIERARCHY[ROLES.SUPERADMIN]
];

const AuthProvider = ({ children }) => {
  const api = useApi();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const updateUser = user => {
    const username = user ? user.username : null;
    Sentry.configureScope(scope => {
      scope.setUser({ username });
    });

    setUser(user);
  };

  const hasRole = useCallback((user, role) => {
    const roleList = ROLE_HIERARCHY[user.role];

    if (roleList === undefined) {
      return false;
    }

    return roleList.indexOf(role) !== -1;
  }, []);

  const hasPermission = useCallback((myPermissions, requiredPermission) => {
    return myPermissions.indexOf(requiredPermission) !== -1;
  }, []);

  const getAssignableRoles = useCallback(
    user => {
      const roles = [ROLES.DEMO, ROLES.USER, ROLES.APP_USER];

      if (hasRole(user, ROLES.SUPERADMIN)) {
        roles.push(ROLES.ADMIN);
      }

      if (hasRole(user, ROLES.MASTER)) {
        roles.push(ROLES.SUPERADMIN);
      }

      return roles;
    },
    [hasRole]
  );

  useEffect(() => {
    api
      .get("/v2/users/me")
      .then(response => updateUser(response.data))
      .catch(() => {})
      .then(() => setLoading(false));
  }, [api]);

  const contextValue = {
    user,
    setUser: updateUser,
    hasRole,
    hasPermission,
    getAssignableRoles
  };

  return isLoading ? null : (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
