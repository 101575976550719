import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from "@material-ui/core";

import CounterRow from "./CounterRow";
import I18nText from "../../../../elements/I18nText";

const CounterTable = ({ sensors }) => {
  const theme = useTheme();

  return (
    <MuiTable size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <I18nText>devices.status.table.header.name</I18nText>
          </TableCell>
          <TableCell align="right">
            <I18nText>devices.status.table.header.counterValue</I18nText>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sensors.map(sensor => (
          <CounterRow
            key={sensor.id}
            sensor={sensor}
            rowColor={theme.custom.colors.sensors[sensor.sequence]}
          />
        ))}
      </TableBody>
    </MuiTable>
  );
};

export default CounterTable;
