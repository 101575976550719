import React from "react";
import { Formik, Form } from "formik";
import { Grid, TextField, makeStyles } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import Section from "../../../../../elements/Section";
import SectionHeader from "../../../../../elements/SectionHeader";
import I18nText from "../../../../../elements/I18nText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ChargingPeriodTypeField from "./ChargingPeriodTypeField";
import TimeOfInvoicingField from "./TimeOfInvoicingField";
import PriceField from "./PriceField";
import ExemptedQuantityField from "./ExemptedQuantityField";
import { ALIAS } from "../../../../../domain/billingTerms";
import { FullWidthTextField } from "../../../../../elements/FormField";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const TermsForm = ({ values, isEdit = false, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>
                    devices.settings.billing.edit.sections.position
                  </I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {values.alias === ALIAS.CUSTOM ? (
                      <FullWidthTextField
                        name="custom.name"
                        variant="outlined"
                        label={<I18nText>billing.common.name</I18nText>}
                        inputProps={{ maxLength: 32, autoComplete: "off" }}
                      />
                    ) : (
                      <TextField
                        classes={classes}
                        size="small"
                        value={t(`billing.aliases.${values.alias}`)}
                        disabled={true}
                        variant="outlined"
                        label={<I18nText>billing.common.name</I18nText>}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <PriceField />
                  </Grid>
                  {(values.alias === ALIAS.SMS_DEVICE ||
                    values.alias === ALIAS.SMS_EXTERNAL) && (
                    <Grid item xs={12}>
                      <ExemptedQuantityField />
                    </Grid>
                  )}
                  {values.alias === ALIAS.DEVICE_LICENSE && (
                    <Grid item xs={12}>
                      <FullWidthTextField
                        name="additionalText"
                        variant="outlined"
                        label={
                          <I18nText>billing.common.additionalText</I18nText>
                        }
                        inputProps={{ maxLength: 64, autoComplete: "off" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Section>
            </Grid>

            <Grid item md={4} xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>
                    devices.settings.billing.edit.sections.chargingPeriod
                  </I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ChargingPeriodTypeField />
                  </Grid>

                  <Grid item xs={12}>
                    <TimeOfInvoicingField />
                  </Grid>

                  {isEdit && (
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        size="small"
                        variant="dialog"
                        inputVariant="outlined"
                        ampm={false}
                        label={
                          <I18nText>billing.common.invoicedUntil</I18nText>
                        }
                        value={values.invoicedUntil}
                        onChange={value =>
                          setFieldValue("invoicedUntil", value)
                        }
                        format="dd.MM.yyyy"
                      />
                    </Grid>
                  )}
                </Grid>
              </Section>
            </Grid>

            <Grid item md={4} xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>
                    devices.settings.billing.edit.sections.validity
                  </I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      size="small"
                      variant="dialog"
                      inputVariant="outlined"
                      ampm={false}
                      label={<I18nText>billing.common.validFrom</I18nText>}
                      value={values.validFrom}
                      onChange={value => setFieldValue("validFrom", value)}
                      format="dd.MM.yyyy"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      size="small"
                      variant="dialog"
                      inputVariant="outlined"
                      ampm={false}
                      label={<I18nText>billing.common.validUntil</I18nText>}
                      value={values.validUntil}
                      onChange={value => setFieldValue("validUntil", value)}
                      format="dd.MM.yyyy"
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TermsForm;
