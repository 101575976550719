import React from "react";
import { alpha, makeStyles, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250
    }
  }
}));

const Search = ({ options, searchTerm, onFocus, onSearch, onSubmit }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="search"
      variant="outlined"
      name="searchTerm"
      value={null}
      freeSolo
      filterOptions={x => x}
      noOptionsText=""
      options={options}
      renderTags={() => {}}
      onFocus={onFocus}
      onInputChange={(e, value) => {
        onSearch(value);
      }}
      onChange={(_, value) => onSubmit(value)}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={params => {
        return (
          <div className={classes.container} ref={params.InputProps.ref}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Input
              {...params.inputProps}
              value={searchTerm}
              autoComplete="5974h593h"
              classes={{
                root: classes.input
              }}
              size="small"
            />
          </div>
        );
      }}
    />
  );
};

export default Search;
