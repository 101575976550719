import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";

import I18nText from "../../../../../elements/I18nText";

const PermissionCheckbox = ({ permissions, name, setFieldValue }) => {
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={e => {
              setFieldValue(`permissions.${name}`, e.target.checked);
            }}
            checked={permissions[name]}
            color="primary"
          />
        }
        label={<I18nText>{`access.permissions.${name}`}</I18nText>}
      />
    </Grid>
  );
};

export default PermissionCheckbox;
