import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import I18nText from "../../../../../elements/I18nText";
import {
  ListActionsWrapper,
  DeleteAction
} from "../../../../../elements/ListAction";

const RefDateQueries = ({ refDateQueries, onDeleteRefDateQuery }) => {
  const { t } = useTranslation();

  const renderIntervals = intervals =>
    intervals
      .map(interval => t(`refDateQueries.intervals.${interval}`))
      .join(", ");

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <I18nText>refDateQueries.user</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>refDateQueries.table.header.intervalType</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>refDateQueries.table.header.intervals</I18nText>
            </TableCell>
            <TableCell>
              <I18nText></I18nText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refDateQueries.map(query => {
            return (
              <TableRow key={query.id}>
                <TableCell>{query.username}</TableCell>
                <TableCell>
                  <I18nText>{`refDateQueries.intervalTypes.${query.intervalType}`}</I18nText>
                </TableCell>
                <TableCell>{renderIntervals(query.intervals)}</TableCell>
                <TableCell align="right">
                  <ListActionsWrapper>
                    <DeleteAction onClick={() => onDeleteRefDateQuery(query)} />
                  </ListActionsWrapper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default RefDateQueries;
