import React from "react";
import { Formik, Form, FastField } from "formik";
import { Grid } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";

import I18nText from "../../../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";

const ActiveFlagForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FastField
                component={CheckboxWithLabel}
                name="active"
                type="checkbox"
                color="primary"
                Label={{
                  label: (
                    <I18nText>
                      devices.settings.configuration.activeFlag
                    </I18nText>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ActiveFlagForm;
