import React from "react";
import { Box, useTheme } from "@material-ui/core";

import SensorError from "./SensorError";
import SensorName from "./SensorName";
import SensorTemperature from "./SensorTemperature";
import { SENSOR_STATUS } from "../../../domain/sensor";
import SensorValue from "./SensorValue";

const Row = React.memo(({ sensor, sensorSettings, inactiveDevice = false }) => {
  const theme = useTheme();
  let colorOverride = null;

  if (inactiveDevice) {
    colorOverride = theme.palette.grey.A200;
  }

  return (
    <Box>
      {sensor.status === SENSOR_STATUS.ERROR ? (
        <SensorError sensor={sensor} colorOverride={colorOverride} />
      ) : (
        <SensorValue
          sensorSettings={sensorSettings}
          sensor={sensor}
          colorOverride={colorOverride}
        />
      )}
      {sensorSettings.showTemperatures && (
        <SensorTemperature sensor={sensor} colorOverride={colorOverride} />
      )}
      {sensorSettings.showNames && <SensorName name={sensor.name} />}
    </Box>
  );
});

export default Row;
