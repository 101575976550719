import React from "react";

import NotificationForm from "./NotificationForm";
import { EVENT_TYPE_CHANNELS } from "../../../../../domain/notificationEvent";
import {
  TIME_UNITS,
  inMostSuitableTimeUnit
} from "../../../../../utils/timeUtils";

const FormContainer = ({ contact, existingEvents = [], onSubmit, onClose }) => {
  const getExistingEventByName = eventName => {
    return existingEvents.find(event => event.name === eventName);
  };

  const events = [];

  EVENT_TYPE_CHANNELS.forEach(eventType => {
    const existingEvent = getExistingEventByName(eventType.name);

    let notifyOnceIn;
    if (!existingEvent || existingEvent.notifyOnceIn === 0) {
      notifyOnceIn = {
        value: 12, // Default: 12 hours
        unit: TIME_UNITS.HOUR
      };
    } else {
      notifyOnceIn = inMostSuitableTimeUnit(existingEvent.notifyOnceIn, [
        TIME_UNITS.MINUTE,
        TIME_UNITS.HOUR,
        TIME_UNITS.DAY
      ]);
    }

    events.push({
      name: eventType.name,
      channels: existingEvent !== undefined ? existingEvent.channels : [],
      notifyOnceIn,
      allowedChannels: eventType.channels
    });
  });

  const values = {
    events
  };

  return (
    <NotificationForm
      contact={contact}
      values={values}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default FormContainer;
