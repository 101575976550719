import React from "react";
import PublicUrlForm from "./PublicUrlForm";

import { useApi } from "../../../../../api";
import { setFieldErrorsFromResponse } from "../../../../../utils/formErrors";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const PublicUrlContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    api
      .put(`/v2/devices/${device.id}/public-url`, values)
      .then(() => {
        enqueueSnackbar(
          "devices.settings.metadata.responses.publicUrlUpdated",
          {
            variant: "success"
          }
        );

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    publicUrlAccessEnabled: device.publicUrlAccessEnabled
  };

  return (
    <PublicUrlForm values={values} onClose={onClose} onSubmit={handleSubmit} />
  );
};

export default PublicUrlContainer;
