import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ROLES, useAuth } from "../../../auth";
import I18nText from "../../../elements/I18nText";
import Account from "./Account";
import DeleteContainer from "../DeleteContainer";

const AccountContainer = ({ user, onReload }) => {
  const { user: me, hasRole } = useAuth();
  const history = useHistory();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleted = () => {
    setConfirmDelete(false);

    history.push("/users");
  };

  const handleDeleteUser = () => {
    setConfirmDelete(true);
  };

  const listOptions = {
    login: [
      {
        label: "ID",
        value: user.id
      },
      {
        label: "users.common.username",
        value: user.username
      },
      {
        label: "users.common.role",
        value: <I18nText>{`roles.${user.role}`}</I18nText>
      },
      {
        label: "users.common.active",
        value: <I18nText>{`users.statuses.${user.status}`}</I18nText>
      },
      {
        label: "users.common.parentUser",
        value: `${user.parentUser.name} (${user.parentUser.username})`
      }
    ],
    api: [],
    basedata: [
      {
        label: "users.common.company",
        value: user.contact.company
      },
      {
        label: "users.common.form",
        value: user.contact.form ? (
          <I18nText>{`users.forms.${user.contact.form}`}</I18nText>
        ) : (
          ""
        )
      },
      {
        label: "users.common.firstName",
        value: user.contact.firstName
      },
      {
        label: "users.common.lastName",
        value: user.contact.lastName
      },
      {
        label: "users.common.street",
        value: `${user.address.street} ${user.address.streetNumber}`
      },
      {
        label: "users.common.city",
        value: `${user.address.countryCode}-${user.address.postalCode} ${user.address.city}`
      },
      {
        label: "users.common.email",
        value: user.contact.email
      },
      {
        label: "users.common.website",
        value: user.contact.website
      },
      {
        label: "users.common.cellNumber",
        value: user.contact.cellNumber
      },
      {
        label: "users.common.phoneNumber",
        value: user.contact.phoneNumber
      },
      {
        label: "users.common.faxNumber",
        value: user.contact.faxNumber
      },
      {
        label: "users.common.taxId",
        value: user.contact.taxId
      }
    ]
  };

  if (hasRole(me, ROLES.MASTER)) {
    listOptions.api = [
      {
        label: "API Auth Token",
        value: user.authToken
      }
    ];
  }

  return (
    <>
      {confirmDelete && (
        <DeleteContainer
          user={user}
          onCancel={() => setConfirmDelete(false)}
          onDeleted={handleDeleted}
        />
      )}
      <Account
        listOptions={listOptions}
        onDelete={handleDeleteUser}
        user={user}
        onReload={onReload}
      />
    </>
  );
};

export default AccountContainer;
