import React from "react";
import { DialogContent } from "@material-ui/core";

import FixedPositionDialog from "../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../elements/DialogTitleWithCloseIcon";
import ContactForm from "../ContactForm";
import I18nText from "../../../elements/I18nText";

const FormDialog = ({ values, onClose, onSubmit }) => {
  return (
    <FixedPositionDialog open={true} scroll="body">
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>contacts.update.title</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>
        <ContactForm values={values} onClose={onClose} onSubmit={onSubmit} />
      </DialogContent>
    </FixedPositionDialog>
  );
};

export default FormDialog;
