import React from "react";
import { makeStyles } from "@material-ui/core";

import Sensor from "./Sensor";
import Background from "./Background";

const Chart = ({ sensors, sensorRatios, getColorBySequence }) => {
  const scaleWidth = 40;

  const MIN_WIDTH_CHART = 760;
  const TOTAL_WIDTH_ALL_SENSORS = 570;

  const sensorWidth = ratio =>
    parseInt(TOTAL_WIDTH_ALL_SENSORS * (ratio / 100), 10);

  const classes = makeStyles({
    outerContainer: {
      position: "relative",
      marginTop: 50,
      width: "100%",
      minWidth: MIN_WIDTH_CHART,
      height: 225
    },
    innerContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      marginLeft: scaleWidth,
      width: "calc(100% - 40px)",
      height: "100%"
    },
    sensorContainer: {
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: "center"
    }
  })();

  return (
    <>
      <div className={classes.outerContainer}>
        <Background scaleWidth={scaleWidth} zIndex={0} />
        <div className={classes.innerContainer}>
          {sensors.map(sensor => (
            <div
              key={sensor.id}
              className={classes.sensorContainer}
              style={{
                flexBasis: `${sensorRatios[sensor.id]}%`
              }}
            >
              <Sensor
                width={sensorWidth(sensorRatios[sensor.id])}
                sensor={sensor}
                color={getColorBySequence(sensor.sequence)}
                zIndex={1}
              ></Sensor>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Chart;
