import { useTranslation } from "../../i18n";

const Indication = ({ children }) => {
  const { t } = useTranslation();

  const indication = t(`messages.indications.${children}`);

  if (indication === `messages.indications.${children}`) {
    return "-";
  }

  return indication;
};

export default Indication;
