import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AppLayout from "../AppLayout";
import Layout from "./Layout";
import Contacts from "../Contacts";
import Profile from "./Profile";

const SettingsContainer = () => {
  const { url } = useRouteMatch();

  return (
    <AppLayout selectedMenu="settings">
      <Switch>
        <Route path={`${url}/contacts`}>
          <Layout subPage="contacts">
            <Contacts />
          </Layout>
        </Route>
        <Route path={url}>
          <Layout subPage="profile">
            <Profile />
          </Layout>
        </Route>
      </Switch>
    </AppLayout>
  );
};

export default SettingsContainer;
