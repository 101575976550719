import React, { useEffect, useState } from "react";

import List from "./List";
import { useApi } from "../../../api";
import DeleteContainer from "./DeleteContainer";
import Dialog from "../Dialog";
import { useLocation } from "react-router-dom";

const ListContainer = ({ onClose }) => {
  const api = useApi();
  const location = useLocation();
  const [refDateQueries, setRefDateQueries] = useState([]);
  const [reload, doReload] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [confirmDeleteQueryId, setConfirmDeleteQueryId] = useState(null);

  useEffect(() => {
    if (location.reload) {
      doReload(true);
    }
  }, [location]);

  useEffect(() => {
    if (!reload) {
      return;
    }

    doReload(false);

    api.get("/v2/ref-date-queries").then(response => {
      setRefDateQueries(response.data);
      setLoading(false);
    });
  }, [api, reload]);

  const handleConfirmDelete = id => {
    setConfirmDeleteQueryId(id);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {confirmDeleteQueryId !== null && (
        <DeleteContainer
          id={confirmDeleteQueryId}
          onCancel={() => setConfirmDeleteQueryId(null)}
          onDeleted={() => {
            const idx = refDateQueries
              .map(o => o.id)
              .indexOf(confirmDeleteQueryId);
            const newState = [...refDateQueries];
            newState.splice(idx, 1);

            setRefDateQueries(newState);
            setConfirmDeleteQueryId(null);
          }}
        />
      )}
      <Dialog onClose={onClose}>
        <List
          refDateQueries={refDateQueries}
          onDelete={handleConfirmDelete}
          onClose={onClose}
        />
      </Dialog>
    </>
  );
};

export default ListContainer;
