import React, { useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";

import { useApi } from "../../../../../api";
import I18nText from "../../../../../elements/I18nText";
import ConfirmationDialog from "../../../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../../../elements/Button";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";
import { Box, TextField, useTheme } from "@material-ui/core";

const ResetContainer = ({ device, onCancel }) => {
  const api = useApi();
  const theme = useTheme();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [userInput, setUserInput] = useState("");
  const [isResetting, setResetting] = useState(false);

  const isValidUserInput = () => {
    return parseInt(userInput, 10) === device.id;
  };

  const resetDevice = () => {
    setResetting(true);

    if (!isValidUserInput()) {
      return;
    }

    api.post(`/v2/devices/${device.id}/reset-data`).then(() => {
      enqueueSnackbar("devices.settings.metadata.responses.reset", {
        variant: "success"
      });
      setResetting(false);
      onCancel();
    });

    return;
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isResetting}
      ConfirmButton={
        <DeleteButton
          disabled={!isValidUserInput() || isResetting}
          onClick={resetDevice}
        />
      }
    >
      <Box display="flex" justifyContent="center" marginBottom={2}>
        <WarningIcon
          style={{ fontSize: 40, color: theme.palette.error.main }}
        />
      </Box>
      <Box
        color={theme.palette.error.main}
        style={{
          fontWeight: "bold",
          fontSize: 20,
          textAlign: "center",
          paddingBottom: 20
        }}
      >
        Alle Messwerte löschen
      </Box>
      <Box color={theme.palette.error.main} fontWeight="bold">
        Achtung! Sie sind im Begriff die gesammelten Messwerte dieser Anlage
        unwiederbringlich zu löschen. Die Anlage selbst bleibt erhalten.
        <br />
        Fahren Sie nur fort, wenn Sie sicher sind, dass Sie die Messwerte
        wirklich löschen wollen
      </Box>
      <Box marginY={2}>
        Zur Bestätigung geben Sie bitte die Anlagennummer in das untenstehende
        Textfeld ein und klicken Sie dann auf "löschen".
      </Box>
      <Box marginY={2}>
        Die Messwerte werden dann zur Löschung freigegeben. Es kann mehrere
        Minuten dauern, bis alle Messwerte vollständig gelöscht wurden. Unter
        Umständen sind die Messwerte bis zu deren vollständigen Löschung im
        System noch sichtbar.
      </Box>
      <TextField
        size="small"
        variant="outlined"
        label={<I18nText>devices.common.id</I18nText>}
        value={userInput}
        onChange={e => setUserInput(e.target.value)}
      />
    </ConfirmationDialog>
  );
};

export default ResetContainer;
