import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import useFormat from "../../../hooks/useFormat";
import { SENSOR_TYPE } from "../../../domain/sensor";

const SensorValue = ({ sensor, sensorSettings, colorOverride }) => {
  const { formatNumber, formatUnit } = useFormat();
  const theme = useTheme();

  const isThresholdReached = sensor.pct <= sensor.threshold;

  const getBackgroundColor = () => {
    if (colorOverride) {
      return colorOverride;
    }

    if (isThresholdReached) {
      return sensor.isInactive
        ? theme.palette.warning.main
        : theme.palette.error.main;
    }

    return sensor.isInactive
      ? theme.palette.grey.A100
      : theme.palette.success.main;
  };

  const getTextValue = () => {
    if (sensor.type === SENSOR_TYPE.PROBE && sensorSettings.showPercentages) {
      return formatNumber(sensor.pct) + " %";
    }

    let value = formatNumber(sensor.value);

    if (sensor.unit && sensorSettings.showUnits) {
      value += " " + formatUnit(sensor.unit);
    }

    return value;
  };

  const value = getTextValue();

  if (sensor.type === SENSOR_TYPE.COUNTER) {
    return <Box align="center">{value}</Box>;
  }

  const borderStyle = sensorSettings.showVisuals
    ? `1px solid ${getBackgroundColor()}`
    : "none";

  return (
    <Box
      border={borderStyle}
      borderRadius={2}
      width="100%"
      position="relative"
      height={19}
    >
      {sensorSettings.showVisuals && (
        <Box
          position="absolute"
          width={sensor.pct + "%"}
          top={0}
          bottom={0}
          bgcolor={getBackgroundColor()}
        ></Box>
      )}
      <Box
        position="absolute"
        whiteSpace="nowrap"
        textAlign="right"
        width="100%"
        top={1}
        paddingRight={1}
        bottom={0}
      >
        {getTextValue()}
      </Box>
    </Box>
  );
};

export default SensorValue;
