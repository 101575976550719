import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const IotSendInterval = React.memo(() => {
  const options = [60, 120, 360, 480];

  return (
    <BubbleHelpFieldWrapper id="devices.edit.iotSendInterval">
      <FullWidthSelect
        fastField={false}
        variant="outlined"
        required
        label={
          <I18nText>devices.settings.configuration.iotSendInterval</I18nText>
        }
        name="iotSendInterval"
        inputProps={{
          id: "iot-send-interval-select"
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <I18nText>{`devices.settings.configuration.iotSendIntervalOptions.${option}`}</I18nText>
          </MenuItem>
        ))}
      </FullWidthSelect>
    </BubbleHelpFieldWrapper>
  );
});

export default IotSendInterval;
