import { format as dateFormat } from "date-fns";

export const FORMAT_DATE = "DATE";
export const FORMAT_TIME = "TIME";
export const FORMAT_DATETIME = "DATETIME";
export const FORMAT_DATETIME_LONG = "DATETIME_LONG";

export const formatFlag = isoCode => {
  if (!isoCode) {
    return;
  }

  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const formatDate = locale => (date, format = FORMAT_DATE) => {
  const formats = {
    de: {
      [FORMAT_DATE]: "dd.MM.yyyy",
      [FORMAT_TIME]: "HH:mm",
      [FORMAT_DATETIME]: "dd.MM.yyyy HH:mm",
      [FORMAT_DATETIME_LONG]: "dd.MM.yyyy HH:mm:ss"
    },
    en: {
      [FORMAT_DATE]: "MM/dd/yyyy",
      [FORMAT_TIME]: "hh:mm a",
      [FORMAT_DATETIME]: "MM/dd/yyyy hh:mm a",
      [FORMAT_DATETIME_LONG]: "MM/dd/yyyy hh:mm:ss a"
    }
  };

  return dateFormat(date, formats[locale][format]);
};

export const formatUnit = locale => (unit, fallback) => {
  if (fallback === undefined) {
    fallback = unit;
  }

  if (!unit) {
    return "";
  }

  switch (unit.toLowerCase()) {
    case "l":
      if (locale === "en") {
        return "l";
      }

      return "L";

    case "kg":
      return "kg";

    case "kwh":
      return "kWh";

    case "mwh":
      return "MWh";

    case "cbm":
      return "cbm";

    case "c":
      return "°C";

    default:
      return fallback;
  }
};

export const formatNumber = locale => (number, decimals, fallback) => {
  if (fallback === undefined) {
    fallback = "";
  }

  if (number === null || isNaN(number)) {
    return fallback;
  }

  if (decimals === undefined) {
    decimals = 0;
  }

  let sep, dec;
  switch (locale) {
    case "de":
    case "fr":
      sep = ".";
      dec = ",";

      break;

    default:
      sep = ",";
      dec = ".";
  }

  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");

  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);

  const s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }

  return s.join(dec);
};

const toFixedFix = (n, prec) => {
  const k = Math.pow(10, prec);

  return "" + (Math.round(n * k) / k).toFixed(prec);
};
