import React from "react";
import { Formik, Form } from "formik";
import { Grid, MenuItem } from "@material-ui/core";

import {
  FullWidthSelect,
  FullWidthTextField
} from "../../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../elements/Button";
import I18nText from "../../../../elements/I18nText";

const NotificationForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FullWidthTextField
                name="message"
                variant="outlined"
                label={<I18nText>system.notifications.common.message</I18nText>}
                multiline
                inputProps={{ rows: 4, autoComplete: "off" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FullWidthSelect
                variant="outlined"
                name="severity"
                label={
                  <I18nText>system.notifications.common.severity</I18nText>
                }
              >
                <MenuItem value="info">
                  <I18nText>system.notifications.severities.info</I18nText>
                </MenuItem>
                <MenuItem value="minor">
                  <I18nText>system.notifications.severities.minor</I18nText>
                </MenuItem>
                <MenuItem value="significant">
                  <I18nText>
                    system.notifications.severities.significant
                  </I18nText>
                </MenuItem>
                <MenuItem value="critical">
                  <I18nText>system.notifications.severities.critical</I18nText>
                </MenuItem>
              </FullWidthSelect>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationForm;
