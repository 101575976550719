import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { formatRFC3339, startOfDay, endOfDay } from "date-fns";

import Legend from "./Legend";
import Table from "./Table";
import Chart from "./Chart";
import Consumption from "./Consumption";
import { useApi } from "../../../api";
import { useObjectConfig } from "../../../objectConfig";
import config from "../../../config";
import { useDevice } from "../../../domain/device";
import { SENSOR_TYPE } from "../../../domain/sensor";
import { useLocale } from "../../../i18n";

const ConsumptionContainer = () => {
  const { locale } = useLocale();
  const api = useApi();
  const { t } = useTranslation();
  const { set: setConfig } = useObjectConfig();
  const theme = useTheme();

  const [exportConfig, setExportConfig] = useState({
    customRange: false,
    from: null,
    to: null
  });

  const { device, deviceConfig } = useDevice();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isExporting, setExporting] = useState(false);
  const [excludedSensorIds, setExcludedSensorIds] = useState(
    deviceConfig["ui.consumption.excludedSensors"]
  );
  const [excludedYears, setExcludedYears] = useState(
    deviceConfig["ui.consumption.excludedYears"]
  );
  const [years, setYears] = useState([]);
  const [connectedSensors, setConnectedSensors] = useState([]);

  const shortMonthNames = [...Array(13).keys()]
    .slice(1)
    .map(idx => t(`date.months.short.${idx}`));

  const handleToggleSensor = id => () => {
    let newSet = [...excludedSensorIds];
    const isExcluded = newSet.indexOf(id) !== -1;

    if (!isExcluded) {
      newSet.push(id);
    } else {
      newSet = newSet.filter(item => item !== id);
    }

    setExcludedSensorIds(newSet);

    setConfig("device", device.id, "ui.consumption.excludedSensors", newSet);
  };

  const handleToggleYear = year => () => {
    let newSet = [...excludedYears];
    const isExcluded = newSet.indexOf(year) !== -1;

    if (!isExcluded) {
      newSet.push(year);
    } else {
      newSet = newSet.filter(item => item !== year);
    }

    setExcludedYears(newSet);

    setConfig("device", device.id, "ui.consumption.excludedYears", newSet);
  };

  const extractYearNames = data => {
    return [...new Set(data.map(item => item.year))].sort((a, b) => b - a);
  };

  const transformData = raw => {
    const mapByYear = {};

    raw.forEach(item => {
      if (mapByYear[item.year] === undefined) {
        mapByYear[item.year] = [...new Array(12)].map(x => null);
      }

      const idx = item.month - 1;

      mapByYear[item.year][idx] = item.data.reduce((accumulated, current) => {
        if (excludedSensorIds.indexOf(current.sensorId) !== -1) {
          // Sensor is excluded
          return accumulated;
        }

        return accumulated + current.consumption;
      }, 0);
    });

    const byYear = [];
    for (let year in mapByYear) {
      if (excludedYears.indexOf(parseInt(year, 10)) !== -1) {
        // Year is excluded
        continue;
      }

      byYear.push({
        name: year,
        data: mapByYear[year],
        animation: false,
        color: theme.custom.colors.years[year]
      });
    }

    byYear.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      }

      if (a.name < b.name) {
        return 1;
      }

      return 0;
    });

    return byYear;
  };

  useEffect(() => {
    setExcludedSensorIds(deviceConfig["ui.consumption.excludedSensors"]);
    setExcludedYears(deviceConfig["ui.consumption.excludedYears"]);
  }, [deviceConfig]);

  useEffect(() => {
    const connectedSensors = device.sensors.filter(
      sensor => sensor.type === SENSOR_TYPE.PROBE && sensor.isConnected
    );

    setConnectedSensors(connectedSensors);

    setLoading(true);
    api.get(`/v2/devices/${device.id}/consumption`).then(response => {
      setData(response.data);
      setYears(extractYearNames(response.data));
      setLoading(false);
    });
  }, [api, device.id, device.sensors]);

  const handleExport = (type = "simple") => {
    setExporting(type);
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setExporting(false);
      }
    }, 1000);

    const exportType =
      type === "extended" ? "extended_consumption" : "simple_consumption";
    const fileFormat = type === "extended" ? "xlsx" : "csv";

    let query = `export_type=${exportType}&file_format=${fileFormat}&device_id=${device.id}`;

    if (locale) {
      query += `&locale=${locale}`;
    }

    if (exportConfig.customRange) {
      if (exportConfig.from) {
        const formattedFrom = encodeURIComponent(
          formatRFC3339(startOfDay(exportConfig.from))
        );

        query += `&from=${formattedFrom}`;
      }

      if (exportConfig.to) {
        const formattedTo = encodeURIComponent(
          formatRFC3339(endOfDay(exportConfig.to))
        );

        query += `&to=${formattedTo}`;
      }
    }

    const location = `${config.api.baseUrl}/v2/devices/export/?${query}`;
    window.location.href = location;
  };

  const updateExportConfig = (key, value) => {
    setExportConfig(state => ({ ...state, [key]: value }));
  };

  const transformedData = transformData(data);
  const noData = !isLoading && transformedData.length === 0;

  return (
    <Consumption
      noData={noData}
      isLoading={isLoading}
      onExport={handleExport}
      isExporting={isExporting}
      exportConfig={exportConfig}
      updateExportConfig={updateExportConfig}
      ChartComponent={
        <Chart data={transformedData} monthNames={shortMonthNames} />
      }
      TableComponent={
        <Table data={transformedData} monthNames={shortMonthNames} />
      }
      LegendComponent={
        <Legend
          years={years}
          connectedSensors={connectedSensors}
          excludedSensorIds={excludedSensorIds}
          excludedYears={excludedYears}
          onToggleSensor={handleToggleSensor}
          onToggleYear={handleToggleYear}
        />
      }
    />
  );
};

export default ConsumptionContainer;
