import { RECEIVED_TOKEN, RESET_TOKEN } from "../actions/csrf";

const initialState = {
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_TOKEN:
      return {
        ...state,
        token: action.token
      };

    case RESET_TOKEN:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
