import React from "react";
import { Grid } from "@material-ui/core";

import { useAuth, ROLES } from "../../../../../../auth";
import { useDevice } from "../../../../../../domain/device";
import SectionSubTitle from "../../../../../../elements/SectionSubTitle";
import SmsHeader from "./SmsHeader";
import ValueInterval from "./ValueInterval";
import TimeInterval from "./TimeInterval";
import ThresholdSensor from "./ThresholdSensor";
import ThresholdInterval from "./ThresholdInterval";
import I18nText from "../../../../../../elements/I18nText";
import { DEVICE_TYPE } from "../../../../../../domain/device";
import Watchdog from "./Watchdog";
import FillingRecognitionInterval from "./FillingRecognitionInterval";

const Reporting = () => {
  const { user, hasRole } = useAuth();
  const { device } = useDevice();

  if (device.type === DEVICE_TYPE.NET) {
    return (
      <Grid container>
        <Grid item xs={12} md={9} lg={6}>
          <ThresholdSensor />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          {device.type === DEVICE_TYPE.SMS && (
            <Grid item xs={12}>
              <SmsHeader />
            </Grid>
          )}
          {device.type !== DEVICE_TYPE.IOT && (
            <>
              <Grid item xs={12}>
                <SectionSubTitle>
                  <I18nText>
                    devices.settings.configuration.sections.timeBasedNotifications
                  </I18nText>
                </SectionSubTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <ValueInterval />
                  </Grid>
                  <Grid item xs={12} sm={1} />
                  <Grid item xs={12} sm={6}>
                    <TimeInterval />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <SectionSubTitle>
              <I18nText>
                devices.settings.configuration.sections.thresholdNotifications
              </I18nText>
            </SectionSubTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ThresholdSensor />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ThresholdInterval />
              </Grid>
            </Grid>
          </Grid>

          {device.type === DEVICE_TYPE.LX_IOT && (
            <Grid item xs={12}>
              <SectionSubTitle>
                <I18nText>
                  devices.settings.configuration.sections.fillingRecognition
                </I18nText>
              </SectionSubTitle>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FillingRecognitionInterval />
                </Grid>
              </Grid>
            </Grid>
          )}

          {hasRole(user, ROLES.MASTER) && device.type === DEVICE_TYPE.SMS && (
            <Grid item xs={12}>
              <SectionSubTitle>
                <I18nText>Watchdog</I18nText>
              </SectionSubTitle>
              <Watchdog />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reporting;
