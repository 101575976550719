import React from "react";
import { Checkbox } from "@material-ui/core";

const IsConnected = ({ prefix, isConnected, setFieldValue }) => (
  <Checkbox
    checked={isConnected}
    onChange={e => setFieldValue(`${prefix}.isConnected`, e.target.checked)}
    value={isConnected}
    color="primary"
  />
);

export default IsConnected;
