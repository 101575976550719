import React from "react";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { IconButton, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    fontSize: 20
  }
});

const InfinityPagination = ({ page, showNextPage, onSelectPage }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton disabled={page === 1} onClick={() => onSelectPage(1)}>
        <FirstPageIcon className={classes.icon} />
      </IconButton>

      <IconButton disabled={page === 1} onClick={() => onSelectPage(page - 1)}>
        <KeyboardArrowLeftIcon className={classes.icon} />
      </IconButton>
      <Box display="flex" marginX={2} alignItems="center" fontSize={14}>
        {page}
      </Box>
      <IconButton
        disabled={!showNextPage}
        onClick={() => onSelectPage(page + 1)}
      >
        <KeyboardArrowRightIcon className={classes.icon} />
      </IconButton>
    </>
  );
};

export default InfinityPagination;
