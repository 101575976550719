import React from "react";
import CommentForm from "./CommentForm";

import { useApi } from "../../../../../api";
import { setFieldErrorsFromResponse } from "../../../../../utils/formErrors";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const CommentContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    api
      .put(`/v2/devices/${device.id}/comment`, values)
      .then(() => {
        enqueueSnackbar("devices.settings.metadata.responses.commentUpdated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    content: device.comment
  };

  return (
    <CommentForm values={values} onClose={onClose} onSubmit={handleSubmit} />
  );
};

export default CommentContainer;
