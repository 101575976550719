import React from "react";
import MuiPagination from "@material-ui/lab/Pagination";

const Pagination = ({
  page,
  totalCount,
  perPage,
  siblingCount = 2,
  onSelectPage
}) => {
  const pages = Math.ceil(totalCount / perPage);

  return (
    <MuiPagination
      count={pages}
      page={page}
      siblingCount={siblingCount}
      onChange={(_, value) => onSelectPage(value)}
    />
  );
};

export default Pagination;
