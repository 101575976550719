import React from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";

import Layout from "./Layout";
import { PermissionProtectedRoute } from "../ProtectedRoute";
import Status from "./Status";
import Process from "./Process";
import Consumption from "./Consumption";
import Settings from "./Settings";
import { useDevice } from "../../domain/device";
import Messages from "../Messages";
import { useAuth, PERMISSIONS } from "../../auth";

const DeviceContainer = () => {
  const { url } = useRouteMatch();
  const { device } = useDevice();
  const { hasPermission } = useAuth();

  return (
    <PermissionProtectedRoute
      requiredPermission={PERMISSIONS.VIEW_STATUS}
      myPermissions={device.permissions}
      redirectOnDeny="/devices"
    >
      <Switch>
        <Route path={url} strict exact>
          <Layout device={device} subPage="status">
            <Status device={device} />
          </Layout>
        </Route>
        <Route path={`${url}/process`} exact>
          <PermissionProtectedRoute
            requiredPermission={PERMISSIONS.VIEW_PROCESS}
            myPermissions={device.permissions}
            redirectOnDeny={url}
          >
            <Layout device={device} subPage="process">
              <Process />
            </Layout>
          </PermissionProtectedRoute>
        </Route>
        <Route path={`${url}/consumption`} exact>
          <PermissionProtectedRoute
            requiredPermission={PERMISSIONS.VIEW_CONSUMPTION}
            myPermissions={device.permissions}
            redirectOnDeny={url}
          >
            <Layout device={device} subPage="consumption">
              <Consumption />
            </Layout>
          </PermissionProtectedRoute>
        </Route>
        <Route path={`${url}/settings`}>
          <PermissionProtectedRoute
            requiredPermission={PERMISSIONS.VIEW_CONFIGURATION}
            myPermissions={device.permissions}
            redirectOnDeny={url}
          >
            <Layout device={device} subPage="settings">
              <Settings />
            </Layout>
          </PermissionProtectedRoute>
        </Route>
        <Route path={`${url}/messages`}>
          <PermissionProtectedRoute
            requiredPermission={PERMISSIONS.VIEW_MESSAGING}
            myPermissions={device.permissions}
            redirectOnDeny={url}
          >
            <Layout device={device} subPage="messages">
              <Messages
                device={device}
                allowedToSend={hasPermission(
                  device.permissions,
                  PERMISSIONS.CONFIGURATION
                )}
              />
            </Layout>
          </PermissionProtectedRoute>
        </Route>
        <Route>
          <Redirect to={url} />
        </Route>
      </Switch>
    </PermissionProtectedRoute>
  );
};

export default DeviceContainer;
