import React from "react";
import { Formik, Form } from "formik";
import {
  Grid,
  MenuItem,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

import Section from "../../../../elements/Section";
import I18nText from "../../../../elements/I18nText";
import {
  FullWidthTextField,
  UserSelect,
  FullWidthSelect
} from "../../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../elements/Button";
import { ROLES } from "../../../../auth";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const LoginForm = ({
  values,
  password,
  roles = [],
  onClose,
  onSubmit,
  validate,
  onRegeneratePassword
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={values}
      validate={validate}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ values, submitForm, setFieldValue, errors, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Section>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="username"
                      variant="outlined"
                      label={<I18nText>users.common.username</I18nText>}
                      inputProps={{ maxLength: 96, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UserSelect
                      name="parentUser"
                      value={values.parentUser}
                      atLeastRole={ROLES.ADMIN}
                      label={<I18nText>users.common.parentUser</I18nText>}
                      hasError={errors && errors.user}
                      onUpdate={value => setFieldValue("parentUser", value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthSelect
                      variant="outlined"
                      name="role"
                      label={<I18nText>users.common.role</I18nText>}
                      inputProps={{
                        disabled: roles.length === 1
                      }}
                    >
                      {roles.map(option => (
                        <MenuItem key={option} value={option}>
                          <I18nText>{`roles.${option}`}</I18nText>
                        </MenuItem>
                      ))}
                    </FullWidthSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={e => {
                            setFieldValue("active", e.target.checked);
                          }}
                          checked={values.active}
                          color="primary"
                        />
                      }
                      label={<I18nText>users.common.active</I18nText>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={e => {
                            setFieldValue("changePassword", e.target.checked);
                          }}
                          checked={values.changePassword}
                          color="primary"
                        />
                      }
                      label={<I18nText>users.account.changePassword</I18nText>}
                    />
                  </Grid>
                  {values.changePassword && (
                    <Grid item xs={12}>
                      <TextField
                        name="password"
                        size="small"
                        classes={classes}
                        disabled
                        value={password}
                        label={<I18nText>users.common.password</I18nText>}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={onRegeneratePassword}
                              >
                                <RefreshIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      >
                        {password}
                      </TextField>
                    </Grid>
                  )}
                </Grid>
              </Section>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
