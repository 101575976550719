import React from "react";
import { Button } from "@material-ui/core";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import QueryDialog from "./QueryDialog";
import I18nText from "../../../elements/I18nText";

const QueryContainer = ({ deviceId, forceReload }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const showQueryDialog = () => {
    history.push(`${url}/send`);
  };

  const closeQueryDialog = () => {
    history.push(`/devices/${deviceId}/messages`);
  };

  return (
    <>
      <Button variant="contained" onClick={showQueryDialog}>
        <I18nText>messages.actions.query</I18nText>
      </Button>
      <Switch>
        <Route path={`${url}/send`}>
          <QueryDialog
            deviceId={deviceId}
            onClose={closeQueryDialog}
            forceReload={forceReload}
          />
        </Route>
      </Switch>
    </>
  );
};

export default QueryContainer;
