import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { MenuItem, makeStyles, TextField } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import I18nText from "../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../elements/FormField/BubbleHelpFieldWrapper";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  icon: {
    display: "flex",
    alignItems: "center"
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main
  }
}));

const useTitleStyles = makeStyles({
  root: {
    marginBottom: 0
  }
});

const Alarm = ({ status, title, onUpdateStatus }) => {
  const classes = useStyles();
  const titleClasses = useTitleStyles();

  return (
    <Alert
      severity="error"
      classes={classes}
      variant="outlined"
      icon={<ReportProblemOutlinedIcon />}
      action={
        <BubbleHelpFieldWrapper id="devices.status.alarm">
          <TextField
            label={<I18nText>devices.status.alarms.statusLabel</I18nText>}
            select
            size="small"
            style={{ minWidth: 200 }}
            variant="outlined"
            value={status}
            onChange={e => onUpdateStatus(e.target.value)}
          >
            <MenuItem value="disregard">
              <I18nText>devices.status.alarms.status.disregard</I18nText>
            </MenuItem>
            <MenuItem value="seen">
              <I18nText>devices.status.alarms.status.seen</I18nText>
            </MenuItem>
            <MenuItem value="revoked">
              <I18nText>devices.status.alarms.status.revoked</I18nText>
            </MenuItem>
          </TextField>
        </BubbleHelpFieldWrapper>
      }
    >
      <AlertTitle classes={titleClasses}>{title}</AlertTitle>
    </Alert>
  );
};

export default Alarm;
