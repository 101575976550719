import React from "react";
import { MenuItem } from "@material-ui/core";

import { FullWidthSelect } from "../../../../../../elements/FormField";
import I18nText from "../../../../../../elements/I18nText";

export const SIM_CREDITOR_NONE = "__none__";
const SIM_CREDITOR_TECSON = 1;

const SimCreditor = () => (
  <FullWidthSelect
    variant="outlined"
    required
    label={<I18nText>devices.settings.configuration.simCreditor</I18nText>}
    name="simCreditorId"
  >
    <MenuItem value={SIM_CREDITOR_NONE}>
      <I18nText>{`devices.settings.configuration.simCreditorOptions.customerSim`}</I18nText>
    </MenuItem>
    <MenuItem value={SIM_CREDITOR_TECSON}>
      <I18nText>{`devices.settings.configuration.simCreditorOptions.tecson`}</I18nText>
    </MenuItem>
  </FullWidthSelect>
);

export default SimCreditor;
