import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";

import LoadingOverlay from "../../elements/LoadingOverlay";
import Row from "./Row";
import I18nText from "../../elements/I18nText";
import NoData from "./NoData";

const Table = React.memo(
  ({
    noData,
    messages,
    isUpdating,
    sort,
    onUpdateSort,
    onSelectMessage,
    showDeviceId
  }) => {
    const sorted = by => sort.by === by;

    if (noData) {
      return <NoData />;
    }

    return (
      <>
        <LoadingOverlay isLoading={isUpdating} minHeight={200}>
          <MuiTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sorted("createdAt")}
                    direction={
                      sorted("createdAt") && sort.desc ? "desc" : "asc"
                    }
                    onClick={() => onUpdateSort("createdAt")}
                  >
                    <I18nText>messages.table.header.time</I18nText>
                  </TableSortLabel>
                </TableCell>
                {showDeviceId && (
                  <TableCell>
                    <I18nText>messages.table.header.deviceId</I18nText>
                  </TableCell>
                )}
                <TableCell>
                  <I18nText>messages.table.header.indication</I18nText>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell>
                  <I18nText>messages.table.header.content</I18nText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map(message => (
                <Row
                  key={message.id}
                  message={message}
                  showDeviceId={showDeviceId}
                  onSelectMessage={onSelectMessage}
                />
              ))}
            </TableBody>
          </MuiTable>
        </LoadingOverlay>
      </>
    );
  }
);

export default Table;
