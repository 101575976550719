import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useApi } from "../../api";

const DebtorSelect = React.memo(
  ({ value, name, label, hasError, onUpdate }) => {
    const api = useApi();
    const [options, setOptions] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      api.get("/v2/debtors").then(response => {
        setOptions(
          response.data.map(debtor => ({
            ident: debtor.ident
          }))
        );

        setLoading(false);
      });
    }, [api]);

    return (
      <Autocomplete
        id="debtor-select"
        variant="outlined"
        name={name}
        disableClearable={false}
        clearOnBlur={false}
        disabled={isLoading}
        autoSelect={false}
        value={value}
        options={options}
        freeSolo
        onChange={(_, value) => {
          if (typeof value === "string") {
            onUpdate({ ident: value });
          } else if (value && value.ident) {
            onUpdate(value);
          } else {
            onUpdate(value);
          }
        }}
        filterOptions={options => {
          return options.filter(
            option => option.ident.indexOf(value.ident) !== -1
          );
        }}
        onInputChange={(_, value) => {
          if (typeof value === "string") {
            onUpdate({ ident: value });
          } else if (value && value.ident) {
            onUpdate(value);
          }
        }}
        autoHighlight
        getOptionLabel={option => option.ident}
        getOptionSelected={(option, value) => option.ident === value.ident}
        renderInput={params => (
          <TextField
            error={hasError}
            {...params}
            label={label}
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off"
            }}
          />
        )}
      />
    );
  }
);

export default DebtorSelect;
