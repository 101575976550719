import React, { useEffect, useState } from "react";

import { useApi } from "../api";
import { useAuth } from "../auth";
import { useWhitelabel } from "../domain/whitelabel";
import DeviceIdsContext from "./DeviceIdsContext";

const DeviceIdsProvider = ({ children }) => {
  const api = useApi();
  const [reload, setReload] = useState(true);
  const { user } = useAuth();
  const [deviceIds, setDeviceIds] = useState([]);
  const { whitelabelConfig, whitelabelConfigLoaded } = useWhitelabel();

  const doReload = () => setReload(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    setReload(true);
  }, [user]);

  // TODO Reload when groupByUser changes in Device List Table Config
  useEffect(() => {
    if (!whitelabelConfigLoaded) {
      return;
    }

    if (!reload) {
      return;
    }

    setReload(false);

    const sort = {
      by: whitelabelConfig["ui.devices.sort.by"],
      desc: whitelabelConfig["ui.devices.sort.desc"]
    };

    let query = `sort=${sort.by}:${sort.desc ? "desc" : "asc"}`;

    if (whitelabelConfig["ui.devices.groupByUser"]) {
      query += "&grouped=1";
    }

    api
      .get(`/v2/devices/ids?${query}`)
      .then(response => setDeviceIds(response.data));
  }, [reload, user, api, whitelabelConfig, whitelabelConfigLoaded]);

  const contextValue = { deviceIds, reload: doReload };

  return (
    <DeviceIdsContext.Provider value={contextValue}>
      {children}
    </DeviceIdsContext.Provider>
  );
};

export default DeviceIdsProvider;
