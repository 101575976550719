import React from "react";
import { useTheme } from "@material-ui/core";

import Chart from "./Chart";
import { SENSOR_TYPE } from "../../../../domain/sensor";

const ChartContainer = ({ device }) => {
  const theme = useTheme();

  const getColorBySequence = sequence => theme.custom.colors.sensors[sequence];
  const connectedSensors = device.sensors.filter(
    sensor => sensor.type === SENSOR_TYPE.PROBE && sensor.isConnected
  );

  const getSensorRatios = sensors => {
    const byCapacity = [...sensors];
    byCapacity.sort((a, b) => (a.capacity > b.capacity ? 1 : -1));

    const total = sensors.reduce(
      (accumulated, currentSensor) => accumulated + currentSensor.capacity,
      0
    );

    const sensorPcts = {};
    let totalLeft = total;

    for (let i = 0; i < byCapacity.length; i++) {
      let sensor = byCapacity[i];

      let pct = Math.max((sensor.capacity / totalLeft) * 100, 10);
      pct = Math.min(pct, 40);

      sensorPcts[sensor.id] = pct;

      totalLeft -= pct;
    }

    return sensorPcts;
  };

  return (
    <Chart
      sensors={connectedSensors}
      sensorRatios={getSensorRatios(connectedSensors)}
      getColorBySequence={getColorBySequence}
    />
  );
};

export default ChartContainer;
