import React from "react";

import BasedataForm from "./BasedataForm";
import { useApi } from "../../../../api";
import { setFieldErrorsFromResponse } from "../../../../utils/formErrors";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const BasedataContainer = ({ user, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      contact: { ...values.contact },
      address: { ...values.address }
    };

    api
      .put(`/v2/users/${user.id}/basedata`, params)
      .then(() => {
        enqueueSnackbar("users.responses.basedataUpdated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    address: { ...user.address },
    contact: { ...user.contact }
  };

  return (
    <BasedataForm values={values} onClose={onClose} onSubmit={handleSubmit} />
  );
};

export default BasedataContainer;
