import React, { memo } from "react";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { getDaysInMonth } from "date-fns";

import useFormat from "../../../hooks/useFormat";

const Chart = memo(({ data, monthNames }) => {
  const { formatNumber } = useFormat();
  const { t } = useTranslation();

  const options = {
    reflow: true,
    chart: {
      type: "column",
      marginTop: 20
    },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        cursor: "pointer",
        dataLabels: {
          enabled: false
        }
      }
    },
    xAxis: {
      categories: monthNames
    },
    yAxis: {
      title: {
        text: t("devices.consumption.chart.yLegend"),
        rotation: 0,
        align: "high",
        y: -10,
        offset: 0
      },
      labels: {
        formatter: label => formatNumber(label.value)
      }
    },
    series: data,
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function() {
        function compare(a, b) {
          return a.key > b.key ? -1 : 1;
        }

        this.points.sort(compare);

        const rows = [];
        this.points.forEach(value => {
          let year = parseInt(value.series.name, 10);
          let month = parseInt(value.point.index, 10) + 1;

          let daysInMonth = getDaysInMonth(new Date(year, month, 0));

          // This month? Daily consumption only until the current day
          let now = new Date();
          if (now.getFullYear() === year && now.getMonth() + 1 === month) {
            daysInMonth = now.getDate();
          }

          let dailyConsumption = isNaN(daysInMonth)
            ? "-"
            : formatNumber(value.point.y / daysInMonth, 0);

          rows.push(`
            <tr>
              <td style="padding: 0.2rem 0.3rem; font-size: 0.8rem; color: ${value.series.color}">
                ${value.series.name}
              </td>
              <td style="padding: 0.2rem 0.3rem; font-size: 0.8rem; text-align: right; font-weight: bold">
                ${dailyConsumption}
              </td>
            </tr>`);
        });

        return `
          <table style="margin: 0; padding:0; border: none">
            <tr>
              <th width="50px" style="padding: 0.2rem 0.3rem; font-size: 0.8rem;">${
                this.x
              }</th>
              <th width="125px" style="padding: 0.2rem 0.3rem; font-size: 0.8rem;">
                ${t("devices.consumption.chart.tooltip.title")}
              </th>
            </tr>
            ${rows.join("")}
          </table>`;
      }
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { height: "300px" } }}
    />
  );
});

export default Chart;
