import React from "react";
import QueryForm from "./QueryForm";

import { useApi } from "../../../api";
import {
  createFieldErrorMessage,
  setFieldErrorsFromResponse
} from "../../../utils/formErrors";
import { MESSAGE_TYPE } from "../../../domain/message";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const QueryFormContainer = ({ deviceId, onClose, forceReload }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const validate = values => {
    const errors = {};
    if (!values.content.length) {
      errors.content = createFieldErrorMessage("content", "required");
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      type: MESSAGE_TYPE.SMS,
      deviceId,
      content: values.content
    };

    api
      .post("/v2/messages", params)
      .then(() => {
        enqueueSnackbar("messages.responses.released", {
          variant: "success"
        });

        forceReload();
        onClose();
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    content: ""
  };

  return (
    <QueryForm
      values={values}
      onClose={onClose}
      validator={validate}
      onSubmit={handleSubmit}
    />
  );
};

export default QueryFormContainer;
