import React from "react";
import { Box } from "@material-ui/core";

const SensorName = ({ name }) => (
  <Box fontSize={11} align="center">
    {name}
  </Box>
);

export default SensorName;
