import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { useDevice } from "../../../../domain/device";
import { useApi } from "../../../../api";
import List from "./List";
import DeleteContainer from "./DeleteContainer";
import EditRecipient from "./Edit";
import CreateRecipient from "./Create";
import { REGEXP_ID } from "../../../../utils/stringUtils";

const NotificationsContainer = () => {
  const { device } = useDevice();
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [recipients, setRecipients] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteRecipient, setConfirmDeleteRecipient] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/notification-recipients`)
      .then(response => {
        setRecipients(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, device, reload]);

  const handleConfirmDelete = recipient => {
    setConfirmDeleteRecipient(recipient);
  };

  const handleEditRecipient = recipient => {
    history.push(`${url}/${recipient.id}`);
  };

  const handleCreateRecipient = () => history.push(`${url}/new`);

  const handleClose = () => {
    setReload(true);
    history.push(url);
  };

  useEffect(() => {
    if (!device) {
      return;
    }

    setReload(true);
  }, [device]);

  return (
    <>
      <Switch>
        <Route path={`${url}/new`}>
          <CreateRecipient device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/:recipientId(${REGEXP_ID})`}>
          {({
            match: {
              params: { recipientId }
            }
          }) => (
            <EditRecipient
              device={device}
              recipientId={recipientId}
              onClose={handleClose}
            />
          )}
        </Route>
      </Switch>
      {confirmDeleteRecipient !== null && (
        <DeleteContainer
          deviceId={device.id}
          recipientId={confirmDeleteRecipient.id}
          onCancel={() => setConfirmDeleteRecipient(null)}
          onDeleted={() => {
            setReload(true);
            setConfirmDeleteRecipient(null);
          }}
        />
      )}
      <List
        isLoading={isLoading}
        recipients={recipients}
        onCreateRecipient={handleCreateRecipient}
        onEditRecipient={handleEditRecipient}
        onDeleteRecipient={handleConfirmDelete}
      />
    </>
  );
};

export default NotificationsContainer;
