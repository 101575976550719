import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

import useDebounce from "../../hooks/useDebounce";

const Filter = ({ onSetFilterTerm }) => {
  const [filterTerm, setFilterTerm] = useState("");
  const debouncedFilterTerm = useDebounce(filterTerm, 300);

  useEffect(() => {
    onSetFilterTerm(debouncedFilterTerm);
  }, [debouncedFilterTerm, onSetFilterTerm]);

  return (
    <TextField
      id="filter"
      label="Filter"
      type="search"
      size="small"
      variant="outlined"
      onChange={e => setFilterTerm(e.target.value)}
    />
  );
};

export default Filter;
