import React from "react";
import { Formik, Form, FastField } from "formik";
import { Grid } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";

import I18nText from "../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../elements/FormField/BubbleHelpFieldWrapper";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";

const PublicUrlForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BubbleHelpFieldWrapper id="devices.edit.publicUrlAccessEnabled">
                <FastField
                  component={CheckboxWithLabel}
                  name="publicUrlAccessEnabled"
                  type="checkbox"
                  color="primary"
                  Label={{
                    label: (
                      <I18nText>
                        devices.settings.configuration.publicUrlAccessEnabled
                      </I18nText>
                    )
                  }}
                />
              </BubbleHelpFieldWrapper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PublicUrlForm;
