import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";

import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import DescriptionList from "../../../elements/DescriptionList";
import I18nText from "../../../elements/I18nText";
import { nl2br } from "../../../utils/stringUtils";
import Comment from "./Comment";
import Basedata from "./BaseData";
import Login from "./Login";
import { DeleteButton } from "../../../elements/Button";

const Account = ({ listOptions, user, onDelete, onReload }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleClose = () => {
    history.push(url);

    onReload();
  };

  const editComment = () => history.push(`/users/${user.id}/account/comment`);
  const editBasedata = () => history.push(`/users/${user.id}/account/basedata`);
  const editLogin = () => history.push(`/users/${user.id}/account/login`);

  return (
    <>
      <Switch>
        <Route path={`${url}/comment`}>
          <Comment user={user} onClose={handleClose} />
        </Route>
        <Route path={`${url}/login`}>
          <Login user={user} onClose={handleClose} />
        </Route>
        <Route path={`${url}/basedata`}>
          <Basedata user={user} onClose={handleClose} />
        </Route>
      </Switch>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Section>
              <SectionHeader onEdit={editLogin}>
                <I18nText>users.account.sections.login</I18nText>
              </SectionHeader>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DescriptionList options={listOptions.login} />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          {listOptions.api.length > 0 && (
            <Grid item xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>users.account.sections.api</I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DescriptionList options={listOptions.api} />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader onEdit={editBasedata}>
              <I18nText>users.account.sections.basedata</I18nText>
            </SectionHeader>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DescriptionList options={listOptions.basedata} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader onEdit={editComment}>
              <I18nText>users.account.sections.comment</I18nText>
            </SectionHeader>
            {nl2br(user.comment)}
          </Section>
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader />
            <Box display="flex" justifyContent="flex-end">
              <DeleteButton onClick={onDelete}></DeleteButton>
            </Box>
          </Section>
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
