import React from "react";

import DescriptionList from "../../../../elements/DescriptionList";
import I18nText from "../../../../elements/I18nText";
import Section from "../../../../elements/Section";
import SectionHeader from "../../../../elements/SectionHeader";

const DebtorView = ({ device, onEditDebtor }) => {
  const options = [
    {
      label: "devices.settings.billing.debtorIdent",
      value: device.debtor ? (
        device.debtor.ident
      ) : (
        <I18nText>devices.settings.billing.noDebtor</I18nText>
      )
    }
  ];

  return (
    <Section>
      <SectionHeader onEdit={onEditDebtor}>
        <I18nText>devices.settings.billing.sections.debtor</I18nText>
      </SectionHeader>
      <DescriptionList options={options} />
    </Section>
  );
};

export default DebtorView;
