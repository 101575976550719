import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../elements/FormField";
import { TIMES_OF_INVOICING } from "../../../../../domain/billingTerms";

const TimeOfInvoicingField = () => {
  return (
    <FullWidthSelect
      variant="outlined"
      name="timeOfInvoicing"
      label={<I18nText>billing.common.timeOfInvoicing</I18nText>}
    >
      <MenuItem value={TIMES_OF_INVOICING.BEGIN}>
        <I18nText>{`billing.timesOfInvoicing.${TIMES_OF_INVOICING.BEGIN}`}</I18nText>
      </MenuItem>
      <MenuItem value={TIMES_OF_INVOICING.MID}>
        <I18nText>{`billing.timesOfInvoicing.${TIMES_OF_INVOICING.MID}`}</I18nText>
      </MenuItem>
      <MenuItem value={TIMES_OF_INVOICING.END}>
        <I18nText>{`billing.timesOfInvoicing.${TIMES_OF_INVOICING.END}`}</I18nText>
      </MenuItem>
    </FullWidthSelect>
  );
};

export default TimeOfInvoicingField;
