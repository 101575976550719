import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";

const SimType = () => {
  const options = ["contract", "prepaid"];

  return (
    <FullWidthSelect
      variant="outlined"
      label={<I18nText>devices.settings.configuration.simType</I18nText>}
      name="simType"
      inputProps={{
        disabled: options.length === 1
      }}
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {options.map(option => (
        <MenuItem key={option} value={option}>
          <I18nText>{`devices.settings.configuration.simTypeOptions.${option}`}</I18nText>
        </MenuItem>
      ))}
    </FullWidthSelect>
  );
};

export default SimType;
