import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachmentIcon from "@material-ui/icons/Attachment";

import I18nText from "../../../elements/I18nText";

const useListStyles = makeStyles({
  padding: {
    paddingTop: 0
  }
});

const useListItemStyles = makeStyles(theme => ({
  root: {
    height: 60,
    "&$selected": {
      backgroundColor: theme.palette.common.white
    },
    "&$button:hover": {
      backgroundColor: "#e5e5e5"
    }
  },
  selected: {},
  button: {}
}));

const useListItemTextStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.text.main,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const useListItemIconStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 0
    }
  }
}));

const Menu = ({ subPage }) => {
  const [selectedItem, selectItem] = useState(subPage);
  const classes = {
    list: useListStyles(),
    listItem: useListItemStyles(),
    listItemText: useListItemTextStyles(),
    listItemIcon: useListItemIconStyles()
  };

  return (
    <>
      <List classes={classes.list}>
        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "notifications"}
          onClick={() => selectItem("notifications")}
          component={Link}
          to="/system/notifications"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>system.navigation.notifications</I18nText>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "billing"}
          onClick={() => selectItem("billing")}
          component={Link}
          to="/system/billing"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>system.navigation.billing</I18nText>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "unassigned-devices"}
          onClick={() => selectItem("unassigned-devices")}
          component={Link}
          to="/system/unassigned-devices"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <AttachmentIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>system.navigation.unassignedDevices</I18nText>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default Menu;
