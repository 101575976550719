import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../elements/DialogTitleWithCloseIcon";

import FixedPositionDialog from "../../elements/FixedPositionDialog";
import I18nText from "../../elements/I18nText";
import WhitelabeledImprint from "../Whitelabeling/WhitelabeledImprint";

const Imprint = ({ onClose }) => (
  <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="sm">
    <DialogTitleWithCloseIcon onClose={onClose}>
      <I18nText>imprint.title</I18nText>
    </DialogTitleWithCloseIcon>
    <DialogContent>
      <WhitelabeledImprint />
    </DialogContent>
  </FixedPositionDialog>
);

export default Imprint;
