import React from "react";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthTextField } from "../../../../../../elements/FormField";

const HardwareVersion = React.memo(() => (
  <FullWidthTextField
    name="hardwareVersion"
    variant="outlined"
    label={<I18nText>devices.settings.configuration.hardwareVersion</I18nText>}
    inputProps={{ maxLength: 40, autoComplete: "off" }}
  />
));

export default HardwareVersion;
