import React, { useState, useEffect, useMemo } from "react";
import { Divider, MenuItem } from "@material-ui/core";

import { useApi } from "../../api";
import { useTranslation } from "../../i18n";
import useFormat from "../../hooks/useFormat";
import FullWidthSelect from "./FullWidthSelect";

const DIVIDER_IDENTIFIER = "__DIVIDER__";

const CountrySelect = React.memo(({ name, label, required }) => {
  const api = useApi();
  const { t } = useTranslation();
  const { formatFlag } = useFormat();
  const [countryCodes, setCountryCodes] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const preferredChoices = useMemo(
    () => ["DE", "CH", "AT", "FR", "NL", "LU", "BE"].reverse(),
    []
  );

  useEffect(() => {
    api.get("/v2/countries").then(response => {
      setCountryCodes(response.data);
      setLoading(false);
    });
  }, [api]);

  useEffect(() => {
    const countries = countryCodes.map(code => ({
      code,
      name: t(`countries.${code}`)
    }));

    countries.sort((a, b) => {
      return a.name < b.name;
    });

    countries.unshift(DIVIDER_IDENTIFIER);

    const prefs = [...preferredChoices];
    prefs.forEach(choice => {
      const idx = countries.findIndex(value => value.code === choice);

      if (idx !== -1) {
        countries.splice(idx, 1);
      }

      countries.unshift({
        code: choice,
        name: t(`countries.${choice}`)
      });
    });

    if (countries.length > 0 && countries[0] === DIVIDER_IDENTIFIER) {
      countries.shift();
    }

    setOptions(countries);
  }, [countryCodes, preferredChoices, t]);

  return (
    <FullWidthSelect
      fastField={false}
      variant="outlined"
      label={label}
      name={name}
      required={required}
      inputProps={{
        id: "country-select"
      }}
    >
      {!isLoading &&
        options.map(option =>
          option === DIVIDER_IDENTIFIER ? (
            <Divider key={DIVIDER_IDENTIFIER} />
          ) : (
            <MenuItem key={option.code} value={option.code}>
              {formatFlag(option.code)} {option.name}
            </MenuItem>
          )
        )}
    </FullWidthSelect>
  );
});

export default CountrySelect;
