import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import I18nText from "../../../elements/I18nText";
import FormattedDate from "../../../elements/FormattedDate";
import LoadingOverlay from "../../../elements/LoadingOverlay";
import { FORMAT_DATETIME_LONG } from "../../../utils/formatting";

const LoginHistory = React.memo(({ logins, isLoading }) => {
  return (
    <>
      <LoadingOverlay isLoading={isLoading} minHeight={200}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <I18nText>users.loginHistory.loggedInAt</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>users.loginHistory.ipAddress</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>users.loginHistory.whitelabel</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>users.loginHistory.result</I18nText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logins.map(login => (
              <TableRow key={login.id}>
                <TableCell>
                  <FormattedDate format={FORMAT_DATETIME_LONG}>
                    {login.loggedInAt}
                  </FormattedDate>
                </TableCell>
                <TableCell>{login.ipAddress}</TableCell>
                <TableCell>{login.whitelabel}</TableCell>
                <TableCell>{login.result}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </LoadingOverlay>
    </>
  );
});

export default LoginHistory;
