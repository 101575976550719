import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { useApi } from "../../../../api";
import List from "./List";
import { REGEXP_ID } from "../../../../utils/stringUtils";
import Invoice from "./Invoice";
import { format } from "date-fns";

const InvoicesContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [year, setYear] = useState(format(new Date(), "yyyy"));
  const [groupedInvoices, setGroupedInvoices] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get(`/v2/invoices?year=${year}`)
      .then(response => {
        const grouped = [];
        response.data.forEach(invoice => {
          const key = invoice.billedAt;
          let group = grouped.find(elem => elem.key === key);

          if (group === undefined) {
            group = {
              key,
              items: []
            };

            grouped.push(group);
          }

          group.items.push(invoice);
        });

        setGroupedInvoices(grouped);

        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, reload, year]);

  const handleSelect = invoice => {
    history.push(`${url}/invoices/${invoice.id}`);
  };

  const handleSelectYear = year => {
    setYear(year);
    setReload(true);
  };

  const handleClose = () => {
    setReload(true);
    history.push(url);
  };

  return (
    <>
      <Switch>
        <Route path={`${url}/invoices/:invoiceId(${REGEXP_ID})`}>
          {({
            match: {
              params: { invoiceId }
            }
          }) => (
            <Invoice
              invoiceId={invoiceId}
              onClose={handleClose}
              onUpdate={() => setReload(true)}
            />
          )}
        </Route>
      </Switch>

      <List
        isLoading={isLoading}
        groupedInvoices={groupedInvoices}
        onSelect={handleSelect}
        year={year}
        onSelectYear={handleSelectYear}
      />
    </>
  );
};

export default InvoicesContainer;
