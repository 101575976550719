import React from "react";
import { Table as MuiTable, TableBody } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import LoadingOverlay from "../../elements/LoadingOverlay";
import Row from "./Row";
import UserHeaderRow from "./UserHeaderRow";
import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";

const Table = React.memo(
  ({
    devices,
    sort,
    onUpdateSort,
    groupByUser,
    isUpdating,
    tableColumns,
    sensorSettings,
    onTogglePercentages
  }) => {
    const history = useHistory();

    const handleSelectDevice = deviceId => {
      history.push(`/devices/${deviceId}`);
    };

    const showColumn = column => tableColumns.indexOf(column) !== -1;

    const rows = [];
    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];

      if (groupByUser) {
        if (i === 0 || device.user.id !== devices[i - 1].user.id) {
          const idx = rows.findIndex(
            row => row.type === "user" && row.id === device.user.id
          );

          if (idx === -1) {
            rows.push({
              type: "user",
              id: device.user.id,
              node: (
                <UserHeaderRow
                  colSpan={tableColumns.length}
                  key={`user_${device.user.id}`}
                  user={device.user}
                />
              )
            });
          }
        }
      }

      rows.push({
        type: "device",
        id: device.id,
        node: (
          <Row
            key={device.id}
            device={device}
            onSelect={handleSelectDevice}
            showColumn={showColumn}
            sensorSettings={sensorSettings}
          />
        )
      });
    }

    return (
      <>
        <LoadingOverlay isLoading={isUpdating} minHeight={200}>
          <MuiTable size="small">
            <TableHeader
              sort={sort}
              onUpdateSort={onUpdateSort}
              tableColumns={tableColumns}
              showPercentages={sensorSettings.showPercentages}
              onTogglePercentages={onTogglePercentages}
            />
            <TableBody>{rows.map(row => row.node)}</TableBody>
            <TableFooter columnCount={tableColumns.length} devices={devices} />
          </MuiTable>
        </LoadingOverlay>
      </>
    );
  }
);

export default Table;
