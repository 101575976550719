import { createFieldErrorMessage } from "./formErrors";

export const numberValidator = ({
  required = false,
  min = null,
  max = null
}) => value => {
  if (!value) {
    return required
      ? createFieldErrorMessage("default", "required")
      : undefined;
  }

  // Check for whole number
  if (typeof value !== "number" || value % 1 !== 0) {
    return "notAnInteger";
  }

  // Check for min and max
  if (min !== null && value < min) {
    return createFieldErrorMessage("default", "min", { min });
  }

  if (max !== null && value > max) {
    return createFieldErrorMessage("default", "max", { max });
  }
};
