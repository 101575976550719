import React from "react";
import { Grid } from "@material-ui/core";

import { useAuth, ROLES } from "../../../../../../auth";
import RawJson from "./RawJson";

const LxIotDevice = () => {
  const { user, hasRole } = useAuth();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {hasRole(user, ROLES.MASTER) && (
            <Grid item xs={12}>
              <RawJson />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LxIotDevice;
