import { createTheme } from "@material-ui/core/styles";

import colors from "../colors";

const aconColors = {
  text: "#090909",
  red: "#ff1400",
  redHover: "#f32c41",
  blue: "#2b59f8",
  sensors: {
    1: "#a53865",
    2: "#47585e",
    3: "#2c4184",
    4: "#773d7a"
  },
  years: {
    2004: "#6aa5a4",
    2005: "#bf2f69",
    2006: "#3339a2",
    2008: "#d6659c",
    2009: "#826aad",
    2010: "#b3dde2",
    2011: "#6c88ee",
    2012: "#2e9ce0",
    2013: "#d3c1f3",
    2014: "#49717f",
    2015: "#6aa5a4",
    2016: "#bf2f69",
    2017: "#3339a2",
    2018: "#d6659c",
    2019: "#826aad",
    2020: "#b3dde2",
    2021: "#6c88ee",
    2022: "#2e9ce0",
    2023: "#d3c1f3",
    2024: "#49717f",
    2025: "#089c85",
    2026: "#ae6612"
  }
};

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#ffffff"
        },
        html: {
          backgroundColor: "#ffffff"
        }
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 12px 6px 8px"
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#d7d5d3"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none"
      },
      containedPrimary: {
        backgroundColor: aconColors.red,
        "&:hover": {
          backgroundColor: aconColors.redHover
        }
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "#b1b0b0"
        }
      }
    }
  },
  palette: {
    text: {
      primary: aconColors.text
    },
    primary: {
      main: colors.BRAND_PRIMARY
    },
    secondary: {
      main: colors.BRAND_SECONDARY
    },
    success: {
      main: colors.SUCCESS
    },
    warning: {
      main: colors.WARNING
    },
    error: {
      main: colors.ERROR
    },
    background: {
      default: "#f1efed"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontSize: 12
  },
  custom: {
    colors: {
      sensors: { ...aconColors.sensors },
      years: { ...aconColors.years },
      messages: {
        statuses: { ...colors.MESSAGE_STATUSES }
      },
      deviceStatus: {
        ok: "#357458",
        alarm: "#c14530"
      }
    },
    dialog: {
      margin: 10
    },
    topBar: {
      background: `linear-gradient(90deg, ${aconColors.red} 0%, ${aconColors.blue} 100%)`,
      menuItem: {
        backgroundColorHighlight: "rgba(255, 255, 255, 0.15)"
      }
    },
    deviceSideMenu: {
      backgroundColor: "#efefef",
      quickNav: {
        backgroundColor: "#dddddd"
      },
      menuItem: {
        backgroundColorHover: "#dddddd",
        backgroundColorSelected: "#f7f6f6"
      }
    }
  }
});

export default theme;
