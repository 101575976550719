import { keysToCamel, toCamel } from "./stringUtils";
import { i18n } from "../i18n";

export const createFieldErrorMessage = (fieldName, errorCode, args) => {
  const messagePath = `apiErrors.${errorCode}`;

  let translationKey = messagePath + ".default";

  if (i18n.exists(`${messagePath}.fields.${fieldName}`)) {
    translationKey = `${messagePath}.fields.${fieldName}`;
  }

  return i18n.t(translationKey, keysToCamel(args));
};

export const setFieldErrorsFromResponse = (response, setFieldError) => {
  if (!response || !response.data) {
    return;
  }

  response.data.forEach(error => {
    if (error.path === null) {
      return;
    }

    const fieldName = error.path.reduce((acculumated, current) => {
      let currentKey = current;

      if (typeof currentKey === "string") {
        currentKey = toCamel(current);
      }

      if (acculumated === null) {
        return currentKey;
      }

      return acculumated + "." + currentKey;
    }, null);

    let errorCode = error.code;
    if (error.code === "length") {
      // Special handling for length error (missing min/max)

      errorCode = getLengthErrorCode(error.args);
    }

    setFieldError(
      fieldName,
      createFieldErrorMessage(fieldName, errorCode, error.args)
    );
    /*

    const messagePath = `apiErrors.${errorCode}`;

    let translationKey = messagePath + ".default";

    if (i18n.exists(`${messagePath}.fields.${keyName}`)) {
      translationKey = `${messagePath}.fields.${keyName}`;
    }

    setFieldError(keyName, i18n.t(translationKey, error.args));
    */
  });
};

const getLengthErrorCode = args => {
  if (!args.min) {
    return "lengthMax";
  }

  if (!args.max) {
    return "lengthMin";
  }

  return "length";
};
