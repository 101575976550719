import React, { useEffect, useCallback } from "react";

import I18nText from "../../../../../elements/I18nText";
import ContactSelect from "./ContactSelect";
import { Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";

import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../../../elements/Button";
import { useState } from "react";
import CreateContainer from "../../../../Contacts/Create/CreateContainer";
import { useApi } from "../../../../../api";

const ContactForm = ({
  contact,
  excludeList = [],
  onContactSelected,
  onContactCreated,
  onClose,
  onSubmit
}) => {
  const api = useApi();

  const [isLoading, setLoading] = useState(true);
  const [contactOptions, setContactOptions] = useState([]);
  const [showContactDialog, setShowContactDialog] = useState(false);

  const filteredOptions = useCallback(
    value => {
      return value.filter(
        contact => excludeList.find(id => id === contact.id) === undefined
      );
    },
    [excludeList]
  );

  const loadContacts = useCallback(() => {
    return api
      .get("/v2/contacts")
      .then(response => {
        setContactOptions(response.data);
      })
      .catch(() => {})
      .then(() => setLoading(false));
  }, [api]);

  useEffect(() => {
    loadContacts();
  }, [api, loadContacts]);

  const handleContactCreated = response => {
    const chunks = response.headers.location.split("/");
    const id = chunks[chunks.length - 1];

    loadContacts().then(() => {
      api.get(`/v2/contacts/${id}`).then(response => {
        setShowContactDialog(false);
        onContactCreated(response.data);
      });
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContactSelect
            name="contact"
            excludeList={excludeList}
            options={filteredOptions(contactOptions)}
            isLoading={isLoading}
            required
            value={contact}
            onUpdate={value => onContactSelected(value)}
            label={
              <I18nText>
                devices.settings.notifications.labels.selectContact
              </I18nText>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton
            icon={<AddIcon />}
            onClick={() => setShowContactDialog(true)}
          >
            <I18nText>
              devices.settings.notifications.labels.newContact
            </I18nText>
          </PrimaryButton>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={<CancelButton onClick={onClose} />}
            rightButton={
              <PrimaryButton
                disabled={!contact}
                icon={<ArrowForwardIosIcon />}
                onClick={onSubmit}
              >
                <I18nText>actions.continue</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>

      {showContactDialog && (
        <CreateContainer
          onClose={() => setShowContactDialog(false)}
          onCreated={handleContactCreated}
        />
      )}
    </>
  );
};

export default ContactForm;
