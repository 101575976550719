import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";
import FormContainer from "../Form/FormContainer";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const EditContainer = ({ device, aceId, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [ace, setAce] = useState(null);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/aces/${aceId}`)
      .then(response => {
        setAce(response.data);
        setLoading(false);
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          onClose();
        }
      });
  }, [api, device, aceId, onClose]);

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {
      userId: values.userId,
      permissions: []
    };

    for (const permission in values.permissions) {
      if (values.permissions[permission]) {
        params.permissions.push(permission);
      }
    }

    api
      .put(`/v2/devices/${device.id}/aces/${ace.id}`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.access.responses.updated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog>
      <FormContainer
        userId={ace.userId}
        username={ace.username}
        existingPermissions={ace.permissions}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default EditContainer;
