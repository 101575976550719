import React from "react";
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import TabBar from "./TabBar";

import { useDevice } from "../../../domain/device";
import Configuration from "./configuration";
import Metadata from "./metadata";
import Notifications from "./notifications";
import Access from "./access";
import Billing from "./billing";
import { PermissionProtectedRoute } from "../../ProtectedRoute";
import { PERMISSIONS, ROLES } from "../../../auth";
import RoleAndPermissionProtectedRoute from "../../ProtectedRoute/RoleAndPermissionProtectedRoute";

const SettingsContainer = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { device } = useDevice();

  const handleSetView = view =>
    history.push(`/devices/${device.id}/settings/${view}`);

  return (
    <Switch>
      <Route path={`${url}/configuration`}>
        <TabBar
          device={device}
          selectedView={"configuration"}
          onSelectView={handleSetView}
        />
        <Configuration />
      </Route>
      <Route path={`${url}/metadata`}>
        <TabBar
          device={device}
          selectedView={"metadata"}
          onSelectView={handleSetView}
        />
        <Metadata />
      </Route>
      <Route path={`${url}/notification-recipients`}>
        <TabBar
          device={device}
          selectedView={"notification-recipients"}
          onSelectView={handleSetView}
        />
        <Notifications />
      </Route>
      <PermissionProtectedRoute
        requiredPermission={PERMISSIONS.EDIT}
        myPermissions={device.permissions}
        path={`${url}/access`}
        redirectOnDeny={url}
      >
        <TabBar
          device={device}
          selectedView={"access"}
          onSelectView={handleSetView}
        />
        <Access />
      </PermissionProtectedRoute>
      <RoleAndPermissionProtectedRoute
        role={ROLES.MASTER}
        requiredPermission={PERMISSIONS.EDIT}
        myPermissions={device.permissions}
        path={`${url}/billing`}
        redirectOnDeny={url}
      >
        <TabBar
          device={device}
          selectedView={"billing"}
          onSelectView={handleSetView}
        />
        <Billing />
      </RoleAndPermissionProtectedRoute>
      <Route>
        <Redirect to={`${url}/configuration`} />
      </Route>
    </Switch>
  );
};

export default SettingsContainer;
