import React, { useEffect, useState } from "react";

import config from "../../../../config";
import { useApi } from "../../../../api";
import Pagination from "../../../../elements/Pagination";
import List from "./List";

const TermsContainer = () => {
  const api = useApi();

  const [groupedTerms, setGroupedTerms] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const handleExport = () => {
    const location = `${config.api.baseUrl}/v2/billing-terms/export`;
    window.location.href = location;
  };

  useEffect(() => {
    setLoading(true);

    api.get(`/v2/billing-terms?page=${page}`).then(response => {
      setGroupedTerms(response.data);

      setTotalCount(response.headers["x-total-count"]);
      setLoading(false);
    });
  }, [api, page]);

  return (
    <List
      groupedTerms={groupedTerms}
      onExport={handleExport}
      isLoading={isLoading}
      paginationComponent={
        <Pagination
          totalCount={totalCount}
          perPage={100}
          page={page}
          onSelectPage={page => setPage(page)}
          allowPerPageSelect={false}
        />
      }
    />
  );
};

export default TermsContainer;
