import React from "react";
import { useField } from "formik";
import { Grid } from "@material-ui/core";

import { IOT_TYPE, useDevice } from "../../../../../../domain/device";
import SectionSubTitle from "../../../../../../elements/SectionSubTitle";
import ThresholdSensor from "./ThresholdSensor";
import I18nText from "../../../../../../elements/I18nText";
import SendTime from "./SendTime";
import IotSendInterval from "./IotSendInterval";
import IotGpsInterval from "./IotGpsInterval";
import IotV230Fail from "./IotV230Fail";
import IotAlarm from "./IotAlarm";
import IotTemp from "./IotTemp";

const IotDevice = () => {
  const [{ value: iotType }] = useField("iotType");
  const { device } = useDevice();

  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <SendTime name="iotSendTime1" required={true} />
              </Grid>
              <Grid item xs={6}>
                <SendTime
                  name="iotSendTime2"
                  required={false}
                  withBubbleHelp={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SectionSubTitle>
              <I18nText>
                devices.settings.configuration.sections.thresholdNotifications
              </I18nText>
            </SectionSubTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ThresholdSensor />
              </Grid>
            </Grid>
          </Grid>

          {iotType === IOT_TYPE.GPS && (
            <Grid item xs={12}>
              <IotGpsInterval />
            </Grid>
          )}

          {(iotType === IOT_TYPE.GPS || iotType === IOT_TYPE.PRO) && (
            <>
              <Grid item xs={12}>
                <IotSendInterval />
              </Grid>
              <Grid item xs={12}>
                <IotV230Fail />
              </Grid>
              <Grid item xs={12}>
                <IotAlarm idx={1} />
              </Grid>
              <Grid item xs={12}>
                <IotAlarm idx={2} />
              </Grid>
              <Grid item xs={12}>
                <IotTemp />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IotDevice;
