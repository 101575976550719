import React from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../api";
import { setFieldErrorsFromResponse } from "../../../../utils/formErrors";
import NotificationForm from "./NotificationForm";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const CreateContainer = ({ onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    api
      .post("/v2/system-notifications", { ...values })
      .then(() => {
        enqueueSnackbar("system.notifications.responses.created", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    message: "",
    severity: "info"
  };

  return (
    <Dialog>
      <NotificationForm
        values={values}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default CreateContainer;
