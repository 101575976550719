import React from "react";
import { Box, LinearProgress } from "@material-ui/core";

const Process = ({ ChartComponent, LegendComponent, isLoading }) => {
  if (isLoading) {
    return (
      <Box marginTop={2}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return (
    <>
      {ChartComponent}
      {LegendComponent}
    </>
  );
};

export default Process;
