import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";

import I18nText from "../../../elements/I18nText";

const useListStyles = makeStyles({
  padding: {
    paddingTop: 0
  }
});

const useListItemStyles = makeStyles(theme => ({
  root: {
    height: 60,
    "&$selected": {
      backgroundColor: theme.palette.common.white
    },
    "&$button:hover": {
      backgroundColor: "#e5e5e5"
    }
  },
  selected: {},
  button: {}
}));

const useListItemTextStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.text.main,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const useListItemIconStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 0
    }
  }
}));

const Menu = ({ subPage }) => {
  const [selectedItem, selectItem] = useState(subPage);
  const classes = {
    list: useListStyles(),
    listItem: useListItemStyles(),
    listItemText: useListItemTextStyles(),
    listItemIcon: useListItemIconStyles()
  };

  return (
    <>
      <List classes={classes.list}>
        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "profile"}
          onClick={() => selectItem("profile")}
          component={Link}
          to="/settings"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>settings.navigation.profile</I18nText>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default Menu;
