export const TIME_UNITS = {
  SECOND: "s",
  MINUTE: "m",
  HOUR: "h",
  DAY: "d"
};

const SECOND_TO_M = 60;
const SECOND_TO_H = 3600;
const SECOND_TO_D = 86400;

export const inMostSuitableTimeUnit = (
  seconds,
  limitToTimeUnits = [
    TIME_UNITS.SECOND,
    TIME_UNITS.MINUTE,
    TIME_UNITS.HOUR,
    TIME_UNITS.DAY
  ]
) => {
  if (
    seconds % SECOND_TO_D === 0 &&
    limitToTimeUnits.find(unit => unit === TIME_UNITS.DAY) !== undefined
  ) {
    return {
      value: parseInt(seconds / SECOND_TO_D, 10),
      unit: TIME_UNITS.DAY
    };
  }

  if (
    seconds % SECOND_TO_H === 0 &&
    limitToTimeUnits.find(unit => unit === TIME_UNITS.HOUR) !== undefined
  ) {
    return {
      value: parseInt(seconds / SECOND_TO_H, 10),
      unit: TIME_UNITS.HOUR
    };
  }

  if (
    seconds % SECOND_TO_M === 0 &&
    limitToTimeUnits.find(unit => unit === TIME_UNITS.MINUTE) !== undefined
  ) {
    return {
      value: parseInt(seconds / SECOND_TO_M, 10),
      unit: TIME_UNITS.MINUTE
    };
  }

  return {
    value: seconds,
    unit: TIME_UNITS.SECOND
  };
};

export const valueAndTimeUnitToSeconds = (value, timeUnit) => {
  let factor = 1;

  switch (timeUnit) {
    case TIME_UNITS.MINUTE:
      factor = SECOND_TO_M;
      break;

    case TIME_UNITS.HOUR:
      factor = SECOND_TO_H;
      break;

    case TIME_UNITS.DAY:
      factor = SECOND_TO_D;
      break;

    default:
  }

  return value * factor;
};
