import React from "react";

import { useApi } from "../../../api";
import { setFieldErrorsFromResponse } from "../../../utils/formErrors";
import FormDialog from "./FormDialog";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const CreateContainer = ({ onClose, onCreated }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = { ...values };

    api
      .post("/v2/contacts", params)
      .then(response => {
        enqueueSnackbar("contacts.responses.created", { variant: "success" });

        onCreated(response);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const values = {
    firstName: "",
    lastName: "",
    role: "",
    cellNumber: "",
    email: "",
    language: "de",
    comment: ""
  };

  return (
    <FormDialog values={values} onClose={onClose} onSubmit={handleSubmit} />
  );
};

export default CreateContainer;
