import React, { useState } from "react";

import { useApi } from "../../api";
import I18nText from "../../elements/I18nText";
import ConfirmationDialog from "../../elements/ConfirmationDialog";
import { DeleteButton } from "../../elements/Button";
import useI18nSnackbar from "../../hooks/useI18nSnackbar";

const DeleteContainer = ({ user, onCancel, onDeleted }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const deleteUser = () => {
    setDeleting(true);

    api.delete(`/v2/users/${user.id}`).then(() => {
      enqueueSnackbar("users.responses.deleted", {
        variant: "success"
      });

      setDeleting(false);
      onDeleted();
    });
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton disabled={isDeleting} onClick={deleteUser} />
      }
    >
      <I18nText>users.delete.confirmation</I18nText>
    </ConfirmationDialog>
  );
};

export default DeleteContainer;
