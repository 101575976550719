import React from "react";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import ListContainer from "./List/ListContainer";
import CreateContainer from "./Form/CreateContainer";
import EditContainer from "./Form/EditContainer";
import { REGEXP_ID } from "../../utils/stringUtils";

const RefDateQueriesContainer = ({ onClose }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <>
      <ListContainer onClose={onClose} />
      <Switch>
        <Route path={`${url}/new`}>
          <CreateContainer onClose={() => history.push(url)} />
        </Route>
        <Route path={`${url}/:refDateQueryId(${REGEXP_ID})`}>
          {({
            match: {
              params: { refDateQueryId }
            }
          }) => (
            <EditContainer
              refDateQueryId={refDateQueryId}
              onClose={() => history.push(url)}
            />
          )}
        </Route>
        <Route>
          <Redirect to="/devices/ref-date-queries" />
        </Route>
      </Switch>
    </>
  );
};

export default RefDateQueriesContainer;
