import React from "react";
import UpdateIcon from "@material-ui/icons/Update";

import ConfirmationDialog from "../../elements/ConfirmationDialog";
import I18nText from "../../elements/I18nText";
import { PrimaryButton } from "../../elements/Button";

const Confirmation = ({ deviceIds, isSubmitting, onConfirm, onCancel }) => (
  <ConfirmationDialog
    onCancel={onCancel}
    isSubmitting={isSubmitting}
    ConfirmButton={
      <PrimaryButton
        disabled={isSubmitting}
        icon={<UpdateIcon />}
        onClick={() => onConfirm(deviceIds)}
      >
        <I18nText>blockQueries.actions.confirm</I18nText>
      </PrimaryButton>
    }
  >
    <I18nText values={{ count: deviceIds.length }}>
      blockQueries.confirmation.content
    </I18nText>
  </ConfirmationDialog>
);

export default Confirmation;
