import React from "react";
import { Box } from "@material-ui/core";

import Table from "./Table/Table";
import Pagination from "../../elements/Pagination";
import QuickSelect from "./QuickSelect";

const BlockQuery = React.memo(
  ({
    devices,
    totalCount,
    perPage,
    page,
    isUpdating,
    isInitiallyLoaded,
    isQuerying,
    onSelectPage,
    onQuickSelect,
    preSelectedDeviceIds,
    onSubmit,
    onClose,
    queryStatuses
  }) => {
    return (
      <>
        {isInitiallyLoaded && !isQuerying && (
          <QuickSelect onSelect={onQuickSelect} />
        )}

        <Table
          devices={devices}
          queryStatuses={queryStatuses}
          onSubmit={onSubmit}
          onClose={onClose}
          isQuerying={isQuerying}
          isUpdating={isUpdating}
          isInitiallyLoaded={isInitiallyLoaded}
          preSelectedDeviceIds={preSelectedDeviceIds}
          paginationComponent={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              paddingX={1}
              paddingY={2}
            >
              <Pagination
                totalCount={totalCount}
                perPage={perPage}
                page={page}
                onSelectPage={onSelectPage}
                allowPerPageSelect={false}
              />
            </Box>
          }
        />
      </>
    );
  }
);

export default BlockQuery;
