import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  makeStyles,
  LinearProgress,
  Box,
  Button
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import I18nText from "../../../../elements/I18nText";

import FormattedDate from "../../../../elements/FormattedDate";
import { FORMAT_DATE } from "../../../../utils/formatting";
import FormattedNumber from "../../../../elements/FormattedNumber";
import { ALIAS } from "../../../../domain/billingTerms";

const useStyles = makeStyles({
  headerCell: {
    fontWeight: "bold"
  },
  rowHeader: {
    paddingTop: 40,
    fontWeight: "bold"
  }
});

const List = React.memo(
  ({ groupedTerms, isLoading, onExport, paginationComponent }) => {
    const theme = useTheme();
    const classes = useStyles();

    const TableHeader = () => {
      return (
        <>
          <TableRow>
            <TableCell className={classes.headerCell}>
              <I18nText>billing.common.debtor</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              <I18nText>users.common.username</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>Inbound Gateway</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>Outbound Gateway</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.type</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.chargingPeriodType</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.price</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.exemptedQuantity</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.invoicedUntil</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.nextInvoicingAt</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>Fällig?</I18nText>
            </TableCell>
          </TableRow>
        </>
      );
    };

    const renderDebtor = device => {
      return device.debtor && device.debtor.ident;
    };

    const renderUser = device => {
      return device.user && device.user.username;
    };

    const renderInboundGateway = device => {
      if (!device.inboundGateway) {
        return "";
      }

      const gw = device.inboundGateway;

      return (
        <>
          <I18nText>{`mobileProviders.${gw.provider}`}</I18nText> ({gw.username}
          )
        </>
      );
    };

    const renderOutboundGateway = device => {
      if (!device.inboundGateway) {
        return "";
      }

      const gw = device.inboundGateway;

      return (
        <>
          <I18nText>{`mobileProviders.${gw.provider}`}</I18nText> ({gw.username}
          )
        </>
      );
    };

    const renderChargingPeriod = terms => {
      return (
        <>
          <I18nText>{`billing.chargingPeriodTypes.${terms.chargingPeriodType}`}</I18nText>{" "}
          (
          <I18nText>{`billing.timesOfInvoicing.${terms.timeOfInvoicing}`}</I18nText>
          )
        </>
      );
    };

    const renderAlias = terms => {
      if (terms.alias === ALIAS.CUSTOM) {
        return terms.custom.name;
      }

      return <I18nText>{`billing.aliases.${terms.alias}`}</I18nText>;
    };

    const renderPrice = terms => {
      if (terms.price === null) {
        return "";
      }

      return (
        <>
          <FormattedNumber decimals={2}>{terms.price}</FormattedNumber>{" "}
          {terms.currency}
        </>
      );
    };

    const renderSmsIncl = terms => {
      if (
        terms.alias === ALIAS.SMS_DEVICE ||
        terms.alias === ALIAS.SMS_EXTERNAL
      ) {
        return (
          <I18nText values={{ count: terms.custom.exemptedQuantity }}>
            billing.common.smsIncluded
          </I18nText>
        );
      }

      return "";
    };

    const renderIsScheduled = terms => {
      if (terms.isScheduled) {
        return (
          <CheckIcon
            fontSize="small"
            style={{ color: theme.palette.error.light }}
          />
        );
      }

      return (
        <ScheduleIcon
          fontSize="small"
          style={{ color: theme.palette.success.main }}
        />
      );
    };

    return (
      <>
        <Box display="flex" padding={2} justifyContent="flex-end">
          <Button
            onClick={onExport}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<CloudDownloadIcon />}
          >
            <I18nText>system.billing.csvExport</I18nText>
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          paddingX={1}
          paddingTop={3}
          paddingBottom={2}
        >
          {paginationComponent}
        </Box>
        {isLoading && (
          <Box spacing={2} margin={2}>
            <LinearProgress color="secondary" />
          </Box>
        )}
        <Table size="small">
          <TableBody>
            {groupedTerms.map(group => (
              <Fragment key={group.device.id}>
                <TableRow>
                  <TableCell className={classes.rowHeader} colSpan={11}>
                    Anlage #{group.device.id}
                  </TableCell>
                </TableRow>
                <TableHeader />

                {group.terms.map(terms => (
                  <TableRow key={terms.id}>
                    <TableCell className={classes.cell}>
                      {renderDebtor(group.device)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderUser(group.device)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderInboundGateway(group.device)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderOutboundGateway(group.device)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderAlias(terms)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderChargingPeriod(terms)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderPrice(terms)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderSmsIncl(terms)}
                    </TableCell>
                    <TableCell align="center">
                      <FormattedDate format={FORMAT_DATE} fallback="-">
                        {terms.invoicedUntil}
                      </FormattedDate>
                    </TableCell>
                    <TableCell align="center">
                      <FormattedDate format={FORMAT_DATE} fallback="-">
                        {terms.nextInvoicingAt}
                      </FormattedDate>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderIsScheduled(terms)}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default List;
