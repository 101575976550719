import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect
} from "react-router-dom";
import { useApi } from "../../api";
import { ROLES } from "../../auth";
import { RoleProtectedRoute } from "../ProtectedRoute";

import Account from "./Account";
import Access from "./Access";
import LoginHistory from "./LoginHistory";
import Layout from "./Layout";

const UserContainer = ({ id }) => {
  const { url } = useRouteMatch();
  const api = useApi();
  const history = useHistory();

  const [reload, doReload] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    api.get(`/v2/users/${id}`).then(response => {
      if (!response.data.parentUser) {
        history.push("/users");
      }

      setUser(response.data);

      doReload(false);
    });
  }, [id, api, reload, history]);

  useEffect(() => {
    doReload(true);
  }, [id]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Switch>
        <Route path={`${url}/account`}>
          <Layout user={user} subPage="account">
            <Account user={user} onReload={() => doReload(true)} />
          </Layout>
        </Route>
        <Route path={`${url}/access`}>
          <Layout user={user} subPage="access">
            <Access user={user} onReload={() => doReload(true)} />
          </Layout>
        </Route>
        <RoleProtectedRoute role={ROLES.MASTER} path={`${url}/login-history`}>
          <Layout user={user} subPage="login-history">
            <LoginHistory user={user} />
          </Layout>
        </RoleProtectedRoute>
        <Route>
          <Redirect to={`${url}/account`} />
        </Route>
      </Switch>
    </>
  );
};

export default UserContainer;
