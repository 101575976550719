import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";

import Row from "./Row";
import I18nText from "../../../elements/I18nText";
import LoadingOverlay from "../../../elements/LoadingOverlay";
import Confirmation from "../Confirmation";
import {
  ButtonsWrapper,
  CancelButton,
  SecondaryButton,
  PrimaryButton
} from "../../../elements/Button";

const Table = React.memo(
  ({
    devices,
    onSubmit,
    onClose,
    isQuerying,
    preSelectedDeviceIds,
    isUpdating,
    isInitiallyLoaded,
    queryStatuses,
    paginationComponent
  }) => {
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
      setSelectedDeviceIds(preSelectedDeviceIds);
    }, [preSelectedDeviceIds]);

    const handleSubmit = useCallback(() => setShowConfirmation(true), []);

    const handleConfirm = useCallback(
      deviceIds => {
        setShowConfirmation(false);
        onSubmit(deviceIds);
      },
      [onSubmit]
    );

    const onToggleDevice = useCallback(deviceId => {
      const id = parseInt(deviceId, 10);

      setSelectedDeviceIds(prev => {
        const idx = prev.indexOf(id);

        if (idx === -1) {
          return [...prev, id];
        }

        const newState = [...prev];
        newState.splice(idx, 1);

        return newState;
      });
    }, []);

    const isDeviceSelected = deviceId =>
      selectedDeviceIds.indexOf(deviceId) !== -1;

    return (
      <>
        {paginationComponent}
        <LoadingOverlay isLoading={isUpdating} minHeight={200}>
          <MuiTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <I18nText>devices.common.id</I18nText>
                </TableCell>

                <TableCell>
                  <I18nText>devices.common.name</I18nText>
                </TableCell>
                <TableCell>
                  <I18nText>devices.common.address</I18nText>
                </TableCell>
                <TableCell>
                  <I18nText>devices.common.lastReportedAt</I18nText>
                </TableCell>
                <TableCell align="center">
                  <I18nText>devices.common.status</I18nText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map(device => (
                <Row
                  key={device.id}
                  device={device}
                  isSelected={isDeviceSelected(device.id)}
                  onToggleDevice={onToggleDevice}
                  status={queryStatuses[device.id]}
                  isQuerying={isQuerying}
                />
              ))}
            </TableBody>
          </MuiTable>
        </LoadingOverlay>

        {paginationComponent}

        {isInitiallyLoaded && !isQuerying && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginTop={2}>
                <ButtonsWrapper
                  leftButton={<CancelButton onClick={onClose} />}
                  rightButton={
                    <PrimaryButton
                      onClick={() => handleSubmit(selectedDeviceIds)}
                      disabled={selectedDeviceIds.length === 0}
                      icon={<SendIcon />}
                    >
                      <I18nText values={{ count: selectedDeviceIds.length }}>
                        blockQueries.actions.submit
                      </I18nText>
                    </PrimaryButton>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {isQuerying && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginTop={2}>
                <ButtonsWrapper
                  rightButton={
                    <SecondaryButton onClick={onClose} icon={<CloseIcon />}>
                      <I18nText>actions.close</I18nText>
                    </SecondaryButton>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {showConfirmation && (
          <Confirmation
            deviceIds={selectedDeviceIds}
            onConfirm={handleConfirm}
            onCancel={() => setShowConfirmation(false)}
          />
        )}
      </>
    );
  }
);

export default Table;
