import React from "react";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthTextField } from "../../../../../../elements/FormField";

const Host = () => (
  <FullWidthTextField
    name="host"
    variant="outlined"
    label={<I18nText>devices.settings.configuration.host</I18nText>}
    inputProps={{ maxLength: 255, autoComplete: "off" }}
  />
);

export default Host;
