import React from "react";
import { makeStyles, Box } from "@material-ui/core";

import { useWhitelabel, WHITELABELS } from "../../domain/whitelabel";

import mobeneLogo from "../../assets/mobene/header.png";
import smartinspectorLogo from "../../assets/smartinspector/header.jpg";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100vw",
    height: 85,
    backgroundColor: theme.palette.common.white
  }
}));

const WhitelabeledHeader = () => {
  const classes = useStyles();
  const { whitelabel } = useWhitelabel();

  // MOBENE
  if (whitelabel.name === WHITELABELS.MOBENE) {
    return (
      <Box className={classes.container} paddingLeft={2} paddingY={1}>
        <img src={mobeneLogo} alt="Mobene" />
      </Box>
    );
  }

  // SMARTINSPECTOR
  if (whitelabel.name === WHITELABELS.SMARTINSPECTOR) {
    return (
      <Box
        className={classes.container}
        style={{ backgroundColor: "rgb(211, 211, 211)" }}
      >
        <img src={smartinspectorLogo} alt="Smartinspector" />
      </Box>
    );
  }

  return null;
};

export default WhitelabeledHeader;
