import React from "react";
import { useSnackbar } from "notistack";

import I18nText from "../elements/I18nText";

const useI18nSnackbar = () => {
  const { enqueueSnackbar, ...rest } = useSnackbar();

  const enqueueDecorator = (
    translationKey,
    options,
    translationValues = {}
  ) => {
    return enqueueSnackbar(
      <I18nText values={translationValues}>{translationKey}</I18nText>,
      options
    );
  };

  return {
    enqueueSnackbar: enqueueDecorator,
    ...rest
  };
};

export default useI18nSnackbar;
