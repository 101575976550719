import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../elements/DialogTitleWithCloseIcon";

import QueryFormContainer from "./QueryFormContainer";
import FixedPositionDialog from "../../../elements/FixedPositionDialog";
import I18nText from "../../../elements/I18nText";

const QueryDialog = ({ deviceId, forceReload, onClose }) => {
  return (
    <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="sm">
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>messages.query.title</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>
        <QueryFormContainer
          deviceId={deviceId}
          onClose={onClose}
          forceReload={forceReload}
        />
      </DialogContent>
    </FixedPositionDialog>
  );
};

export default QueryDialog;
