import { useContext } from "react";

import ResourcesContext from "../../context/ResourcesContext";

const useSmsGateways = () => {
  const { smsGateways } = useContext(ResourcesContext);

  if (smsGateways === undefined) {
    throw new Error("useSmsGateways must be used within a ResourcesContext");
  }

  return smsGateways;
};

export default useSmsGateways;
