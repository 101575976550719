import React from "react";
import { DialogContent } from "@material-ui/core";
import FixedPositionDialog from "../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../elements/DialogTitleWithCloseIcon";
import BasedataContainer from "./BasedataContainer";
import I18nText from "../../../../elements/I18nText";

const BasedataDialog = ({ user, onClose }) => {
  return (
    <>
      <FixedPositionDialog
        open={true}
        scroll="body"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>users.account.sections.basedata</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <BasedataContainer user={user} onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default BasedataDialog;
