import React from "react";
import { MenuItem, ListSubheader } from "@material-ui/core";

import I18nText from "../../../elements/I18nText";
import { FullWidthSelect } from "../../../elements/FormField";

const LionelGatewaySelect = React.memo(({ gateways, name }) => {
  const countries = [...new Set(gateways.map(gateway => gateway.country))];

  const options = [];
  countries.forEach(countryCode => {
    options.push(
      <ListSubheader key={countryCode} value="">
        <I18nText>{`countries.${countryCode.toUpperCase()}`}</I18nText>
      </ListSubheader>
    );

    gateways.forEach(gateway => {
      if (gateway.country === countryCode) {
        options.push(
          <MenuItem key={gateway.id} value={gateway.id}>
            {gateway.label} (
            <I18nText>{`mobileProviders.${gateway.provider}`}</I18nText>,{" "}
            {gateway.owner})
          </MenuItem>
        );
      }
    });
  });

  return (
    <FullWidthSelect
      fastField={false}
      required
      variant="outlined"
      label={<I18nText>{`devices.settings.configuration.${name}`}</I18nText>}
      name={name}
      inputProps={{
        id: `${name}-select`
      }}
    >
      {options.map(option => option)}
    </FullWidthSelect>
  );
});

export default LionelGatewaySelect;
