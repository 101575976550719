import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";

import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";
import { useTranslation } from "../../../../../../i18n";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%"
  }
});

const Picker = ({ name, required = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { setValue } = helpers;

  const updateValue = val => {
    setValue(val);
  };

  const label = t(`devices.settings.configuration.${name}`);

  return (
    <KeyboardTimePicker
      classes={classes}
      variant="dialog"
      required={required}
      ampm={false}
      error={meta.error}
      label={label}
      value={value}
      minutesStep={5}
      onChange={val => updateValue(val)}
      format="HH:mm"
    />
  );
};

const BubbleHelpWrapped = ({ name, children }) => {
  return (
    <BubbleHelpFieldWrapper id="devices.edit.iotSendTime">
      {children}
    </BubbleHelpFieldWrapper>
  );
};

const SendTime = ({ name, required = false, withBubbleHelp = false }) => {
  if (withBubbleHelp) {
    return (
      <BubbleHelpWrapped>
        <Picker name={name} required={required} />
      </BubbleHelpWrapped>
    );
  }

  return <Picker name={name} required={required} />;
};

export default SendTime;
