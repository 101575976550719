import { useContext } from "react";

import LocaleContext from "../context/LocaleContext";

const useLocale = () => {
  const context = useContext(LocaleContext);
  if (context === undefined) {
    throw new Error("useLocale must be used within a LocaleProvider");
  }

  return context;
};

export default useLocale;
