import React, { useState } from "react";

import TopBar from "./TopBar";
import { useHistory } from "react-router-dom";

const TopBarContainer = ({ selectedMenu }) => {
  const history = useHistory();
  const [selected, setSelected] = useState(selectedMenu);

  const navigateHome = () => {
    history.push("/");
  };

  return (
    <TopBar
      selected={selected}
      onSelect={setSelected}
      onNavigateHome={navigateHome}
    />
  );
};

export default TopBarContainer;
