import React from "react";
import { makeStyles } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  marker: {
    fontSize: 11,
    transform: "translateX(-2px) translateY(2px)"
  }
});

const DisregardIcon = () => {
  const classes = useStyles();

  return (
    <>
      <MailOutlineIcon fontSize="small" />
      <ClearIcon className={classes.marker} />
    </>
  );
};

export default DisregardIcon;
