import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import { PERMISSIONS } from "../../../auth";
import useDebounce from "../../../hooks/useDebounce";
import Search from "./Search";

const SearchContainer = () => {
  const api = useApi();
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const renderDeviceLabel = device => {
    return `#${device.id} ${device.name}, ${device.address.street} ${device.address.streetNumber}, ${device.address.countryCode}-${device.address.postalCode} ${device.address.city}`;
  };

  const handleSubmit = value => {
    let deviceId;
    if (typeof value === "string") {
      if (value.match(/^\d+$/) === null) {
        return;
      }

      deviceId = value;
    } else {
      deviceId = value.id;
    }

    setSearchTerm("");
    history.push(`/devices/${deviceId}`);
  };

  const clearOptions = () => {
    setOptions([]);
  };

  useEffect(() => {
    let mounted = true;

    if (!debouncedSearchTerm) {
      return undefined;
    }

    api
      .get(
        `/v2/devices?per_page=100&permission=${PERMISSIONS.VIEW_MESSAGING}&search_term=${debouncedSearchTerm}`
      )
      .then(response => {
        if (!mounted) {
          return;
        }

        let newOptions = [];

        const results = response.data.map(device => ({
          id: device.id,
          label: renderDeviceLabel(device)
        }));

        setOptions([...newOptions, ...results]);
      })
      .catch(() => {});

    return () => {
      mounted = false;
    };
  }, [api, debouncedSearchTerm]);

  return (
    <Search
      searchTerm={searchTerm}
      options={options}
      onFocus={clearOptions}
      onSubmit={handleSubmit}
      onSearch={setSearchTerm}
    />
  );
};

export default SearchContainer;
