import { useContext } from "react";

import WhitelabelContext from "../../context/WhitelabelContext";

const useWhitelabel = () => {
  const context = useContext(WhitelabelContext);
  if (context === undefined) {
    throw new Error("useWhitelabel must be used within a WhitelabelProvider");
  }

  return context;
};

export default useWhitelabel;
