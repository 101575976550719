import React from "react";
import { Grid, Box, Dialog, DialogContent, Slide } from "@material-ui/core";
import { ButtonsWrapper, CancelButton } from "./Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = ({
  isSubmitting,
  children,
  onCancel,
  ConfirmButton
}) => {
  return (
    <>
      <Dialog
        open={true}
        scroll="body"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box fontSize={14}>{children}</Box>
            </Grid>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onCancel} disabled={isSubmitting} />
                }
                rightButton={ConfirmButton}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
