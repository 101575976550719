import React from "react";
import axios from "axios";

import ApiContext from "../context/ApiContext";
import { receivedToken } from "../actions/csrf";
import { keysToCamel, keysToSnake } from "../utils/stringUtils";
import { store } from "../store";
import config from "../config";

const ApiProvider = ({ children }) => {
  const options = {
    baseURL: config.api.baseUrl,
    timeout: 30000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    transformRequest: [
      data => keysToSnake(data),
      ...axios.defaults.transformRequest
    ]
  };

  const apiClient = axios.create(options);

  apiClient.interceptors.request.use(
    config => {
      const state = store.getState();
      const csrfToken = state.csrf && state.csrf.token;

      if (csrfToken) {
        config.headers["X-Csrf-Token"] = csrfToken;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  apiClient.interceptors.response.use(response => {
    if (response && response.headers && response.headers["x-csrf-token"]) {
      store.dispatch(receivedToken(response.headers["x-csrf-token"]));
    }

    return response;
  });

  apiClient.interceptors.response.use(
    response => {
      if (response && response.data) {
        response.data = keysToCamel(response.data);
      }

      return response;
    },
    error => {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return (
    <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
  );
};

export default ApiProvider;
