import React from "react";
import { TableRow, TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    borderBottom: "none",
    fontWeight: "bold"
  }
});

const UserHeaderRow = ({ user, colSpan }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} classes={classes}>
        {user.name}
      </TableCell>
    </TableRow>
  );
};

export default UserHeaderRow;
