import React from "react";
import { FastField } from "formik";

import { NumberField } from "../../../../../elements/FormField";
import { numberValidator } from "../../../../../utils/formValidators";

const ExemptedQuantityField = () => (
  <FastField
    component={NumberField}
    label="billing.common.exemptedQuantity"
    name="custom.exemptedQuantity"
    validate={numberValidator({ required: false, min: 0 })}
    min={0}
  />
);

export default ExemptedQuantityField;
