import React from "react";
import useFormat from "../hooks/useFormat";

const FormattedNumber = ({ children, decimals = 0, fallback = "", unit }) => {
  const { formatNumber } = useFormat();

  if (children !== 0 && !children) {
    return fallback;
  }

  const value = formatNumber(children, decimals, fallback);

  if (unit) {
    return (
      <>
        {value} {unit}
      </>
    );
  }

  return value;
};

export default FormattedNumber;
