import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from "@material-ui/core";

import ProbeRow from "./ProbeRow";
import Footer from "./Footer";
import I18nText from "../../../../elements/I18nText";

const ProbeTable = ({ sensors }) => {
  const theme = useTheme();
  const showTemperatureColumn =
    sensors.filter(sensor => sensor.temperatureValue !== null).length > 0;

  return (
    <MuiTable size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <I18nText>devices.status.table.header.name</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>devices.status.table.header.contentType</I18nText>
          </TableCell>
          <TableCell align="right">
            <I18nText>devices.status.table.header.capacity</I18nText>
          </TableCell>
          {showTemperatureColumn && (
            <TableCell align="right">
              <I18nText>devices.status.table.header.temperature</I18nText>
            </TableCell>
          )}
          <TableCell align="right">
            <I18nText>devices.status.table.header.netFreeSpace</I18nText>
          </TableCell>
          <TableCell align="right">
            <I18nText>devices.status.table.header.netValue</I18nText>
          </TableCell>
          <TableCell align="right">
            <I18nText>devices.status.table.header.valuePct</I18nText>
          </TableCell>
          <TableCell align="right">
            <I18nText>devices.status.table.header.value</I18nText>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sensors.map(sensor => (
          <ProbeRow
            key={sensor.id}
            sensor={sensor}
            rowColor={theme.custom.colors.sensors[sensor.sequence]}
            showTemperatureColumn={showTemperatureColumn}
          />
        ))}
      </TableBody>
      <Footer sensors={sensors} showTemperatureColumn={showTemperatureColumn} />
    </MuiTable>
  );
};

export default ProbeTable;
