import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../elements/Button";
import Section from "../../../../elements/Section";
import SectionHeader from "../../../../elements/SectionHeader";
import I18nText from "../../../../elements/I18nText";
import PermissionCheckbox from "./PermissionCheckbox";
import { PERMISSIONS } from "../../../../auth";

const PermissionsForm = ({ deviceId, values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, values, setFieldValue, isSubmitting }) => {
        const isValid = () => {
          for (const permission in values.permissions) {
            if (values.permissions[permission]) {
              return true;
            }
          }

          return false;
        };

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Section>
                  <SectionHeader>
                    <I18nText>devices.common.id</I18nText>
                  </SectionHeader>
                  {deviceId}
                </Section>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Section>
                  <SectionHeader>
                    <I18nText>access.permissions.aggregated.read</I18nText>
                  </SectionHeader>
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.VIEW_STATUS}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.VIEW_PROCESS}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.VIEW_CONSUMPTION}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.VIEW_CONFIGURATION}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.VIEW_MESSAGING}
                    setFieldValue={setFieldValue}
                  />
                </Section>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Section>
                  <SectionHeader>
                    <I18nText>access.permissions.aggregated.write</I18nText>
                  </SectionHeader>
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.CONFIGURATION}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.EDIT}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.DELETE}
                    setFieldValue={setFieldValue}
                  />
                </Section>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Section>
                  <SectionHeader>
                    <I18nText>access.permissions.aggregated.execute</I18nText>
                  </SectionHeader>

                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.QUERY}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.REF_DATE_QUERY}
                    setFieldValue={setFieldValue}
                  />
                  <PermissionCheckbox
                    permissions={values.permissions}
                    name={PERMISSIONS.EXPORT}
                    setFieldValue={setFieldValue}
                  />
                </Section>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ButtonsWrapper
                  leftButton={
                    <CancelButton onClick={onClose} disabled={isSubmitting} />
                  }
                  rightButton={
                    <SaveButton
                      onClick={submitForm}
                      disabled={!isValid() || isSubmitting}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PermissionsForm;
