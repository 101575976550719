import React from "react";
import { FastField } from "formik";
import { makeStyles } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";

const useStyles = makeStyles({
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRightWidth: 0
    },
    "& .MuiOutlinedInput-input": {
      paddingRight: 0
    }
  }
});

const Capacity = ({ prefix }) => {
  const classes = useStyles();

  return (
    <FastField
      component={NumberField}
      name={`${prefix}.capacity`}
      className={classes.formControl}
      validate={numberValidator({ min: 0, max: 9999999 })}
      min={0}
      max={9999999}
    />
  );
};

export default Capacity;
