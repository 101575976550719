import { useField } from "formik";
import React from "react";
import PhoneInput from "react-phone-input-2";

import de from "react-phone-input-2/lang/de.json";

import "react-phone-input-2/lib/style.css";

import { useLocale } from "../../i18n";
import { FormHelperText, makeStyles, useTheme } from "@material-ui/core";

const PREFERRED_COUNTRIES = ["de", "ch", "at", "fr", "nl", "lu", "be"];

const MASKS = {
  de: "... ..........",
  ch: ".. ... .. ......",
  fr: ". .. .. .. .......",
  be: "... .. .. ......",
  at: ". .............",
  es: "... ... ........."
};

const useStyles = makeStyles(theme => ({
  label: {
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    top: -7,
    left: 50,
    paddingLeft: 4,
    paddingRight: 4,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem"
  }
}));

const CellNumberField = ({ name, label }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { locale } = useLocale();
  const [field, meta, helpers] = useField(name);

  const inputStyles = {
    width: "100%",
    paddingTop: 5,
    borderRadius: 4,
    color: theme.palette.text.primary,
    fontSize: 13.714
  };

  const { value } = field;
  const { setValue } = helpers;

  const updateValue = value => {
    const newValue = value ? "+" + value : "";

    setValue(newValue);
  };

  const fieldValue = value.replace(/^\+/, "");

  const localizationProp = {};
  if (locale === "de") {
    localizationProp.localization = de;
  }

  return (
    <div style={{ position: "relative" }}>
      <PhoneInput
        placeholder="+49 160 12345678"
        country="de"
        inputProps={{ size: "small" }}
        value={fieldValue}
        defaultMask="... ..............."
        error={!!meta.error}
        masks={MASKS}
        onChange={updateValue}
        {...localizationProp}
        preferredCountries={PREFERRED_COUNTRIES}
        inputStyle={inputStyles}
      />
      {!!meta.error && (
        <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>
      )}
      <span className={classes.label}>{label}</span>
    </div>
  );
};

export default CellNumberField;
