import React from "react";
import { TableCell, TableRow } from "@material-ui/core";

import { Trans, useTranslation } from "../../../i18n";
import FormattedDate from "../../../elements/FormattedDate";
import {
  ListActionsWrapper,
  EditAction,
  DeleteAction
} from "../../../elements/ListAction";

const Row = React.memo(({ query, onDelete, onSelect }) => {
  const { t } = useTranslation();

  const renderIntervals = intervals =>
    intervals
      .map(interval => t(`refDateQueries.intervals.${interval}`))
      .join(", ");

  return (
    <TableRow key={query.id}>
      <TableCell>
        <Trans i18nKey={`refDateQueries.intervalTypes.${query.intervalType}`} />
      </TableCell>
      <TableCell>{renderIntervals(query.intervals)}</TableCell>
      <TableCell>{query.queryAt}</TableCell>
      <TableCell>{query.deviceIds.length}</TableCell>
      <TableCell>
        {query.isActive ? (
          <Trans i18nKey="refDateQueries.isActive" />
        ) : (
          <Trans i18nKey="refDateQueries.isNotActive" />
        )}
      </TableCell>
      <TableCell>
        <FormattedDate>{query.lastRunAt}</FormattedDate>
      </TableCell>
      <TableCell>
        <FormattedDate>{query.nextRunAt}</FormattedDate>
      </TableCell>
      <TableCell align="right">
        <ListActionsWrapper>
          <EditAction onClick={onSelect} />
          <DeleteAction onClick={() => onDelete(query.id)} />
        </ListActionsWrapper>
      </TableCell>
    </TableRow>
  );
});

export default Row;
