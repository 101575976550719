import { Grid, FormControlLabel, Radio } from "@material-ui/core";
import React, { useState } from "react";
import { RadioGroup } from "formik-material-ui";
import { FullWidthTextField } from "../../../elements/FormField";
import UnassignedLxIotDeviceSelect from "../../../elements/FormField/UnassignedLxIotDeviceSelect";
import I18nText from "../../../elements/I18nText";

const LxIotDeviceForm = ({ assignValue, assignOnSelect, assignHasError }) => {
  const [action, setAction] = useState("create");

  const handleSelectAction = event => {
    const newAction = event.target.value;
    if (newAction === "create") {
      assignOnSelect({
        id: "",
        label: ""
      });
    }

    setAction(newAction);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup name="action" value={action} onChange={handleSelectAction}>
          <FormControlLabel
            value="create"
            control={<Radio disabled={false} />}
            label="Neues LX-IoT-Gerät einrichten"
          />
          <FormControlLabel
            value="assign"
            control={<Radio disabled={false} />}
            label="LX-IoT-Gerät aus Liste wählen"
          />
        </RadioGroup>
      </Grid>

      {action === "create" && (
        <Grid item xs={12}>
          <FullWidthTextField
            name="iccid"
            variant="outlined"
            required
            label={<I18nText>devices.common.iccid</I18nText>}
            inputProps={{ maxLength: 32, autoComplete: "off" }}
          />
        </Grid>
      )}

      {action === "assign" && (
        <Grid item xs={12}>
          <UnassignedLxIotDeviceSelect
            name="lxIotDevice"
            value={assignValue}
            hasError={assignHasError}
            onUpdate={value => assignOnSelect(value)}
            label={<I18nText>devices.create.selectNetDevice</I18nText>}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default LxIotDeviceForm;
