import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  let styles = {
    root: props => {
      let root = {};

      if (props.fullWidth) {
        root.width = "100%";
      }

      if (props.iconOnly) {
        root.minWidth = "auto";
      }

      return root;
    },
    startIcon: props => {
      let style = {};

      if (props.iconOnly) {
        style.marginRight = -4;
      }

      return style;
    }
  };

  return styles;
});

const PrimaryButton = ({
  onClick,
  disabled,
  children,
  icon,
  fullWidth,
  ...props
}) => {
  const iconOnly = !children;
  const classes = useStyles({ fullWidth, iconOnly });

  return (
    <Button
      variant="contained"
      color="primary"
      classes={classes}
      disabled={disabled}
      startIcon={icon}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
