import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const Title = ({ children, ...props }) => {
  const useStyles = makeStyles(theme => ({
    title: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium
    }
  }));

  const classes = useStyles();

  return (
    <Box className={classes.title} {...props}>
      {children}
    </Box>
  );
};

export default Title;
