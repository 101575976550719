import React from "react";

import ProtectedRoute from "./ProtectedRoute";
import { useAuth } from "../../auth";

const RoleAndPermissionProtectedRoute = ({
  role,
  requiredPermission,
  myPermissions,
  redirectOnDeny,
  ...rest
}) => {
  const { user, hasRole } = useAuth();

  const guard = () => {
    return (
      user &&
      hasRole(user, role) &&
      myPermissions.indexOf(requiredPermission) !== -1
    );
  };

  return (
    <ProtectedRoute guard={guard} {...rest} redirectOnDeny={redirectOnDeny} />
  );
};

export default RoleAndPermissionProtectedRoute;
