import React, { useState } from "react";

import { useApi } from "../../../api";
import I18nText from "../../../elements/I18nText";
import ConfirmationDialog from "../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../elements/Button";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const DeleteContainer = ({ id, onCancel, onDeleted }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const deleteRefDateQuery = () => {
    setDeleting(true);

    api.delete(`/v2/ref-date-queries/${id}`).then(() => {
      setDeleting(false);
      onDeleted();
    });

    enqueueSnackbar("refDateQueries.responses.deleted", {
      variant: "success"
    });
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton disabled={isDeleting} onClick={deleteRefDateQuery} />
      }
    >
      <I18nText>refDateQueries.delete.confirmation</I18nText>
    </ConfirmationDialog>
  );
};

export default DeleteContainer;
