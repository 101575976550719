import React from "react";
import { Formik, Form } from "formik";
import { Grid, makeStyles, TableContainer, Paper } from "@material-ui/core";

import Sensor from "./sensors/Sensor";
import Reporting from "./reporting/Reporting";
import IotDeviceReporting from "./reporting/IotDevice";
import SmsDevice from "./smsDevice/SmsDevice";
import Device from "./device/Device";
import IotDevice from "./iotDevice/IotDevice";
import Header from "./sensors/Header";
import AmpHeader from "./sensors/AmpHeader";
import AmpSensor from "./sensors/AmpSensor";
import Section from "../../../../../elements/Section";
import SectionHeader from "../../../../../elements/SectionHeader";
import I18nText from "../../../../../elements/I18nText";
import { DEVICE_CAPABILITIES, useDevice } from "../../../../../domain/device";
import NetDevice from "./netDevice/NetDevice";
import LxIotDevice from "./lxIotDevice/LxIotDevice";
import { DEVICE_TYPE } from "../../../../../domain/device";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import { useAuth, ROLES } from "../../../../../auth";

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    minWidth: 1100,
    tableLayout: "fixed"
  }
}));

const ConfigurationForm = ({
  values,
  capabilities,
  showSmsGatewaySelect,
  validator,
  onClose,
  onSubmit
}) => {
  const { device } = useDevice();
  const { user, hasRole } = useAuth();

  const classes = useStyles();

  const showTemperatureColumns =
    values.sensors.filter(sensor => sensor.temperatureLimitValue !== undefined)
      .length > 0;

  return (
    <Formik
      initialValues={values}
      validate={validator}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, errors, isSubmitting, values, setFieldValue }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Section>
                  <SectionHeader>
                    <I18nText>
                      devices.settings.configuration.sections.sensors
                    </I18nText>
                  </SectionHeader>
                  <TableContainer component={Paper} elevation={0}>
                    {capabilities === DEVICE_CAPABILITIES.AMPERE ? (
                      <table className={classes.table}>
                        <AmpHeader />
                        <tbody>
                          {values.sensors.map((sensor, idx) => (
                            <AmpSensor
                              key={sensor.id}
                              sensor={sensor}
                              prefix={`sensors.${idx}`}
                              setFieldValue={setFieldValue}
                              errors={errors}
                            />
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className={classes.table}>
                        <Header
                          showTemperatureColumn={showTemperatureColumns}
                          deviceType={device.type}
                        />
                        <tbody>
                          {values.sensors.map((sensor, idx) => (
                            <Sensor
                              key={sensor.id}
                              deviceType={device.type}
                              sensor={sensor}
                              prefix={`sensors.${idx}`}
                              setFieldValue={setFieldValue}
                              errors={errors}
                            />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </TableContainer>
                </Section>
              </Grid>

              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <Grid item xs={12}>
                    <Section>
                      <SectionHeader>
                        <I18nText>
                          devices.settings.configuration.sections.reporting
                        </I18nText>
                      </SectionHeader>
                      {device.type === DEVICE_TYPE.IOT ? (
                        <IotDeviceReporting />
                      ) : (
                        <Reporting />
                      )}
                    </Section>

                    <Grid item xs={12}>
                      <Section>
                        <SectionHeader>
                          <I18nText>
                            devices.settings.configuration.sections.device
                          </I18nText>
                        </SectionHeader>
                        <Device />
                      </Section>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  {device.type === DEVICE_TYPE.SMS && (
                    <Grid item xs={12}>
                      <Section>
                        <SectionHeader>
                          <I18nText>
                            devices.settings.configuration.sections.smsDevice
                          </I18nText>
                        </SectionHeader>
                        <SmsDevice
                          values={values}
                          showSmsGatewaySelect={showSmsGatewaySelect}
                        />
                      </Section>
                    </Grid>
                  )}

                  {device.type === DEVICE_TYPE.IOT && (
                    <Grid item xs={12}>
                      <Section>
                        <SectionHeader>
                          <I18nText>
                            devices.settings.configuration.sections.iotDevice
                          </I18nText>
                        </SectionHeader>
                        <IotDevice values={values} />
                      </Section>
                    </Grid>
                  )}

                  {device.type === DEVICE_TYPE.LX_IOT &&
                    hasRole(user, ROLES.MASTER) && (
                      <Grid item xs={12}>
                        <Section>
                          <SectionHeader>
                            <I18nText>
                              devices.settings.configuration.sections.lxIotDevice
                            </I18nText>
                          </SectionHeader>
                          <LxIotDevice />
                        </Section>
                      </Grid>
                    )}

                  {device.type === DEVICE_TYPE.NET && (
                    <Grid item xs={12} md={6}>
                      <Section>
                        <SectionHeader>
                          <I18nText>
                            devices.settings.configuration.sections.netDevice
                          </I18nText>
                        </SectionHeader>
                        <NetDevice />
                      </Section>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ButtonsWrapper
                  leftButton={
                    <CancelButton onClick={onClose} disabled={isSubmitting} />
                  }
                  rightButton={
                    <SaveButton onClick={submitForm} disabled={isSubmitting} />
                  }
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConfigurationForm;
