import React, { useMemo } from "react";
import { Box, LinearProgress, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const LoadingOverlay = React.memo(
  ({ isLoading, minHeight, children, progressType = "linear" }) => {
    const OverlayContainer = useMemo(
      () =>
        styled(Box)({
          position: "relative",
          minHeight
        }),
      [minHeight]
    );

    const ProgressContainer = styled(Box)({
      position: "absolute",
      transform:
        progressType === "linear" ? "translateX(50%)" : "translate(-50%, -50%)",
      top: progressType === "linear" ? 100 : "50%",
      left: progressType === "linear" ? "initial" : "50%",
      width: progressType === "linear" ? "50%" : "auto",
      zIndex: 1601,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    });

    const OverlayContent = styled(Box)({
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255,255,255,.75)",
      opacity: 0,
      transition: "opacity 0.3s",
      zIndex: 1600
    });

    return (
      <OverlayContainer>
        {isLoading && (
          <>
            <OverlayContent style={{ opacity: isLoading ? 0.8 : 0 }} />
            <ProgressContainer>
              {progressType === "linear" ? (
                <LinearProgress color="secondary" />
              ) : (
                <CircularProgress color="secondary" />
              )}
            </ProgressContainer>
          </>
        )}
        {children}
      </OverlayContainer>
    );
  }
);

export default LoadingOverlay;
