import React from "react";
import { Box, Grid } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import DebtorSelect from "../../../../../elements/FormField/DebtorSelect";
import I18nText from "../../../../../elements/I18nText";

const DebtorForm = ({
  values,
  onClose,
  isSubmitting,
  onSubmit,
  onUpdateValues
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginBottom={4}>
            <DebtorSelect
              name="debtor"
              value={values}
              onUpdate={values => onUpdateValues(values)}
              label={<I18nText>devices.settings.billing.debtorIdent</I18nText>}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={
              <CancelButton onClick={onClose} disabled={isSubmitting} />
            }
            rightButton={
              <SaveButton onClick={onSubmit} disabled={isSubmitting} />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DebtorForm;
