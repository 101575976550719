import React, { useEffect, useState } from "react";
import { Box, LinearProgress, useTheme } from "@material-ui/core";

import { useDevice } from "../../../domain/device";
import { useApi } from "../../../api";
import { useObjectConfig } from "../../../objectConfig";
import { SENSOR_TYPE } from "../../../domain/sensor";
import Chart from "./Chart";
import Legend from "./Legend";
import Process from "./Process";
import NoData from "./NoData";
import { useTranslation } from "react-i18next";
import HideSensorData from "./HideSensorData";
import { ROLES, useAuth } from "../../../auth";

const filterConnectedSensors = sensors => {
  return sensors.filter(
    sensor => sensor.type === SENSOR_TYPE.PROBE && sensor.isConnected
  );
};

const ProcessContainer = () => {
  const api = useApi();
  const theme = useTheme();
  const { t } = useTranslation();
  const { hasRole, user } = useAuth();
  const { device, deviceConfig } = useDevice();
  const { set: setConfig } = useObjectConfig();

  const [reload, setReload] = useState(true);
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [isLoadingData, setLoadingData] = useState(true);
  const [initialTimePeriod, setInitialTimePeriod] = useState(1);
  const [showMarkers, setShowMarkers] = useState(false);
  const [confirmHideSensorData, setConfirmHideSensorData] = useState(null);

  const timePeriodOptions = [
    {
      type: "month",
      count: 1,
      text: t("highcharts.rangeSelector.1m"),
      events: {
        click: () => handleUpdateTimePeriod(0)
      }
    },
    {
      type: "month",
      count: 3,
      text: t("highcharts.rangeSelector.3m"),
      events: {
        click: () => handleUpdateTimePeriod(1)
      }
    },
    {
      type: "year",
      count: 1,
      text: t("highcharts.rangeSelector.1y"),
      events: {
        click: () => handleUpdateTimePeriod(2)
      }
    },
    {
      type: "year",
      count: 3,
      text: t("highcharts.rangeSelector.3y"),
      events: {
        click: () => handleUpdateTimePeriod(3)
      }
    },
    {
      type: "ytd",
      text: new Date().getFullYear(),
      events: {
        click: () => handleUpdateTimePeriod(4)
      }
    }
  ];

  const [excludedSensorIds, setExcludedSensorIds] = useState(
    deviceConfig["ui.process.excludedSensors"]
  );

  useEffect(() => {
    setExcludedSensorIds(deviceConfig["ui.process.excludedSensors"]);
    setInitialTimePeriod(deviceConfig["ui.process.defaultTimePeriod"]);
  }, [deviceConfig]);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);

    const query = `/v2/devices/${device.id}/process?limit=25000`;

    api
      .get(query)
      .then(response => {
        setNoData(false);
        setData(response.data);
        setLoadingData(false);

        const headerStartsAt = response.headers["x-process-start"];
        if (!headerStartsAt) {
          setNoData(true);
        }
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [device, api, reload]);

  useEffect(() => {
    if (!device) {
      return;
    }

    setLoadingData(true);
    setReload(true);
  }, [device]);

  const handleConfirmHideSensorData = sensorDataId => {
    setConfirmHideSensorData(sensorDataId);
  };

  const handleToggleSensor = id => () => {
    let newSet = [...excludedSensorIds];
    const isExcluded = newSet.indexOf(id) !== -1;

    if (!isExcluded) {
      newSet.push(id);
    } else {
      newSet = newSet.filter(item => item !== id);
    }

    setExcludedSensorIds(newSet);

    setConfig("device", device.id, "ui.process.excludedSensors", newSet);
  };

  const handleUpdateTimePeriod = index => {
    setInitialTimePeriod(index);

    // Don't save "4" (YTD) (doesn't work somehow)
    if (index === 4) {
      return;
    }

    setConfig("device", device.id, "ui.process.defaultTimePeriod", index);
  };

  const handleToggleMarkers = () => {
    setShowMarkers(flag => !flag);
  };

  const transformData = apiData => {
    const filteredSensorData = apiData.filter(
      perSensor => excludedSensorIds.indexOf(perSensor.sensorId) === -1
    );

    return filteredSensorData.map(perSensor => {
      const sensor = device.sensors.find(
        sensor => sensor.id === perSensor.sensorId
      );

      if (!sensor) {
        return {};
      }

      return {
        name: perSensor.sensorId,
        boostThreshold: 1,
        color: theme.custom.colors.sensors[sensor.sequence],
        label: `(${sensor.sequence}) ${sensor.name}`,
        unit: sensor.unit,
        threshold: sensor.threshold,
        marker: {
          symbol: "triangle",
          enabled: showMarkers
        },
        data: perSensor.data.map(data => {
          return {
            x: new Date(data[1]).getTime(),
            y: data[3],
            v: data[2],
            id: data[0]
          };
        })
      };
    });
  };

  if (isLoadingData) {
    return (
      <Box marginTop={2}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  if (noData) {
    return <NoData />;
  }

  const chartData = transformData(data);

  const thresholdLineValue =
    chartData.length === 1 ? chartData[0].threshold : null;

  return (
    <>
      {confirmHideSensorData !== null && (
        <HideSensorData
          sensorData={confirmHideSensorData}
          onCancel={() => setConfirmHideSensorData(null)}
          onHidden={() => {
            setReload(true);
            setConfirmHideSensorData(null);
          }}
        />
      )}
      <Process
        ChartComponent={
          <Chart
            data={chartData}
            initialTimePeriod={initialTimePeriod}
            timePeriodOptions={timePeriodOptions}
            showMarkers={showMarkers}
            thresholdLineValue={thresholdLineValue}
            onHideSensorData={handleConfirmHideSensorData}
            grantHideRights={hasRole(user, ROLES.SUPERADMIN)}
          />
        }
        LegendComponent={
          <Legend
            excludedSensorIds={excludedSensorIds}
            connectedSensors={filterConnectedSensors(device.sensors)}
            onToggleSensor={handleToggleSensor}
            showMarkers={showMarkers}
            onToggleMarkers={handleToggleMarkers}
          />
        }
      />
    </>
  );
};

export default ProcessContainer;
