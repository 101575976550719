import React from "react";
import {
  FormControl,
  Checkbox,
  Input,
  InputLabel,
  ListItemText,
  Select,
  TextField,
  MenuItem,
  Grid,
  makeStyles
} from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

import I18nText from "../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../elements/Button";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const Export = ({
  values,
  setValue,
  onClose,
  onSubmit,
  isValid,
  contentTypeOptions,
  periodOptions,
  outputOptions,
  isExporting
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            classes={classes}
            label={<I18nText>devices.export.fileFormat</I18nText>}
            size="small"
            variant="outlined"
            select
            value={values.fileFormat}
            onChange={e => setValue("fileFormat", e.target.value)}
          >
            <MenuItem value="xlsx">Excel (*.xlsx)</MenuItem>
            <MenuItem value="xls">Excel 97 (*.xls)</MenuItem>
            <MenuItem value="csv">CSV (*.csv)</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            classes={classes}
            label={<I18nText>devices.export.period</I18nText>}
            size="small"
            select
            variant="outlined"
            value={values.period}
            onChange={e => setValue("period", e.target.value)}
          >
            {periodOptions.map(period => (
              <MenuItem key={period.value} value={period.value}>
                {period.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            error={values.outputs.length === 0}
          >
            <InputLabel id="outputs-label">
              <I18nText>devices.export.output</I18nText>
            </InputLabel>
            <Select
              style={{ minWidth: 300 }}
              labelId="outputs-label"
              multiple
              required
              value={values.outputs}
              onChange={e => setValue("outputs", e.target.value)}
              input={<Input />}
              renderValue={selected => {
                return selected
                  .map(value => outputOptions.find(o => o.value === value).name)
                  .join(", ");
              }}
            >
              {outputOptions.map(output => (
                <MenuItem key={output.value} value={output.value}>
                  <Checkbox
                    color="primary"
                    checked={values.outputs.indexOf(output.value) !== -1}
                  />
                  <ListItemText primary={output.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="content-types-label">
              <I18nText>devices.export.contentTypes</I18nText>
            </InputLabel>
            <Select
              style={{ minWidth: 300 }}
              labelId="content-types-label"
              multiple
              value={values.contentTypes}
              onChange={e => setValue("contentTypes", e.target.value)}
              input={<Input />}
              renderValue={selected => {
                return selected
                  .map(
                    value =>
                      contentTypeOptions.find(o => o.value === value).name
                  )
                  .join(", ");
              }}
            >
              {contentTypeOptions.map(contentType => (
                <MenuItem key={contentType.value} value={contentType.value}>
                  <Checkbox
                    color="primary"
                    checked={
                      values.contentTypes.indexOf(contentType.value) !== -1
                    }
                  />
                  <ListItemText primary={contentType.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={
              <CancelButton onClick={onClose} disabled={isExporting} />
            }
            rightButton={
              <PrimaryButton
                onClick={onSubmit}
                disabled={!isValid || isExporting}
                icon={<CloudDownloadOutlinedIcon />}
              >
                <I18nText>devices.export.actions.export</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Export;
