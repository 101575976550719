import React, { useState } from "react";
import { DialogContent, Tab, Tabs, makeStyles } from "@material-ui/core";
import FixedPositionDialog from "../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../elements/DialogTitleWithCloseIcon";

import ListExportContainer from "./List/ExportContainer";
import DataFlowExportContainer from "./DataFlow/ExportContainer";
import I18nText from "../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey.A100,
    textTransform: "none",
    position: "relative",
    fontSize: 14,
    fontWeight: "normal",
    "&$selected": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      fontWeight: 500
    },
    "&:hover": {
      fontWeight: 500
    }
  },
  selected: {}
}));

const useTabsStyles = makeStyles({
  indicator: {
    display: "none"
  }
});

const Content = ({ onClose }) => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const [selectedTab, setSelectedTab] = useState("list");

  const handleSelectTab = tab => {
    setSelectedTab(tab);
  };

  return (
    <>
      <Tabs
        classes={tabsClasses}
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, value) => handleSelectTab(value)}
      >
        <Tab
          classes={classes}
          value="list"
          label={<I18nText>devices.export.sections.list</I18nText>}
        />
        <Tab
          classes={classes}
          value="dataFlow"
          label={<I18nText>devices.export.sections.dataFlow</I18nText>}
        />
      </Tabs>
      {selectedTab === "list" && <ListExportContainer onClose={onClose} />}
      {selectedTab === "dataFlow" && (
        <DataFlowExportContainer onClose={onClose} />
      )}
    </>
  );
};

const ExportDialog = ({ onClose }) => {
  return (
    <>
      <FixedPositionDialog open={true} scroll="body">
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>devices.export.title</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <Content onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default ExportDialog;
