import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    "& > *": {
      padding: 0
    },
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));

const ListActionsWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

export default ListActionsWrapper;
