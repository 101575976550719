import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { FastField } from "formik";

import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const FtpUpdateEnabled = () => (
  <BubbleHelpFieldWrapper id="devices.edit.ftpUpdateEnabled">
    <FastField
      component={CheckboxWithLabel}
      type="checkbox"
      name="iotFtpUpdateEnabled"
      color="primary"
      Label={{
        label: "FTP Update"
      }}
    />
  </BubbleHelpFieldWrapper>
);

export default FtpUpdateEnabled;
