import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import I18nText from "../../../elements/I18nText";
import { ROLES, useAuth } from "../../../auth";
import { DEVICE_SMS_GATEWAY_TYPE, DEVICE_TYPE } from "../../../domain/device";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
    textTransform: "none",
    position: "relative",
    fontSize: 14,
    fontWeight: "normal",
    "&$selected": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      fontWeight: 500
    },
    "&:hover": {
      fontWeight: 500
    }
  },
  selected: {}
}));

const useTabsStyles = makeStyles({
  indicator: {
    display: "none"
  }
});

const TypeSelector = ({ onSelectType, selectedType }) => {
  const { user, hasRole } = useAuth();
  const classes = useStyles();
  const tabsClasses = useTabsStyles();

  // If user is not MASTER, she cannot choose, lionel is the only option
  if (!hasRole(user, ROLES.MASTER)) {
    return null;
  }

  return (
    <>
      <Tabs
        classes={tabsClasses}
        value={selectedType}
        variant="scrollable"
        textColor="primary"
        onChange={(_, e) => onSelectType(e)}
      >
        <Tab
          classes={classes}
          value={DEVICE_SMS_GATEWAY_TYPE.LIONEL}
          label={<I18nText>devices.common.smsGatewayTypes.lionel</I18nText>}
        ></Tab>

        <Tab
          classes={classes}
          value={DEVICE_SMS_GATEWAY_TYPE.ONCE}
          label={<I18nText>devices.common.smsGatewayTypes.1nce</I18nText>}
        ></Tab>

        <Tab
          classes={classes}
          value={DEVICE_TYPE.IOT}
          label={<I18nText>devices.common.typeOptions.iot</I18nText>}
        ></Tab>

        <Tab
          classes={classes}
          value={DEVICE_TYPE.LX_IOT}
          label={<I18nText>devices.common.typeOptions.lx_iot</I18nText>}
        ></Tab>

        <Tab
          classes={classes}
          value={DEVICE_TYPE.NET}
          label={<I18nText>devices.common.typeOptions.net</I18nText>}
        ></Tab>
      </Tabs>
    </>
  );
};

export default TypeSelector;
