import React from "react";
import CloseIcon from "@material-ui/icons/Close";

import SecondaryButton from "./SecondaryButton";
import I18nText from "../I18nText";

const CancelButton = ({ onClick, disabled, fullWidth }) => (
  <SecondaryButton
    disabled={disabled}
    icon={<CloseIcon />}
    onClick={onClick}
    fullWidth={fullWidth}
  >
    <I18nText>actions.cancel</I18nText>
  </SecondaryButton>
);

export default CancelButton;
