import React from "react";
import {
  TableCell,
  CircularProgress,
  Checkbox,
  TableRow
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import FormattedDate from "../../../elements/FormattedDate";
import I18nText from "../../../elements/I18nText";

const Row = React.memo(
  ({ device, isSelected, isQuerying, onToggleDevice, status }) => {
    const Spinner = styled(CircularProgress)({
      margin: 0
    });

    const Check = styled(Checkbox)({
      padding: 0
    });

    return (
      <TableRow
        selected={isSelected}
        style={{ cursor: "pointer" }}
        onClick={() => !isQuerying && onToggleDevice(device.id)}
      >
        <TableCell scope="row">{device.id}</TableCell>
        <TableCell>{device.name}</TableCell>
        <TableCell>
          {device.address.street} {device.address.streetNumber},{" "}
          {device.address.countryCode}-{device.address.postalCode}{" "}
          {device.address.city}
        </TableCell>

        <TableCell>
          <FormattedDate>{device.lastReportedAt}</FormattedDate>
        </TableCell>
        <TableCell align="center">
          {!isQuerying ? (
            <Check
              color="primary"
              checked={isSelected}
              onChange={() => {}}
              disabled={isQuerying}
              value={device.id}
            />
          ) : status === "waiting" ? (
            <Spinner size={14} />
          ) : status ? (
            <I18nText>{`messages.statuses.${status}`}</I18nText>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

export default Row;
