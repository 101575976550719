import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";
import ProbeRow from "./ProbeRow";
import CounterRow from "./CounterRow";
import { SENSOR_TYPE } from "../../../../domain/sensor";

const SensorTable = ({ sensors }) => {
  const showTemperatureColumn =
    sensors.filter(
      sensor =>
        sensor.type === SENSOR_TYPE.PROBE &&
        sensor.temperatureLimitType !== "none"
    ).length > 0;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <I18nText>sensors.subType</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>sensors.name</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>sensors.contentType</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>sensors.capacity</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>sensors.fillLimit</I18nText>
          </TableCell>
          <TableCell>
            <I18nText>sensors.threshold</I18nText>
          </TableCell>
          {showTemperatureColumn && (
            <TableCell>
              <I18nText>sensors.temperatureLimitValue</I18nText>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sensors.map(sensor => {
          const RowComponent =
            sensor.type === SENSOR_TYPE.COUNTER ? CounterRow : ProbeRow;

          return (
            <RowComponent
              key={sensor.id}
              sensor={sensor}
              showTemperatureColumn={showTemperatureColumn}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SensorTable;
