import React from "react";
import { DialogContent } from "@material-ui/core";
import FixedPositionDialog from "../../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../../elements/DialogTitleWithCloseIcon";
import ActiveFlagContainer from "./ActiveFlagContainer";
import I18nText from "../../../../../elements/I18nText";

const ActiveFlagDialog = ({ device, onClose }) => {
  return (
    <>
      <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="xs">
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>devices.settings.metadata.sections.activeFlag</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <ActiveFlagContainer device={device} onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default ActiveFlagDialog;
