import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useApi } from "../../api";
import { useAuth } from "../../auth";

const UserSelect = React.memo(
  ({
    value,
    name,
    label,
    atLeastRole = undefined, // show only users having at least this role
    hasError,
    required,
    onUpdate,
    excludeList
  }) => {
    const api = useApi();
    const [options, setOptions] = useState([]);
    const { hasRole } = useAuth();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      api
        .get("/v2/users")
        .then(response => {
          const excludeIdsList = excludeList ? excludeList : [];
          let filtered = response.data.filter(
            user => excludeIdsList.find(id => id === user.id) === undefined
          );

          if (atLeastRole !== undefined) {
            filtered = filtered.filter(user => hasRole(user, atLeastRole));
          }

          setOptions(
            filtered.map(user => ({
              id: user.id,
              username: user.username,
              label: `${user.name} (${user.username})`
            }))
          );
        })
        .catch(() => {})
        .then(() => setLoading(false));
    }, [api, excludeList, atLeastRole, hasRole]);

    return (
      <Autocomplete
        id="user-select"
        variant="outlined"
        name={name}
        disableClearable={true}
        disabled={isLoading}
        autoSelect={true}
        value={value}
        options={options}
        onChange={(_, value) => onUpdate(value)}
        autoHighlight
        getOptionLabel={option => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            error={hasError}
            {...params}
            required={required}
            label={label}
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off"
            }}
          />
        )}
      />
    );
  }
);

export default UserSelect;
