import React, { useState, useEffect, useMemo } from "react";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { startOfDay, endOfDay, subWeeks } from "date-fns";

import Table from "./Table";
import { useApi } from "../../api";
import QueryContainer from "./Query/QueryContainer";
import { DEVICE_TYPE } from "../../domain/device";
import Filter from "./Filter";
import { MESSAGE_TYPE } from "../../domain/message";
import { formatRFC3339 } from "date-fns";
import { REGEXP_ID } from "../../utils/stringUtils";
import MessageDetails from "./Details";
import Messages from "./Messages";
import Pagination from "../../elements/Pagination";
import InfinityPagination from "../../elements/InfinityPagination";
import PageSelect from "../../elements/PageSelect";
import { useAuth, ROLES, PERMISSIONS } from "../../auth";

export const CONTEXT_DEFAULT = "default";
export const CONTEXT_DEVICE = "device";
export const ALL_VALUE = "_all_";

const MessagesContainer = ({ device }) => {
  const defaultFilter = useMemo(
    () => ({
      type: !device ? MESSAGE_TYPE.SMS : ALL_VALUE,
      direction: ALL_VALUE,
      status: ALL_VALUE,
      device,
      mode: ALL_VALUE,
      from: subWeeks(new Date(), 2),
      to: new Date()
    }),
    [device]
  );

  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { user, hasRole, hasPermission } = useAuth();

  const [context, setContext] = useState(
    device ? CONTEXT_DEVICE : CONTEXT_DEFAULT
  );
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [cacheBuster, setCacheBuster] = useState("");
  const [filter, setFilter] = useState(defaultFilter);

  const [sort, setSort] = useState({
    by: "createdAt",
    desc: true
  });

  const handleSelectPerPage = perPage => {
    const value = parseInt(perPage, 10);

    setPerPage(value);
    setPage(1);
  };

  const forceReload = () => setCacheBuster(Math.round(Math.random() * 1000000));

  const handleUpdateFilter = values => {
    setFilter(values);
    setPage(1);
  };

  const handleUpdateSort = by => {
    let desc = false;
    if (sort.by === by) {
      if (sort.desc === false) {
        desc = true;
      }
    }
    setSort({ by, desc });
  };

  useEffect(() => {
    handleUpdateFilter(defaultFilter);
  }, [device, defaultFilter]);

  useEffect(() => {
    setContext(device ? CONTEXT_DEVICE : CONTEXT_DEFAULT);
  }, [device]);

  useEffect(() => {
    let mounted = true;
    let query = "";

    if (sort) {
      query += `&sort=${sort.by}:${sort.desc ? "desc" : "asc"}`;
    }

    if (page) {
      query += `&page=${page}`;
    }

    if (perPage) {
      query += `&per_page=${perPage}`;
    }

    if (context === CONTEXT_DEVICE) {
      query += "&total_count=1";
    }

    if (filter.device) {
      query += `&device_id=${filter.device.id}`;
    }

    if (filter.direction !== ALL_VALUE) {
      query += `&direction=${filter.direction}`;
    }

    if (filter.status !== ALL_VALUE) {
      query += `&status=${filter.status}`;
    }

    if (filter.mode !== ALL_VALUE) {
      query += `&mode=${filter.mode}`;
    }

    if (filter.type !== ALL_VALUE) {
      query += `&type=${filter.type}`;
    }

    const formattedFrom = encodeURIComponent(
      formatRFC3339(startOfDay(filter.from))
    );

    query += `&from=${formattedFrom}`;

    const formattedTo = encodeURIComponent(formatRFC3339(endOfDay(filter.to)));

    query += `&to=${formattedTo}`;

    setLoading(true);
    api.get(`/v2/messages?${query}`).then(response => {
      if (!mounted) {
        return;
      }

      setMessages(response.data);

      if (response.headers["x-total-count"] !== undefined) {
        setTotalCount(response.headers["x-total-count"]);
      } else {
        setTotalCount(null);
      }

      setHasMoreResults(response.headers["x-has-more-results"]);
      setLoading(false);
    });

    return () => {
      mounted = false;
    };
  }, [api, filter, context, page, perPage, sort, cacheBuster]);

  const handleClose = () => {
    history.push(url);
  };

  const handleSelectMessage = messageId => {
    let target = `/messages/${messageId}`;

    if (context === CONTEXT_DEVICE) {
      target = `/devices/${device.id}${target}`;
    }

    history.push(target);
  };

  return (
    <>
      <Switch>
        <Route path={`${url}/:messageId(${REGEXP_ID})`}>
          {({
            match: {
              params: { messageId }
            }
          }) => <MessageDetails messageId={messageId} onClose={handleClose} />}
        </Route>
      </Switch>
      <Messages
        context={context}
        filterComponent={
          <Filter
            context={context}
            values={filter}
            onApply={handleUpdateFilter}
            onRefresh={forceReload}
            queryComponent={
              device &&
              device.ident &&
              hasPermission(device.permissions, PERMISSIONS.QUERY) &&
              hasRole(user, ROLES.ADMIN) &&
              device.type === DEVICE_TYPE.SMS ? (
                <QueryContainer
                  deviceId={device.id}
                  forceReload={forceReload}
                />
              ) : null
            }
          />
        }
        paginationComponent={
          context === CONTEXT_DEVICE ? (
            <Pagination
              totalCount={totalCount}
              perPage={perPage}
              page={page}
              onSelectPage={setPage}
              allowPerPageSelect={false}
            />
          ) : (
            <InfinityPagination
              page={page}
              onSelectPage={setPage}
              showNextPage={hasMoreResults}
            />
          )
        }
        pageSelectComponent={
          <PageSelect
            perPage={perPage}
            perPageOptions={[50, 100, 250, 500]}
            onSelectPerPage={handleSelectPerPage}
          />
        }
        tableComponent={
          <Table
            noData={!isLoading && messages.length === 0}
            messages={messages}
            isUpdating={isLoading}
            onUpdateSort={handleUpdateSort}
            sort={sort}
            showDeviceId={!device}
            onSelectMessage={handleSelectMessage}
          />
        }
      />
    </>
  );
};

export default MessagesContainer;
