import React from "react";
import { useField } from "formik";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles
} from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const IotV230Fail = () => {
  const classes = useStyles();
  const [{ value: enabled }, _, { setValue: setEnabled }] = useField(
    "iotV230FailEnabled"
  );

  const [{ value: text }, metaText, { setValue: setText }] = useField(
    "iotV230FailText"
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  setEnabled(e.target.checked);
                }}
                checked={enabled}
                color="primary"
              />
            }
            label={
              <I18nText>
                devices.settings.configuration.iotV230FailEnabled
              </I18nText>
            }
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <BubbleHelpFieldWrapper id="devices.edit.iotV230FailText">
            <TextField
              classes={classes}
              size="small"
              value={text}
              error={metaText.error}
              variant="outlined"
              onChange={e => setText(e.target.value)}
              inputProps={{ pattern: "[A-Za-z0-9]*", maxLength: 15 }}
              label={
                <I18nText>
                  devices.settings.configuration.iotV230FailText
                </I18nText>
              }
            />
          </BubbleHelpFieldWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default IotV230Fail;
