import { useCallback } from "react";
import { useApi } from "../api";

export const useObjectConfig = () => {
  const api = useApi();

  return {
    get: useCallback(get(api), [api]),
    set: useCallback(set(api), [api])
  };
};

// TODO caching (hash obj,id,key)
const cache = {};

const get = api => collection => {
  let query = "";

  //let mustRefresh = false;
  const fromCache = {};

  collection.forEach(({ obj, id, key }) => {
    const hash = JSON.stringify([obj, id, key]);

    if (cache[hash] !== undefined) {
      fromCache[key] = cache[hash];
    } else {
      //mustRefresh = true;
    }

    query += "&params[]=" + hash;
  });

  return new Promise((resolve, reject) => {
    api
      .get(`/v2/config?${query}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

const set = api => (...params) => {
  let collection;

  if (params.length === 4) {
    collection = [
      {
        obj: params[0],
        id: params[1],
        key: params[2],
        value: params[3]
      }
    ];
  } else if (params.length === 1) {
    collection = params[0];
  } else {
    throw new Error("invalid parameters");
  }

  return new Promise((resolve, reject) => {
    api
      .put("/v2/config", collection)
      .then(response => {
        collection.forEach(({ obj, id, key, value }) => {
          cache[JSON.stringify([obj, id, key])] = value;
        });

        resolve(response.data);
      })
      .catch(error => reject(error));
  });
};
