import React from "react";
import { TableCell, Checkbox, TableRow, styled } from "@material-ui/core";

import FormattedDate from "../../../../elements/FormattedDate";

const Row = React.memo(({ device, isSelected, onToggleDevice }) => {
  const Check = styled(Checkbox)({
    padding: 0
  });

  return (
    <TableRow
      selected={isSelected}
      style={{ cursor: "pointer" }}
      onClick={() => onToggleDevice(device.id)}
    >
      <TableCell scope="row">{device.id}</TableCell>
      <TableCell>{device.name}</TableCell>
      <TableCell>
        {device.address.street} {device.address.streetNumber},{" "}
        {device.address.countryCode}-{device.address.postalCode}{" "}
        {device.address.city}
      </TableCell>
      <TableCell>
        <FormattedDate>{device.lastReportedAt}</FormattedDate>
      </TableCell>
      <TableCell align="center">
        <Check
          color="primary"
          checked={isSelected}
          onChange={() => {}}
          value={device.id}
        />
      </TableCell>
    </TableRow>
  );
});

export default Row;
