import { Grid, FormControlLabel, Radio } from "@material-ui/core";
import React, { useState } from "react";
import { RadioGroup } from "formik-material-ui";

import { FullWidthTextField } from "../../../elements/FormField";
import I18nText from "../../../elements/I18nText";
import UnassignedIotDeviceSelect from "../../../elements/FormField/UnassignedIotDeviceSelect";

const IotDeviceForm = ({ assignValue, assignOnSelect, assignHasError }) => {
  const [action, setAction] = useState("create");

  const handleSelectAction = event => {
    const newAction = event.target.value;
    if (newAction === "create") {
      assignOnSelect({
        id: "",
        label: ""
      });
    }

    setAction(newAction);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup name="action" value={action} onChange={handleSelectAction}>
          <FormControlLabel
            value="create"
            control={<Radio disabled={false} />}
            label="Neues IoT-Gerät einrichten"
          />
          <FormControlLabel
            value="assign"
            control={<Radio disabled={false} />}
            label="IoT-Gerät aus Liste wählen"
          />
        </RadioGroup>
      </Grid>

      {action === "create" && (
        <Grid item xs={12}>
          <FullWidthTextField
            name="thingName"
            variant="outlined"
            required
            label={<I18nText>devices.common.thingName</I18nText>}
            inputProps={{ maxLength: 128, autoComplete: "off" }}
          />
        </Grid>
      )}

      {action === "assign" && (
        <Grid item xs={12}>
          <UnassignedIotDeviceSelect
            name="iotDevice"
            value={assignValue}
            hasError={assignHasError}
            onUpdate={value => assignOnSelect(value)}
            label={<I18nText>devices.create.selectNetDevice</I18nText>}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default IotDeviceForm;
