import React from "react";
import CheckIcon from "@material-ui/icons/Check";

import ConfirmationDialog from "../../../elements/ConfirmationDialog";
import I18nText from "../../../elements/I18nText";
import { PrimaryButton } from "../../../elements/Button";

const ConfirmImpersonate = ({
  username,
  isSubmitting,
  onConfirm,
  onCancel
}) => (
  <ConfirmationDialog
    onCancel={onCancel}
    isSubmitting={isSubmitting}
    ConfirmButton={
      <PrimaryButton
        disabled={isSubmitting}
        icon={<CheckIcon />}
        onClick={onConfirm}
      >
        <I18nText>actions.confirm</I18nText>
      </PrimaryButton>
    }
  >
    <I18nText values={{ username }}>users.impersonate.confirmation</I18nText>
  </ConfirmationDialog>
);

export default ConfirmImpersonate;
