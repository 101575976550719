import React from "react";
import { makeStyles } from "@material-ui/core";

import useFormat from "../../../../hooks/useFormat";
import I18nText from "../../../../elements/I18nText";
import { SENSOR_STATUS } from "../../../../domain/sensor";

const Sensor = ({ sensor, color, width, zIndex }) => {
  const { formatNumber, formatUnit } = useFormat();

  const classes = makeStyles(theme => ({
    container: {
      zIndex,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      width: "80%"
    },
    name: {
      position: "absolute",
      top: -50,
      width: "100%",
      textAlign: "center",
      color: theme.palette.text.primary
    },
    sensorContainer: {
      width,
      position: "relative",
      height: "calc(100% + 1px)",
      border: "1px solid #999",
      backgroundColor: theme.palette.common.white
    },
    content: {
      position: "absolute",
      top: 100 - sensor.pct + "%",
      height: `${sensor.pct}%`,
      width: "100%",
      background: `linear-gradient(90deg, ${color}ee 0%, ${color} 50%, ${color}ee 100%)`
    },
    contentText: {
      position: "absolute",
      bottom: 76,
      width: "100%",
      textAlign: "center",
      fontWeight: "bold"
    },
    errorText: {
      position: "absolute",
      bottom: 76,
      width: "100%",
      textAlign: "center",
      fontWeight: "bold",
      color: theme.palette.error.main
    },
    fillLimit: {
      display: "inlineBlock",
      position: "absolute",
      left: -10,
      top: `calc(${100 - sensor.fillLimit + "%"} - 7px)`,
      width: 0,
      height: 13,
      borderStyle: "solid",
      borderWidth: "7.5px 0 7.5px 13.0px",
      borderColor: `transparent transparent transparent ${theme.palette.warning.main}`
    },
    fillLimitLine: {
      display: "inlineBlock",
      position: "absolute",
      left: 4,
      top: 100 - sensor.fillLimit + "%",
      width: "calc(100% - 5px)",
      height: 0,
      borderBottom: "1px dashed #a4a4a4"
    },
    threshold: {
      display: "inlineBlock",
      position: "absolute",
      left: -10,
      top: `calc(${100 - sensor.threshold + "%"} - 7px)`,
      width: 0,
      height: 13,
      borderStyle: "solid",
      borderWidth: "7.5px 0 7.5px 13.0px",
      borderColor: `transparent transparent transparent ${theme.palette.error.main}`
    },
    thresholdLine: {
      display: "inlineBlock",
      position: "absolute",
      left: 4,
      top: 100 - sensor.threshold + "%",
      width: "calc(100% - 5px)",
      height: 0,
      borderBottom: "1px dashed #a4a4a4"
    }
  }))();

  return (
    <div className={classes.container}>
      <div className={classes.name}>{sensor.name}</div>
      <div className={classes.sensorContainer}>
        {sensor.status === SENSOR_STATUS.OK && (
          <div className={classes.content}></div>
        )}
        <div className={classes.fillLimit}></div>
        <div className={classes.fillLimitLine}></div>
        <div className={classes.threshold}></div>
        <div className={classes.thresholdLine}></div>

        {sensor.status === SENSOR_STATUS.ERROR ? (
          <div className={classes.errorText}>
            {sensor.alarms.length > 0 ? (
              <>
                <p>
                  <I18nText>alarms.title</I18nText>
                </p>
                <p>{sensor.alarms[0].value}</p>
              </>
            ) : (
              <p>
                <I18nText>sensors.status.error</I18nText>
              </p>
            )}
          </div>
        ) : (
          <div className={classes.contentText}>
            <p>
              {formatNumber(sensor.value, 0)} {formatUnit(sensor.unit)}
            </p>
            <p>{formatNumber(sensor.pct, 0)} %</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sensor;
