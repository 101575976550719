import React from "react";
import {
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Box,
  useTheme,
  makeStyles
} from "@material-ui/core";

import I18nText from "../../elements/I18nText";

const useStyles = makeStyles({
  togglePercentages: {
    "&:hover": {
      cursor: "pointer"
    }
  }
});

const TableHeader = ({
  sort,
  onUpdateSort,
  tableColumns,
  showPercentages,
  onTogglePercentages
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const showColumn = column => tableColumns.indexOf(column) !== -1;

  const sorted = by => sort.by === by;

  return (
    <TableHead>
      <TableRow>
        {showColumn("id") && (
          <TableCell>
            <TableSortLabel
              active={sorted("id")}
              direction={sorted("id") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("id")}
            >
              <I18nText>devices.list.table.header.id</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("username") && (
          <TableCell>
            <TableSortLabel
              active={sorted("username")}
              direction={sorted("username") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("username")}
            >
              <I18nText>devices.list.table.header.user</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("name") && (
          <TableCell>
            <TableSortLabel
              active={sorted("name")}
              direction={sorted("name") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("name")}
            >
              <I18nText>devices.list.table.header.name</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("project") && (
          <TableCell>
            <TableSortLabel
              active={sorted("project")}
              direction={sorted("project") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("project")}
            >
              <I18nText>devices.list.table.header.project</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("countryCode") && (
          <TableCell>
            <TableSortLabel
              active={sorted("address.countryCode")}
              direction={
                sorted("address.countryCode") && sort.desc ? "desc" : "asc"
              }
              onClick={() => onUpdateSort("address.countryCode")}
            >
              <I18nText>devices.list.table.header.countryCode</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("postalCode") && (
          <TableCell>
            <TableSortLabel
              active={sorted("address.postalCode")}
              direction={
                sorted("address.postalCode") && sort.desc ? "desc" : "asc"
              }
              onClick={() => onUpdateSort("address.postalCode")}
            >
              <I18nText>devices.list.table.header.postalCode</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("city") && (
          <TableCell>
            <TableSortLabel
              active={sorted("address.city")}
              direction={sorted("address.city") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("address.city")}
            >
              <I18nText>devices.list.table.header.city</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("street") && (
          <TableCell>
            <TableSortLabel
              active={sorted("address.street")}
              direction={sorted("address.street") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("address.street")}
            >
              <I18nText>devices.list.table.header.street</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("lastReportedAt") && (
          <TableCell>
            <TableSortLabel
              active={sorted("lastReportedAt")}
              direction={sorted("lastReportedAt") && sort.desc ? "desc" : "asc"}
              onClick={() => onUpdateSort("lastReportedAt")}
            >
              <I18nText>devices.list.table.header.lastReportedAt</I18nText>
            </TableSortLabel>
          </TableCell>
        )}
        {showColumn("simCredit") && (
          <TableCell>
            <I18nText>devices.list.table.header.simCredit</I18nText>
          </TableCell>
        )}
        {showColumn("status") && (
          <TableCell align="center">
            <I18nText>devices.list.table.header.status</I18nText>
          </TableCell>
        )}

        <TableCell align="center" style={{ minWidth: 400 }}>
          <Box
            className={classes.togglePercentages}
            component="span"
            onClick={onTogglePercentages}
            color={
              showPercentages
                ? theme.palette.grey.A200
                : theme.palette.text.primary
            }
          >
            <I18nText>devices.list.table.header.values</I18nText>
          </Box>{" "}
          |{" "}
          <Box
            className={classes.togglePercentages}
            component="span"
            onClick={onTogglePercentages}
            color={
              showPercentages
                ? theme.palette.text.primary
                : theme.palette.grey.A200
            }
          >
            %
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
