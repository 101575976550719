import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import List from "./List";
import Delete from "./Delete";

const ListContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [netDevices, setNetDevices] = useState([]);
  const [iotDevices, setIotDevices] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoadingNet, setLoadingNet] = useState(false);
  const [isLoadingIot, setLoadingIot] = useState(false);
  const [confirmDeleteDevice, setConfirmDeleteDevice] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoadingIot(true);
    setLoadingNet(true);

    api
      .get("/v2/devices/unassigned/net")
      .then(response => {
        setNetDevices(response.data);
        setLoadingNet(false);
      })
      .catch(() => {})
      .then(() => setReload(false));

    api
      .get("/v2/devices/unassigned/iot")
      .then(response => {
        setIotDevices(response.data);
        setLoadingIot(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, reload]);

  const handleDelete = device => {
    setConfirmDeleteDevice(device);
  };

  const handleDeleted = () => {
    setReload(true);
    setConfirmDeleteDevice(null);
    history.push(url);
  };

  return (
    <>
      {confirmDeleteDevice && (
        <Delete
          device={confirmDeleteDevice}
          onCancel={() => setConfirmDeleteDevice(null)}
          onDeleted={handleDeleted}
        />
      )}
      <List
        isLoadingNet={isLoadingNet}
        isLoadingIot={isLoadingIot}
        netDevices={netDevices}
        iotDevices={iotDevices}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ListContainer;
