import React, { useCallback, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { useDevice } from "../../../../domain/device";
import MetadataDialog from "./BaseData/MetadataDialog";
import SectionHeader from "../../../../elements/SectionHeader";
import I18nText from "../../../../elements/I18nText";
import DescriptionList from "../../../../elements/DescriptionList";
import Section from "../../../../elements/Section";
import RefDateQueries from "./RefDateQueries";
import { nl2br } from "../../../../utils/stringUtils";
import { useAuth, ROLES, PERMISSIONS } from "../../../../auth";
import Comment from "./Comment";
import MasterComment from "./MasterComment";
import PublicUrl from "./PublicUrl";
import ActiveFlag from "./ActiveFlag";
import Delete from "./Delete";
import { DeleteButton } from "../../../../elements/Button";
import { useTheme } from "@material-ui/styles";
import Reset from "./Reset";
import { useWhitelabel } from "../../../../domain/whitelabel";
import { useEffect } from "react";

const Metadata = () => {
  const { device, reload } = useDevice();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { user, hasRole, hasPermission } = useAuth();
  const theme = useTheme();

  const [showResetData, setShowResetData] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);

  const { whitelabelConfig, whitelabelConfigLoaded } = useWhitelabel();

  useEffect(() => {
    if (!whitelabelConfigLoaded) {
      return;
    }

    if (whitelabelConfig["ui.devices.settings.showResetData"]) {
      setShowResetData(true);
    }
  }, [whitelabelConfig, whitelabelConfigLoaded]);

  const handleClose = doReload => {
    history.push(url);

    doReload && reload();
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const handleReset = () => {
    setConfirmReset(true);
  };

  const handleDeleted = () => {
    history.push("/devices");
  };

  const editMetadata = () =>
    history.push(`/devices/${device.id}/settings/metadata/edit`);

  const editPublicUrl = () =>
    history.push(`/devices/${device.id}/settings/metadata/public-url`);

  const editActiveFlag = () =>
    history.push(`/devices/${device.id}/settings/metadata/active-flag`);

  const editComment = () =>
    history.push(`/devices/${device.id}/settings/metadata/comment`);

  const editMasterComment = () =>
    history.push(`/devices/${device.id}/settings/metadata/master-comment`);

  const getGeneralOptions = useCallback(() => {
    const options = [
      {
        label: "devices.settings.metadata.user",
        value: device.user.username
      },
      {
        label: "devices.common.name",
        value: device.name
      }
    ];

    if (device.project) {
      options.push({
        label: "devices.common.project",
        value: device.project
      });
    }

    return options;
  }, [device]);

  const getAddressOptions = useCallback(() => {
    const options = [
      {
        label: "devices.settings.metadata.street",
        value: `${device.address.street} ${device.address.streetNumber}`
      },
      {
        label: "devices.settings.metadata.postalCode",
        value: device.address.postalCode
      },
      {
        label: "devices.settings.metadata.city",
        value: device.address.city
      },
      {
        label: "devices.settings.metadata.countryCode",
        value: device.address.countryCode ? (
          <I18nText>{`countries.${device.address.countryCode}`}</I18nText>
        ) : (
          ""
        )
      }
    ];

    return options;
  }, [device]);

  return (
    <>
      {confirmDelete && (
        <Delete
          device={device}
          onCancel={() => setConfirmDelete(false)}
          onDeleted={handleDeleted}
        />
      )}
      {confirmReset && (
        <Reset device={device} onCancel={() => setConfirmReset(false)} />
      )}

      <Switch>
        <Route path={`${url}/edit`}>
          <MetadataDialog device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/comment`}>
          <Comment device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/public-url`}>
          <PublicUrl device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/active-flag`}>
          <ActiveFlag device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/master-comment`}>
          <MasterComment device={device} onClose={handleClose} />
        </Route>
      </Switch>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section>
            <Box marginTop={2}>
              <SectionHeader
                onEdit={editMetadata}
                disableEdit={
                  !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
                }
              >
                <I18nText>devices.settings.metadata.sections.baseData</I18nText>
              </SectionHeader>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DescriptionList options={getGeneralOptions()} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DescriptionList options={getAddressOptions()} />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>

      {hasPermission(device.permissions, PERMISSIONS.EDIT) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Section>
              <SectionHeader>
                <I18nText>
                  devices.settings.metadata.sections.refDateQueries
                </I18nText>
              </SectionHeader>
              <RefDateQueries deviceId={device.id} />
            </Section>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader
              onEdit={editPublicUrl}
              disableEdit={
                !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
              }
            >
              <I18nText>devices.settings.metadata.sections.publicUrl</I18nText>
            </SectionHeader>
            {device.publicUrlAccessEnabled ? (
              <span style={{ color: theme.palette.success.main }}>
                <I18nText>
                  devices.settings.metadata.publicUrlAccess.activated
                </I18nText>
              </span>
            ) : (
              <I18nText>
                devices.settings.metadata.publicUrlAccess.deactivated
              </I18nText>
            )}
            {device.publicUrlAccessEnabled && (
              <Box style={{ marginTop: 8 }}>
                <a
                  href={device.publicUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon style={{ fontSize: 12 }} />{" "}
                  <I18nText>
                    devices.settings.configuration.publicUrlOpenInNewTab
                  </I18nText>
                </a>
              </Box>
            )}
          </Section>
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader
              onEdit={editActiveFlag}
              disableEdit={
                !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
              }
            >
              <I18nText>devices.settings.metadata.sections.activeFlag</I18nText>
            </SectionHeader>
            {device.active ? (
              <span style={{ color: theme.palette.success.main }}>
                <I18nText>devices.settings.configuration.activeFlag</I18nText>
              </span>
            ) : (
              <I18nText>devices.settings.configuration.activeFlagOff</I18nText>
            )}
          </Section>
        </Grid>

        <Grid item xs={12} md={6}>
          <Section>
            <SectionHeader
              onEdit={editComment}
              disableEdit={
                !hasPermission(device.permissions, PERMISSIONS.CONFIGURATION)
              }
            >
              <I18nText>devices.settings.metadata.sections.comment</I18nText>
            </SectionHeader>
            {nl2br(device.comment)}
          </Section>
        </Grid>

        {hasRole(user, ROLES.MASTER) && (
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader onEdit={editMasterComment}>
                <I18nText>
                  devices.settings.metadata.sections.masterComment
                </I18nText>
              </SectionHeader>
              {nl2br(device.masterComment)}
            </Section>
          </Grid>
        )}
      </Grid>

      {(showResetData || hasRole(user, ROLES.MASTER)) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>devices.settings.metadata.sections.reset</I18nText>
              </SectionHeader>
              <Box display="flex" justifyContent="flex-end">
                <DeleteButton
                  onClick={handleReset}
                  text="devices.settings.metadata.reset"
                ></DeleteButton>
              </Box>
            </Section>
          </Grid>
        </Grid>
      )}

      {hasRole(user, ROLES.MASTER) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Section>
              <SectionHeader>
                <I18nText>devices.settings.metadata.sections.delete</I18nText>
              </SectionHeader>
              <Box display="flex" justifyContent="flex-end">
                <DeleteButton
                  onClick={handleDelete}
                  text="devices.settings.metadata.delete"
                >
                  Anlage löschen
                </DeleteButton>
              </Box>
            </Section>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Metadata;
