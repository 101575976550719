import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { PrimaryButton } from "../../../../elements/Button";
import I18nText from "../../../../elements/I18nText";
import { EVENT_TYPE } from "../../../../domain/notificationEvent";
import FormattedPhoneNumber from "../../../../elements/FormattedPhoneNumber";
import {
  ListActionsWrapper,
  EditAction,
  DeleteAction
} from "../../../../elements/ListAction";
import FormattedEvent from "./FormattedEvent";
import { useAuth, PERMISSIONS } from "../../../../auth";
import { useDevice } from "../../../../domain/device";

const List = React.memo(
  ({ recipients, onEditRecipient, onCreateRecipient, onDeleteRecipient }) => {
    const { hasPermission } = useAuth();
    const { device } = useDevice();

    const getEventByName = (recipient, eventName) => {
      return recipient.events.find(event => event.name === eventName);
    };

    return (
      <>
        {hasPermission(device.permissions, PERMISSIONS.CONFIGURATION) && (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
              <PrimaryButton onClick={onCreateRecipient} icon={<AddIcon />}>
                <I18nText>devices.settings.notifications.actions.new</I18nText>
              </PrimaryButton>
            </Grid>
          </Grid>
        )}

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <I18nText>devices.settings.notifications.recipient</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>contacts.common.cellNumber</I18nText>
              </TableCell>
              <TableCell>
                <I18nText>contacts.common.email</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>devices.settings.notifications.events.alarm</I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>
                  devices.settings.notifications.events.threshold
                </I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>
                  devices.settings.notifications.events.forward
                </I18nText>
              </TableCell>
              <TableCell align="center">
                <I18nText>
                  devices.settings.notifications.events.sim_credit_low
                </I18nText>
              </TableCell>
              <TableCell style={{ minWidth: 75 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipients.map(recipient => (
              <TableRow key={recipient.id}>
                <TableCell>{recipient.contact.handle}</TableCell>
                <TableCell>
                  <FormattedPhoneNumber fallback="">
                    {recipient.contact.cellNumber}
                  </FormattedPhoneNumber>
                </TableCell>
                <TableCell>{recipient.contact.email}</TableCell>
                <TableCell align="center">
                  <FormattedEvent
                    event={getEventByName(recipient, EVENT_TYPE.ALARM)}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormattedEvent
                    event={getEventByName(recipient, EVENT_TYPE.THRESHOLD)}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormattedEvent
                    event={getEventByName(recipient, EVENT_TYPE.FORWARD)}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormattedEvent
                    event={getEventByName(recipient, EVENT_TYPE.SIM_CREDIT_LOW)}
                  />
                </TableCell>
                <TableCell align="right">
                  {hasPermission(
                    device.permissions,
                    PERMISSIONS.CONFIGURATION
                  ) && (
                    <ListActionsWrapper>
                      <EditAction onClick={() => onEditRecipient(recipient)} />
                      <DeleteAction
                        onClick={() => onDeleteRecipient(recipient)}
                      />
                    </ListActionsWrapper>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default List;
