import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../api";
import Details from "./Details";

const DetailsContainer = ({ messageId, onClose }) => {
  const api = useApi();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    api.get(`/v2/messages/${messageId}`).then(response => {
      setMessage(response.data);
    });
  }, [api, messageId]);

  return (
    <Dialog onClose={onClose}>
      <Details message={message} />
    </Dialog>
  );
};

export default DetailsContainer;
