import React from "react";
import { IconButton, Box } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Logout = ({ onLogout, height = 48 }) => (
  <Box height={height} clone>
    <IconButton onClick={onLogout} color="inherit">
      <ExitToAppIcon />
    </IconButton>
  </Box>
);

export default Logout;
