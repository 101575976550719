import React, { useState, useEffect } from "react";

import Export from "./Export";
import { useApi } from "../../../api";
import config from "../../../config";
import { format } from "date-fns";
import { useTranslation, useLocale } from "../../../i18n";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const ExportContainer = ({ onClose }) => {
  const { locale } = useLocale();
  const { enqueueSnackbar } = useI18nSnackbar();
  const { t } = useTranslation();
  const getPeriodOptions = () => {
    const thisYear = format(new Date(), "yyyy");
    const thisMonth = format(new Date(), "MM");
    const last13months = {
      name: `${thisMonth}.${thisYear - 1} - ${thisMonth}.${thisYear}`,
      value: "last13month"
    };

    const startYear = 2011;

    var options = [last13months];
    for (let c = parseInt(thisYear, 10) - 1; c >= startYear; c--) {
      options.push({
        name: c,
        value: c
      });
    }

    return options;
  };

  const api = useApi();
  const [isExporting, setExporting] = useState(false);
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [isValid, setValid] = useState(true);
  const outputOptions = [
    {
      value: "outflow",
      name: t("devices.export.outputs.outflow")
    },
    {
      value: "inflow",
      name: t("devices.export.outputs.inflow")
    },
    {
      value: "qty",
      name: t("devices.export.outputs.qty")
    }
  ];

  const [values, setValues] = useState({
    fileFormat: "xlsx",
    contentTypes: [],
    outputs: ["inflow", "outflow", "qty"],
    period: getPeriodOptions()[0].value
  });

  useEffect(() => {
    api.get("/v2/content-types").then(response => {
      const options = [];
      response.data.forEach(value => {
        options.push({
          name: t(`sensors.contentTypes.${value}`),
          value
        });
      });

      setContentTypeOptions(options);
    });
  }, [api, t]);

  const handleSetValue = (key, value) => {
    if (key === "outputs") {
      setValid(value.length > 0);
    }

    setValues(state => {
      state[key] = value;

      setValues({ ...state });
    });
  };

  const handleSubmit = () => {
    if (values.outputs.length === 0) {
      setValid(false);

      return;
    }

    let query = `export_type=probe_data_flow&file_format=${values.fileFormat}&period=${values.period}`;

    values.contentTypes.forEach(contentType => {
      query += `&content_types[]=${contentType}`;
    });

    values.outputs.forEach(output => {
      query += `&outputs[]=${output}`;
    });

    if (locale) {
      query += `&locale=${locale}`;
    }

    enqueueSnackbar("devices.export.responses.success", {
      variant: "success"
    });

    setExporting(true);
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setExporting(false);
        onClose();
      }
    }, 1000);

    const location = `${config.api.baseUrl}/v2/devices/export?${query}`;
    window.location.href = location;
  };

  return (
    <Export
      onSubmit={handleSubmit}
      onClose={onClose}
      values={values}
      contentTypeOptions={contentTypeOptions}
      periodOptions={getPeriodOptions()}
      outputOptions={outputOptions}
      setValue={handleSetValue}
      isValid={isValid}
      isExporting={isExporting}
    />
  );
};

export default ExportContainer;
