import React from "react";
import { FastField } from "formik";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";

const FillHeightMm = ({ prefix }) => (
  <FastField
    component={NumberField}
    name={`${prefix}.fillHeightMm`}
    validate={numberValidator({ min: 0, max: 1000000 })}
    min={0}
    max={1000000}
  />
);

export default FillHeightMm;
