import React from "react";
import { Formik, Form } from "formik";
import { Grid, MenuItem } from "@material-ui/core";

import I18nText from "../../../../elements/I18nText";
import {
  CountrySelect,
  FullWidthSelect,
  FullWidthTextField
} from "../../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../elements/Button";

const BasedataForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, errors, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FullWidthSelect
                    variant="outlined"
                    label={<I18nText>users.common.legalType</I18nText>}
                    name="contact.legalType"
                  >
                    <MenuItem value="">&nbsp;</MenuItem>

                    <MenuItem value="private">
                      <I18nText>users.legalTypes.private</I18nText>
                    </MenuItem>
                    <MenuItem value="company">
                      <I18nText>users.legalTypes.company</I18nText>
                    </MenuItem>
                  </FullWidthSelect>
                </Grid>

                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.company"
                    variant="outlined"
                    label={<I18nText>users.common.company</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FullWidthSelect
                    variant="outlined"
                    label={<I18nText>users.common.form</I18nText>}
                    name="contact.form"
                  >
                    <MenuItem value="">&nbsp;</MenuItem>

                    <MenuItem value="mr">
                      <I18nText>users.forms.mr</I18nText>
                    </MenuItem>
                    <MenuItem value="mrs">
                      <I18nText>users.forms.mrs</I18nText>
                    </MenuItem>
                  </FullWidthSelect>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FullWidthTextField
                    name="contact.firstName"
                    variant="outlined"
                    label={<I18nText>users.common.firstName</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <FullWidthTextField
                    name="contact.lastName"
                    variant="outlined"
                    label={<I18nText>users.common.lastName</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <FullWidthTextField
                    name="address.street"
                    variant="outlined"
                    label={<I18nText>users.common.street</I18nText>}
                    inputProps={{ maxLength: 128, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FullWidthTextField
                    name="address.streetNumber"
                    variant="outlined"
                    label={<I18nText>users.common.streetNumber</I18nText>}
                    inputProps={{ maxLength: 12, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FullWidthTextField
                    name="address.postalCode"
                    variant="outlined"
                    label={<I18nText>users.common.postalCode</I18nText>}
                    inputProps={{ maxLength: 8, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <FullWidthTextField
                    name="address.city"
                    variant="outlined"
                    label={<I18nText>users.common.city</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CountrySelect
                    name="address.countryCode"
                    label={<I18nText>users.common.countryCode</I18nText>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.email"
                    variant="outlined"
                    type="email"
                    label={<I18nText>users.common.email</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.website"
                    variant="outlined"
                    type="url"
                    placeholder="https://"
                    label={<I18nText>users.common.website</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.cellNumber"
                    variant="outlined"
                    label={<I18nText>users.common.cellNumber</I18nText>}
                    inputProps={{ maxLength: 32, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.phoneNumber"
                    variant="outlined"
                    label={<I18nText>users.common.phoneNumber</I18nText>}
                    inputProps={{ maxLength: 32, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.faxNumber"
                    variant="outlined"
                    label={<I18nText>users.common.faxNumber</I18nText>}
                    inputProps={{ maxLength: 32, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="contact.taxId"
                    variant="outlined"
                    label={<I18nText>users.common.taxId</I18nText>}
                    inputProps={{ maxLength: 32, autoComplete: "off" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BasedataForm;
