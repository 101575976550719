import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useApi } from "../../api";
import { useAuth } from "../../auth";
import useI18nSnackbar from "../../hooks/useI18nSnackbar";

const Snackbar = () => {
  const { user } = useAuth();
  const api = useApi();
  const [snackbarKey, setSnackbarKey] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useI18nSnackbar();

  useEffect(() => {
    if (!user || !user.impersonatedBy) {
      closeSnackbar(snackbarKey);
      return;
    }

    if (snackbarKey !== null) {
      return;
    }

    const exitImpersonation = () => {
      api.get(`/v2/users/me?_impersonate_user=_exit`).then(() => {
        closeSnackbar(snackbarKey);
        window.location.href = "/";
      });
    };

    const action = key => (
      <Button
        onClick={() => {
          exitImpersonation(key);
        }}
      >
        Logout
      </Button>
    );

    const key = enqueueSnackbar(
      "users.responses.impersonating",
      {
        preventDuplicate: true,
        persist: true,
        action,
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        }
      },
      { username: user.username }
    );

    setSnackbarKey(key);
  }, [user, api, enqueueSnackbar, closeSnackbar, snackbarKey, setSnackbarKey]);

  return null;
};

export default Snackbar;
