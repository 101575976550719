import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from "@material-ui/core";

import Row from "./Row";
import { Trans } from "../../../../i18n";
import LoadingOverlay from "../../../../elements/LoadingOverlay";

const Table = React.memo(
  ({ devices, selectedDeviceIds, onToggleDevice, isUpdating }) => {
    const isDeviceSelected = deviceId =>
      selectedDeviceIds.indexOf(deviceId) !== -1;

    return (
      <LoadingOverlay isLoading={isUpdating} minHeight={150}>
        <TableContainer>
          <MuiTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans i18nKey="devices.common.id" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="devices.common.name" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="devices.common.address" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="devices.common.lastReportedAt" />
                </TableCell>
                <TableCell align="center">{selectedDeviceIds.length}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map(device => (
                <Row
                  key={device.id}
                  device={device}
                  isSelected={isDeviceSelected(device.id)}
                  onToggleDevice={onToggleDevice}
                />
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </LoadingOverlay>
    );
  }
);

export default Table;
