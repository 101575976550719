import React from "react";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import { MenuItem, InputAdornment, makeStyles } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";
import { TIME_UNITS } from "../../../../../../utils/timeUtils";
import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const useStyles = makeStyles({
  root: {
    paddingRight: 0
  },
  input: {
    textAlign: "right"
  }
});

const TimeInterval = () => {
  const unitOptions = [TIME_UNITS.HOUR, TIME_UNITS.DAY];
  const classes = useStyles();

  return (
    <BubbleHelpFieldWrapper id="devices.edit.timeInterval">
      <FastField
        component={NumberField}
        name="timeInterval.value"
        validate={numberValidator({ required: true, min: 0, max: 99 })}
        label="devices.settings.configuration.timeInterval"
        min={0}
        required
        max={99}
        InputProps={{
          classes,
          endAdornment: (
            <InputAdornment position="end">
              <FastField
                component={TextField}
                select
                size="small"
                variant="outlined"
                name="timeInterval.unit"
              >
                {unitOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    <I18nText>{`date.timeUnits.${option}`}</I18nText>
                  </MenuItem>
                ))}
              </FastField>
            </InputAdornment>
          )
        }}
      />
    </BubbleHelpFieldWrapper>
  );
};

export default TimeInterval;
