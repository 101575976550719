import React from "react";
import { Box } from "@material-ui/core";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import { CHANNEL } from "../../../../domain/notificationEvent";

const FormattedEvent = ({ event }) => {
  if (!event) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {event.channels.indexOf(CHANNEL.SMS) !== -1 && (
        <SmartphoneOutlinedIcon style={{ fontSize: 14 }} />
      )}
      {event.channels.indexOf(CHANNEL.MAIL) !== -1 && (
        <MailOutlineOutlinedIcon style={{ fontSize: 16 }} />
      )}
    </Box>
  );
};

export default FormattedEvent;
