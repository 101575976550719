import React from "react";
import { Box, Grid } from "@material-ui/core";

import AppLayout from "../AppLayout";
import { CONTEXT_DEVICE, CONTEXT_DEFAULT } from "./MessagesContainer";

const Layout = ({ context, children }) => {
  return context === CONTEXT_DEVICE ? (
    children
  ) : (
    <AppLayout selectedMenu="messages">{children}</AppLayout>
  );
};

const Messages = ({
  context,
  paginationComponent,
  pageSelectComponent,
  tableComponent,
  filterComponent
}) => {
  return (
    <Layout context={context}>
      <Grid container>
        <Grid item xs={12}>
          <Box padding={context === CONTEXT_DEFAULT ? 3 : 0}>
            {filterComponent}
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" paddingX={1} paddingTop={3} paddingBottom={2}>
        <Box display="flex" flex="1 0 auto" alignItems="flex-end"></Box>
        <Box display="flex" flex="1 0 auto" justifyContent="center">
          {paginationComponent}
        </Box>
        <Box
          display="flex"
          flex="1 1 auto"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {pageSelectComponent}
        </Box>
      </Box>

      {tableComponent}

      <Box display="flex" justifyContent="center" paddingX={1} paddingY={2}>
        <Box display="flex" flex="1 0 auto" alignItems="flex-end"></Box>
        <Box display="flex" flex="1 0 auto" justifyContent="center">
          {paginationComponent}
        </Box>
        <Box
          display="flex"
          flex="1 1 auto"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {pageSelectComponent}
        </Box>
      </Box>
    </Layout>
  );
};

export default Messages;
