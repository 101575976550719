import React from "react";
import { makeStyles } from "@material-ui/core";

const Section = ({ children }) => {
  const useStyles = makeStyles(theme => ({
    section: {
      marginBottom: 14
    }
  }));

  const classes = useStyles();

  return <div className={classes.section}>{children}</div>;
};

export default Section;
