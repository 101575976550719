import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.custom.dialog.margin,
    verticalAlign: "top"
  }
}));

const FixedPositionDialog = props => {
  const classes = useStyles();

  return <Dialog classes={classes} {...props} />;
};

export default FixedPositionDialog;
