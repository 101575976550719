import React from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../api";
import Logout from "./Logout";
import useI18nSnackbar from "../../hooks/useI18nSnackbar";
import { useAuth } from "../../auth";

const LogoutContainer = () => {
  const api = useApi();
  const { setUser } = useAuth();
  const history = useHistory();
  const { enqueueSnackbar } = useI18nSnackbar();

  const handleLogout = () => {
    api.post("/v2/logout").then(() => {
      setUser(null);
      history.push("/login");

      enqueueSnackbar("login.loggedOut", {
        variant: "success"
      });
    });
  };

  return <Logout onLogout={handleLogout} />;
};

export default LogoutContainer;
