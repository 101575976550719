import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useApi } from "../../api";
import useDebounce from "../../hooks/useDebounce";
import I18nText from "../I18nText";

const renderDeviceLabel = device => {
  return `#${device.id} ${device.name}, ${device.address.street} ${device.address.streetNumber}, ${device.address.countryCode}-${device.address.postalCode} ${device.address.city}`;
};

const DeviceSelect = React.memo(
  ({
    value,
    name,
    label,
    excludedIds = [],
    hasError,
    required,
    onUpdate,
    permission
  }) => {
    const api = useApi();
    const [options, setOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 600);

    useEffect(() => {
      let mounted = true;

      if (!debouncedSearchTerm) {
        return undefined;
      }

      let query = `/v2/devices?per_page=100&search_term=${debouncedSearchTerm}`;

      if (permission) {
        query += `&permission=${permission}`;
      }

      api
        .get(query)
        .then(response => {
          if (!mounted) {
            return;
          }

          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          let filtered = response.data.filter(
            device => excludedIds.find(id => id === device.id) === undefined
          );

          const results = filtered.map(device => ({
            id: device.id,
            label: renderDeviceLabel(device)
          }));

          setOptions([...newOptions, ...results]);
        })
        .catch(() => {});

      return () => {
        mounted = false;
      };
    }, [api, value, permission, excludedIds, debouncedSearchTerm]);

    return (
      <Autocomplete
        id="device-select"
        variant="outlined"
        name={name}
        autoSelect={true}
        filterOptions={x => x}
        value={value}
        noOptionsText={<I18nText>devices.selector.noOptions</I18nText>}
        options={options}
        onInputChange={(e, value) => {
          setSearchTerm(value);
        }}
        onChange={(_, value) => onUpdate(value)}
        getOptionLabel={option => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            error={hasError}
            {...params}
            required={required}
            label={label}
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off"
            }}
          />
        )}
      />
    );
  }
);

export default DeviceSelect;
