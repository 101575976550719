import React, { Fragment } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import I18nText from "../I18nText";

const useStyles = makeStyles({
  label: {
    fontWeight: 500
  }
});

const DescriptionList = ({ options, xs = 6, sm = 6, md = 5 }) => {
  const classes = useStyles();
  const complementaryXs = 12 - xs;
  const complementarySm = 12 - sm;
  const complementaryMd = 12 - md;

  return (
    <Grid container spacing={1}>
      {options.map(option => (
        <Fragment key={option.label}>
          <Grid item xs={xs} sm={sm} md={md}>
            <Box className={classes.label}>
              <I18nText>{option.label}</I18nText>
            </Box>
          </Grid>
          <Grid
            item
            xs={complementaryXs}
            sm={complementarySm}
            md={complementaryMd}
          >
            {option.value}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

export default DescriptionList;
