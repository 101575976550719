import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import FormContainer from "./FormContainer";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const CreateContainer = ({ onClose }) => {
  const api = useApi();
  const history = useHistory();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = values => {
    setSubmitting(true);

    const { id, ...params } = values;

    api
      .post("/v2/ref-date-queries", params)
      .then(() => {
        enqueueSnackbar("refDateQueries.responses.created", {
          variant: "success"
        });

        setSubmitting(false);
        history.push({ pathname: "/devices/ref-date-queries", reload: true });
      })
      .catch(() => setSubmitting(false));
  };

  const initialValues = {
    isActive: true,
    intervalType: "monthly",
    monthlyInterval: "monthly_first",
    dailyIntervals: [],
    intradayInterval: "intraday_12",
    queryAt: new Date(Math.round(new Date().getTime() / 300000) * 300000), // rounded to nearest 5 mins
    olderThan: 1800
  };

  return (
    <FormContainer
      onSubmit={handleSubmit}
      onClose={onClose}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
    />
  );
};

export default CreateContainer;
