import React from "react";
import { FastField } from "formik";
import { InputAdornment } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const ValueInterval = React.memo(() => (
  <BubbleHelpFieldWrapper id="devices.edit.valueInterval">
    <FastField
      component={NumberField}
      name="valueInterval"
      validate={numberValidator({ min: 0, max: 99 })}
      min={0}
      required
      max={99}
      label="devices.settings.configuration.valueInterval"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>
      }}
    />
  </BubbleHelpFieldWrapper>
));

export default ValueInterval;
