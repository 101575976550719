import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import I18nText from "../I18nText";
import AlertButton from "./AlertButton";

const DeleteButton = ({
  onClick,
  disabled,
  fullWidth,
  iconOnly = false,
  text = null
}) => (
  <AlertButton
    disabled={disabled}
    icon={<DeleteIcon />}
    onClick={onClick}
    fullWidth={fullWidth}
  >
    {!iconOnly && (
      <I18nText>{text !== null ? text : "actions.delete"}</I18nText>
    )}
  </AlertButton>
);

export default DeleteButton;
