import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";

import { useWhitelabel, WHITELABELS } from "../../domain/whitelabel";
import mobeneLogo from "../../assets/mobene/login.png";
import smartinspectorLogo from "../../assets/smartinspector/login.jpg";
import waterviewLogo from "../../assets/waterview/header.jpg";
import aconLogo from "../../assets/acon/login.png";
import aconLogo2x from "../../assets/acon/login@2x.png";

const useStyles = makeStyles({
  logo: {
    fontSize: 56
  },
  title: {
    display: "flex",
    paddingTop: 0,
    fontWeight: 600,
    fontSize: 45
  }
});

const WhitelabeledLoginLogo = () => {
  const classes = useStyles();
  const { whitelabel } = useWhitelabel();

  if (whitelabel.name === WHITELABELS.MOBENE) {
    return (
      <Box paddingBottom={2}>
        <img style={{ maxWidth: "100%" }} src={mobeneLogo} alt="Mobene" />
      </Box>
    );
  }

  if (whitelabel.name === WHITELABELS.ACON) {
    return (
      <Box paddingBottom={2}>
        <img
          style={{ maxWidth: "100%" }}
          src={aconLogo}
          srcSet={`${aconLogo} 1x, ${aconLogo2x} 2x`}
          alt="acon"
        />
      </Box>
    );
  }

  if (whitelabel.name === WHITELABELS.SMARTINSPECTOR) {
    return (
      <Box paddingBottom={2}>
        <img
          style={{ maxWidth: "100%" }}
          src={smartinspectorLogo}
          alt="Smartinspector"
        />
      </Box>
    );
  }

  if (whitelabel.name === WHITELABELS.WATERVIEW) {
    return (
      <Box paddingBottom={2}>
        <img style={{ maxWidth: "100%" }} src={waterviewLogo} alt="WaterView" />
      </Box>
    );
  }

  return (
    <Box display="flex">
      <EqualizerIcon className={classes.logo} />{" "}
      <Box className={classes.title}>OilView</Box>
    </Box>
  );
};

export default WhitelabeledLoginLogo;
