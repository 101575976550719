import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";
import { setFieldErrorsFromResponse } from "../../../../../utils/formErrors";
import { valueAndTimeUnitToSeconds } from "../../../../../utils/timeUtils";
import FormContainer from "../Form/FormContainer";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const EditContainer = ({ device, recipientId, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [recipient, setRecipient] = useState(null);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/notification-recipients/${recipientId}`)
      .then(response => {
        setRecipient(response.data);
        setLoading(false);
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          onClose();
        }
      });
  }, [api, device, recipientId, onClose]);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      events: []
    };

    values.events.forEach(event => {
      if (event.channels.length === 0) {
        return;
      }

      event.notifyOnceIn = valueAndTimeUnitToSeconds(
        event.notifyOnceIn.value,
        event.notifyOnceIn.unit
      );

      params.events.push(event);
    });

    api
      .put(
        `/v2/devices/${device.id}/notification-recipients/${recipient.id}`,
        params
      )
      .then(() => {
        enqueueSnackbar("devices.settings.notifications.responses.updated", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog>
      <FormContainer
        contact={recipient.contact}
        existingEvents={recipient.events}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default EditContainer;
