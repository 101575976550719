import React from "react";
import { Box } from "@material-ui/core";

import BubbleHelp from "../../components/BubbleHelp";

const BubbleHelpFieldWrapper = ({ id, children }) => (
  <Box display="flex">
    <Box flex="1 0 0">{children}</Box>
    <Box flex="0 1 0">
      <BubbleHelp id={id} paddingTop={9} />
    </Box>
  </Box>
);

export default BubbleHelpFieldWrapper;
