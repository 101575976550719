import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter
} from "@material-ui/core";

import useFormat from "../../../hooks/useFormat";
import TableFooterCell from "../../../elements/TableFooterCell";

const Table = ({ data, monthNames }) => {
  const { formatNumber } = useFormat();

  const getTotalForYear = annualData =>
    annualData.reduce((accumulated, current) => accumulated + current, 0);

  const getTotalForMonth = (data, monthIdx) => {
    return data.reduce((accumulated, currentYear) => {
      if (!currentYear.data[monthIdx]) {
        return accumulated;
      }

      return accumulated + currentYear.data[monthIdx];
    }, 0);
  };

  const getTotal = data => {
    return monthNames.reduce((accumulated, _, idx) => {
      return accumulated + getTotalForMonth(data, idx);
    }, 0);
  };

  return (
    <MuiTable size="small">
      <TableHead>
        <TableRow>
          <TableCell>Jahr</TableCell>
          {monthNames.map(month => (
            <TableCell align="center" key={month}>
              {month}
            </TableCell>
          ))}
          <TableCell align="center">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(item => {
          return (
            <TableRow key={item.name}>
              <TableCell>{item.name}</TableCell>
              {item.data.map((data, idx) => {
                return (
                  <TableCell align="right" key={item.name + idx}>
                    {formatNumber(data)}
                  </TableCell>
                );
              })}
              <TableCell align="right">
                {formatNumber(getTotalForYear(item.data))}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableFooterCell />
          {monthNames.map((month, idx) => (
            <TableFooterCell align="right" key={month}>
              {formatNumber(getTotalForMonth(data, idx))}
            </TableFooterCell>
          ))}
          <TableFooterCell align="right">
            {formatNumber(getTotal(data))}
          </TableFooterCell>
        </TableRow>
      </TableFooter>
    </MuiTable>
  );
};

export default Table;
