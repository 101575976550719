import React from "react";
import { Grid, Box } from "@material-ui/core";

import Dialog from "../Dialog";
import {
  SaveButton,
  ButtonsWrapper,
  CancelButton
} from "../../../elements/Button";

const Layout = ({
  onClose,
  paginationComponent,
  tableComponent,
  metadataComponent,
  isUpdating,
  isSubmitting,
  selectedDeviceIds,
  onSubmit
}) => {
  return (
    <Dialog onClose={onClose}>
      <Box marginBottom={4}>{metadataComponent}</Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        paddingX={1}
        paddingY={2}
      >
        {paginationComponent}
      </Box>
      {tableComponent}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        paddingX={1}
        paddingY={2}
      >
        {paginationComponent}
      </Box>

      {!isUpdating && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box marginTop={2}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton
                    onClick={() => onSubmit(selectedDeviceIds)}
                    disabled={isSubmitting || selectedDeviceIds.length === 0}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default Layout;
