import { useContext } from "react";

import ResourcesContext from "../../context/ResourcesContext";

const useContentTypes = () => {
  const { contentTypes } = useContext(ResourcesContext);

  if (contentTypes === undefined) {
    throw new Error("useContentTypes must be used within a ResourcesContext");
  }

  return contentTypes;
};

export default useContentTypes;
