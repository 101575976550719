import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody
} from "@material-ui/core";

import EventForm from "./EventForm";
import I18nText from "../../../../../elements/I18nText";

const EventsForm = ({ contact, events, setFieldValue }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <I18nText>devices.settings.notifications.labels.event</I18nText>
        </TableCell>
        <TableCell align="center">
          <I18nText>devices.settings.notifications.labels.sms</I18nText>
        </TableCell>
        <TableCell align="center">
          <I18nText>devices.settings.notifications.labels.email</I18nText>
        </TableCell>
        <TableCell style={{ minWidth: 200, width: 200 }}>
          <I18nText>
            devices.settings.notifications.labels.notifyOnceIn
          </I18nText>
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {events.map((event, i) => (
        <EventForm
          key={event.name}
          event={event}
          contact={contact}
          setFieldValue={setFieldValue}
          prefix={`events.${i}`}
        />
      ))}
    </TableBody>
  </Table>
);

export default EventsForm;
