import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../../../elements/DialogTitleWithCloseIcon";

import FixedPositionDialog from "../../../../../elements/FixedPositionDialog";
import I18nText from "../../../../../elements/I18nText";

const Dialog = ({ children, onClose }) => (
  <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="md">
    <DialogTitleWithCloseIcon onClose={onClose}>
      <I18nText>devices.settings.billing.create.title</I18nText>
    </DialogTitleWithCloseIcon>
    <DialogContent>{children}</DialogContent>
  </FixedPositionDialog>
);

export default Dialog;
