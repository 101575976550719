import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { REGEXP_ID } from "../../utils/stringUtils";
import DeviceProvider from "../../context/DeviceProvider";
import Device from "../Device";
import AppLayout from "../AppLayout";
import DeviceList from "../DeviceList";

const DevicesContainer = () => {
  const { url } = useRouteMatch();

  return (
    <AppLayout selectedMenu="devices">
      <Switch>
        <Route path={`${url}/:deviceId(${REGEXP_ID})`}>
          {({
            match: {
              params: { deviceId }
            }
          }) => (
            <DeviceProvider deviceId={deviceId}>
              <Device id={deviceId} />
            </DeviceProvider>
          )}
        </Route>
        <Route path={url}>
          <DeviceList />
        </Route>
      </Switch>
    </AppLayout>
  );
};

export default DevicesContainer;
