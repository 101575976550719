import React, { useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";

import { useApi } from "../../../../api";
import I18nText from "../../../../elements/I18nText";
import ConfirmationDialog from "../../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../../elements/Button";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";
import { Box, TextField, useTheme } from "@material-ui/core";

const DeleteContainer = ({ device, onCancel, onDeleted }) => {
  const api = useApi();
  const theme = useTheme();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [userInput, setUserInput] = useState("");
  const [isDeleting, setDeleting] = useState(false);

  const isValidUserInput = () => {
    return parseInt(userInput, 10) === device.id;
  };

  const deleteDevice = () => {
    setDeleting(true);

    if (!isValidUserInput()) {
      return;
    }

    api.delete(`/v2/devices/${device.id}`).then(() => {
      enqueueSnackbar("devices.settings.metadata.responses.deleted", {
        variant: "success"
      });
      setDeleting(false);
      onDeleted();
    });

    return;
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton
          disabled={!isValidUserInput() || isDeleting}
          onClick={deleteDevice}
        />
      }
    >
      <Box display="flex" justifyContent="center" marginBottom={2}>
        <WarningIcon
          style={{ fontSize: 40, color: theme.palette.error.main }}
        />
      </Box>
      <Box color={theme.palette.error.main} fontWeight="bold">
        Achtung! Sie sind im Begriff diese Anlage mit all ihren Daten komplett
        und unwiederbringlich zu löschen. Fahren Sie nur fort, wenn Sie sicher
        sind, dass Sie diese Anlage wirklich löschen möchten.
      </Box>
      <Box marginY={2}>
        Zur Bestätigung geben Sie bitte die Anlagennummer in das untenstehende
        Textfeld ein und klicken Sie dann auf "löschen".
      </Box>
      <Box marginY={2}>
        Die Anlage wird dann zur Löschung freigegeben. Es kann mehrere Minuten
        dauern, bis alle Daten vollständig gelöscht wurden. Unter Umständen ist
        die Anlage bis zur vollständigen Löschung im System noch sichtbar.
      </Box>
      <TextField
        size="small"
        variant="outlined"
        label={<I18nText>devices.common.id</I18nText>}
        value={userInput}
        onChange={e => setUserInput(e.target.value)}
      />
    </ConfirmationDialog>
  );
};

export default DeleteContainer;
