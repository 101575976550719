import React from "react";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";

import { Box } from "@material-ui/core";
import {
  MESSAGE_DIRECTION,
  MESSAGE_MODE,
  MESSAGE_TYPE
} from "../../domain/message";
import FormattedIdent from "../../elements/FormattedIdent";

const renderIcon = message => {
  if (message.mode !== MESSAGE_MODE.SYSTEM_TO_EXTERNAL) {
    return <BusinessOutlinedIcon style={{ fontSize: 16 }} />;
  }

  if (message.type === MESSAGE_TYPE.MAIL) {
    return <MailOutlineOutlinedIcon style={{ fontSize: 16 }} />;
  }

  return <SmartphoneOutlinedIcon style={{ fontSize: 16 }} />;
};

const renderIdent = message => {
  let ident =
    message.direction === MESSAGE_DIRECTION.OUT ? message.to : message.from;

  if (message.type === MESSAGE_TYPE.SMS) {
    const identType =
      message.direction === MESSAGE_DIRECTION.OUT
        ? message.toType
        : message.fromType;

    ident = <FormattedIdent type={identType}>{ident}</FormattedIdent>;
  }

  if (
    message.mode === MESSAGE_MODE.SYSTEM_TO_DEVICE ||
    message.mode === MESSAGE_MODE.DEVICE_TO_SYSTEM
  ) {
    return (
      <>
        <strong>{message.deviceId}</strong>&nbsp;({ident})
      </>
    );
  }

  return ident;
};

const CommunicationRemote = ({ message }) => {
  return (
    <Box display="flex" justifyContent="flex-start">
      {renderIcon(message)}
      <Box display="flex" alignItems="center" paddingLeft={1}>
        {renderIdent(message)}
      </Box>
    </Box>
  );
};

export default CommunicationRemote;
