import React from "react";
import { Box, useTheme } from "@material-ui/core";

import I18nText from "../../../elements/I18nText";

const FormattedPermission = ({ permissions, name }) => {
  const theme = useTheme();

  return (
    <Box
      color={
        permissions.indexOf(name) !== -1
          ? theme.palette.text.primary
          : theme.palette.grey[300]
      }
    >
      <I18nText>{`access.permissions.${name}`}</I18nText>
    </Box>
  );
};

export default FormattedPermission;
