import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";

import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";
import EventsForm from "../Form/EventsForm";
import Section from "../../../../../elements/Section";
import SectionHeader from "../../../../../elements/SectionHeader";
import I18nText from "../../../../../elements/I18nText";
import FormattedPhoneNumber from "../../../../../elements/FormattedPhoneNumber";
import DescriptionList from "../../../../../elements/DescriptionList";

const NotificationForm = ({ contact, values, onClose, onSubmit }) => {
  const options = [
    {
      label: "contacts.common.handle",
      value: contact.handle
    },
    {
      label: "contacts.common.cellNumber",
      value: <FormattedPhoneNumber>{contact.cellNumber}</FormattedPhoneNumber>
    },
    {
      label: "contacts.common.email",
      value: contact.email
    }
  ];

  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>
                    devices.settings.notifications.sections.recipient
                  </I18nText>
                </SectionHeader>
                <DescriptionList options={options} />
              </Section>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Section>
                <SectionHeader>
                  <I18nText>
                    devices.settings.notifications.sections.events
                  </I18nText>
                </SectionHeader>
                <EventsForm
                  contact={contact}
                  events={values.events}
                  setFieldValue={setFieldValue}
                />
              </Section>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationForm;
