import React from "react";
import { FullWidthTextField } from "../../../elements/FormField";

import I18nText from "../../../elements/I18nText";

const OnceDeviceForm = () => {
  return (
    <FullWidthTextField
      name="iccid"
      variant="outlined"
      required
      label={<I18nText>devices.common.iccid</I18nText>}
      inputProps={{ maxLength: 32, autoComplete: "off" }}
    />
  );
};

export default OnceDeviceForm;
