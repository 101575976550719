import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import I18nText from "../../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
    textTransform: "none",
    position: "relative",
    fontSize: 14,
    fontWeight: "normal",
    "&$selected": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      fontWeight: 500
    },
    "&:hover": {
      fontWeight: 500
    }
  },
  selected: {}
}));

const useTabsStyles = makeStyles({
  indicator: {
    display: "none"
  }
});

const TabBar = ({ onSelectView, selectedView }) => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();

  return (
    <>
      <Tabs
        classes={tabsClasses}
        value={selectedView}
        variant="fullWidth"
        textColor="primary"
        onChange={(_, e) => onSelectView(e)}
      >
        <Tab
          classes={classes}
          value="invoices"
          label={<I18nText>system.billing.tabs.invoices</I18nText>}
        ></Tab>
        <Tab
          classes={classes}
          value="terms"
          label={<I18nText>system.billing.tabs.terms</I18nText>}
        ></Tab>
      </Tabs>
    </>
  );
};

export default TabBar;
