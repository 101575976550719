import React from "react";
import {
  TextField,
  Input,
  MenuItem,
  ListSubheader,
  makeStyles
} from "@material-ui/core";

import I18nText from "../../elements/I18nText";

const useStyles = makeStyles({
  root: {
    minWidth: 300
  }
});

const QuickSelect = React.memo(({ onSelect }) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        classes={classes}
        label={<I18nText>blockQueries.quickSelect.label</I18nText>}
        select
        size="small"
        variant="outlined"
        defaultValue=""
        onChange={e => onSelect(e.target.value)}
        input={<Input />}
      >
        <MenuItem value="all">
          <I18nText>blockQueries.quickSelect.values.all</I18nText>
        </MenuItem>
        <MenuItem value="thisPage">
          <I18nText>blockQueries.quickSelect.values.thisPage</I18nText>
        </MenuItem>
        <MenuItem value="none">
          <I18nText>blockQueries.quickSelect.values.none</I18nText>
        </MenuItem>
        <ListSubheader>
          <I18nText>blockQueries.quickSelect.values.notReportedSince</I18nText>
        </ListSubheader>
        <MenuItem value={3600}>
          <I18nText>blockQueries.quickSelect.values.3600</I18nText>
        </MenuItem>
        <MenuItem value={7200}>
          <I18nText>blockQueries.quickSelect.values.7200</I18nText>
        </MenuItem>
        <MenuItem value={10800}>
          <I18nText>blockQueries.quickSelect.values.10800</I18nText>
        </MenuItem>
        <MenuItem value={21600}>
          <I18nText>blockQueries.quickSelect.values.21600</I18nText>
        </MenuItem>
        <MenuItem value={43200}>
          <I18nText>blockQueries.quickSelect.values.43200</I18nText>
        </MenuItem>
        <MenuItem value={86400}>
          <I18nText>blockQueries.quickSelect.values.86400</I18nText>
        </MenuItem>
        <MenuItem value={172800}>
          <I18nText>blockQueries.quickSelect.values.172800</I18nText>
        </MenuItem>
        <MenuItem value={259200}>
          <I18nText>blockQueries.quickSelect.values.259200</I18nText>
        </MenuItem>
        <MenuItem value={604800}>
          <I18nText>blockQueries.quickSelect.values.604800</I18nText>
        </MenuItem>
      </TextField>
    </>
  );
});

export default QuickSelect;
