import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import HistoryIcon from "@material-ui/icons/History";

import I18nText from "../../../elements/I18nText";
import { ROLES, useAuth } from "../../../auth";

const useListStyles = makeStyles({
  padding: {
    paddingTop: 0
  }
});

const useListItemStyles = makeStyles(theme => ({
  root: {
    height: 60,
    "&$selected": {
      backgroundColor: theme.palette.common.white
    },
    "&$button:hover": {
      backgroundColor: "#e5e5e5"
    }
  },
  selected: {},
  button: {}
}));

const useListItemTextStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.text.main,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const useListItemIconStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 0
    }
  }
}));

const Menu = ({ user, subPage }) => {
  const [selectedItem, selectItem] = useState(subPage);
  const classes = {
    list: useListStyles(),
    listItem: useListItemStyles(),
    listItemText: useListItemTextStyles(),
    listItemIcon: useListItemIconStyles()
  };

  const { hasRole, user: me } = useAuth();

  return (
    <>
      <List classes={classes.list}>
        <ListItem
          button
          classes={classes.listItem}
          component={Link}
          to="/users"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <ArrowBackIosIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>users.navigation.overview</I18nText>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "account"}
          onClick={() => selectItem("account")}
          component={Link}
          to={`/users/${user.id}/account`}
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>users.navigation.account</I18nText>
          </ListItemText>
        </ListItem>

        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "access"}
          onClick={() => selectItem("access")}
          component={Link}
          to={`/users/${user.id}/access`}
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>users.navigation.access</I18nText>
          </ListItemText>
        </ListItem>

        {hasRole(me, ROLES.MASTER) && (
          <ListItem
            button
            classes={classes.listItem}
            selected={selectedItem === "login-history"}
            onClick={() => selectItem("login-history")}
            component={Link}
            to={`/users/${user.id}/login-history`}
          >
            <ListItemIcon classes={classes.listItemIcon}>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText classes={classes.listItemText}>
              <I18nText>users.navigation.loginHistory</I18nText>
            </ListItemText>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default Menu;
