import { useContext } from "react";
import { useHistory } from "react-router-dom";

import ApiContext from "../context/ApiContext";

const useApi = () => {
  const history = useHistory();

  const apiClient = useContext(ApiContext);
  if (apiClient === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  apiClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        history.push("/login");
      }

      // Do something with response error
      return Promise.reject(error);
    }
  );

  return apiClient;
};

export default useApi;
