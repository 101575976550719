import React from "react";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { FastField } from "formik";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const FullWidthTextField = props => {
  const classes = useStyles();

  return (
    <FastField
      component={TextField}
      size="small"
      classes={classes}
      {...props}
    />
  );
};

export default FullWidthTextField;
