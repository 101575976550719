import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import translationDE from "./translations/de/translation.json";
import translationEN from "./translations/en/translation.json";

export const DEFAULT_LOCALE = "de";

const resources = {
  de: {
    translation: translationDE
  },
  en: {
    translation: translationEN
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false
  },
  returnEmptyString: false // @REMOVEME TODO This is for development purposes to find missing translations
});

export default i18n;

const Trans = ({ i18nKey, values }) => {
  const { t } = useTranslation();

  return t(i18nKey, values);
};

export { Trans, useTranslation };
