import React from "react";
import { Formik, Form } from "formik";
import { Grid, MenuItem } from "@material-ui/core";

import Section from "../../elements/Section";
import I18nText from "../../elements/I18nText";
import {
  FullWidthTextField,
  FullWidthSelect,
  CellNumberField
} from "../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../elements/Button";

const ContactForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Section>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="role"
                      variant="outlined"
                      label={<I18nText>contacts.common.role</I18nText>}
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="firstName"
                      variant="outlined"
                      label={<I18nText>contacts.common.firstName</I18nText>}
                      inputProps={{ maxLength: 64, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="lastName"
                      variant="outlined"
                      label={<I18nText>contacts.common.lastName</I18nText>}
                      inputProps={{ maxLength: 64, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CellNumberField
                      name="cellNumber"
                      label={<I18nText>contacts.common.cellNumber</I18nText>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="email"
                      variant="outlined"
                      label={<I18nText>contacts.common.email</I18nText>}
                      inputProps={{
                        maxLength: 128,
                        type: "email",
                        autoComplete: "off"
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthSelect
                      variant="outlined"
                      name="language"
                      label={<I18nText>contacts.common.language</I18nText>}
                    >
                      {["de", "en"].map(option => (
                        <MenuItem key={option} value={option}>
                          <I18nText>{`languages.${option}`}</I18nText>
                        </MenuItem>
                      ))}
                    </FullWidthSelect>
                  </Grid>
                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="comment"
                      variant="outlined"
                      multiline
                      label={<I18nText>contacts.common.comment</I18nText>}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
