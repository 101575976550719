export const RECEIVED_TOKEN = "CSRF_RECEIVED_TOKEN";
export const RESET_TOKEN = "CSRF_RESET_TOKEN";

export const receivedToken = token => {
  return { type: RECEIVED_TOKEN, token };
};

export const resetToken = () => {
  return { type: RESET_TOKEN };
};
