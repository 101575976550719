import React from "react";
import { Box } from "@material-ui/core";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

import I18nText from "../../../elements/I18nText";

const NoData = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="center"
    padding={4}
  >
    <NotInterestedIcon />
    <Box padding={2}>
      <I18nText>devices.consumption.noData</I18nText>
    </Box>
  </Box>
);

export default NoData;
