import React from "react";
import { Formik, Form, Field } from "formik";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import { FullWidthTextField } from "../../../elements/FormField";
import I18nText from "../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../elements/Button";

const CredentialsForm = ({ credentials, validator, onSubmit, onClose }) => {
  return (
    <Formik
      initialValues={credentials}
      validate={validator}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                component={TextField}
                autoComplete="username"
                name="username"
                type="hidden"
              />
            </Grid>

            <Grid item xs={12}>
              <FullWidthTextField
                name="currentPassword"
                type="password"
                variant="outlined"
                label={
                  <I18nText>settings.credentials.currentPassword</I18nText>
                }
                autoComplete="current-password"
              />
            </Grid>

            <Grid item xs={12}>
              <FullWidthTextField
                name="password"
                type="password"
                variant="outlined"
                label={<I18nText>settings.credentials.newPassword</I18nText>}
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <FullWidthTextField
                name="passwordRepeat"
                type="password"
                variant="outlined"
                label={<I18nText>settings.credentials.repeatPassword</I18nText>}
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CredentialsForm;
