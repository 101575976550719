import React from "react";
import { Table, TableBody } from "@material-ui/core";

import TreeNode from "./TreeNode";

const Tree = ({ nodes, filter, nodeIsFiltered, onSelectNode }) => {
  return (
    <Table size="small">
      <TableBody>
        {nodes.map(node => (
          <TreeNode
            key={node.id}
            filter={filter}
            onSelect={onSelectNode}
            nodeIsFiltered={nodeIsFiltered}
            node={node}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Tree;
