import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";

import I18nText from "../../../../../elements/I18nText";
import RefDateQueries from "./RefDateQueries";
import DeleteContainer from "./DeleteContainer";
import { useApi } from "../../../../../api";

const RefDateQueriesContainer = ({ deviceId }) => {
  const [refDateQueries, setRefDateQueries] = useState(null);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteRefDateQuery, setConfirmDeleteRefDateQuery] = useState(
    null
  );
  const api = useApi();

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api.get(`/v2/devices/${deviceId}/ref-date-queries`).then(response => {
      setRefDateQueries(response.data);
      setLoading(false);
      setReload(false);
    });
  }, [api, reload, deviceId]);

  const handleConfirmDelete = refDateQuery => {
    setConfirmDeleteRefDateQuery(refDateQuery);
  };

  useEffect(() => {
    if (!deviceId) {
      return;
    }

    setReload(true);
  }, [deviceId]);

  return (
    <>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : refDateQueries !== null && refDateQueries.length > 0 ? (
        <>
          {confirmDeleteRefDateQuery !== null && (
            <DeleteContainer
              deviceId={deviceId}
              refDateQueryId={confirmDeleteRefDateQuery.id}
              onCancel={() => setConfirmDeleteRefDateQuery(null)}
              onDeleted={() => {
                setReload(true);
                setConfirmDeleteRefDateQuery(null);
              }}
            />
          )}
          <RefDateQueries
            refDateQueries={refDateQueries}
            onDeleteRefDateQuery={handleConfirmDelete}
          />
        </>
      ) : (
        <I18nText>devices.settings.metadata.noRefDateQueries</I18nText>
      )}
    </>
  );
};

export default RefDateQueriesContainer;
