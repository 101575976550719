import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../elements/DialogTitleWithCloseIcon";

import ConfigContainer from "./ConfigContainer";
import FixedPositionDialog from "../../../elements/FixedPositionDialog";
import I18nText from "../../../elements/I18nText";

const ConfigDialog = ({ onClose }) => {
  return (
    <FixedPositionDialog
      open={true}
      scroll="body"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>devices.list.config.title</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>
        <ConfigContainer onClose={onClose} />
      </DialogContent>
    </FixedPositionDialog>
  );
};

export default ConfigDialog;
