import React from "react";
import { DialogContent } from "@material-ui/core";
import FixedPositionDialog from "../../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../../elements/DialogTitleWithCloseIcon";
import ConfigurationContainer from "./ConfigurationContainer";
import I18nText from "../../../../../elements/I18nText";

const ConfigurationDialog = ({ device, onClose }) => {
  return (
    <>
      <FixedPositionDialog
        open={true}
        scroll="body"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>devices.settings.tabs.configuration</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <ConfigurationContainer device={device} onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default ConfigurationDialog;
