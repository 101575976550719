import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { REGEXP_ID } from "../../utils/stringUtils";
import AppLayout from "../AppLayout";
import UserList from "../UserList";
import User from "../User";

const UsersContainer = () => {
  const { url } = useRouteMatch();

  return (
    <AppLayout selectedMenu="users">
      <Switch>
        <Route path={`${url}/:userId(${REGEXP_ID})`}>
          {({
            match: {
              params: { userId }
            }
          }) => <User id={userId} />}
        </Route>
        <Route path={url}>
          <UserList />
        </Route>
      </Switch>
    </AppLayout>
  );
};

export default UsersContainer;
