import React, { useState } from "react";

import { useApi } from "../../../api";
import I18nText from "../../../elements/I18nText";
import ConfirmationDialog from "../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../elements/Button";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const RevokeContainer = ({ notificationId, onCancel, onRevoked }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isRevoking, setRevoking] = useState(false);

  const revoke = () => {
    setRevoking(true);

    api.delete(`/v2/system-notifications/${notificationId}`).then(() => {
      enqueueSnackbar("system.notifications.responses.revoked", {
        variant: "success"
      });
      setRevoking(false);
      onRevoked();
    });
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isRevoking}
      ConfirmButton={<DeleteButton disabled={isRevoking} onClick={revoke} />}
    >
      <I18nText>system.notifications.revoke.confirmation</I18nText>
    </ConfirmationDialog>
  );
};

export default RevokeContainer;
