import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const ThresholdSensor = React.memo(() => {
  const options = ["first", "all", "first_upper", "all_upper"];

  return (
    <BubbleHelpFieldWrapper id="devices.edit.thresholdSensor">
      <FullWidthSelect
        fastField={false}
        variant="outlined"
        required
        label={
          <I18nText>devices.settings.configuration.thresholdSensor</I18nText>
        }
        name="thresholdSensor"
        inputProps={{
          id: "threshold-sensor-select"
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <I18nText>{`devices.settings.configuration.thresholdSensorOptions.${option}`}</I18nText>
          </MenuItem>
        ))}
      </FullWidthSelect>
    </BubbleHelpFieldWrapper>
  );
});

export default ThresholdSensor;
