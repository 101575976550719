import React from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../elements/FormField";
import { CHARGING_PERIOD_TYPES } from "../../../../../domain/billingTerms";

const ChargingPeriodTypeField = () => {
  return (
    <FullWidthSelect
      variant="outlined"
      label={<I18nText>billing.common.chargingPeriodType</I18nText>}
      name="chargingPeriodType"
    >
      <MenuItem value={CHARGING_PERIOD_TYPES.WEEKLY}>
        <I18nText>{`billing.chargingPeriodTypes.${CHARGING_PERIOD_TYPES.WEEKLY}`}</I18nText>
      </MenuItem>
      <MenuItem value={CHARGING_PERIOD_TYPES.MONTHLY}>
        <I18nText>{`billing.chargingPeriodTypes.${CHARGING_PERIOD_TYPES.MONTHLY}`}</I18nText>
      </MenuItem>
      <MenuItem value={CHARGING_PERIOD_TYPES.QUARTERLY}>
        <I18nText>{`billing.chargingPeriodTypes.${CHARGING_PERIOD_TYPES.QUARTERLY}`}</I18nText>
      </MenuItem>
      <MenuItem value={CHARGING_PERIOD_TYPES.YEARLY}>
        <I18nText>{`billing.chargingPeriodTypes.${CHARGING_PERIOD_TYPES.YEARLY}`}</I18nText>
      </MenuItem>
    </FullWidthSelect>
  );
};

export default ChargingPeriodTypeField;
