import React from "react";
import { FastField } from "formik";
import { InputAdornment } from "@material-ui/core";

import { NumberField } from "../../../../../elements/FormField";

const PriceField = () => (
  <FastField
    component={NumberField}
    label="billing.common.price"
    name="price"
    step={0.01}
    min={0}
    InputProps={{
      endAdornment: <InputAdornment position="end">€</InputAdornment>
    }}
  />
);

export default PriceField;
