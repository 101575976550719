import React from "react";
import StorageIcon from "@material-ui/icons/Storage";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { Box } from "@material-ui/core";
import { MESSAGE_DIRECTION } from "../../domain/message";

const CommunicationServer = ({ message }) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Box width={20}>{message.gatewayId}</Box>
      <StorageIcon style={{ fontSize: 16 }} />
      {message.direction === MESSAGE_DIRECTION.IN ? (
        <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
      ) : (
        <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
      )}
    </Box>
  );
};

export default CommunicationServer;
