import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { useDevice } from "../../../../domain/device";
import { useApi } from "../../../../api";
import List from "./List";
import DeleteContainer from "./DeleteContainer";
import EditAce from "./Edit";
import CreateAce from "./Create";
import { REGEXP_ID } from "../../../../utils/stringUtils";

const AccessContainer = () => {
  const { device } = useDevice();
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [aces, setAces] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteAce, setConfirmDeleteAce] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/aces`)
      .then(response => {
        setAces(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, device, reload]);

  const handleConfirmDelete = ace => {
    setConfirmDeleteAce(ace);
  };

  const handleEditAce = ace => {
    history.push(`${url}/${ace.id}`);
  };

  const handleCreateAce = () => history.push(`${url}/new`);

  const handleClose = () => {
    setReload(true);
    history.push(url);
  };

  useEffect(() => {
    if (!device) {
      return;
    }

    setReload(true);
  }, [device]);

  return (
    <>
      <Switch>
        <Route path={`${url}/new`}>
          <CreateAce device={device} onClose={handleClose} />
        </Route>
        <Route path={`${url}/:aceId(${REGEXP_ID})`}>
          {({
            match: {
              params: { aceId }
            }
          }) => <EditAce device={device} aceId={aceId} onClose={handleClose} />}
        </Route>
      </Switch>
      {confirmDeleteAce !== null && (
        <DeleteContainer
          deviceId={device.id}
          aceId={confirmDeleteAce.id}
          onCancel={() => setConfirmDeleteAce(null)}
          onDeleted={() => {
            setReload(true);
            setConfirmDeleteAce(null);
          }}
        />
      )}
      <List
        isLoading={isLoading}
        aces={aces}
        onCreateAce={handleCreateAce}
        onEditAce={handleEditAce}
        onDeleteAce={handleConfirmDelete}
      />
    </>
  );
};

export default AccessContainer;
