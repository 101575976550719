import React from "react";
import { Box, makeStyles, MenuItem, TextField } from "@material-ui/core";

import I18nText from "./I18nText";

const useStyles = makeStyles({
  root: {
    width: 100
  }
});

const PageSelect = ({
  perPage,
  perPageOptions = [10, 25, 50, 100],
  onSelectPerPage
}) => {
  const classes = useStyles();

  return (
    <Box minWidth={100} clone>
      <TextField
        select
        size="small"
        classes={classes}
        label={<I18nText>pagination.perPage</I18nText>}
        variant="outlined"
        value={perPage}
        onChange={e => onSelectPerPage(e.target.value)}
      >
        {perPageOptions.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default PageSelect;
