import React from "react";
import { Grid } from "@material-ui/core";

import { useAuth, ROLES } from "../../../../../../auth";
import CellNumber from "./CellNumber";
import SimType from "./SimType";
import SimProvider from "./SimProvider";
import SimCreditor from "./SimCreditor";
import SmsGateway from "./SmsGateway";
import ForceSms from "./ForceSms";
import Iccid from "./Iccid";

const SmsDevice = ({ values, showSmsGatewaySelect }) => {
  const { user, hasRole } = useAuth();

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          {values.cellNumber !== undefined && (
            <Grid item xs={12}>
              <CellNumber />
            </Grid>
          )}

          {values.iccid !== undefined && (
            <Grid item xs={12}>
              <Iccid />
            </Grid>
          )}

          <Grid item xs={12}>
            <SimType />
          </Grid>
          {values.simProvider !== undefined && (
            <Grid item xs={12}>
              <SimProvider />
            </Grid>
          )}
          {hasRole(user, ROLES.MASTER) && (
            <Grid item xs={12}>
              <SimCreditor />
            </Grid>
          )}

          {showSmsGatewaySelect && values.inboundGatewayId !== undefined && (
            <Grid item xs={12}>
              <SmsGateway type="inbound" />
            </Grid>
          )}

          {showSmsGatewaySelect && values.outboundGatewayId !== undefined && (
            <Grid item xs={12}>
              <SmsGateway type="outbound" />
            </Grid>
          )}
          <Grid item xs={12}>
            <ForceSms />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SmsDevice;
