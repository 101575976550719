import useWhitelabel from "./useWhitelabel";

const WHITELABELS = {
  MOBENE: "Mobene",
  SMARTINSPECTOR: "Smartinspector",
  OILVIEW: "Oilview",
  OPTILOG: "Optilog",
  ACON: "Acon",
  WATERVIEW: "Waterview"
};

export { useWhitelabel, WHITELABELS };
