import { useContext } from "react";

import ResourcesContext from "../../context/ResourcesContext";

const useContentDensities = () => {
  const { contentDensities } = useContext(ResourcesContext);

  if (contentDensities === undefined) {
    throw new Error(
      "useContentDensities must be used within a ResourcesContext"
    );
  }

  return contentDensities;
};

export default useContentDensities;
