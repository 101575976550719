import React from "react";

import I18nText from "../../../../elements/I18nText";

import { Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../../elements/Button";
import { DeviceSelect } from "../../../../elements/FormField";
import { PERMISSIONS } from "../../../../auth";

const DeviceForm = ({
  device,
  excludedIds = [],
  onDeviceSelected,
  onClose,
  onSubmit
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DeviceSelect
            name="device"
            excludedIds={excludedIds}
            permission={PERMISSIONS.EDIT}
            value={device}
            required={true}
            onUpdate={value => onDeviceSelected(value)}
            label={<I18nText>users.access.create.selectDevice</I18nText>}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={<CancelButton onClick={onClose} />}
            rightButton={
              <PrimaryButton
                disabled={!device}
                icon={<ArrowForwardIosIcon />}
                onClick={onSubmit}
              >
                <I18nText>actions.continue</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceForm;
