import React from "react";
import { DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../elements/DialogTitleWithCloseIcon";

import FixedPositionDialog from "../../../elements/FixedPositionDialog";
import I18nText from "../../../elements/I18nText";
import MapContainer from "./MapContainer";

const MapDialog = ({ onClose }) => {
  return (
    <FixedPositionDialog
      open={true}
      scroll="body"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>devices.list.map.title</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>
        <MapContainer onClose={onClose} />
      </DialogContent>
    </FixedPositionDialog>
  );
};

export default MapDialog;
