import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import I18nText from "../../../elements/I18nText";

const TableColumnCheckbox = ({ name, checked, onUpdate }) => {
  const label = (
    <I18nText>{`devices.list.table.header.${
      name === "username" ? "user" : name
    }`}</I18nText>
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          color="primary"
          onChange={onUpdate}
          value={checked}
        />
      }
      label={label}
    />
  );
};

export default TableColumnCheckbox;
