import React, { useState, useEffect } from "react";

import BlockQuery from "./BlockQuery";
import { useApi } from "../../api";
import { PERMISSIONS } from "../../auth";
import Dialog from "./Dialog";
import useI18nSnackbar from "../../hooks/useI18nSnackbar";

const BlockQueryContainer = ({ onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [devices, setDevices] = useState([]);
  const [preSelectedDeviceIds, setPreSelectedDeviceIds] = useState([]);
  const [queryStatuses, setQueryStatuses] = useState([]);
  const [blockQueryEndpoint, setBlockQueryEndpoint] = useState(null);
  const [quickSelect, setQuickSelect] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(1);
  const perPage = 50;
  const [isUpdating, setUpdating] = useState(0);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const returnFunc = () => {
      mounted = false;
    };

    if (!quickSelect) {
      return returnFunc;
    }

    if (quickSelect === "none") {
      setPreSelectedDeviceIds([]);

      return returnFunc;
    }

    if (quickSelect === "thisPage") {
      setPreSelectedDeviceIds(devices.map(device => device.id));

      return returnFunc;
    }

    let query = `type=sms&permission=${PERMISSIONS.QUERY}`;

    if (quickSelect !== "all") {
      query += `&not_reported_since=${quickSelect}`;
    }

    setUpdating(counter => counter + 1);

    api.get(`/v2/devices/ids?${query}`).then(response => {
      if (!mounted) {
        return;
      }

      setPreSelectedDeviceIds(response.data);

      setUpdating(counter => counter - 1);
    });

    return returnFunc;
  }, [api, quickSelect, devices]);

  useEffect(() => {
    let mounted = true;
    let query = `type=sms&permission=${PERMISSIONS.QUERY}`;

    if (page) {
      query += `&page=${page}`;
    }

    if (perPage) {
      query += `&per_page=${perPage}`;
    }

    setUpdating(counter => counter + 1);

    api.get(`/v2/devices?${query}`).then(response => {
      if (!mounted) {
        return;
      }
      setDevices(response.data);
      setTotalCount(response.headers["x-total-count"]);
      setUpdating(counter => counter - 1);
      setInitialLoading(false);
    });

    return () => {
      mounted = false;
    };
  }, [api, page]);

  useEffect(() => {
    if (!blockQueryEndpoint) {
      return;
    }

    const timer = setInterval(() => {
      api.get(blockQueryEndpoint).then(response => {
        const statuses = {};
        response.data.queries.forEach(query => {
          statuses[query.deviceId] = query.status;
        });

        setQueryStatuses(statuses);
      });
    }, 5000);

    return () => clearInterval(timer);
  }, [api, blockQueryEndpoint]);

  const handleSubmit = selectedDeviceIds => {
    api
      .post("/v2/block-queries", {
        deviceIds: selectedDeviceIds
      })
      .then(response => {
        enqueueSnackbar("blockQueries.responses.ok", {
          variant: "success"
        });

        const statuses = {};
        selectedDeviceIds.forEach(id => (statuses[id] = "waiting"));

        setQueryStatuses(statuses);
        setBlockQueryEndpoint(response.headers.location);
      });
  };
  const handleSelectPage = page => {
    setPage(page);
  };

  const handleQuickSelect = value => {
    setQuickSelect(value);
  };

  return (
    <Dialog onClose={onClose}>
      <BlockQuery
        devices={devices}
        totalCount={totalCount}
        perPage={perPage}
        page={page}
        isUpdating={isUpdating > 0}
        isInitiallyLoaded={!isInitialLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
        queryStatuses={queryStatuses}
        isQuerying={blockQueryEndpoint !== null}
        onQuickSelect={handleQuickSelect}
        onSelectPage={handleSelectPage}
        preSelectedDeviceIds={preSelectedDeviceIds}
      />
    </Dialog>
  );
};

export default BlockQueryContainer;
