import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";

import { REGEXP_ID } from "../../utils/stringUtils";
import { useApi } from "../../api";
import List from "./List";
import EditContainer from "./Edit/EditContainer";
import CreateContainer from "./Create/CreateContainer";
import DeleteContainer from "./DeleteContainer";

const ListContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [contacts, setContacts] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [confirmDeleteContact, setConfirmDeleteContact] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);

    api.get("/v2/contacts").then(response => {
      setContacts(response.data);
      setLoading(false);
    });
  }, [api, reload]);

  const handleConfirmDelete = contact => {
    setConfirmDeleteContact(contact);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {confirmDeleteContact && (
        <DeleteContainer
          contactId={confirmDeleteContact.id}
          deviceCount={confirmDeleteContact.deviceCount}
          onCancel={() => {
            setConfirmDeleteContact(null);
          }}
          onDeleted={() => {
            setReload(true);
            setConfirmDeleteContact(null);
          }}
        />
      )}
      <List
        contacts={contacts}
        onEditContact={contact => history.push(`/contacts/${contact.id}`)}
        onDeleteContact={handleConfirmDelete}
      />
      <Switch>
        <Route path={`${url}/new`}>
          <CreateContainer
            onCreated={() => {
              setReload(true);
              history.push("/contacts");
            }}
            onClose={() => {
              history.push("/contacts");
            }}
          />
        </Route>
        <Route path={`${url}/:contactId(${REGEXP_ID})`} exact>
          {({
            match: {
              params: { contactId }
            }
          }) => (
            <EditContainer
              contactId={contactId}
              onClose={() => {
                setReload(true);
                history.push("/contacts");
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  );
};

export default ListContainer;
