import React from "react";

import Profile from "./Profile";
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";

import Credentials from "../Credentials";
import { useAuth } from "../../../auth";

const ProfileContainer = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const { user } = useAuth();

  return (
    <>
      <Switch>
        <Route path={`${url}/credentials`}>
          <Credentials onClose={() => history.push(url)} />
        </Route>
      </Switch>
      <Profile
        user={user}
        onEditCredentials={() => history.push("/settings/credentials")}
      />
    </>
  );
};

export default ProfileContainer;
