import React from "react";
import { Box, useTheme } from "@material-ui/core";
import I18nText from "../../../elements/I18nText";

const SensorError = ({ sensor, colorOverride }) => {
  const theme = useTheme();
  let errorText = "";

  if (sensor.alarms.length > 0) {
    errorText = sensor.alarms[0].value;
  } else {
    errorText = <I18nText>sensors.status.error</I18nText>;
  }

  return (
    <Box
      textAlign="center"
      fontWeight="bold"
      color={colorOverride ?? theme.palette.error.main}
    >
      {errorText}
    </Box>
  );
};

export default SensorError;
