import React from "react";
import { TableRow, TableFooter as MuiTableFooter } from "@material-ui/core";

import useFormat from "../../hooks/useFormat";
import I18nText from "../../elements/I18nText";
import { SENSOR_TYPE, SENSOR_STATUS } from "../../domain/sensor";
import TableFooterCell from "../../elements/TableFooterCell";

const TableFooter = ({ devices, columnCount }) => {
  const { formatNumber, formatUnit } = useFormat();

  const getTotalsByUnit = devices => {
    let sensors = [];
    devices.forEach(device => {
      const connectedProbes = device.sensors.filter(
        sensor =>
          sensor.type === SENSOR_TYPE.PROBE &&
          sensor.isConnected &&
          sensor.status !== SENSOR_STATUS.ERROR
      );

      sensors = [...sensors, ...connectedProbes];
    });

    // Find distinct units
    const units = [...new Set(sensors.map(sensor => sensor.unit))];

    const totalToUnitMap = {};
    units.forEach(unit => {
      totalToUnitMap[unit] = sensors.reduce(
        (accumulated, currentSensor) => {
          if (currentSensor.unit !== unit) {
            return accumulated;
          }

          return {
            netFreeSpace: accumulated.netFreeSpace + currentSensor.netFreeSpace,
            value: accumulated.value + currentSensor.value
          };
        },
        {
          netFreeSpace: 0,
          value: 0
        }
      );
    });

    const totalsByUnit = [];
    for (const unit in totalToUnitMap) {
      const value = totalToUnitMap[unit].value;
      const netFreeSpace = totalToUnitMap[unit].netFreeSpace;

      totalsByUnit.push({
        unit,
        netFreeSpace,
        value
      });
    }

    return totalsByUnit;
  };

  return (
    <MuiTableFooter>
      {getTotalsByUnit(devices).map(totals => (
        <React.Fragment key={totals.unit}>
          <TableRow>
            <TableFooterCell colSpan={columnCount}></TableFooterCell>
            <TableFooterCell align="right">
              <I18nText>devices.list.table.footer.netFreeSpace</I18nText>:{" "}
              {formatNumber(totals.netFreeSpace)} {formatUnit(totals.unit)}
            </TableFooterCell>
          </TableRow>
          <TableRow>
            <TableFooterCell colSpan={columnCount}></TableFooterCell>
            <TableFooterCell align="right">
              <I18nText>devices.list.table.footer.total</I18nText>:{" "}
              {formatNumber(totals.value)} {formatUnit(totals.unit)}
            </TableFooterCell>
          </TableRow>
        </React.Fragment>
      ))}
    </MuiTableFooter>
  );
};

export default TableFooter;
