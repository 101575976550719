import React from "react";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const EditAction = ({ onClick, iconProps, ...props }) => (
  <IconButton onClick={onClick} {...props}>
    <EditIcon fontSize="small" {...iconProps} />
  </IconButton>
);

export default EditAction;
