import React from "react";
import { makeStyles } from "@material-ui/core";

import FormattedPhoneNumber from "./FormattedPhoneNumber";

const useStyles = makeStyles({
  span: {
    whiteSpace: "nowrap"
  }
});

const FormattedIdent = ({ children, type, fallback = "-" }) => {
  const classes = useStyles();

  if (type === "cell_number") {
    return (
      <FormattedPhoneNumber fallback={fallback}>
        {children}
      </FormattedPhoneNumber>
    );
  }

  if (!children) {
    return fallback;
  }

  return <span className={classes.span}>{children}</span>;
};

export default FormattedIdent;
