import { useContext } from "react";

import DeviceContext from "../../context/DeviceContext";

const useDevice = () => {
  const device = useContext(DeviceContext);

  if (device === undefined) {
    throw new Error("useDevice must be used within a DeviceContext");
  }

  return device;
};

export default useDevice;
