import React from "react";

import I18nText from "../../../../../../elements/I18nText";
import { CellNumberField } from "../../../../../../elements/FormField";

const CellNumber = () => (
  <CellNumberField
    name="cellNumber"
    label={<I18nText>devices.common.cellNumber</I18nText>}
  />
);

export default CellNumber;
