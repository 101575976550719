import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

import I18nText from "../I18nText";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const useInputStyles = makeStyles({
  input: {
    textAlign: "right"
  }
});

const NumberField = props => {
  const {
    size = "small",
    field: { name },
    label,
    min = null,
    max = null,
    step = 1,
    InputProps
  } = props;

  const inputClasses = useInputStyles();
  const fieldClasses = useStyles();

  const labelValue = label ? <I18nText>{label}</I18nText> : null;

  return (
    <TextField
      {...fieldToTextField(props)}
      name={name}
      size={size}
      classes={fieldClasses}
      type="number"
      variant="outlined"
      label={labelValue}
      InputProps={{
        classes: inputClasses,
        ...InputProps,
        autoComplete: "off"
      }}
      inputProps={{ step, min, max }}
    />
  );
};

export default NumberField;
