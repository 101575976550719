import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../api";
import DeviceForm from "./DeviceForm";
import FormContainer from "../Form/FormContainer";
import { PERMISSIONS } from "../../../../auth";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const DEFAULT_PERMISSIONS = [
  PERMISSIONS.VIEW_STATUS,
  PERMISSIONS.VIEW_CONSUMPTION,
  PERMISSIONS.VIEW_PROCESS,
  PERMISSIONS.VIEW_MESSAGING,
  PERMISSIONS.VIEW_CONFIGURATION,
  PERMISSIONS.CONFIGURATION,
  PERMISSIONS.EDIT,
  PERMISSIONS.QUERY,
  PERMISSIONS.EXPORT,
  PERMISSIONS.REF_DATE_QUERY
];

const CreateContainer = ({ user, onClose }) => {
  const api = useApi();
  const [device, setDevice] = useState(null);
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [step, setStep] = useState("device");
  const [excludedIds, setExcludedIds] = useState([]);

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `/v2/devices/ids?user_id=${user.id}?permission=${PERMISSIONS.VIEW_STATUS}`
      )
      .then(response => {
        // Set existing devices on exlude list
        setExcludedIds([...response.data]);
        setLoading(false);
      });
  }, [api, user.id]);

  const handleSubmit = (values, { setSubmitting }) => {
    const params = {
      userId: values.userId,
      permissions: []
    };

    for (const permission in values.permissions) {
      if (values.permissions[permission]) {
        params.permissions.push(permission);
      }
    }

    api
      .post(`/v2/devices/${device.id}/aces`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.access.responses.created", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handleDeviceSelected = device => {
    setDevice(device);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog>
      {step === "device" && (
        <DeviceForm
          device={device}
          excludedIds={excludedIds}
          onDeviceSelected={handleDeviceSelected}
          onClose={onClose}
          onSubmit={() => setStep("permissions")}
        />
      )}
      {step === "permissions" && (
        <FormContainer
          existingPermissions={DEFAULT_PERMISSIONS}
          deviceId={device.id}
          userId={user.id}
          username={user.username}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </Dialog>
  );
};

export default CreateContainer;
