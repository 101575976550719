import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import { PrimaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: 63,
    borderBottom: "1px solid #00000029"
  },
  topColumn: {
    height: 32,
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 2
  },
  bottomColumn: {
    height: 31,
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 2
  },
  singleColumn: {
    height: 63,
    display: "flex",
    alignItems: "center"
  },
  metadata: {
    display: "flex",
    paddingLeft: 30,
    flex: "1 0"
  }
});

const useBoldTextStyles = makeStyles({
  root: {
    fontSize: 13,
    fontWeight: "bold"
  }
});

const useTextStyles = makeStyles({
  root: {
    fontSize: 13
  }
});

const InfoBar = ({ user, onAddSubUser, canGetSubUser, onImpersonateUser }) => {
  const classes = useStyles();

  const muiClasses = {
    text: useTextStyles(),
    boldText: useBoldTextStyles()
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.metadata}>
          <Box flex={1} minWidth={275}>
            <div className={classes.topColumn}>
              <Typography noWrap={true} classes={muiClasses.boldText}>
                {user.username}
              </Typography>
            </div>
            <div className={classes.bottomColumn}>
              <Typography noWrap={true} classes={muiClasses.text}>
                {user.name}
              </Typography>
            </div>
          </Box>
        </div>

        <Box flex="0 1">
          <div
            className={classes.singleColumn}
            style={{
              selfAlign: "flex-end",
              justifyContent: "flex-end",
              paddingRight: 20
            }}
          >
            <PrimaryButton
              icon={<SupervisorAccountIcon />}
              onClick={onImpersonateUser}
            >
              <I18nText>users.infoBar.impersonateUser</I18nText>
            </PrimaryButton>
          </div>
        </Box>

        {canGetSubUser && (
          <Box flex="0 1">
            <div
              className={classes.singleColumn}
              style={{
                selfAlign: "flex-end",
                justifyContent: "flex-end",
                paddingRight: 20
              }}
            >
              <PrimaryButton icon={<PersonAddIcon />} onClick={onAddSubUser}>
                <I18nText>users.infoBar.addSubUser</I18nText>
              </PrimaryButton>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default InfoBar;
