import React, { useState, useEffect } from "react";

import { useApi } from "../../../api";
import LoginHistory from "./LoginHistory";

const LoginHistoryContainer = ({ user }) => {
  const api = useApi();
  const [logins, setLogins] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const query = `/v2/users/${user.id}/logins`;

    setLoading(true);

    api
      .get(query)
      .then(response => {
        setLogins(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setLoading(false));
  }, [api, user.id]);

  return <LoginHistory logins={logins} isLoading={isLoading} />;
};

export default LoginHistoryContainer;
