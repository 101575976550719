import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";

import I18nText from "../../../../../elements/I18nText";
import {
  CountrySelect,
  UserSelect,
  FullWidthTextField
} from "../../../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";

const MetadataForm = ({ values, onClose, onSubmit, enableUserSelect }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, errors, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {enableUserSelect && (
                  <Grid item xs={12}>
                    <UserSelect
                      name="user"
                      required
                      value={values.user}
                      hasError={errors && errors.user}
                      onUpdate={value => setFieldValue("user", value)}
                      label={
                        <I18nText>devices.settings.metadata.user</I18nText>
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="name"
                    variant="outlined"
                    label={<I18nText>devices.settings.metadata.name</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="project"
                    variant="outlined"
                    label={
                      <I18nText>devices.settings.metadata.project</I18nText>
                    }
                    inputProps={{ maxLength: 128, autoComplete: "off" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="address.street"
                    variant="outlined"
                    label={
                      <I18nText>devices.settings.metadata.street</I18nText>
                    }
                    inputProps={{ maxLength: 128, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="address.streetNumber"
                    variant="outlined"
                    label={
                      <I18nText>
                        devices.settings.metadata.streetNumber
                      </I18nText>
                    }
                    inputProps={{ maxLength: 12, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="address.postalCode"
                    variant="outlined"
                    label={
                      <I18nText>devices.settings.metadata.postalCode</I18nText>
                    }
                    inputProps={{ maxLength: 8, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FullWidthTextField
                    name="address.city"
                    variant="outlined"
                    label={<I18nText>devices.settings.metadata.city</I18nText>}
                    inputProps={{ maxLength: 64, autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CountrySelect
                    name="address.countryCode"
                    required
                    label={
                      <I18nText>devices.settings.metadata.countryCode</I18nText>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MetadataForm;
