import React from "react";
import { TableRow, TableCell, makeStyles, useTheme } from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";

const useCellStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: 32
  }
}));

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center"
  },
  colorBox: {
    width: 30,
    height: 30,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const CounterRow = ({ sensor, showTemperatureColumn }) => {
  const classes = useStyles();
  const cellClasses = useCellStyles();
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell classes={cellClasses}>
        <div
          className={classes.colorBox}
          style={{
            backgroundColor: theme.custom.colors.sensors[sensor.sequence]
          }}
        >
          {sensor.sequence}
        </div>
      </TableCell>
      <TableCell>
        <I18nText>{`sensors.subTypes.${sensor.subType}`}</I18nText>
      </TableCell>
      <TableCell>{sensor.name}</TableCell>
      <TableCell></TableCell>
      <TableCell>
        {sensor.unit ? (
          <I18nText>{`sensors.units.${sensor.unit}`}</I18nText>
        ) : null}
      </TableCell>
      <TableCell colSpan={showTemperatureColumn ? 3 : 2}></TableCell>
    </TableRow>
  );
};

export default CounterRow;
