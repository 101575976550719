import React from "react";
import { FORMAT_DATETIME } from "../utils/formatting";
import useFormat from "../hooks/useFormat";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  span: {
    whiteSpace: "nowrap"
  }
});

const FormattedDate = ({
  children,
  format = FORMAT_DATETIME,
  fallback = ""
}) => {
  const { formatDate } = useFormat();
  const classes = useStyles();

  if (!children) {
    return fallback;
  }

  const value = formatDate(new Date(children), format);

  return <span className={classes.span}>{value}</span>;
};

export default FormattedDate;
