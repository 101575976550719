import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { MenuItem, makeStyles, TextField } from "@material-ui/core";

import I18nText from "../../../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  icon: {
    display: "flex",
    alignItems: "center"
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.warning.main
  }
}));

const useTitleStyles = makeStyles({
  root: {
    marginBottom: 0
  }
});

const Threshold = ({ status, title, onUpdateStatus }) => {
  const classes = useStyles();
  const titleClasses = useTitleStyles();

  return (
    <Alert
      severity="warning"
      classes={classes}
      variant="outlined"
      action={
        <TextField
          variant="outlined"
          label={<I18nText>devices.status.threshold.statusLabel</I18nText>}
          select
          style={{ minWidth: 200 }}
          size="small"
          value={status}
          onChange={e => onUpdateStatus(e.target.value)}
        >
          <MenuItem value="disregard">
            <I18nText>devices.status.threshold.status.disregard</I18nText>
          </MenuItem>
          <MenuItem value="seen">
            <I18nText>devices.status.threshold.status.seen</I18nText>
          </MenuItem>
          <MenuItem value="revoked">
            <I18nText>devices.status.threshold.status.revoked</I18nText>
          </MenuItem>
        </TextField>
      }
    >
      <AlertTitle classes={titleClasses}>{title}</AlertTitle>
    </Alert>
  );
};

export default Threshold;
