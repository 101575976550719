import React, { useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";

import { useApi } from "../../../../api";
import I18nText from "../../../../elements/I18nText";
import ConfirmationDialog from "../../../../elements/ConfirmationDialog";
import { SaveButton } from "../../../../elements/Button";
import { Box, TextField, useTheme } from "@material-ui/core";

const ConfirmationContainer = ({ user, syncWithUser, onCancel, onSynced }) => {
  const api = useApi();
  const theme = useTheme();
  const [userInput, setUserInput] = useState("");
  const [isSyncing, setSyncing] = useState(false);

  const isValidUserInput = () => {
    return userInput === user.username;
  };

  const confirm = () => {
    setSyncing(true);

    if (!isValidUserInput()) {
      return;
    }

    api
      .put(`/v2/users/${user.id}/sync-access`, {
        syncWithUserId: syncWithUser.id
      })
      .then(() => {
        setSyncing(false);
        onSynced();
      });

    return;
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isSyncing}
      ConfirmButton={
        <SaveButton
          disabled={!isValidUserInput() || isSyncing}
          onClick={confirm}
        />
      }
    >
      <Box display="flex" justifyContent="center" marginBottom={2}>
        <WarningIcon
          style={{ fontSize: 40, color: theme.palette.error.main }}
        />
      </Box>
      <Box color={theme.palette.error.main} fontWeight="bold">
        Achtung! Sie sind im Begriff dem Benutzer <i>{user.username}</i> die
        gleichen Benutzerrechte zu geben, die der Benutzer{" "}
        <i>{syncWithUser.username}</i> besitzt.
      </Box>
      <Box marginY={2}>
        Bitte beachten Sie, dass ALLE Benutzerrechte des Benutzers{" "}
        {user.username} überschrieben werden, auch von Anlagen, die sich im
        Eigentum des Benutzer befinden.
      </Box>
      <Box marginY={2}>
        Zur Bestätigung geben Sie bitte den Benutzernamen des Benutzers, der die
        Rechte erhalten soll (<b>{user.username}</b>) in das untenstehende
        Textfeld ein und klicken Sie dann auf "bestätigen".
      </Box>
      <TextField
        size="small"
        variant="outlined"
        label={<I18nText>users.common.username</I18nText>}
        value={userInput}
        onChange={e => setUserInput(e.target.value)}
      />
    </ConfirmationDialog>
  );
};

export default ConfirmationContainer;
