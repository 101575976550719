import React from "react";
import { Menu, MenuItem } from "@material-ui/core";

import { useLocale } from "../../i18n";
import I18nText from "../../elements/I18nText";

const LocaleMenu = ({ anchor, onClose }) => {
  const { setLocale } = useLocale();

  const updateLocale = locale => () => {
    setLocale(locale);
    onClose();
  };

  return (
    <Menu
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      anchorEl={anchor}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchor)}
      onClose={onClose}
    >
      <MenuItem onClick={updateLocale("de")}>
        <I18nText>i18n.locales.de</I18nText>
      </MenuItem>
      <MenuItem onClick={updateLocale("en")}>
        <I18nText>i18n.locales.en</I18nText>
      </MenuItem>
    </Menu>
  );
};

export default LocaleMenu;
