import React from "react";
import { TableRow, TableFooter } from "@material-ui/core";

import useFormat from "../../../../hooks/useFormat";
import I18nText from "../../../../elements/I18nText";
import { SENSOR_TYPE, SENSOR_STATUS } from "../../../../domain/sensor";
import TableFooterCell from "../../../../elements/TableFooterCell";

const Footer = ({ sensors, showTemperatureColumn }) => {
  const { formatNumber, formatUnit } = useFormat();

  const getTotalsByUnit = sensors => {
    // Find distinct units
    const units = [...new Set(sensors.map(sensor => sensor.unit))];

    const totalToUnitMap = {};
    units.forEach(unit => {
      totalToUnitMap[unit] = sensors.reduce(
        (accumulated, currentSensor) => {
          if (
            currentSensor.unit !== unit ||
            currentSensor.status === SENSOR_STATUS.ERROR ||
            currentSensor.type !== SENSOR_TYPE.PROBE
          ) {
            return accumulated;
          }

          return {
            capacity: accumulated.capacity + currentSensor.capacity,
            netCapacity: accumulated.netCapacity + currentSensor.netCapacity,
            value: accumulated.value + currentSensor.value
          };
        },
        {
          capacity: 0,
          netCapacity: 0,
          value: 0
        }
      );
    });

    const totalsByUnit = [];
    for (const unit in totalToUnitMap) {
      const value = totalToUnitMap[unit].value;
      const netCapacity = totalToUnitMap[unit].netCapacity;
      const capacity = totalToUnitMap[unit].capacity;

      totalsByUnit.push({
        unit,
        capacity,
        netCapacity,
        value,
        netFreeSpace: Math.max(Math.round(netCapacity - value, 0)),
        pct: Math.max(
          Math.min(
            Math.round(capacity > 0 ? (value / capacity) * 100 : 0),
            100
          ),
          0
        ),
        netPct: Math.max(
          Math.min(
            Math.round(netCapacity > 0 ? (value / netCapacity) * 100 : 0),
            100
          ),
          0
        )
      });
    }

    return totalsByUnit;
  };

  return (
    <TableFooter>
      {getTotalsByUnit(sensors).map(totals => {
        return (
          <TableRow key={totals.unit}>
            <TableFooterCell />
            <TableFooterCell colSpan={2}>
              <I18nText>devices.status.table.header.total</I18nText>
            </TableFooterCell>
            <TableFooterCell align="right">
              {formatNumber(totals.capacity)} {formatUnit(totals.unit)}
            </TableFooterCell>
            {showTemperatureColumn && <TableFooterCell />}
            <TableFooterCell align="right">
              {formatNumber(totals.netFreeSpace)} {formatUnit(totals.unit)}
            </TableFooterCell>
            <TableFooterCell align="right">{totals.netPct} %</TableFooterCell>
            <TableFooterCell align="right">{totals.pct} %</TableFooterCell>
            <TableFooterCell align="right">
              {formatNumber(totals.value)} {formatUnit(totals.unit)}
            </TableFooterCell>
          </TableRow>
        );
      })}
    </TableFooter>
  );
};

export default Footer;
