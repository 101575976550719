import React from "react";
import {
  Route,
  Redirect,
  Switch,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import TabBar from "./TabBar";
import Invoices from "./Invoices";
import Terms from "./Terms";

const BillingContainer = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleSetView = view => history.push(`/system/billing/${view}`);

  return (
    <Switch>
      <Route path={`${url}/invoices`}>
        <TabBar selectedView={"invoices"} onSelectView={handleSetView} />
        <Invoices />
      </Route>
      <Route path={`${url}/terms`}>
        <TabBar selectedView={"terms"} onSelectView={handleSetView} />
        <Terms />
      </Route>
      <Route>
        <Redirect to={`${url}/invoices`} />
      </Route>
    </Switch>
  );
};

export default BillingContainer;
