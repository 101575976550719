import React from "react";

import Counter from "./Counter";
import Probe from "./Probe";
import { SENSOR_TYPE } from "../../../../../../domain/sensor";
import { DEVICE_TYPE } from "../../../../../../domain/device";
import LxIotProbe from "./LxIotProbe";

const Sensor = React.memo(props => {
  const { sensor, deviceType } = props;

  if (sensor.type === SENSOR_TYPE.COUNTER) {
    return <Counter {...props} />;
  }

  if (deviceType === DEVICE_TYPE.LX_IOT) {
    return <LxIotProbe {...props} />;
  }

  return <Probe {...props} />;
});

export default Sensor;
