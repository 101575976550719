import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";

import { FullWidthTextField } from "../../../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../../../elements/Button";

const CommentForm = ({ values, onClose, onSubmit }) => {
  return (
    <Formik
      initialValues={values}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FullWidthTextField
                name="content"
                variant="outlined"
                multiline
                inputProps={{ rows: 4, autoComplete: "off" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;
