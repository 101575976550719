import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ContactSelect = React.memo(
  ({
    value,
    isLoading,
    options,
    name,
    label,
    required,
    hasError,
    onUpdate
  }) => {
    return (
      <Autocomplete
        id="user-select"
        variant="outlined"
        name={name}
        disableClearable={false}
        clearOnEscape
        disabled={isLoading}
        autoSelect={true}
        value={value}
        options={options}
        onChange={(_, value) => onUpdate(value)}
        autoHighlight
        getOptionLabel={option => option.handle}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            error={hasError}
            {...params}
            required={required}
            label={label}
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off"
            }}
          />
        )}
      />
    );
  }
);

export default ContactSelect;
