import React from "react";
import { DialogContent, Box, makeStyles } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../elements/DialogTitleWithCloseIcon";

import FixedPositionDialog from "../../elements/FixedPositionDialog";
import I18nText from "../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(4),
    fontSize: 16,
    fontWeight: 600
  },
  t: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600
  },
  t1: {
    marginBottom: theme.spacing(1),
    fontSize: 13,
    fontWeight: 600
  },
  t2: {
    fontSize: 12,
    fontWeight: 600
  },
  n: {
    marginBottom: theme.spacing(2)
  }
}));

const PrivacyPolicy = ({ onClose }) => {
  const c = useStyles();

  return (
    <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="md">
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>Datenschutzerklärung</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>
        <Box display="flex" justifyContent="flex-end">
          English version below
        </Box>
        <Box className={c.t}>1. Datenschutz auf einen Blick</Box>

        <Box className={c.t1}>Allgemeine Hinweise</Box>
        <Box className={c.n}>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </Box>

        <Box className={c.t1}>Datenerfassung auf unserer Website</Box>

        <Box className={c.t2}>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </Box>
        <Box className={c.n}>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen.
        </Box>

        <Box className={c.t2}>Wie erfassen wir Ihre Daten?</Box>
        <Box className={c.n}>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </Box>

        <Box className={c.n}>
          Andere Daten werden automatisch beim Besuch der Website durch unsere
          IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
          betreten.
        </Box>

        <Box className={c.t2}>Wofür nutzen wir Ihre Daten?</Box>
        <Box className={c.n}>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </Box>

        <Box className={c.t2}>
          Welche Rechte haben Sie bezüglich Ihrer Daten?
        </Box>
        <Box className={c.n}>
          Sie haben jederzeit das Recht Auskunft über Herkunft, Empfänger und
          Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie
          haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
          dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
          Datenschutz können Sie sich jederzeit unter der im Impressum
          angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </Box>

        <Box className={c.t1}>Analyse-Tools und Tools von Drittanbietern</Box>
        <Box className={c.n}>
          Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit Cookies und mit
          sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
          erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
          zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
          sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
          Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
        </Box>
        <Box className={c.n}>
          Sie können dieser Analyse widersprechen. Über die
          Widerspruchsmöglichkeiten werden wir Sie in dieser
          Datenschutzerklärung informieren.
        </Box>

        <Box className={c.t}>
          2. Allgemeine Hinweise und Pflichtinformationen
        </Box>

        <Box className={c.t1}>Datenschutz</Box>
        <Box className={c.n}>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </Box>
        <Box className={c.n}>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </Box>
        <Box className={c.n}>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </Box>

        <Box className={c.t1}>Hinweis zur verantwortlichen Stelle</Box>

        <Box className={c.n}>Die zuständige Aufsichtsbehörde ist:</Box>
        <Box className={c.n}>
          Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein
        </Box>
        <Box className={c.n}>
          Landesbeauftragte für Datenschutz Schleswig-Holstein
        </Box>
        <Box className={c.n}>Holstenstr. 98</Box>
        <Box className={c.n}>D-24103 Kiel</Box>
        <Box className={c.n}>Telefon: (+49) 4 31 / 988-1200</Box>
        <Box className={c.n}>E-Mail: mail@datenschutzzentrum.de</Box>

        <Box className={c.t1}>
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </Box>
        <Box className={c.n}>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </Box>

        <Box className={c.t1}>
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </Box>
        <Box className={c.n}>
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
          sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </Box>

        <Box className={c.t1}>Recht auf Datenübertragbarkeit</Box>
        <Box className={c.n}>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </Box>

        <Box className={c.t1}>SSL- bzw. TLS-Verschlüsselung</Box>
        <Box className={c.n}>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von “http://” auf “https://”
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </Box>

        <Box className={c.n}>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </Box>

        <Box className={c.t}>3. Datenerfassung auf unserer Website</Box>
        <Box className={c.t1}>Cookies</Box>
        <Box className={c.n}>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert.
        </Box>
        <Box className={c.n}>
          Die meisten der von uns verwendeten Cookies sind so genannte
          “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
          gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
          Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
          beim nächsten Besuch wiederzuerkennen.
        </Box>
        <Box className={c.n}>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browser
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </Box>
        <Box className={c.n}>
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
          werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung
          seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
          Surfverhaltens) gespeichert werden, werden diese in dieser
          Datenschutzerklärung gesondert behandelt.
        </Box>

        <Box className={c.t1}>Server-Log-Dateien</Box>
        <Box className={c.n}>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </Box>
        <Box className={c.n}>- Browsertyp und Browserversion</Box>
        <Box className={c.n}>- verwendetes Betriebssystem</Box>
        <Box className={c.n}>- Referrer URL</Box>
        <Box className={c.n}>- Hostname des zugreifenden Rechners</Box>
        <Box className={c.n}>- Uhrzeit der Serveranfrage</Box>
        <Box className={c.n}>- IP-Adresse</Box>

        <Box className={c.n}>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.
        </Box>
        <Box className={c.n}>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit . f DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </Box>

        <Box className={c.t1}>Registrierung auf dieser Website</Box>
        <Box className={c.n}>
          Sie können sich auf unserer Website registrieren, um zusätzliche
          Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
          verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
          Dienstes, für den Sie sich registriert haben. Die bei der
          Registrierung abgefragten Pflichtangaben müssen vollständig angegeben
          werden. Anderenfalls werden wir die Registrierung ablehnen.
        </Box>

        <Box className={c.n}>
          Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
          notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
          E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
        </Box>
        <Box className={c.n}>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
          können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu
          reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
          der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </Box>
        <Box className={c.n}>
          Die bei der Registrierung erfassten Daten werden von uns gespeichert,
          solange Sie auf unserer Website registriert sind und werden
          anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
          unberührt.
        </Box>

        <Box className={c.title}>Privacy Policy</Box>

        <Box className={c.t}>1. An overview of data protection</Box>

        <Box className={c.t1}>General</Box>
        <Box className={c.n}>
          The following gives a simple overview of what happens to your personal
          information when you visit our website. Personal information is any
          data with which you could be personally identified. Detailed
          information on the subject of data protection can be found in our
          privacy policy found below.
        </Box>

        <Box className={c.t1}>Data collection on our website</Box>

        <Box className={c.t2}>
          Who is responsible for the data collection on this website?
        </Box>
        <Box className={c.n}>
          The data collected on this website are processed by the website
          operator. The operator's contact details can be found in the website's
          required legal notice.
        </Box>

        <Box className={c.t2}>How do we collect your data?</Box>
        <Box className={c.n}>
          Some data are collected when you provide it to us. This could, for
          example, be data you enter on a contact form.
        </Box>

        <Box className={c.n}>
          Other data are collected automatically by our IT systems when you
          visit the website. These data are primarily technical data such as the
          browser and operating system you are using or when you accessed the
          page. These data are collected automatically as soon as you enter our
          website.
        </Box>

        <Box className={c.t2}>What do we use your data for?</Box>
        <Box className={c.n}>
          Part of the data is collected to ensure the proper functioning of the
          website. Other data can be used to analyze how visitors use the site.
        </Box>

        <Box className={c.t2}>What rights do you have regarding your data?</Box>
        <Box className={c.n}>
          You always have the right to request information about your stored
          data, its origin, its recipients, and the purpose of its collection.
          You also have the right to request that it be corrected, blocked, or
          deleted. You can contact us at any time using the address given in the
          legal notice if you have further questions about the issue of privacy
          and data protection. You may also, of course, file a complaint with
          the competent regulatory authorities.
        </Box>

        <Box className={c.t1}>Analytics and third-party tools</Box>
        <Box className={c.n}>
          When visiting our website, statistical analyses may be made of your
          surfing behavior. This happens primarily using cookies and analytics.
          The analysis of your surfing behavior is usually anonymous, i.e. we
          will not be able to identify you from this data. You can object to
          this analysis or prevent it by not using certain tools. Detailed
          information can be found in the following privacy policy.
        </Box>
        <Box className={c.n}>
          You can object to this analysis. We will inform you below about how to
          exercise your options in this regard.
        </Box>

        <Box className={c.t}>
          2. General information and mandatory information
        </Box>

        <Box className={c.t1}>Data protection</Box>
        <Box className={c.n}>
          The operators of this website take the protection of your personal
          data very seriously. We treat your personal data as confidential and
          in accordance with the statutory data protection regulations and this
          privacy policy.
        </Box>
        <Box className={c.n}>
          If you use this website, various pieces of personal data will be
          collected. Personal information is any data with which you could be
          personally identified. This privacy policy explains what information
          we collect and what we use it for. It also explains how and for what
          purpose this happens.
        </Box>
        <Box className={c.n}>
          Please note that data transmitted via the internet (e.g. via email
          communication) may be subject to security breaches. Complete
          protection of your data from third-party access is not possible.
        </Box>

        <Box className={c.t1}>The concerning party</Box>

        <Box className={c.n}>The concerning party is:</Box>
        <Box className={c.n}>
          Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein
        </Box>
        <Box className={c.n}>
          Landesbeauftragte für Datenschutz Schleswig-Holstein
        </Box>
        <Box className={c.n}>Holstenstr. 98</Box>
        <Box className={c.n}>D-24103 Kiel</Box>
        <Box className={c.n}>Telephone: (+49) 4 31 / 988-1200</Box>
        <Box className={c.n}>Email: mail@datenschutzzentrum.de</Box>

        <Box className={c.t1}>
          Revocation of your consent to the processing of your data
        </Box>
        <Box className={c.n}>
          Many data processing operations are only possible with your express
          consent. You may revoke your consent at any time with future effect.
          An informal email making this request is sufficient. The data
          processed before we receive your request may still be legally
          processed.
        </Box>

        <Box className={c.t1}>
          Right to file complaints with regulatory authorities
        </Box>
        <Box className={c.n}>
          If there has been a breach of data protection legislation, the person
          affected may file a complaint with the competent regulatory
          authorities. The competent regulatory authority for matters related to
          data protection legislation is the data protection officer of the
          German state in which our company is headquartered. A list of data
          protection officers and their contact details can be found at the
          following link:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </Box>

        <Box className={c.t1}>Right to data portability</Box>
        <Box className={c.n}>
          You have the right to have data which we process based on your consent
          or in fulfillment of a contract automatically delivered to yourself or
          to a third party in a standard, machine-readable format. If you
          require the direct transfer of data to another responsible party, this
          will only be done to the extent technically feasible.
        </Box>

        <Box className={c.t1}>SSL or TLS encryption</Box>
        <Box className={c.n}>
          This site uses SSL or TLS encryption for security reasons and for the
          protection of the transmission of confidential content, such as the
          inquiries you send to us as the site operator. You can recognize an
          encrypted connection in your browser's address line when it changes
          from "http://" to "https://" and the lock icon is displayed in your
          browser's address bar.
        </Box>

        <Box className={c.n}>
          If SSL or TLS encryption is activated, the data you transfer to us
          cannot be read by third parties.
        </Box>

        <Box className={c.t}>3. Data collection on our website</Box>
        <Box className={c.t1}>Cookies</Box>
        <Box className={c.n}>
          Some of our web pages use cookies. Cookies do not harm your computer
          and do not contain any viruses. Cookies help make our website more
          user-friendly, efficient, and secure. Cookies are small text files
          that are stored on your computer and saved by your browser.
        </Box>
        <Box className={c.n}>
          Most of the cookies we use are so-called "session cookies." They are
          automatically deleted after your visit. Other cookies remain in your
          device's memory until you delete them. These cookies make it possible
          to recognize your browser when you next visit the site.
        </Box>
        <Box className={c.n}>
          You can configure your browser to inform you about the use of cookies
          so that you can decide on a case-by-case basis whether to accept or
          reject a cookie. Alternatively, your browser can be configured to
          automatically accept cookies under certain conditions or to always
          reject them, or to automatically delete cookies when closing your
          browser. Disabling cookies may limit the functionality of this
          website.
        </Box>
        <Box className={c.n}>
          Cookies which are necessary to allow electronic communications or to
          provide certain functions you wish to use (such as the shopping cart)
          are stored pursuant to Art. 6 paragraph 1, letter f of DSGVO. The
          website operator has a legitimate interest in the storage of cookies
          to ensure an optimized service provided free of technical errors. If
          other cookies (such as those used to analyze your surfing behavior)
          are also stored, they will be treated separately in this privacy
          policy.
        </Box>

        <Box className={c.t1}>Server log files</Box>
        <Box className={c.n}>
          The website provider automatically collects and stores information
          that your browser automatically transmits to us in server log files.
          These are:
        </Box>
        <Box className={c.n}>- Browser type and browser version</Box>
        <Box className={c.n}>- Operating system used</Box>
        <Box className={c.n}>- Referrer URL</Box>
        <Box className={c.n}>- Host name of the accessing computer</Box>
        <Box className={c.n}>- Time of the server request</Box>
        <Box className={c.n}>- IP address</Box>

        <Box className={c.n}>
          These data will not be combined with data from other sources.
        </Box>
        <Box className={c.n}>
          The basis for data processing is Art. 6 (1) (f) DSGVO, which allows
          the processing of data to fulfill a contract or for measures
          preliminary to a contract.
        </Box>

        <Box className={c.t1}>Registration on this website</Box>
        <Box className={c.n}>
          You can register on our website in order to access additional
          functions offered here. The input data will only be used for the
          purpose of using the respective site or service for which you have
          registered. The mandatory information requested during registration
          must be provided in full. Otherwise, we will reject your registration.
        </Box>

        <Box className={c.n}>
          To inform you about important changes such as those within the scope
          of our site or technical changes, we will use the email address
          specified during registration.
        </Box>
        <Box className={c.n}>
          We will process the data provided during registration only based on
          your consent per Art. 6 (1)(a) DSGVO. You may revoke your consent at
          any time with future effect. An informal email making this request is
          sufficient. The data processed before we receive your request may
          still be legally processed.
        </Box>
        <Box className={c.n}>
          We will continue to store the data collected during registration for
          as long as you remain registered on our website. Statutory retention
          periods remain unaffected.
        </Box>
      </DialogContent>
    </FixedPositionDialog>
  );
};

export default PrivacyPolicy;
