import React from "react";

import { FullWidthTextField } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";
import I18nText from "../../../../../../elements/I18nText";

const SmsHeader = React.memo(() => {
  return (
    <BubbleHelpFieldWrapper id="devices.edit.smsHeader">
      <FullWidthTextField
        name="smsHeader"
        variant="outlined"
        label={<I18nText>devices.settings.configuration.smsHeader</I18nText>}
        inputProps={{ maxLength: 32, autoComplete: "off" }}
      />
    </BubbleHelpFieldWrapper>
  );
});

export default SmsHeader;
