import React from "react";
import { TextField, MenuItem, Grid, makeStyles } from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import I18nText from "../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../elements/Button";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const Export = ({ values, setValue, onSubmit, onClose, isExporting }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            classes={classes}
            select
            size="small"
            variant="outlined"
            label={<I18nText>devices.export.fileFormat</I18nText>}
            value={values.fileFormat}
            onChange={e => setValue("fileFormat", e.target.value)}
          >
            <MenuItem value="xlsx">Excel (*.xlsx)</MenuItem>
            <MenuItem value="xls">Excel 97 (*.xls)</MenuItem>
            <MenuItem value="csv">CSV (*.csv)</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <KeyboardDateTimePicker
            classes={classes}
            size="small"
            variant="dialog"
            inputVariant="outlined"
            ampm={false}
            label={<I18nText>devices.export.date</I18nText>}
            value={values.selectedDate}
            onChange={value => setValue("selectedDate", value)}
            disableFuture={true}
            format="dd.MM.yyyy HH:mm"
          />
        </Grid>
        {values.wantsCurrentData && (
          <Grid item xs={12}>
            <TextField
              classes={classes}
              label={<I18nText>devices.export.interpolationMode</I18nText>}
              select
              variant="outlined"
              size="small"
              value={values.interpolationMode}
              onChange={e => setValue("interpolationMode", e.target.value)}
            >
              <MenuItem value="interpolate">
                <I18nText>
                  devices.export.interpolationModes.interpolate
                </I18nText>
              </MenuItem>
              <MenuItem value="last_value">
                <I18nText>devices.export.interpolationModes.lastValue</I18nText>
              </MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={
              <CancelButton onClick={onClose} disabled={isExporting} />
            }
            rightButton={
              <PrimaryButton
                onClick={onSubmit}
                disabled={isExporting}
                icon={<CloudDownloadOutlinedIcon />}
              >
                <I18nText>devices.export.actions.export</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Export;
