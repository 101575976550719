import React from "react";
import { FastField } from "formik";
import { InputAdornment } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";

const FillingRecognitionInterval = React.memo(() => (
  <FastField
    component={NumberField}
    name="fillingRecognitionInterval"
    validate={numberValidator({ min: 0, max: 99 })}
    min={0}
    required
    max={99}
    label="devices.settings.configuration.fillingRecognitionInterval"
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>
    }}
  />
));

export default FillingRecognitionInterval;
