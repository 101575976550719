import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import I18nText from "../../../elements/I18nText";
import { useAuth, ROLES, PERMISSIONS } from "../../../auth";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
    textTransform: "none",
    position: "relative",
    fontSize: 14,
    fontWeight: "normal",
    "&$selected": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
      fontWeight: 500
    },
    "&:hover": {
      fontWeight: 500
    }
  },
  selected: {}
}));

const useTabsStyles = makeStyles({
  indicator: {
    display: "none"
  }
});

const TabBar = ({ device, onSelectView, selectedView }) => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const { user, hasRole, hasPermission } = useAuth();

  if (!device) {
    return null;
  }

  return (
    <>
      <Tabs
        classes={tabsClasses}
        value={selectedView}
        variant="fullWidth"
        textColor="primary"
        onChange={(_, e) => onSelectView(e)}
      >
        <Tab
          classes={classes}
          value="configuration"
          label={<I18nText>devices.settings.tabs.configuration</I18nText>}
        ></Tab>
        <Tab
          classes={classes}
          value="metadata"
          label={<I18nText>devices.settings.tabs.metadata</I18nText>}
        ></Tab>
        <Tab
          classes={classes}
          value="notification-recipients"
          label={<I18nText>devices.settings.tabs.notifications</I18nText>}
        ></Tab>
        {hasPermission(device.permissions, PERMISSIONS.EDIT) && (
          <Tab
            classes={classes}
            value="access"
            label={<I18nText>devices.settings.tabs.access</I18nText>}
          ></Tab>
        )}
        {hasRole(user, ROLES.MASTER) && (
          <Tab
            classes={classes}
            value="billing"
            label={<I18nText>devices.settings.tabs.billing</I18nText>}
          ></Tab>
        )}
      </Tabs>
    </>
  );
};

export default TabBar;
