import React, { useState } from "react";

import { useApi } from "../../../../api";
import {
  setFieldErrorsFromResponse,
  createFieldErrorMessage
} from "../../../../utils/formErrors";
import FormDialog from "./FormDialog";
import LoginForm from "./LoginForm";
import { useAuth } from "../../../../auth";
import { generatePassword } from "../../../../utils/stringUtils";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const LoginContainer = ({ user, onClose }) => {
  const api = useApi();
  const { user: me, getAssignableRoles } = useAuth();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [password, setPassword] = useState(generatePassword());

  const validate = values => {
    const errors = {};

    if (values.username.length < 4 || values.username.length > 32) {
      errors.username = createFieldErrorMessage("username", "length", {
        min: 4,
        max: 96
      });
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      username: values.username,
      parentUserId: values.parentUser.id,
      role: values.role,
      active: values.active
    };

    if (values.changePassword) {
      params.password = password;
      params.passwordRepeat = password;
    }

    api
      .put(`/v2/users/${user.id}/login`, params)
      .then(() => {
        enqueueSnackbar("users.responses.updated", { variant: "success" });

        onClose();
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const handleRegeneratePassword = () => {
    setPassword(generatePassword());
  };

  const roles = getAssignableRoles(me);

  const initialValues = {
    changePassword: false,
    username: user.username,
    parentUser: {
      id: user.parentUserId,
      label: `${user.parentUser.name} (${user.parentUser.username})`
    },
    role: user.role,
    active: user.status === "ok"
  };

  return (
    <FormDialog onClose={onClose}>
      <LoginForm
        roles={roles}
        password={password}
        values={initialValues}
        onClose={onClose}
        onSubmit={handleSubmit}
        validate={validate}
        onRegeneratePassword={handleRegeneratePassword}
      />
    </FormDialog>
  );
};

export default LoginContainer;
