import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { REGEXP_ID } from "../../../utils/stringUtils";
import { useApi } from "../../../api";
import Pagination from "../../../elements/Pagination";
import Access from "./Access";
import DeleteContainer from "./DeleteContainer";
import EditAce from "./Edit";
import CreateAce from "./Create";
import SyncContainer from "./Sync";

const AccessContainer = ({ user }) => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [devices, setDevices] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [cacheBuster, setCacheBuster] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [confirmDeleteAce, setConfirmDeleteAce] = useState(null);

  useEffect(() => {
    let target = `/v2/devices?user_id=${user.id}&per_page=100&page=${page}#${cacheBuster}`;

    setQuery(target);
  }, [user, page, cacheBuster]);

  const doReload = () => {
    setCacheBuster(Math.round(Math.random() * 1000));
  };

  useEffect(() => {
    if (!query) {
      return;
    }

    setLoading(true);

    api
      .get(query)
      .then(response => {
        setDevices(response.data);
        setTotalCount(response.headers["x-total-count"]);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setLoading(false));
  }, [api, query]);

  const handleConfirmDelete = value => {
    setConfirmDeleteAce(value);
  };

  const handleCreateAce = () => history.push(`${url}/new`);

  const handleSyncAccess = () => history.push(`${url}/sync`);

  const handleClose = () => {
    doReload();
    history.push(url);
  };

  return (
    <>
      <Switch>
        <Route path={`${url}/new`}>
          <CreateAce user={user} onClose={handleClose} />
        </Route>
        <Route path={`${url}/sync`}>
          <SyncContainer user={user} onClose={handleClose} />
        </Route>
        <Route path={`${url}/:deviceId(${REGEXP_ID})/:aceId(${REGEXP_ID})`}>
          {({
            match: {
              params: { aceId, deviceId }
            }
          }) => (
            <EditAce deviceId={deviceId} aceId={aceId} onClose={handleClose} />
          )}
        </Route>
      </Switch>
      {confirmDeleteAce !== null && (
        <DeleteContainer
          deviceId={confirmDeleteAce.deviceId}
          aceId={confirmDeleteAce.aceId}
          onCancel={() => setConfirmDeleteAce(null)}
          onDeleted={() => {
            doReload();
            setConfirmDeleteAce(null);
          }}
        />
      )}
      <Access
        paginationComponent={
          <Pagination
            totalCount={totalCount}
            perPage={100}
            page={page}
            onSelectPage={page => setPage(page)}
            allowPerPageSelect={false}
          />
        }
        devices={devices}
        isLoading={isLoading}
        onSync={handleSyncAccess}
        onCreate={handleCreateAce}
        onEdit={device =>
          history.push(`${url}/${device.id}/${device.userPermissions.aceId}`)
        }
        onDelete={handleConfirmDelete}
        user={user}
      />
    </>
  );
};

export default AccessContainer;
