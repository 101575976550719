import React, { useState } from "react";

import Dialog from "./Dialog";
import SyncForm from "./SyncForm";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";
import { useAuth } from "../../../../auth";
import ConfirmationContainer from "./ConfirmationContainer";

const SyncContainer = ({ user, onClose }) => {
  const { user: myself } = useAuth();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [syncWithUser, setSyncWithUser] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const exludedUsers = [myself.id, user.id];

  const handleSubmit = () => {
    setShowConfirmation(true);
  };

  const handleCancelConfirm = () => {
    setSyncWithUser(null);
  };

  const handleSynced = () => {
    enqueueSnackbar("users.responses.updated", {
      variant: "success"
    });

    onClose();
  };

  return (
    <>
      <Dialog>
        <SyncForm
          user={user}
          excludeList={exludedUsers}
          onClose={onClose}
          onUserSelected={u => setSyncWithUser(u)}
          syncWithUser={syncWithUser}
          onSubmit={handleSubmit}
        />
      </Dialog>
      {showConfirmation && syncWithUser && (
        <ConfirmationContainer
          user={user}
          syncWithUser={syncWithUser}
          onCancel={handleCancelConfirm}
          onSynced={handleSynced}
        />
      )}
    </>
  );
};

export default SyncContainer;
