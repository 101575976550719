import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { useLocale } from "../i18n";

const localeMap = {
  en: enLocale,
  de: deLocale
};

const MuiPickersLocalizedProvider = ({ children }) => {
  const { locale } = useLocale();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default MuiPickersLocalizedProvider;
