import React, { useEffect, useState } from "react";

import { useObjectConfig } from "../../../objectConfig";
import { useWhitelabel } from "../../../domain/whitelabel";
import Config from "./Config";
import { useHistory } from "react-router-dom";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const ConfigContainer = ({ onClose }) => {
  const { enqueueSnackbar } = useI18nSnackbar();
  const {
    whitelabel,
    whitelabelConfig,
    whitelabelConfigLoaded,
    reloadWhitelabelConfig
  } = useWhitelabel();

  const { set: setConfig } = useObjectConfig();
  const history = useHistory();
  const [tableColumns, setTableColumns] = useState({});
  const [sensorSettings, setSensorSettings] = useState({});
  const [groupByUser, setGroupByUser] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!whitelabelConfigLoaded) {
      return;
    }

    setTableColumns(whitelabelConfig["ui.devices.list.columns"]);
    setGroupByUser(whitelabelConfig["ui.devices.groupByUser"]);
    setSensorSettings({
      showNames: whitelabelConfig["ui.devices.list.sensors.showNames"],
      showVisuals: whitelabelConfig["ui.devices.list.sensors.showVisuals"],
      showUnits: whitelabelConfig["ui.devices.list.sensors.showUnits"],
      showTemperatures:
        whitelabelConfig["ui.devices.list.sensors.showTemperatures"]
    });

    setLoading(false);
  }, [whitelabelConfig, whitelabelConfigLoaded]);

  const handleToggleTableColumn = (name, flag) => {
    const idx = tableColumns.indexOf(name);

    const found = idx !== -1;

    const newColumns = [...tableColumns];

    if (!flag && found) {
      newColumns.splice(idx, 1);

      setTableColumns(newColumns);

      return;
    }

    if (flag && !found) {
      newColumns.push(name);

      setTableColumns(newColumns);
    }
  };

  const handleToggleSensorSetting = (name, flag) => {
    setSensorSettings({ ...sensorSettings, [name]: flag });
  };

  const handleToggleGroupByUser = value => {
    setGroupByUser(value);
  };

  const handleSave = () => {
    setSubmitting(true);

    setConfig([
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.list.columns",
        value: tableColumns
      },
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.groupByUser",
        value: groupByUser
      },
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.list.sensors.showNames",
        value: sensorSettings.showNames
      },
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.list.sensors.showVisuals",
        value: sensorSettings.showVisuals
      },
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.list.sensors.showUnits",
        value: sensorSettings.showUnits
      },
      {
        obj: "whitelabel",
        id: whitelabel.id,
        key: "ui.devices.list.sensors.showTemperatures",
        value: sensorSettings.showTemperatures
      }
    ])
      .then(() => {
        reloadWhitelabelConfig();
        setSubmitting(false);
        history.push("/devices");
        enqueueSnackbar("devices.list.config.responses.ok", {
          variant: "success"
        });
      })
      .catch(() => setSubmitting(false));
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Config
        tableColumns={tableColumns}
        sensorSettings={sensorSettings}
        groupByUser={groupByUser}
        onToggleGroupByUser={handleToggleGroupByUser}
        onToggleTableColumn={handleToggleTableColumn}
        onToggleSensorSetting={handleToggleSensorSetting}
        onSave={handleSave}
        onClose={onClose}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default ConfigContainer;
