import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Dialog from "./Dialog";
import { useApi } from "../../../api";
import {
  DEVICE_SMS_GATEWAY_TYPE,
  DEVICE_TYPE,
  useDeviceIds
} from "../../../domain/device";
import {
  createFieldErrorMessage,
  setFieldErrorsFromResponse
} from "../../../utils/formErrors";
import DeviceForm from "./DeviceForm";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";
import { useAuth } from "../../../auth";
import { useSmsGateways } from "../../../domain/smsGateway";

const CreateContainer = ({ onClose }) => {
  const api = useApi();
  const history = useHistory();
  const { user } = useAuth();
  const { reload: reloadDeviceIds } = useDeviceIds();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [type, setType] = useState(DEVICE_SMS_GATEWAY_TYPE.LIONEL);
  const [debtorValue, setDebtorValue] = useState({
    ident: ""
  });

  const smsGateways = [...useSmsGateways()];

  const validate = values => {
    const errors = {};

    switch (type) {
      case DEVICE_TYPE.NET:
        if (!values.netDevice || !values.netDevice.id) {
          errors.netDevice = createFieldErrorMessage("netDevice", "required");
        }

        break;

      case DEVICE_TYPE.LX_IOT:
        if (values.iccid) {
          break;
        }

        if (values.lxIotDevice && values.lxIotDevice.id) {
          break;
        }

        if (!values.iccid) {
          errors.iccid = createFieldErrorMessage("iccid", "required");
        }

        errors.lxIotDevice = createFieldErrorMessage("lxIotDevice", "required");

        break;

      case DEVICE_TYPE.IOT:
        if (values.thingName) {
          break;
        }

        if (values.iotDevice && values.iotDevice.id) {
          break;
        }

        if (!values.thingName) {
          errors.thingName = createFieldErrorMessage("thingName", "required");
        }

        errors.netDevice = createFieldErrorMessage("iotDevice", "required");

        break;

      case DEVICE_SMS_GATEWAY_TYPE.ONCE:
        if (!values.iccid) {
          errors.iccid = createFieldErrorMessage("iccid", "required");
        }

        break;

      case DEVICE_SMS_GATEWAY_TYPE.LIONEL:
        if (smsGateways.length > 1) {
          if (!values.inboundGatewayId) {
            errors.inboundGatewayId = createFieldErrorMessage(
              "inboundGatewayId",
              "required"
            );
          }

          if (!values.outboundGatewayId) {
            errors.outboundGatewayId = createFieldErrorMessage(
              "outboundGatewayId",
              "required"
            );
          }
        }

        break;

      default:
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      debtorIdent: debtorValue.ident,
      userId: values.user.id,
      name: values.name,
      address: { ...values.address }
    };

    // Assign NET Device
    if (type === DEVICE_TYPE.NET) {
      return assignDevice(
        values.netDevice.id,
        { ...params },
        { setSubmitting, setFieldError }
      );
    }

    // Assign IoT Device
    if (type === DEVICE_TYPE.IOT && values.iotDevice.id) {
      return assignDevice(
        values.iotDevice.id,
        { ...params },
        { setSubmitting, setFieldError }
      );
    }

    // Assign LX-IoT Device
    if (type === DEVICE_TYPE.LX_IOT && values.lxIotDevice.id) {
      return assignDevice(
        values.lxIotDevice.id,
        { ...params },
        { setSubmitting, setFieldError }
      );
    }

    if (type === DEVICE_TYPE.IOT) {
      params.thingName = values.thingName;
      params.type = DEVICE_TYPE.IOT;
    } else if (type === DEVICE_TYPE.LX_IOT) {
      params.iccid = values.iccid;
      params.type = DEVICE_TYPE.LX_IOT;
    } else {
      // SMS Types (type = "1nce" or "lionel")
      params.type = DEVICE_TYPE.SMS;
      params.smsGatewayType = type;

      if (type === DEVICE_SMS_GATEWAY_TYPE.ONCE) {
        params.iccid = values.iccid;
      }

      if (type === DEVICE_SMS_GATEWAY_TYPE.LIONEL) {
        params.cellNumber = values.cellNumber;

        if (smsGateways.length > 1) {
          params.inboundGatewayId = values.inboundGatewayId;
          params.outboundGatewayId = values.outboundGatewayId;
        }
      }
    }

    api
      .post("/v2/devices", params)
      .then(response => {
        enqueueSnackbar("devices.create.responses.created", {
          variant: "success"
        });

        const apiUrl = response.headers.location;
        const newId = apiUrl.substr(apiUrl.lastIndexOf("/") + 1);

        reloadDeviceIds();
        history.push(`/devices/${newId}/settings/configuration`);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const assignDevice = (id, params, { setSubmitting, setFieldError }) => {
    api
      .put(`/v2/devices/${id}/assign-to-user`, params)
      .then(() => {
        enqueueSnackbar("devices.create.responses.assignedToUser", {
          variant: "success"
        });

        reloadDeviceIds();
        history.push(`/devices/${id}/settings/configuration`);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const handleUpdateDebtorValue = values => {
    let newValues = {};
    if (values === null) {
      newValues = {
        ident: ""
      };
    } else {
      newValues = { ...values };
    }

    setDebtorValue(newValues);
  };

  const values = {
    name: "",
    iccid: "",
    thingName: "",
    cellNumber: "",
    user: {
      id: user.id,
      label: `${user.name} (${user.username})`
    },
    address: {
      street: "",
      streetNumber: "",
      postalCode: "",
      city: "",
      countryCode: "DE"
    },
    debtorIdent: "",
    netDevice: {
      id: "",
      label: ""
    },
    iotDevice: {
      id: "",
      label: ""
    },
    lxIotDevice: {
      id: "",
      label: ""
    },
    outboundGatewayId: "",
    inboundGatewayId: ""
  };

  return (
    <Dialog onClose={onClose}>
      <DeviceForm
        values={values}
        type={type}
        smsGateways={smsGateways}
        validate={validate}
        onClose={onClose}
        debtorValue={debtorValue}
        onUpdateDebtorValue={handleUpdateDebtorValue}
        onUpdateType={value => setType(value)}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default CreateContainer;
