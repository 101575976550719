import React from "react";
import { Box, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import I18nText from "../../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../../elements/Button";
import { UserSelect } from "../../../../elements/FormField";

const SyncForm = ({
  user,
  excludeList = [],
  onUserSelected,
  syncWithUser,
  onClose,
  onSubmit
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{ fontSize: 14 }}>
            Hier haben Sie die Möglichkeit, dem Benutzer <b>{user.username}</b>{" "}
            die gleichen Rechte einzuräumen wie einem bestehenden Benutzer.
          </Box>
          <Box marginY={2} style={{ fontSize: 14 }}>
            Bitte wählen Sie den Benutzer, dessen Zugriffsrechte vom Benutzer{" "}
            <b>{user.username}</b> kopiert werden sollen.
          </Box>
          <UserSelect
            name="syncWithUser"
            excludeList={excludeList}
            value={syncWithUser}
            required
            onUpdate={value => onUserSelected(value)}
            label={
              <I18nText>devices.settings.access.create.selectUser</I18nText>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={<CancelButton onClick={onClose} />}
            rightButton={
              <PrimaryButton
                disabled={!user}
                icon={<ArrowForwardIosIcon />}
                onClick={onSubmit}
              >
                <I18nText>actions.save</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SyncForm;
