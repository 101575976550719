import React from "react";
import { MenuItem, makeStyles } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const SubType = ({ prefix, options }) => {
  const classes = useStyles();

  return (
    <FullWidthSelect
      variant="outlined"
      classes={classes}
      name={`${prefix}.subType`}
      inputProps={{
        disabled: options.length === 1
      }}
    >
      {options.map(option => (
        <MenuItem key={option} value={option}>
          <I18nText>{`sensors.subTypes.${option}`}</I18nText>
        </MenuItem>
      ))}
    </FullWidthSelect>
  );
};

export default SubType;
