import React from "react";
import { Box, useTheme } from "@material-ui/core";

import useFormat from "../../../../hooks/useFormat";

const TemperatureContent = ({ sensor }) => {
  const theme = useTheme();
  const { formatNumber, formatUnit } = useFormat();

  if (sensor.temperatureValue === null) {
    return null;
  }

  const value =
    formatNumber(sensor.temperatureValue) +
    " " +
    formatUnit(sensor.temperatureUnit);
  let color = theme.palette.text.main;

  if (
    sensor.temperatureLimitType === "above" &&
    sensor.temperatureValue >= sensor.temperatureLimitValue
  ) {
    color = theme.palette.error.main;
  }

  if (
    sensor.temperatureLimitType === "below" &&
    sensor.temperatureValue <= sensor.temperatureLimitValue
  ) {
    color = theme.palette.secondary.main;
  }

  return <Box color={color}>{value}</Box>;
};

export default TemperatureContent;
