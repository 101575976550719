import React, { useEffect, useState } from "react";
import { Box, makeStyles, TableCell, TableRow } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  cell: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
    fontSize: 14
  }
});

const TreeNode = ({ node, level = 0, filter, nodeIsFiltered, onSelect }) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(!filter);
  }, [filter]);

  const handleToggle = () => {
    setCollapsed(state => !state);
  };

  if (nodeIsFiltered(node)) {
    return null;
  }

  const levels = Array(level).fill(0);

  const ToggleComponent = () => {
    const isExpanded = !collapsed && node.children.length !== 0;

    return (
      <>
        {isExpanded ? (
          <ExpandLessIcon className={classes.icon} />
        ) : (
          <ExpandMoreIcon className={classes.icon} />
        )}
      </>
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell className={classes.cell}>
          <Box display="flex">
            {levels.map((l, i) => (
              <Box key={`${node.id}-levels-${i}`} width={20}>
                &nbsp;
              </Box>
            ))}
            <Box onClick={handleToggle} width={20}>
              {node.children.length > 0 && <ToggleComponent />}
            </Box>
            <Box flex="1 0 0" onClick={() => onSelect(node)}>
              {node.title}
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      {!collapsed &&
        node.children.map(child => (
          <TreeNode
            key={child.id}
            filter={filter}
            nodeIsFiltered={nodeIsFiltered}
            node={child}
            onSelect={onSelect}
            level={level + 1}
          />
        ))}
    </>
  );
};

export default TreeNode;
