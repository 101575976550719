import React, { useState } from "react";
import { formatRFC3339 } from "date-fns";

import Export from "./Export";
import config from "../../../config";
import { useLocale } from "../../../i18n";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const ExportContainer = ({ onClose }) => {
  const { locale } = useLocale();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isExporting, setExporting] = useState(false);
  const [values, setValues] = useState({
    fileFormat: "xlsx",
    wantsCurrentData: true,
    interpolationMode: "interpolate",
    selectedDate: new Date()
  });

  const handleSetValue = (key, value) => {
    setValues(state => {
      state[key] = value;

      setValues({ ...state });
    });
  };

  const handleSubmit = () => {
    let query = `export_type=simple_list&file_format=${values.fileFormat}`;

    if (values.interpolationMode) {
      query += `&interpolation_mode=${values.interpolationMode}`;
    }

    if (locale) {
      query += `&locale=${locale}`;
    }

    if (values.selectedDate) {
      const formattedDate = encodeURIComponent(
        formatRFC3339(values.selectedDate)
      );

      query += `&interpolation_date=${formattedDate}`;
    }

    enqueueSnackbar("devices.export.responses.success", {
      variant: "success"
    });

    setExporting(true);
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setExporting(false);
        onClose();
      }
    }, 1000);

    const location = `${config.api.baseUrl}/v2/devices/export?${query}`;
    window.location.href = location;
  };

  return (
    <Export
      onSubmit={handleSubmit}
      values={values}
      onClose={onClose}
      setValue={handleSetValue}
      isExporting={isExporting}
    />
  );
};

export default ExportContainer;
