import React from "react";
import { MenuItem, ListSubheader } from "@material-ui/core";

import { useTranslation } from "../../../../../../i18n";
import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import { useMobileProviders } from "../../../../../../domain/mobileProvider";

const SimProvider = React.memo(() => {
  const { t } = useTranslation();
  const mobileProviders = useMobileProviders();

  const providers = mobileProviders.map(code => ({
    code,
    countryCode: code.substring(0, 2),
    name: t(`mobileProviders.${code}`)
  }));

  const countries = [
    ...new Set(mobileProviders.map(code => code.substring(0, 2)))
  ];

  const options = [];
  countries.forEach(countryCode => {
    options.push(
      <ListSubheader key={countryCode} value="">
        <I18nText>{`countries.${countryCode.toUpperCase()}`}</I18nText>
      </ListSubheader>
    );

    providers.forEach(provider => {
      if (provider.countryCode === countryCode) {
        options.push(
          <MenuItem key={provider.code} value={provider.code}>
            {provider.name}
          </MenuItem>
        );
      }
    });
  });

  return (
    <FullWidthSelect
      fastField={false}
      variant="outlined"
      label={<I18nText>devices.settings.configuration.simProvider</I18nText>}
      name="simProvider"
      inputProps={{
        id: "sim-provider-select"
      }}
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {options.map(option => option)}
    </FullWidthSelect>
  );
});

export default SimProvider;
