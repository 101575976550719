import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const Background = ({ zIndex, scaleWidth }) => {
  const classes = makeStyles({
    legend: {
      zIndex,
      position: "absolute",
      right: "calc(100% - 34px)",
      fontSize: 12
    },
    legend100: {
      top: -7
    },
    legend80: {
      top: "calc(20% - 7px)"
    },
    legend60: {
      top: "calc(40% - 7px)"
    },
    legend40: {
      top: "calc(60% - 7px)"
    },
    legend20: {
      top: "calc(80% - 7px)"
    },
    legend0: {
      top: "calc(100% - 7px)"
    },
    hr: {
      zIndex,
      position: "absolute",
      left: scaleWidth,
      width: `calc(100% - ${scaleWidth}px)`,
      margin: 0,
      border: 0,
      height: 1,
      backgroundColor: "#ccc",
      backgroundImage: "linear-gradient(to right, #eee, #ccc, #eee)"
    },
    hr100: {
      top: 0
    },
    hr80: {
      top: "20%"
    },
    hr60: {
      top: "40%"
    },
    hr40: {
      top: "60%"
    },
    hr20: {
      top: "80%"
    },
    hr0: {
      top: "100%"
    }
  })();

  return (
    <>
      <div className={clsx(classes.legend, classes.legend100)}>100%</div>
      <div className={clsx(classes.legend, classes.legend80)}>80%</div>
      <div className={clsx(classes.legend, classes.legend60)}>60%</div>
      <div className={clsx(classes.legend, classes.legend40)}>40%</div>
      <div className={clsx(classes.legend, classes.legend20)}>20%</div>
      <div className={clsx(classes.legend, classes.legend0)}>0%</div>

      <hr className={clsx(classes.hr, classes.hr100)} />
      <hr className={clsx(classes.hr, classes.hr80)} />
      <hr className={clsx(classes.hr, classes.hr60)} />
      <hr className={clsx(classes.hr, classes.hr40)} />
      <hr className={clsx(classes.hr, classes.hr20)} />
      <hr className={clsx(classes.hr, classes.hr0)} />
    </>
  );
};

export default Background;
