import React from "react";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthTextField } from "../../../../../../elements/FormField";

const Iccid = React.memo(() => (
  <FullWidthTextField
    name="iccid"
    variant="outlined"
    required
    label={<I18nText>devices.common.iccid</I18nText>}
    inputProps={{ maxLength: 32, autoComplete: "off" }}
  />
));

export default Iccid;
