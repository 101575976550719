import React from "react";
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CloseIcon from "@material-ui/icons/Close";

import { Trans } from "../../../i18n";
import {
  PrimaryButton,
  ButtonsWrapper,
  SecondaryButton
} from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";
import Row from "./Row";
import NoData from "./NoData";

const List = React.memo(({ refDateQueries, onDelete, onClose }) => {
  const history = useHistory();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box marginBottom={2} textAlign="right">
            <PrimaryButton
              onClick={() => history.push("/devices/ref-date-queries/new")}
              icon={<AddOutlinedIcon />}
            >
              <I18nText>refDateQueries.actions.new</I18nText>
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
      {refDateQueries.length === 0 ? (
        <NoData />
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.intervalType" />
                </TableCell>
                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.intervals" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.queryAt" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.devices" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.status" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.lastRunAt" />
                </TableCell>

                <TableCell>
                  <Trans i18nKey="refDateQueries.table.header.nextRunAt" />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refDateQueries.map(query => (
                <Row
                  query={query}
                  key={query.id}
                  onDelete={onDelete}
                  onSelect={() =>
                    history.push(`/devices/ref-date-queries/${query.id}`)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginTop={2}>
            <ButtonsWrapper
              rightButton={
                <SecondaryButton onClick={onClose} icon={<CloseIcon />}>
                  <I18nText>actions.close</I18nText>
                </SecondaryButton>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default List;
