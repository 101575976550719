import { Box } from "@material-ui/core";
import React, { useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import SendIcon from "@material-ui/icons/Send";
import locale from "react-json-editor-ajrm/locale/en";
import { PrimaryButton } from "../../../../../../elements/Button";
import { useDevice } from "../../../../../../domain/device";
import { MESSAGE_TYPE } from "../../../../../../domain/message";
import { useApi } from "../../../../../../api";
import I18nText from "../../../../../../elements/I18nText";
import colors from "../../../../../../themes/colors";
import useI18nSnackbar from "../../../../../../hooks/useI18nSnackbar";

const RawJson = React.memo(() => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const { device } = useDevice();
  const [fieldValues, setFieldValues] = useState(null);
  const [json, setJson] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleUpdate = jsonData => {
    setJson(jsonData.json);
    setFieldValues(jsonData);
  };

  const submit = () => {
    if (!json || fieldValues.error) {
      return;
    }

    const params = {
      type: MESSAGE_TYPE.LX_IOT,
      deviceId: device.id,
      content: json
    };

    setSubmitting(true);

    api
      .post("/v2/messages", params)
      .then(() => {
        enqueueSnackbar("messages.responses.released", {
          variant: "success"
        });

        setSubmitting(false);
      })
      .catch(e => {
        enqueueSnackbar("messages.responses.error", {
          variant: "error"
        });

        setSubmitting(false);
      });
  };

  return (
    <>
      <Box>
        <JSONInput
          id="raw_json"
          locale={locale}
          height="200px"
          onChange={handleUpdate}
          colors={{
            background: "#fff"
          }}
          style={{
            container: {
              width: "100%",
              height: 198
            },
            outerBox: {
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: 4
            },
            warningBox: {
              backgroundColor: colors.ERROR,
              color: "#fff",
              maxHeight: 40
            },
            errorMessage: {
              color: "#fff",
              maxHeight: 40
            }
          }}
        />
        <Box sx={{ display: "flex", pt: 2, justifyContent: "flex-end" }}>
          <PrimaryButton
            onClick={submit}
            disabled={
              isSubmitting ||
              fieldValues === null ||
              !json ||
              !!fieldValues.error
            }
            icon={<SendIcon />}
          >
            <I18nText>actions.send</I18nText>
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
});

export default RawJson;
