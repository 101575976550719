import React from "react";
import { Formik, Form } from "formik";
import { Box, Grid } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import I18nText from "../../../elements/I18nText";
import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../elements/Button";
import { FullWidthTextField } from "../../../elements/FormField";

const QueryForm = ({ values, validator, onSubmit, onClose }) => {
  return (
    <Formik
      initialValues={values}
      validate={validator}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <FullWidthTextField
                  variant="outlined"
                  size="small"
                  name="content"
                  autoComplete="off"
                  label={<I18nText>messages.common.content</I18nText>}
                  inputProps={{ maxLength: 160 }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <PrimaryButton
                    disabled={!values.content || isSubmitting}
                    icon={<SendIcon />}
                    onClick={submitForm}
                  >
                    <I18nText>actions.send</I18nText>
                  </PrimaryButton>
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default QueryForm;
