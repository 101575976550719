import { useContext } from "react";

import ResourcesContext from "../../context/ResourcesContext";

const useMobileProviders = () => {
  const { mobileProviders } = useContext(ResourcesContext);

  if (mobileProviders === undefined) {
    throw new Error(
      "useMobileProviders must be used within a ResourcesContext"
    );
  }

  return mobileProviders;
};

export default useMobileProviders;
