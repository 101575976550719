import React from "react";
import { TableCell, TableRow, useTheme, makeStyles } from "@material-ui/core";

import useFormat from "../../../../hooks/useFormat";
import { useTranslation } from "../../../../i18n";
import { SENSOR_STATUS } from "../../../../domain/sensor";
import TemperatureContent from "./TemperatureContent";

const useFirstCellStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: 32
  }
}));

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center"
  },
  colorBox: {
    width: 30,
    height: 30,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const ProbeRow = ({ sensor, showTemperatureColumn }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const firstCellClasses = useFirstCellStyles();
  const { formatNumber, formatUnit } = useFormat();

  const useCellStyles = makeStyles(theme => ({
    root: {
      color:
        sensor.status === SENSOR_STATUS.ALARM ||
        sensor.status === SENSOR_STATUS.ERROR
          ? theme.palette.error.main
          : theme.palette.text.main
    }
  }));

  const cellClasses = useCellStyles();

  const translateContentType = contentType => {
    const prefix = "sensors.contentTypes.";

    const translation = t(prefix + contentType);

    if (translation === prefix + contentType) {
      return contentType;
    }

    return translation;
  };

  return (
    <TableRow key={sensor.id}>
      <TableCell classes={firstCellClasses}>
        <div
          className={classes.colorBox}
          style={{
            backgroundColor: theme.custom.colors.sensors[sensor.sequence]
          }}
        >
          {sensor.sequence}
        </div>
      </TableCell>
      <TableCell classes={cellClasses}>{sensor.name}</TableCell>
      <TableCell classes={cellClasses}>
        {translateContentType(sensor.contentType)}
      </TableCell>
      <TableCell align="right" classes={cellClasses}>
        {formatNumber(sensor.capacity)} {formatUnit(sensor.unit)}
      </TableCell>

      {showTemperatureColumn && (
        <TableCell align="right" classes={cellClasses}>
          {sensor.status !== SENSOR_STATUS.ERROR && (
            <TemperatureContent sensor={sensor} />
          )}
        </TableCell>
      )}

      <TableCell align="right" classes={cellClasses}>
        {sensor.status !== SENSOR_STATUS.ERROR && (
          <>
            {formatNumber(sensor.netFreeSpace)} {formatUnit(sensor.unit)}
          </>
        )}
      </TableCell>
      <TableCell align="right" classes={cellClasses}>
        {sensor.status !== SENSOR_STATUS.ERROR && <>{sensor.netPct} %</>}
      </TableCell>
      <TableCell align="right" classes={cellClasses}>
        {sensor.status !== SENSOR_STATUS.ERROR && <>{sensor.pct} %</>}
      </TableCell>
      <TableCell align="right" classes={cellClasses}>
        {sensor.status !== SENSOR_STATUS.ERROR && (
          <>
            {formatNumber(sensor.value)} {formatUnit(sensor.unit)}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProbeRow;
