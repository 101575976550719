import React from "react";
import { Grid, Box } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { PrimaryButton } from "../../elements/Button";
import I18nText from "../../elements/I18nText";

const List = ({ onAddUser, FilterComponent, TreeComponent }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" padding={1}>
            <Box marginLeft={1}>
              <PrimaryButton onClick={onAddUser} icon={<PersonAddIcon />}>
                <I18nText>users.create.title</I18nText>
              </PrimaryButton>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" paddingX={1} paddingTop={3} paddingBottom={2}>
        {FilterComponent}
      </Box>

      {TreeComponent}
    </>
  );
};

export default List;
