import React from "react";
import { MenuItem } from "@material-ui/core";

import { FullWidthSelect } from "../../../../../../elements/FormField";
import I18nText from "../../../../../../elements/I18nText";

const Unit = ({ prefix, options }) => (
  <FullWidthSelect variant="outlined" name={`${prefix}.unit`}>
    <MenuItem value="">&nbsp;</MenuItem>
    {options.map(option => (
      <MenuItem key={option} value={option}>
        <I18nText>{`sensors.units.${option}`}</I18nText>
      </MenuItem>
    ))}
  </FullWidthSelect>
);

export default Unit;
