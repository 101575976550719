import React, { useState, useEffect } from "react";

import { useApi } from "../../../../api";
import I18nText from "../../../../elements/I18nText";
import Threshold from "./Threshold";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const ThresholdContainer = ({
  deviceId,
  thresholdSensor,
  confirmationStatus
}) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(confirmationStatus ? confirmationStatus : "revoked");
  }, [confirmationStatus, setStatus]);

  const updateStatus = newStatus => {
    switch (newStatus) {
      case "disregard":
        disregard();

        break;

      case "seen":
        seen();

        break;

      default:
        revoke();
    }
  };

  const disregard = () => {
    api
      .put(`/v2/devices/${deviceId}/threshold-confirmation`, {
        status: "disregard"
      })
      .then(() => {
        setStatus("disregard");
        enqueueSnackbar("devices.status.threshold.responses.disregard", {
          variant: "success"
        });
      });
  };

  const seen = () => {
    api
      .put(`/v2/devices/${deviceId}/threshold-confirmation`, { status: "seen" })
      .then(() => {
        setStatus("seen");
        enqueueSnackbar("devices.status.threshold.responses.seen", {
          variant: "success"
        });
      });
  };

  const revoke = () => {
    api.delete(`/v2/devices/${deviceId}/threshold-confirmation`).then(() => {
      setStatus("revoked");
      enqueueSnackbar("devices.status.threshold.responses.revoked", {
        variant: "success"
      });
    });
  };

  const getTitle = () => {
    if (thresholdSensor === "first" || thresholdSensor === "first_upper") {
      return <I18nText>devices.status.threshold.firstSensor</I18nText>;
    }

    return <I18nText>devices.status.threshold.allSensors</I18nText>;
  };

  return (
    <Threshold
      status={status}
      onUpdateStatus={updateStatus}
      title={getTitle()}
    />
  );
};

export default ThresholdContainer;
