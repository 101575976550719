import React, { useState } from "react";
import DebtorForm from "./DebtorForm";

import { useApi } from "../../../../../api";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const DebtorContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const [values, setValues] = useState({
    ident: device.debtor ? device.debtor.ident : ""
  });

  const handleSubmit = () => {
    setSubmitting(true);

    api
      .put(`/v2/devices/${device.id}/debtor`, { ...values })
      .then(() => {
        enqueueSnackbar("devices.settings.billing.responses.debtorUpdated", {
          variant: "success"
        });

        setSubmitting(false);
        onClose(true);
      })
      .catch(e => {
        setSubmitting(false);
        if (!e.response) {
          return;
        }
      });
  };

  const handleUpdateValues = values => {
    let newValues = {};
    if (values === null) {
      newValues = {
        ident: ""
      };
    } else {
      newValues = { ...values };
    }

    setValues(newValues);
  };

  return (
    <DebtorForm
      values={values}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onUpdateValues={handleUpdateValues}
    />
  );
};

export default DebtorContainer;
