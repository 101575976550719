import React, { useState } from "react";

import { useApi } from "../../../api";
import ConfirmationDialog from "../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../elements/Button";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useFormat from "../../../hooks/useFormat";
import { FORMAT_DATETIME } from "../../../utils/formatting";

const HideSensorData = ({ sensorData, onCancel, onHidden }) => {
  const api = useApi();
  const { t } = useTranslation();
  const { formatDate } = useFormat();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const deleteRecipient = () => {
    setDeleting(true);

    api.delete(`/v2/sensor-data/${sensorData.id}`).then(() => {
      enqueueSnackbar("devices.process.responses.sensorDataHidden", {
        variant: "success"
      });
      setDeleting(false);
      onHidden();
    });
  };

  const content = t("devices.process.hideSensorData.confirmation", {
    sensorName: sensorData.sensorName,
    measuredAt: formatDate(new Date(sensorData.measuredAt), FORMAT_DATETIME)
  });

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton disabled={isDeleting} onClick={deleteRecipient} />
      }
    >
      <Box dangerouslySetInnerHTML={{ __html: content }}></Box>
    </ConfirmationDialog>
  );
};

export default HideSensorData;
