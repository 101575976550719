import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import Login from "./Login";
import { useApi } from "../../api";
import { useAuth } from "../../auth";

const COOKIE_NAME = "ov_last_username";

const DEMO_USER_NAME = "Demo";
const DEMO_USER_PASSWORD = "Demo";

const LoginContainer = () => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const history = useHistory();
  const location = useLocation();

  const api = useApi();

  const [username, setUsername] = useState(cookies[COOKIE_NAME] ?? "");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(
    cookies[COOKIE_NAME] ? true : false
  );
  const [loginError, setLoginError] = useState(null);

  const [showImprint, setShowImprint] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const { setUser } = useAuth();

  const handleSubmitDemo = () => {
    login({ username: DEMO_USER_NAME, password: DEMO_USER_PASSWORD });
  };

  const handleSubmit = e => {
    e.preventDefault();

    login();
  };

  const login = value => {
    // Take credentials from state, override if args given (for demo login)
    const credentials = {
      username,
      password
    };

    if (value) {
      credentials.username = value.username;
      credentials.password = value.password;
    }
    api
      .post("/v2/login", { ...credentials })
      .then(response => {
        setUser(response.data.user);

        let expires = new Date();

        // One year from now
        expires.setFullYear(expires.getFullYear() + 1);

        setCookie(COOKIE_NAME, username, {
          path: "/",
          expires
        });

        if (location.state && location.state.redirectTo) {
          history.push(location.state.redirectTo);

          return;
        }

        history.push("/devices");
      })
      .catch(error => {
        if (!error.response) {
          setLoginError("unknownError");

          return;
        }

        if (error.response.status === 400) {
          setLoginError("invalidCredentials");
        } else {
          setLoginError("serverError");
        }
      });
  };

  return (
    <Login
      username={username}
      password={password}
      rememberMe={rememberMe}
      loginError={loginError}
      setUsername={setUsername}
      setPassword={setPassword}
      setRememberMe={setRememberMe}
      onSubmit={handleSubmit}
      onSubmitDemo={handleSubmitDemo}
      onToggleImprint={() => setShowImprint(flag => !flag)}
      onTogglePrivacyPolicy={() => setShowPrivacyPolicy(flag => !flag)}
      showImprint={showImprint}
      showPrivacyPolicy={showPrivacyPolicy}
    />
  );
};

export default LoginContainer;
