import React from "react";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { FastField, Field } from "formik";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const FullWidthSelect = ({
  variant = "outlined",
  fastField = true,
  children,
  ...props
}) => {
  const classes = useStyles();

  const FieldComponent = fastField ? FastField : Field;

  return (
    <FieldComponent
      component={TextField}
      select
      size="small"
      {...props}
      variant={variant}
      classes={classes}
    >
      {children}
    </FieldComponent>
  );
};

export default FullWidthSelect;
