import React from "react";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { i18n, useTranslation } from "../../i18n";

const useStyles = makeStyles({
  icon: props => ({
    fontSize: 16,
    transform: `translate(3px, ${props.paddingTop}px)`,
    "&:hover": {
      cursor: "help"
    }
  })
});

const Content = ({ title, content }) => {
  return (
    <Box padding={1}>
      {title && (
        <Box
          marginBottom={1}
          fontSize={14}
          lineHeight={1.5}
          fontWeight={600}
          dangerouslySetInnerHTML={{ __html: title }}
        ></Box>
      )}
      {content && (
        <Box
          fontSize={12}
          lineHeight={1.5}
          dangerouslySetInnerHTML={{ __html: content }}
        ></Box>
      )}
    </Box>
  );
};

const BubbleHelp = ({ id, placement = "top", paddingTop = 3 }) => {
  const { t } = useTranslation();
  const classes = useStyles({ paddingTop });

  const tTtitle = i18n.exists(`bubbleHelp.${id}.title`)
    ? t(`bubbleHelp.${id}.title`)
    : null;
  const tContent = i18n.exists(`bubbleHelp.${id}.content`)
    ? t(`bubbleHelp.${id}.content`)
    : null;

  return (
    <>
      <Tooltip
        title={<Content title={tTtitle} content={tContent}></Content>}
        placement={placement}
        arrow
      >
        <span>
          <InfoIcon color="secondary" className={classes.icon} />
        </span>
      </Tooltip>
    </>
  );
};

export default BubbleHelp;
