import { TextField, Box, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import SendIcon from "@material-ui/icons/Send";
import { PrimaryButton } from "../../../../../../elements/Button";
import { useDevice } from "../../../../../../domain/device";
import { MESSAGE_TYPE } from "../../../../../../domain/message";
import { useApi } from "../../../../../../api";
import I18nText from "../../../../../../elements/I18nText";
import useI18nSnackbar from "../../../../../../hooks/useI18nSnackbar";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const RawJson = React.memo(() => {
  const classes = useStyles();
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const { device } = useDevice();
  const [message, setMessage] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleUpdate = e => {
    setMessage(e.target.value);
  };

  const submit = () => {
    if (!message) {
      return;
    }

    const params = {
      type: MESSAGE_TYPE.IOT,
      deviceId: device.id,
      content: message
    };

    setSubmitting(true);

    api
      .post("/v2/messages", params)
      .then(() => {
        enqueueSnackbar("messages.responses.released", {
          variant: "success"
        });

        setSubmitting(false);
      })
      .catch(e => {
        enqueueSnackbar("messages.responses.error", {
          variant: "error"
        });

        setSubmitting(false);
      });
  };

  return (
    <>
      <Box>
        <TextField
          classes={classes}
          value={message}
          variant="outlined"
          multiline
          minRows={4}
          onChange={handleUpdate}
        />
        <Box sx={{ display: "flex", pt: 2, justifyContent: "flex-end" }}>
          <PrimaryButton
            onClick={submit}
            disabled={isSubmitting || !message}
            icon={<SendIcon />}
          >
            <I18nText>actions.send</I18nText>
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
});

export default RawJson;
