import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

import useDebounce from "../../hooks/useDebounce";

const SearchTermFilter = ({ onSetSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    onSetSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSetSearchTerm]);

  return (
    <>
      <TextField
        id="filter"
        label="Filter"
        type="search"
        variant="outlined"
        size="small"
        autoComplete="off"
        onChange={e => setSearchTerm(e.target.value)}
      />
    </>
  );
};

export default SearchTermFilter;
