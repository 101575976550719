import React from "react";
import { FastField } from "formik";
import { InputAdornment } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";

const FillLimit = ({ prefix }) => (
  <FastField
    component={NumberField}
    name={`${prefix}.fillLimit`}
    validate={numberValidator({ min: 0, max: 100 })}
    min={0}
    max={100}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>
    }}
  />
);

export default FillLimit;
