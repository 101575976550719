import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles({
  button: {
    color: red[500],
    "&:hover": {
      color: red[700]
    }
  }
});

const DeleteAction = ({ onClick, iconProps, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.button} {...props}>
      <DeleteIcon fontSize="small" {...iconProps} />
    </IconButton>
  );
};

export default DeleteAction;
