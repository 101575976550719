import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import List from "./List";
import RevokeContainer from "./RevokeContainer";
import Create from "./Create";

const NotificationsContainer = () => {
  const api = useApi();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [notifications, setNotifications] = useState([]);
  const [reload, setReload] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [confirmRevoke, setConfirmRevoke] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    setLoading(true);

    api
      .get("/v2/system-notifications")
      .then(response => {
        setNotifications(response.data);
        setLoading(false);
      })
      .catch(() => {})
      .then(() => setReload(false));
  }, [api, reload]);

  const handleConfirmRevoke = notification => {
    setConfirmRevoke(notification);
  };

  const handleCreate = () => history.push(`${url}/new`);

  const handleClose = () => {
    setReload(true);
    history.push(url);
  };

  return (
    <>
      <Switch>
        <Route path={`${url}/new`}>
          <Create onClose={handleClose} />
        </Route>
      </Switch>
      {confirmRevoke !== null && (
        <RevokeContainer
          notificationId={confirmRevoke.id}
          onCancel={() => setConfirmRevoke(null)}
          onRevoked={() => {
            setReload(true);
            setConfirmRevoke(null);
          }}
        />
      )}
      <List
        isLoading={isLoading}
        notifications={notifications}
        onCreate={handleCreate}
        onRevoke={handleConfirmRevoke}
      />
    </>
  );
};

export default NotificationsContainer;
