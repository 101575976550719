import React from "react";
import { DialogContent } from "@material-ui/core";
import FixedPositionDialog from "../../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../../elements/DialogTitleWithCloseIcon";
import CommentContainer from "./CommentContainer";
import I18nText from "../../../../../elements/I18nText";

const CommentDialog = ({ device, onClose }) => {
  return (
    <>
      <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="md">
        <DialogTitleWithCloseIcon onClose={onClose}>
          <I18nText>devices.settings.metadata.sections.comment</I18nText>
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <CommentContainer device={device} onClose={onClose} />
        </DialogContent>
      </FixedPositionDialog>
    </>
  );
};

export default CommentDialog;
