import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Box
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ScheduleIcon from "@material-ui/icons/Schedule";

import I18nText from "../../../../elements/I18nText";

import FormattedDate from "../../../../elements/FormattedDate";
import { FORMAT_DATE } from "../../../../utils/formatting";
import FormattedNumber from "../../../../elements/FormattedNumber";

const useStyles = makeStyles({
  cell: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  headerCell: {
    fontWeight: "bold"
  },
  rowHeader: {
    paddingTop: 40,
    fontWeight: "bold"
  }
});

const renderPrice = invoice => {
  if (invoice.totalPrice === null) {
    return "";
  }

  return (
    <>
      <FormattedNumber decimals={2}>{invoice.totalPrice}</FormattedNumber> €
    </>
  );
};

const YearSelect = ({ year, onChange }) => {
  const firstYear = 2016;
  const size = new Date().getFullYear() - firstYear + 1;

  const options = [...Array(size).keys()].map(i => i + firstYear).reverse();

  return (
    <FormControl style={{ width: 200 }}>
      <InputLabel id="year-selector">
        <I18nText>system.billing.selectYear</I18nText>
      </InputLabel>
      <Select
        labelId="year-selector"
        value={year}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const List = React.memo(
  ({ groupedInvoices, year, isLoading, onSelectYear, onSelect }) => {
    const theme = useTheme();
    const classes = useStyles();

    const TableHeader = () => {
      return (
        <>
          <TableRow>
            <TableCell className={classes.headerCell}>
              <I18nText>ID</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <I18nText>billing.common.debtor</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              <I18nText>billing.common.price</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.billedAt</I18nText>
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              <I18nText>billing.common.status</I18nText>
            </TableCell>
          </TableRow>
        </>
      );
    };

    const renderStatus = invoice => {
      if (invoice.status === "handled") {
        return (
          <CheckIcon
            fontSize="small"
            style={{ color: theme.palette.success.main }}
          />
        );
      }

      return (
        <ScheduleIcon
          fontSize="small"
          style={{ color: theme.palette.warning.main }}
        />
      );
    };

    return (
      <>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          paddingX={1}
          paddingTop={3}
          paddingBottom={2}
        >
          <YearSelect year={year} onChange={onSelectYear} />
        </Box>

        {isLoading && (
          <Box spacing={2} margin={2}>
            <LinearProgress color="secondary" />
          </Box>
        )}
        <Table size="small">
          <TableBody>
            {groupedInvoices.map(group => (
              <Fragment key={group.key}>
                <TableRow>
                  <TableCell className={classes.rowHeader} colSpan={6}>
                    <FormattedDate format={FORMAT_DATE}>
                      {group.key}
                    </FormattedDate>
                  </TableCell>
                </TableRow>
                <TableHeader />

                {group.items.map(invoice => (
                  <TableRow
                    hover
                    key={invoice.id}
                    onClick={() => onSelect(invoice)}
                  >
                    <TableCell className={classes.cell}>{invoice.id}</TableCell>
                    <TableCell className={classes.cell}>
                      {invoice.debtorIdent}
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      {renderPrice(invoice)}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      <FormattedDate format={FORMAT_DATE}>
                        {invoice.billedAt}
                      </FormattedDate>
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {renderStatus(invoice)}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default List;
