import React from "react";
import { FastField } from "formik";

import { NumberField } from "../../../../../../elements/FormField";

const Port = () => (
  <FastField
    component={NumberField}
    name="port"
    variant="outlined"
    label="devices.settings.configuration.port"
    min={0}
    max={65536}
  />
);

export default Port;
