import React, { useState, useEffect } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../../../api";
import { setFieldErrorsFromResponse } from "../../../../../utils/formErrors";
import { valueAndTimeUnitToSeconds } from "../../../../../utils/timeUtils";

import ContactForm from "./ContactForm";
import FormContainer from "../Form/FormContainer";
import useI18nSnackbar from "../../../../../hooks/useI18nSnackbar";

const CreateContainer = ({ device, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isLoading, setLoading] = useState(true);
  const [contact, setContact] = useState(null);
  const [step, setStep] = useState("contact");
  const [excludeList, setExcludeList] = useState([]);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/v2/devices/${device.id}/notification-recipients`)
      .then(response => {
        setExcludeList(response.data.map(recipient => recipient.contact.id));
        setLoading(false);
      });
  }, [api, device]);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = {
      contactId: contact.id,
      events: []
    };

    values.events.forEach(event => {
      if (event.channels.length === 0) {
        return;
      }

      event.notifyOnceIn = valueAndTimeUnitToSeconds(
        event.notifyOnceIn.value,
        event.notifyOnceIn.unit
      );

      params.events.push(event);
    });

    api
      .post(`/v2/devices/${device.id}/notification-recipients`, params)
      .then(() => {
        enqueueSnackbar("devices.settings.notifications.responses.created", {
          variant: "success"
        });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  const handleSelectContact = contact => {
    setContact(contact);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Dialog>
      {step === "contact" && (
        <ContactForm
          contact={contact}
          excludeList={excludeList}
          onContactSelected={handleSelectContact}
          onContactCreated={handleSelectContact}
          onClose={onClose}
          onSubmit={() => setStep("events")}
        />
      )}
      {step === "events" && (
        <FormContainer
          contact={contact}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </Dialog>
  );
};

export default CreateContainer;
