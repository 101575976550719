import React from "react";

import { FullWidthTextField } from "../../../../../../elements/FormField";

const Name = ({ prefix }) => (
  <FullWidthTextField
    name={`${prefix}.name`}
    variant="outlined"
    inputProps={{ maxLength: 40, autoComplete: "off" }}
  />
);

export default Name;
