import React from "react";
import { DialogContent } from "@material-ui/core";

import FixedPositionDialog from "../../../../elements/FixedPositionDialog";
import DialogTitleWithCloseIcon from "../../../../elements/DialogTitleWithCloseIcon";
import I18nText from "../../../../elements/I18nText";

const FormDialog = ({ children, onClose }) => {
  return (
    <FixedPositionDialog open={true} scroll="body">
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>users.account.sections.login</I18nText>
      </DialogTitleWithCloseIcon>
      <DialogContent>{children}</DialogContent>
    </FixedPositionDialog>
  );
};

export default FormDialog;
