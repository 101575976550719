import React from "react";
import { Grid } from "@material-ui/core";

import LionelGatewaySelect from "./LionelGatewaySelect";

const LionelDeviceForm = ({ smsGateways }) => {
  return (
    <Grid container spacing={2}>
      {smsGateways.length > 1 && (
        <>
          <Grid item xs={12}>
            <LionelGatewaySelect
              gateways={smsGateways}
              name="inboundGatewayId"
            />
          </Grid>
          <Grid item xs={12}>
            <LionelGatewaySelect
              gateways={smsGateways}
              name="outboundGatewayId"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LionelDeviceForm;
