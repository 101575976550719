import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles(theme => {
  let styles = {
    root: props => {
      let root = {
        color: theme.palette.common.white,
        backgroundColor: red[500],
        "&:hover": {
          backgroundColor: red[700]
        }
      };

      if (props.fullWidth) {
        root.width = "100%";
      }

      return root;
    }
  };

  return styles;
});

const AlertButton = ({
  onClick,
  disabled,
  children,
  icon,
  fullWidth,
  ...props
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <Button
      variant="contained"
      classes={classes}
      disabled={disabled}
      startIcon={icon}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export default AlertButton;
