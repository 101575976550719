import React, { memo } from "react";
import { Grid, Box, Avatar, Chip } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import I18nText from "../../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 4,
    marginRight: theme.spacing(1)
  }
}));

const Legend = memo(
  ({
    years,
    connectedSensors,
    excludedYears,
    excludedSensorIds,
    onToggleYear,
    onToggleSensor
  }) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flex="0 0 80px">
              <I18nText>devices.consumption.legend.years</I18nText>
            </Box>
            <Box flexGrow={1}>
              {years.map(year => (
                <Chip
                  classes={classes}
                  style={{
                    color:
                      excludedYears.indexOf(year) === -1
                        ? theme.palette.primary.main
                        : theme.palette.grey.A100
                  }}
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor:
                          excludedYears.indexOf(year) === -1
                            ? theme.custom.colors.years[year]
                            : theme.palette.grey.A100
                      }}
                    >
                      {""}
                    </Avatar>
                  }
                  size="small"
                  key={year}
                  label={year}
                  onClick={onToggleYear(year)}
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flex="0 0 80px">
              <I18nText>devices.consumption.legend.sensors</I18nText>
            </Box>
            <Box flexGrow={1}>
              {connectedSensors.map(sensor => (
                <Chip
                  classes={classes}
                  style={{
                    color:
                      excludedSensorIds.indexOf(sensor.id) === -1
                        ? theme.palette.primary.main
                        : theme.palette.grey.A100
                  }}
                  avatar={
                    <Avatar
                      style={{
                        color: theme.palette.common.white,
                        backgroundColor:
                          excludedSensorIds.indexOf(sensor.id) === -1
                            ? theme.custom.colors.sensors[sensor.sequence]
                            : theme.palette.grey.A100
                      }}
                    >
                      {sensor.sequence}
                    </Avatar>
                  }
                  size="small"
                  key={sensor.id}
                  label={sensor.name}
                  onClick={onToggleSensor(sensor.id)}
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
);

export default Legend;
