import React, { useState, useEffect, useMemo } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import { useApi } from "../../api";
import List from "./List";
import Filter from "./Filter";
import Tree from "./Tree";
import Create from "../Users/Create";
import { useAuth } from "../../auth";

const ListContainer = () => {
  const api = useApi();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [reload, setReload] = useState(true);
  const [filterTerm, setFilterTerm] = useState("");
  const [isLoading, setLoading] = useState(true);

  const handleSetFilterTerm = term => {
    if (term === filterTerm) {
      return;
    }

    setFilterTerm(term);
  };

  useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);

    api.get("/v2/users").then(response => {
      setUsers(response.data);

      setLoading(false);
    });
  }, [api, reload]);

  const handleSelectNode = node => {
    history.push(`/users/${node.id}`);
  };

  const hiddenIds = useMemo(() => {
    const entryIsFiltered = entry => {
      if (!filterTerm) {
        return false;
      }

      const iFilter = filterTerm.toLowerCase();

      if (entry.username.toLowerCase().includes(iFilter)) {
        return false;
      }

      return true;
    };

    let filtered = [...users];
    if (filterTerm) {
      filtered = users.filter(entry => !entryIsFiltered(entry));
    }

    return filtered.map(entry => entry.id);
  }, [users, filterTerm]);

  const handleUserCreated = id => {
    setReload(true);

    history.push(`/users/${id}`);
  };

  const nodeIsFiltered = node => {
    return !node.allIds.some(allIds => hiddenIds.indexOf(allIds) !== -1);
  };

  const treeData = useMemo(() => {
    const hashTable = {};

    const dataset = users.map(user => ({
      id: user.id,
      parentUserId: user.parentUserId,
      title: user.username,
      children: [],
      collapsed: true
    }));

    dataset.forEach(
      entry =>
        (hashTable[entry.id] = {
          ...entry,
          allIds: [entry.id]
        })
    );

    let dataTree = {};
    dataset.forEach(entry => {
      if (entry.parentUserId && hashTable[entry.parentUserId] !== undefined) {
        hashTable[entry.parentUserId].children.push(hashTable[entry.id]);

        let parentUserId = entry.parentUserId;
        while (parentUserId) {
          if (hashTable[parentUserId]) {
            hashTable[parentUserId].allIds.push(entry.id);

            parentUserId = hashTable[parentUserId].parentUserId;
          }
        }
      }

      if (entry.id === user.id) {
        dataTree = hashTable[entry.id];
      }
    });

    return dataTree.children;
  }, [users, user.id]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <List
        onAddUser={() => history.push(`${url}/new`)}
        FilterComponent={<Filter onSetFilterTerm={handleSetFilterTerm} />}
        TreeComponent={
          <Tree
            nodes={treeData}
            nodeIsFiltered={nodeIsFiltered}
            filter={filterTerm}
            onSelectNode={handleSelectNode}
          />
        }
      />
      <Switch>
        <Route path={`${url}/new`}>
          <Create
            onClose={() => history.push(url)}
            onCreated={handleUserCreated}
          />
        </Route>
      </Switch>
    </>
  );
};

export default ListContainer;
