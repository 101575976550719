import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ children, guard, redirectOnDeny, ...routeProps }) => {
  if (guard()) {
    return <Route {...routeProps}>{children}</Route>;
  }

  return <Redirect to={redirectOnDeny} />;
};

export default ProtectedRoute;
