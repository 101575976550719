import { useContext } from "react";

import DeviceIdsContext from "../../context/DeviceIdsContext";

const useDeviceIds = () => {
  const device = useContext(DeviceIdsContext);

  if (device === undefined) {
    throw new Error("useDeviceIds must be used within a DeviceIdsContext");
  }

  return device;
};

export default useDeviceIds;
