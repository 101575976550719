import { withStyles } from "@material-ui/core";

const GlobalCss = withStyles(theme => ({
  "@global": {
    // Striped tables
    /*
    ".MuiTableRow-root:nth-child(2n)": {
      backgroundColor: "rgba(0, 0, 0, 0.06)"
    },
    ".MuiTableRow-root.MuiTableRow-hover:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    },
    ".MuiTableCell-root": {
      borderBottom: "none"
    },
    */
    ".MuiTable-root": {
      backgroundColor: theme.palette.common.white
    },
    ".MuiDialog-paperWidthLg": {
      maxWidth: 1400
    },
    // Bigger floating labels
    ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      fontSize: "1rem"
    },
    ".MuiInputLabel-outlined.MuiInputLabel-shrink ~ * .MuiOutlinedInput-notchedOutline legend span": {
      fontSize: "0.75rem !important"
    },
    /*
    // Adapt shrink labels to new input height
    ".MuiFormControl-root .MuiInputLabel-outlined": {
      transform: "translate(6px, 6px) scale(1)" // Select Box
    },

    ".MuiTextField-root > .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)" // Text field
    },
    ".MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(6px, -6px) scale(0.75)",
      backgroundColor: "#fff",
      paddingLeft: 10,
      paddingRight: 10
    },
    ".MuiFormControl-root .MuiOutlinedInput-input": {
      paddingTop: 10,
      paddingBottom: 10
    },

    ".MuiFormControl-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C4C4C4",
      borderRadius: 0
    },
    */

    // Button text transform
    /*
    ".MuiButton-root.MuiButton-root": {
      textTransform: "none"
    },
    */

    // Autocomplete
    '.MuiInputBase-root.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: "0 0 0 9px"
    },

    // Number input fields needs more padding to the right
    ".MuiFormControl-root .MuiOutlinedInput-input[type=number]": {
      paddingRight: 6
    },
    ".MuiFormControl-root .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd": {
      marginLeft: 0
    }
  }
}))(() => null);

export default GlobalCss;
