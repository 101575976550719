import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
    marginBottom: 12
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  leftButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 6
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 14
    }
  }
}));

const ButtonsWrapper = ({ rightButton, leftButton }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.wrapper}>
      {leftButton && (
        <div className={`${classes.button} ${classes.leftButton}`}>
          {React.cloneElement(leftButton, { fullWidth })}
        </div>
      )}
      {rightButton && (
        <div className={classes.button}>
          {React.cloneElement(rightButton, { fullWidth })}
        </div>
      )}
    </div>
  );
};

export default ButtonsWrapper;
