import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { PrimaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";
import { ListActionsWrapper, DeleteAction } from "../../../elements/ListAction";
import FormattedDate from "../../../elements/FormattedDate";

const List = React.memo(({ notifications, onCreate, onRevoke }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
          <PrimaryButton onClick={onCreate} icon={<AddIcon />}>
            <I18nText>system.notifications.actions.new</I18nText>
          </PrimaryButton>
        </Grid>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <I18nText>ID</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>system.notifications.common.message</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>system.notifications.common.createdAt</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>system.notifications.common.severity</I18nText>
            </TableCell>
            <TableCell style={{ minWidth: 75 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map(notification => (
            <TableRow key={notification.id}>
              <TableCell>{notification.id}</TableCell>
              <TableCell>{notification.message}</TableCell>
              <TableCell>
                <FormattedDate>{notification.createdAt}</FormattedDate>
              </TableCell>
              <TableCell>{notification.severity}</TableCell>
              <TableCell align="right">
                <ListActionsWrapper>
                  <DeleteAction onClick={() => onRevoke(notification)} />
                </ListActionsWrapper>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
});

export default List;
