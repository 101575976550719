import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  MapContainer as LeafletContainer,
  TileLayer,
  Popup,
  Marker,
  useMap
} from "react-leaflet";
import L from "leaflet";
import { useApi } from "../../../api";
import FormattedDate from "../../../elements/FormattedDate";
import GreenIcon from "../../../assets/leaflet/marker-green.png";
import YellowIcon from "../../../assets/leaflet/marker-yellow.png";
import RedIcon from "../../../assets/leaflet/marker-red.png";
import { Box } from "@material-ui/core";
import Sensor from "../Sensor";
import { DEVICE_STATUS } from "../../../domain/device";
import { useLocation } from "react-router-dom";

const mapOptions = {
  center: [51.1657, 10.4515],
  zoom: 6,
  scrollWheelZoom: true,
  style: { minHeight: "85vh" }
};

const myIcon = device => {
  let icon = GreenIcon;

  if (device.thresholdStatus) {
    icon = YellowIcon;
  }

  if (device.status !== DEVICE_STATUS.OK) {
    icon = RedIcon;
  }

  return L.icon({
    iconUrl: icon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    shadowAnchor: [4, 62],
    popupAnchor: [0, -40]
  });
};

const DeviceMarker = ({ device, isHighlighted }) => {
  const leafletRef = useRef();
  useEffect(() => {
    if (!isHighlighted) {
      return;
    }

    leafletRef.current.openPopup();
  }, []);

  const navLink = `https://maps.google.de/maps/place/${device.location.lat},${device.location.lng}`;

  const connectedSensors = device.sensors.filter(sensor => sensor.isConnected);

  const sensorSettings = {
    showNames: false,
    showVisuals: true,
    showUnits: true,
    showPercentages: false,
    showTemperatures: true
  };

  return (
    <Marker
      icon={myIcon(device)}
      key={device.id}
      ref={leafletRef}
      position={[device.location.lat, device.location.lng]}
    >
      <Popup>
        <Box marginTop={1}>
          <b>
            <Link to={`/devices/${device.id}`}>#{device.id}</Link> {device.name}
          </b>
        </Box>
        <Box>
          Stand: <FormattedDate>{device.location.updatedAt}</FormattedDate>
        </Box>
        <Box display="flex" flexDirection="column">
          {connectedSensors.map(sensor => (
            <Box paddingTop={1} key={sensor.id} display="flex">
              <Box minWidth={30} flexGrow={0}>
                {sensor.sequence}:
              </Box>
              <Box flexGrow={1}>
                <Sensor sensor={sensor} sensorSettings={sensorSettings} />
              </Box>
            </Box>
          ))}
        </Box>

        <p>
          <a href={navLink} target="_blank" rel="noopener noreferrer">
            <OpenInNewIcon style={{ fontSize: 12 }} />
            In Google Maps öffnen
          </a>
        </p>
      </Popup>
    </Marker>
  );
};

const DeviceContainer = ({ devices, highlightedDevice }) => {
  const map = useMap();

  useEffect(() => {
    if (!highlightedDevice) {
      return;
    }

    map.setView(
      {
        lng: highlightedDevice.location.lng,
        lat: highlightedDevice.location.lat
      },
      12
    );
  }, [highlightedDevice]);

  return devices.map(device => (
    <DeviceMarker
      device={device}
      isHighlighted={device === highlightedDevice}
    />
  ));
};

const MapContainer = () => {
  const api = useApi();
  const [devices, setDevices] = useState([]);
  const [highlightedDevice, setHighlightedDevice] = useState(null);
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const highlightedId = parseInt(query.get("highlight"), 10);

  useEffect(() => {
    setLoading(true);
    api.get(`/v2/devices?location_enabled_only=true`).then(response => {
      setDevices(response.data);
      setLoading(false);

      if (highlightedId) {
        const hd = response.data.find(e => e.id === highlightedId);
        if (hd !== undefined) {
          setHighlightedDevice(hd);
        }
      }
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <LeafletContainer {...mapOptions}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <DeviceContainer
        devices={devices}
        highlightedDevice={highlightedDevice}
      />
    </LeafletContainer>
  );
};

export default MapContainer;
