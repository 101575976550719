import { EVENT_TYPE } from ".";
import CHANNEL from "./channel";

export default [
  {
    name: EVENT_TYPE.ALARM,
    channels: [CHANNEL.SMS, CHANNEL.MAIL]
  },
  {
    name: EVENT_TYPE.THRESHOLD,
    channels: [CHANNEL.SMS, CHANNEL.MAIL]
  },
  {
    name: EVENT_TYPE.FORWARD,
    channels: [CHANNEL.MAIL]
  },
  {
    name: EVENT_TYPE.SIM_CREDIT_LOW,
    channels: [CHANNEL.SMS, CHANNEL.MAIL]
  }
];
