import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",
  release: process.env.REACT_APP_SENTRY_RELEASE || "1.0"
};

if (sentryConfig.dsn) {
  Sentry.init(sentryConfig);

  Sentry.configureScope(scope => {
    scope.setTag("component", "webapp");
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
