import React from "react";
import { useField } from "formik";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";

import IOT_TYPE from "../../../../../../domain/device/iotType";

const IotType = () => {
  const [field, meta, helpers] = useField("iotType");

  const { value } = field;
  const { setValue } = helpers;

  const updateValue = (val, enabled) => {
    if (enabled) {
      setValue(val);
    } else {
      setValue(IOT_TYPE.STD);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  updateValue(IOT_TYPE.GPS, e.target.checked);
                }}
                checked={value === IOT_TYPE.GPS}
                color="primary"
              />
            }
            label="IoT-GPS"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  updateValue(IOT_TYPE.PRO, e.target.checked);
                }}
                checked={value === IOT_TYPE.PRO}
                color="primary"
              />
            }
            label="IoT-PRO"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IotType;
