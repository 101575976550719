import React from "react";
import { Box, Grid } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import AddIcon from "@material-ui/icons/Add";
import MapIcon from "@material-ui/icons/Map";

import { PrimaryButton } from "../../elements/Button";
import I18nText from "../../elements/I18nText";
import { ROLES, useAuth } from "../../auth";

const List = ({
  onShowExport,
  onShowConfig,
  onShowBlockQueries,
  onShowMap,
  onShowRefDateQueries,
  onShowCreate,
  paginationComponent,
  pageSelectComponent,
  showMap,
  tableComponent,
  searchComponent,
  count,
  totalCount
}) => {
  const { user, hasRole } = useAuth();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" padding={1}>
            {showMap && (
              <Box marginLeft={1}>
                <PrimaryButton onClick={onShowMap} icon={<MapIcon />}>
                  <I18nText>devices.list.actions.map</I18nText>
                </PrimaryButton>
              </Box>
            )}
            <Box marginLeft={1}>
              <PrimaryButton onClick={onShowConfig} icon={<TuneIcon />}>
                <I18nText>devices.list.actions.config</I18nText>
              </PrimaryButton>
            </Box>
            <Box marginLeft={1}>
              <PrimaryButton
                onClick={onShowExport}
                icon={<CloudDownloadOutlinedIcon />}
              >
                <I18nText>devices.list.actions.export</I18nText>
              </PrimaryButton>
            </Box>
            <Box marginLeft={1}>
              <PrimaryButton
                onClick={onShowBlockQueries}
                icon={<SendOutlinedIcon />}
              >
                <I18nText>devices.list.actions.blockQueries</I18nText>
              </PrimaryButton>
            </Box>
            {hasRole(user, ROLES.USER) && (
              <Box marginLeft={1}>
                <PrimaryButton
                  onClick={onShowRefDateQueries}
                  icon={<EventOutlinedIcon />}
                >
                  <I18nText>devices.list.actions.refDateQueries</I18nText>
                </PrimaryButton>
              </Box>
            )}
            {hasRole(user, ROLES.ADMIN) && (
              <Box marginLeft={1}>
                <PrimaryButton onClick={onShowCreate} icon={<AddIcon />}>
                  <I18nText>devices.list.actions.createDevice</I18nText>
                </PrimaryButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" paddingX={1} paddingTop={3} paddingBottom={2}>
        <Box display="flex" flex="1 0 auto" alignItems="flex-start">
          {searchComponent}
        </Box>
        <Box
          display="flex"
          flex="1 0 auto"
          alignItems="flex-end"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {paginationComponent}
            <div style={{ paddingTop: 6 }}>
              <I18nText values={{ count, totalCount }}>
                devices.list.showNDevicesOf
              </I18nText>
            </div>
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1 1 auto"
          alignItems="flex-start"
          justifyContent="flex-end"
        >
          {pageSelectComponent}
        </Box>
      </Box>

      {tableComponent}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        paddingX={1}
        paddingY={2}
      >
        {paginationComponent}
      </Box>
    </>
  );
};

export default List;
