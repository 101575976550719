import React, { useState } from "react";
import {
  List,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VerticalSplitOutlinedIcon from "@material-ui/icons/VerticalSplitOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";

import I18nText from "../../../elements/I18nText";
import QuickNav from "./QuickNav";
import { PERMISSIONS } from "../../../auth";

const useListStyles = makeStyles({
  padding: {
    paddingTop: 0
  }
});

const useListItemStyles = makeStyles(theme => ({
  root: {
    height: 60,
    "&$selected": {
      backgroundColor:
        theme.custom.deviceSideMenu.menuItem.backgroundColorSelected
    },
    "&$button:hover": {
      backgroundColor: theme.custom.deviceSideMenu.menuItem.backgroundColorHover
    }
  },
  selected: {},
  button: {}
}));

const useListItemQuickNavStyles = makeStyles(theme => ({
  root: {
    height: 60,
    backgroundColor: theme.custom.deviceSideMenu.quickNav.backgroundColor
  }
}));

const useListItemTextStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.text.main,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const useListItemIconStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 0
    }
  }
}));

const Menu = ({ device, subPage }) => {
  const [selectedItem, selectItem] = useState(subPage);
  const classes = {
    list: useListStyles(),
    listItem: useListItemStyles(),
    listItemQuickNav: useListItemQuickNavStyles(),
    listItemText: useListItemTextStyles(),
    listItemIcon: useListItemIconStyles()
  };

  const hasPermission = permission =>
    device.permissions.indexOf(permission) !== -1;

  return (
    <>
      <List classes={classes.list}>
        <ListItem
          button
          classes={classes.listItem}
          component={Link}
          to="/devices"
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <VerticalSplitOutlinedIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>devices.navigation.overview</I18nText>
          </ListItemText>
        </ListItem>

        <Hidden smDown>
          <ListItem classes={classes.listItemQuickNav}>
            <QuickNav device={device} subPage={subPage} />
          </ListItem>
        </Hidden>

        <ListItem
          button
          classes={classes.listItem}
          selected={selectedItem === "status"}
          onClick={() => selectItem("status")}
          component={Link}
          to={`/devices/${device.id}`}
        >
          <ListItemIcon classes={classes.listItemIcon}>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText classes={classes.listItemText}>
            <I18nText>devices.navigation.status</I18nText>
          </ListItemText>
        </ListItem>

        {hasPermission(PERMISSIONS.VIEW_PROCESS) && (
          <ListItem
            button
            classes={classes.listItem}
            selected={selectedItem === "process"}
            onClick={() => selectItem("process")}
            component={Link}
            to={`/devices/${device.id}/process`}
          >
            <ListItemIcon classes={classes.listItemIcon}>
              <TimelineOutlinedIcon />
            </ListItemIcon>
            <ListItemText classes={classes.listItemText}>
              <I18nText>devices.navigation.process</I18nText>
            </ListItemText>
          </ListItem>
        )}

        {hasPermission(PERMISSIONS.VIEW_CONSUMPTION) && (
          <ListItem
            button
            classes={classes.listItem}
            selected={selectedItem === "consumption"}
            onClick={() => selectItem("consumption")}
            component={Link}
            to={`/devices/${device.id}/consumption`}
          >
            <ListItemIcon classes={classes.listItemIcon}>
              <BarChartOutlinedIcon />
            </ListItemIcon>
            <ListItemText classes={classes.listItemText}>
              <I18nText>devices.navigation.consumption</I18nText>
            </ListItemText>
          </ListItem>
        )}

        {hasPermission(PERMISSIONS.VIEW_CONFIGURATION) && (
          <ListItem
            button
            classes={classes.listItem}
            selected={selectedItem === "settings"}
            onClick={() => selectItem("settings")}
            component={Link}
            to={`/devices/${device.id}/settings`}
          >
            <ListItemIcon classes={classes.listItemIcon}>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText classes={classes.listItemText}>
              <I18nText>devices.navigation.settings</I18nText>
            </ListItemText>
          </ListItem>
        )}

        {hasPermission(PERMISSIONS.VIEW_MESSAGING) && (
          <ListItem
            button
            classes={classes.listItem}
            selected={selectedItem === "messages"}
            onClick={() => selectItem("messages")}
            component={Link}
            to={`/devices/${device.id}/messages`}
          >
            <ListItemIcon classes={classes.listItemIcon}>
              <QuestionAnswerOutlinedIcon />
            </ListItemIcon>
            <ListItemText classes={classes.listItemText}>
              <I18nText>devices.navigation.messaging</I18nText>
            </ListItemText>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default Menu;
