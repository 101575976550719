import React from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { useDevice } from "../../../../../../domain/device";
import { PrimaryButton } from "../../../../../../elements/Button";
import Host from "./Host";
import Port from "./Port";
import I18nText from "../../../../../../elements/I18nText";

const NetDevice = () => {
  const { device } = useDevice();

  const showConfigButton =
    device.host !== undefined && device.port !== undefined;

  const openExternalConfiguration = () => {
    if (!showConfigButton) {
      return;
    }

    const link = `http://${device.host}:${device.port}`;

    window.open(link, "_blank");
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Host />
          </Grid>
          <Grid item xs={12}>
            <Port />
          </Grid>
          {showConfigButton && (
            <Grid item xs={12}>
              <PrimaryButton
                fullWidth={true}
                icon={<OpenInNewIcon />}
                onClick={openExternalConfiguration}
              >
                <I18nText>
                  devices.settings.configuration.externalNetLink
                </I18nText>
              </PrimaryButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NetDevice;
