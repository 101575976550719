import React from "react";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { PrimaryButton, SecondaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";
import {
  ListActionsWrapper,
  EditAction,
  DeleteAction
} from "../../../elements/ListAction";
import { useAuth, PERMISSIONS, ROLES } from "../../../auth";
import FormattedPermission from "./FormattedPermission";
import LoadingOverlay from "../../../elements/LoadingOverlay";

const useStyles = makeStyles(theme => ({
  borderedLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`
  }
}));

const List = React.memo(
  ({
    devices,
    isLoading,
    onEdit,
    onSync,
    onCreate,
    onDelete,
    paginationComponent
  }) => {
    const { user: me, hasPermission, hasRole } = useAuth();
    const classes = useStyles();

    return (
      <>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
            <Box display="flex" justifyContent="flex-end">
              {hasRole(me, ROLES.MASTER) && (
                <Box>
                  <SecondaryButton onClick={onSync} icon={<AddIcon />}>
                    <I18nText>users.access.sync.title</I18nText>
                  </SecondaryButton>
                </Box>
              )}
              <Box style={{ marginLeft: 20 }}>
                <PrimaryButton onClick={onCreate} icon={<AddIcon />}>
                  <I18nText>users.access.create.title</I18nText>
                </PrimaryButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          paddingX={1}
          paddingTop={3}
          paddingBottom={2}
        >
          {paginationComponent}
        </Box>

        <LoadingOverlay isLoading={isLoading} minHeight={200}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <I18nText>devices.common.id</I18nText>
                </TableCell>
                <TableCell colSpan={5}>
                  <I18nText>access.permissions.aggregated.read</I18nText>
                </TableCell>
                <TableCell colSpan={3}>
                  <I18nText>access.permissions.aggregated.write</I18nText>
                </TableCell>
                <TableCell colSpan={3}>
                  <I18nText>access.permissions.aggregated.execute</I18nText>
                </TableCell>
                <TableCell style={{ minWidth: 70 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map(device => (
                <TableRow key={device.id}>
                  <TableCell>{device.id}</TableCell>

                  <TableCell className={classes.borderedLeft}>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.VIEW_STATUS}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.VIEW_PROCESS}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.VIEW_CONSUMPTION}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.VIEW_CONFIGURATION}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.VIEW_MESSAGING}
                    />
                  </TableCell>

                  <TableCell className={classes.borderedLeft}>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.CONFIGURATION}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.EDIT}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.DELETE}
                    />
                  </TableCell>

                  <TableCell className={classes.borderedLeft}>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.QUERY}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.REF_DATE_QUERY}
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedPermission
                      permissions={device.userPermissions.permissions}
                      name={PERMISSIONS.EXPORT}
                    />
                  </TableCell>

                  <TableCell align="right" className={classes.borderedLeft}>
                    {hasPermission(device.permissions, PERMISSIONS.EDIT) && (
                      <ListActionsWrapper>
                        <EditAction onClick={() => onEdit(device)} />
                        <DeleteAction
                          onClick={() =>
                            onDelete({
                              deviceId: device.id,
                              aceId: device.userPermissions.aceId
                            })
                          }
                        />
                      </ListActionsWrapper>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </LoadingOverlay>

        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
          paddingX={1}
          paddingTop={3}
          paddingBottom={2}
        >
          {paginationComponent}
        </Box>
      </>
    );
  }
);

export default List;
