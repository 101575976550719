import React from "react";
import { TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cell: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 12
  }
}));

const TableFooterCell = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.cell} {...rest}>
      {children}
    </TableCell>
  );
};

export default TableFooterCell;
