import React from "react";
import CredentialsForm from "./CredentialsForm";

import { useApi } from "../../../api";
import {
  createFieldErrorMessage,
  setFieldErrorsFromResponse
} from "../../../utils/formErrors";
import { useAuth } from "../../../auth";
import FormDialog from "./FormDialog";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const CredentialsContainer = ({ onClose }) => {
  const { user } = useAuth();
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const validate = values => {
    const errors = {};
    if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = createFieldErrorMessage("password", "mismatch");
    }

    if (values.password.length < 8) {
      errors.password = createFieldErrorMessage("password", "lengthMin", {
        min: 8
      });
    }

    return errors;
  };

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const { username, ...params } = values;

    api
      .put("/v2/users/me/credentials", { ...params })
      .then(() => {
        enqueueSnackbar("settings.credentials.responses.updated", {
          variant: "success"
        });
        onClose();
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setFieldErrorsFromResponse(e.response, setFieldError);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const credentials = {
    username: user.username,
    currentPassword: "",
    password: "",
    passwordRepeat: ""
  };

  return (
    <FormDialog onClose={onClose}>
      <CredentialsForm
        credentials={credentials}
        onSubmit={handleSubmit}
        validator={validate}
        onClose={onClose}
      />
    </FormDialog>
  );
};

export default CredentialsContainer;
