import React from "react";

import ProtectedRoute from "./ProtectedRoute";

const PermissionProtectedRoute = ({
  requiredPermission,
  myPermissions,
  redirectOnDeny,
  ...rest
}) => {
  const guard = () => myPermissions.indexOf(requiredPermission) !== -1;

  return (
    <ProtectedRoute guard={guard} {...rest} redirectOnDeny={redirectOnDeny} />
  );
};

export default PermissionProtectedRoute;
