import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import InfoBar from "./InfoBar";
import ConfirmImpersonate from "./ConfirmImpersonate";
import { useApi } from "../../../api";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";
import Create from "../../Users/Create";
import { ROLES, useAuth } from "../../../auth";

const InfoBarContainer = ({ user }) => {
  const history = useHistory();
  const { hasRole } = useAuth();
  const { enqueueSnackbar } = useI18nSnackbar();
  const api = useApi();

  const [showImpersonation, setShowImpersonation] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const impersonateUser = () => {
    setSubmitting(true);

    api
      .get(`/v2/users/me?_impersonate_user=${user.username}`)
      .then(() => {
        setShowImpersonation(false);

        enqueueSnackbar(
          "users.responses.impersonating",
          {
            variant: "success"
          },
          { username: user.username }
        );

        window.location.href = "/";
      })
      .catch(() => {})
      .then(() => {
        setSubmitting(false);
        setShowImpersonation(false);
      });
  };

  const handleImpersonateUser = () => {
    setShowImpersonation(true);
  };

  const handleUserCreated = id => {
    setShowCreateDialog(false);

    history.push(`/users/${id}`);
  };

  return (
    <>
      {showImpersonation && (
        <ConfirmImpersonate
          isSubmitting={isSubmitting}
          username={user.username}
          onConfirm={impersonateUser}
          onCancel={() => setShowImpersonation(false)}
        />
      )}

      {showCreateDialog && (
        <Create
          parentUser={user}
          onClose={() => setShowCreateDialog(false)}
          onCreated={handleUserCreated}
        />
      )}

      <InfoBar
        onImpersonateUser={handleImpersonateUser}
        canGetSubUser={hasRole(user, ROLES.ADMIN)}
        onAddSubUser={() => setShowCreateDialog(true)}
        user={user}
      />
    </>
  );
};

export default InfoBarContainer;
