import React from "react";
import { Container, Paper } from "@material-ui/core";

import TopBar from "../TopBar";
import SystemNotifications from "../SystemNotifications";

const MAX_WIDTH = 1440;

const AppLayout = ({ children, selectedMenu }) => {
  return (
    <>
      <TopBar selectedMenu={selectedMenu} />
      <Container
        maxWidth={false}
        style={{
          maxWidth: MAX_WIDTH,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Paper square={true}>
          <SystemNotifications maxWidth={MAX_WIDTH} />
          {children}
        </Paper>
      </Container>
    </>
  );
};

export default AppLayout;
