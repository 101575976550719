import React from "react";
import SaveIcon from "@material-ui/icons/Save";

import PrimaryButton from "./PrimaryButton";
import I18nText from "../I18nText";

const SaveButton = ({ onClick, disabled, fullWidth }) => (
  <PrimaryButton
    disabled={disabled}
    icon={<SaveIcon />}
    onClick={onClick}
    fullWidth={fullWidth}
    type="button"
  >
    <I18nText>actions.save</I18nText>
  </PrimaryButton>
);

export default SaveButton;
