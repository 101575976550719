import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

import I18nText from "../../elements/I18nText";
import FormattedPhoneNumber from "../../elements/FormattedPhoneNumber";
import { PrimaryButton } from "../../elements/Button";
import {
  ListActionsWrapper,
  DeleteAction,
  EditAction
} from "../../elements/ListAction";

const List = React.memo(({ contacts, onEditContact, onDeleteContact }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <h1>
            <I18nText>settings.navigation.contacts</I18nText>
          </h1>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right", paddingTop: 16 }}>
          <PrimaryButton component={Link} to="/contacts/new" icon={<AddIcon />}>
            <I18nText>contacts.actions.new</I18nText>
          </PrimaryButton>
        </Grid>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <I18nText>contacts.common.firstName</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>contacts.common.lastName</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>contacts.common.role</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>contacts.common.cellNumber</I18nText>
            </TableCell>
            <TableCell>
              <I18nText>contacts.common.email</I18nText>
            </TableCell>
            <TableCell align="center">
              <I18nText>contacts.common.deviceCount</I18nText>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map(contact => (
            <TableRow key={contact.id}>
              <TableCell>{contact.firstName}</TableCell>
              <TableCell>{contact.lastName}</TableCell>
              <TableCell>{contact.role}</TableCell>
              <TableCell>
                <FormattedPhoneNumber fallback="">
                  {contact.cellNumber}
                </FormattedPhoneNumber>
              </TableCell>
              <TableCell>{contact.email}</TableCell>
              <TableCell align="center">{contact.deviceCount}</TableCell>
              <TableCell align="right">
                <ListActionsWrapper>
                  <EditAction onClick={() => onEditContact(contact)} />
                  <DeleteAction onClick={() => onDeleteContact(contact)} />
                </ListActionsWrapper>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
});

export default List;
