import React from "react";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import {
  TableRow,
  TableCell,
  Checkbox,
  MenuItem,
  InputAdornment,
  makeStyles
} from "@material-ui/core";

import { NumberField } from "../../../../../elements/FormField";
import { numberValidator } from "../../../../../utils/formValidators";
import { TIME_UNITS } from "../../../../../utils/timeUtils";
import I18nText from "../../../../../elements/I18nText";
import { CHANNEL } from "../../../../../domain/notificationEvent";

const useStyles = makeStyles({
  root: {
    paddingRight: 0
  },
  input: {
    textAlign: "right"
  }
});

const contactCanHandleChannel = (contact, channel) => {
  if (!contact) {
    return false;
  }

  if (channel === CHANNEL.SMS) {
    return !!contact.cellNumber;
  }

  if (channel === CHANNEL.MAIL) {
    return !!contact.email;
  }

  return false;
};

const ChannelCheckbox = ({ prefix, event, channelName, setFieldValue }) => {
  return (
    <Checkbox
      onChange={e => {
        const channels = [...event.channels];
        const idx = channels.indexOf(channelName);

        if (idx === -1 && e.target.checked) {
          channels.push(channelName);
        } else if (!e.target.checked && idx !== -1) {
          channels.splice(idx, 1);
        }

        setFieldValue(`${prefix}.channels`, channels);
      }}
      checked={event.channels.indexOf(channelName) !== -1}
      color="primary"
    />
  );
};

const EventForm = ({ prefix, contact, event, setFieldValue }) => {
  const unitOptions = [TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY];
  const classes = useStyles();

  return (
    <TableRow key={event.name}>
      <TableCell>
        <I18nText>{`devices.settings.notifications.events.${event.name}`}</I18nText>
      </TableCell>
      <TableCell align="center">
        {event.allowedChannels.indexOf(CHANNEL.SMS) !== -1 &&
          contactCanHandleChannel(contact, CHANNEL.SMS) && (
            <ChannelCheckbox
              event={event}
              channelName={CHANNEL.SMS}
              prefix={prefix}
              setFieldValue={setFieldValue}
            />
          )}
      </TableCell>
      <TableCell align="center">
        {event.allowedChannels.indexOf(CHANNEL.MAIL) !== -1 &&
          contactCanHandleChannel(contact, CHANNEL.MAIL) && (
            <ChannelCheckbox
              event={event}
              channelName={CHANNEL.MAIL}
              prefix={prefix}
              setFieldValue={setFieldValue}
            />
          )}
      </TableCell>
      <TableCell>
        <FastField
          component={NumberField}
          name={`${prefix}.notifyOnceIn.value`}
          validate={numberValidator({
            min: 0
          })}
          min={0}
          InputProps={{
            classes,
            endAdornment: (
              <InputAdornment position="end">
                <FastField
                  component={TextField}
                  select
                  size="small"
                  variant="outlined"
                  name={`${prefix}.notifyOnceIn.unit`}
                >
                  {unitOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      <I18nText>{`date.timeUnits.${option}`}</I18nText>
                    </MenuItem>
                  ))}
                </FastField>
              </InputAdornment>
            )
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default EventForm;
