import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import I18nText from "../../../../../../elements/I18nText";
import BubbleHelp from "../../../../../BubbleHelp";

const useStyles = makeStyles(theme => ({
  row: {
    paddingBottom: 6
  },
  column: {
    paddingLeft: 12,
    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center"
  },
  color: {
    width: 30
  },
  isConnected: {
    width: 75
  },
  subType: {
    width: 100
  },
  name: {
    width: "40%"
  },
  contentType: {
    width: "30%"
  },
  geometry: {
    width: "30%"
  },
  contentDensity: {
    width: 90
  },
  capacity: {
    width: 175
  },
  fillLimit: {
    width: 110
  },
  fillHeight: {
    width: 90
  },
  measuringRange: {
    width: 90
  },
  threshold: {
    width: 110
  },
  temperatureLimit: {
    width: 100
  }
}));

const LxIotHeader = () => {
  const classes = useStyles();

  return (
    <thead>
      <tr className={classes.row}>
        <th className={classes.color}></th>
        <th className={clsx(classes.isConnected, classes.column)}>
          <I18nText>sensors.isConnected</I18nText>
        </th>
        <th className={clsx(classes.subType, classes.column)}>
          <I18nText>sensors.subType</I18nText>
        </th>
        <th className={clsx(classes.name, classes.column)}>
          <I18nText>sensors.name</I18nText>{" "}
          <BubbleHelp id="devices.edit.sensorName" />
        </th>
        <th className={clsx(classes.contentType, classes.column)}>
          <I18nText>sensors.contentType</I18nText>{" "}
          <BubbleHelp id="devices.edit.contentType" />
        </th>
        <th className={clsx(classes.measuringRange, classes.column)}>
          <I18nText>sensors.measuringRange</I18nText>
        </th>
        <th className={clsx(classes.contentDensity, classes.column)}>
          <I18nText>sensors.contentDensity</I18nText>{" "}
          <BubbleHelp id="devices.edit.contentDensity" />
        </th>
        <th className={clsx(classes.geometry, classes.column)}>
          <I18nText>sensors.geometry</I18nText>{" "}
          <BubbleHelp id="devices.edit.geometry" />
        </th>
        <th className={clsx(classes.fillHeight, classes.column)}>
          <I18nText>sensors.fillHeightMm</I18nText>{" "}
          <BubbleHelp id="devices.edit.fillHeight" />
        </th>
        <th className={clsx(classes.capacity, classes.column)}>
          <I18nText>sensors.capacity</I18nText>{" "}
          <BubbleHelp id="devices.edit.capacity" />
        </th>
        <th className={clsx(classes.fillLimit, classes.column)}>
          <I18nText>sensors.fillLimit</I18nText>{" "}
          <BubbleHelp id="devices.edit.fillLimit" />
        </th>
        <th className={clsx(classes.threshold, classes.column)}>
          <I18nText>sensors.threshold</I18nText>{" "}
          <BubbleHelp id="devices.edit.threshold" />
        </th>
        <th className={clsx(classes.temperatureLimit, classes.column)}>
          <I18nText>sensors.temperatureLimitValue</I18nText>{" "}
          <BubbleHelp id="devices.edit.temperatureLimitValue" />
        </th>
      </tr>
    </thead>
  );
};

export default LxIotHeader;
