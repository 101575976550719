import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const SectionSubTitle = ({ children }) => {
  const useStyles = makeStyles(theme => ({
    title: {
      fontSize: 13,
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: 12
    }
  }));

  const classes = useStyles();

  return <Box className={classes.title}>{children}</Box>;
};

export default SectionSubTitle;
