import { makeStyles } from "@material-ui/core";
import React from "react";

import AppLayout from "../AppLayout";
import ListContainer from "./ListContainer";

const useStyles = makeStyles({
  content: {
    padding: 20,
    minWidth: 760
  }
});

const ContactsContainer = () => {
  const classes = useStyles();

  return (
    <AppLayout selectedMenu="contacts">
      <div className={classes.content}>
        <ListContainer />
      </div>
    </AppLayout>
  );
};

export default ContactsContainer;
