import React from "react";
import { Grid, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { PrimaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";
import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import DescriptionList from "../../../elements/DescriptionList";

const Profile = ({ user, onEditCredentials }) => {
  const options = [
    {
      label: "settings.profile.username",
      value: user.username
    },
    {
      label: "settings.profile.name",
      value: `${user.firstName} ${user.lastName}`
    }
  ];

  if (user.company) {
    options.push({
      label: "settings.profile.company",
      value: user.company
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
          <PrimaryButton onClick={onEditCredentials} icon={<EditIcon />}>
            <I18nText>settings.credentials.title</I18nText>
          </PrimaryButton>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section>
            <Box marginTop={2}>
              <SectionHeader>
                <I18nText>settings.profile.myData</I18nText>
              </SectionHeader>
            </Box>
            <DescriptionList options={options} />
          </Section>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
