import React from "react";

import PermissionsForm from "./PermissionsForm";
import { PERMISSIONS } from "../../../../../auth";

const FormContainer = ({
  userId,
  username,
  existingPermissions = [],
  onSubmit,
  onClose
}) => {
  const permissions = {
    [PERMISSIONS.VIEW_STATUS]:
      existingPermissions.indexOf(PERMISSIONS.VIEW_STATUS) !== -1,
    [PERMISSIONS.VIEW_CONSUMPTION]:
      existingPermissions.indexOf(PERMISSIONS.VIEW_CONSUMPTION) !== -1,
    [PERMISSIONS.VIEW_PROCESS]:
      existingPermissions.indexOf(PERMISSIONS.VIEW_PROCESS) !== -1,
    [PERMISSIONS.VIEW_MESSAGING]:
      existingPermissions.indexOf(PERMISSIONS.VIEW_MESSAGING) !== -1,
    [PERMISSIONS.VIEW_CONFIGURATION]:
      existingPermissions.indexOf(PERMISSIONS.VIEW_CONFIGURATION) !== -1,
    [PERMISSIONS.CONFIGURATION]:
      existingPermissions.indexOf(PERMISSIONS.CONFIGURATION) !== -1,
    [PERMISSIONS.EDIT]: existingPermissions.indexOf(PERMISSIONS.EDIT) !== -1,
    [PERMISSIONS.DELETE]:
      existingPermissions.indexOf(PERMISSIONS.DELETE) !== -1,
    [PERMISSIONS.QUERY]: existingPermissions.indexOf(PERMISSIONS.QUERY) !== -1,
    [PERMISSIONS.EXPORT]:
      existingPermissions.indexOf(PERMISSIONS.EXPORT) !== -1,
    [PERMISSIONS.REF_DATE_QUERY]:
      existingPermissions.indexOf(PERMISSIONS.REF_DATE_QUERY) !== -1
  };

  const values = {
    userId,
    permissions
  };

  return (
    <PermissionsForm
      values={values}
      username={username}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default FormContainer;
