import React, { memo } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  makeStyles
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import NoData from "./NoData";
import { PrimaryButton, SecondaryButton } from "../../../elements/Button";
import I18nText from "../../../elements/I18nText";

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const Consumption = memo(
  ({
    noData,
    isLoading,
    onExport,
    isExporting,
    LegendComponent,
    ChartComponent,
    TableComponent,
    exportConfig,
    updateExportConfig
  }) => {
    const classes = useStyles();

    if (isLoading) {
      return (
        <Box marginTop={2}>
          <LinearProgress color="secondary" />
        </Box>
      );
    }

    if (noData) {
      return (
        <>
          <NoData />
          {LegendComponent}
        </>
      );
    }

    return (
      <>
        {ChartComponent}

        {LegendComponent}
        <Box marginY={2}>{TableComponent}</Box>

        <Grid container spacing={2}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => {
                          updateExportConfig("customRange", e.target.checked);
                        }}
                        checked={exportConfig.customRange}
                        color="primary"
                      />
                    }
                    label={
                      <I18nText>
                        devices.consumption.export.customRange
                      </I18nText>
                    }
                  />
                </Box>
              </Grid>
              {exportConfig.customRange && (
                <>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      classes={classes}
                      size="small"
                      variant="dialog"
                      inputVariant="outlined"
                      ampm={false}
                      label={
                        <I18nText>devices.consumption.export.from</I18nText>
                      }
                      value={exportConfig.from}
                      onChange={value => updateExportConfig("from", value)}
                      disableFuture={true}
                      format="dd.MM.yyyy"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      classes={classes}
                      size="small"
                      variant="dialog"
                      inputVariant="outlined"
                      ampm={false}
                      label={<I18nText>devices.consumption.export.to</I18nText>}
                      value={exportConfig.to}
                      onChange={value => updateExportConfig("to", value)}
                      disableFuture={true}
                      format="dd.MM.yyyy"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <PrimaryButton
                    disabled={isExporting === "simple"}
                    onClick={() => onExport("simple")}
                    icon={<GetAppIcon />}
                  >
                    {isExporting === "simple" ? (
                      <I18nText>
                        devices.consumption.export.button.started
                      </I18nText>
                    ) : (
                      <I18nText>
                        devices.consumption.export.button.export
                      </I18nText>
                    )}
                  </PrimaryButton>
                </Box>

                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <SecondaryButton
                    disabled={isExporting === "extended"}
                    onClick={() => onExport("extended")}
                    icon={<GetAppIcon />}
                  >
                    {isExporting === "extended" ? (
                      <I18nText>
                        devices.consumption.export.button.started
                      </I18nText>
                    ) : (
                      <I18nText>
                        devices.consumption.export.buttonExtended.export
                      </I18nText>
                    )}
                  </SecondaryButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default Consumption;
