import React from "react";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthTextField } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const Iccid = React.memo(() => (
  <BubbleHelpFieldWrapper id="devices.edit.thingName">
    <FullWidthTextField
      name="thingName"
      variant="outlined"
      required
      label={<I18nText>devices.common.thingName</I18nText>}
      inputProps={{ maxLength: 128, autoComplete: "off" }}
    />
  </BubbleHelpFieldWrapper>
));

export default Iccid;
