import { useContext } from "react";

import ResourcesContext from "../../context/ResourcesContext";

const useTankGeometries = () => {
  const { tankGeometries } = useContext(ResourcesContext);

  if (tankGeometries === undefined) {
    throw new Error("useTankGeometries must be used within a ResourcesContext");
  }

  return tankGeometries;
};

export default useTankGeometries;
