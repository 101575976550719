import React from "react";
import UpdateIcon from "@material-ui/icons/Update";

import ConfirmationDialog from "../../../elements/ConfirmationDialog";
import I18nText from "../../../elements/I18nText";
import { PrimaryButton } from "../../../elements/Button";

const ConfirmQuery = ({ isSubmitting, onConfirm, onCancel }) => (
  <ConfirmationDialog
    onCancel={onCancel}
    isSubmitting={isSubmitting}
    ConfirmButton={
      <PrimaryButton
        disabled={isSubmitting}
        icon={<UpdateIcon />}
        onClick={onConfirm}
      >
        <I18nText>devices.infoBar.query.action</I18nText>
      </PrimaryButton>
    }
  >
    <I18nText>devices.infoBar.query.confirmText</I18nText>
  </ConfirmationDialog>
);

export default ConfirmQuery;
