import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import AppLayout from "../AppLayout";
import Layout from "./Layout";
import Notifications from "./Notifications";
import Billing from "./Billing";
import UnassignedDevices from "./UnassignedDevices";

const SystemContainer = () => {
  const { url } = useRouteMatch();

  return (
    <AppLayout selectedMenu="system">
      <Switch>
        <Route path={`${url}/notifications`}>
          <Layout subPage="notifications">
            <Notifications />
          </Layout>
        </Route>
        <Route path={`${url}/billing`}>
          <Layout subPage="billing">
            <Billing />
          </Layout>
        </Route>
        <Route path={`${url}/unassigned-devices`}>
          <Layout subPage="unassigned-devices">
            <UnassignedDevices />
          </Layout>
        </Route>
        <Route>
          <Redirect to={`${url}/notifications`} />
        </Route>
      </Switch>
    </AppLayout>
  );
};

export default SystemContainer;
