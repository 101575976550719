import React from "react";
import { FastField } from "formik";
import { InputAdornment } from "@material-ui/core";

import { NumberField } from "../../../../../../elements/FormField";
import { numberValidator } from "../../../../../../utils/formValidators";

const TemperatureLimit = ({ temperatureLimitValue, prefix }) => {
  if (temperatureLimitValue === undefined) {
    return null;
  }

  return (
    <FastField
      component={NumberField}
      name={`${prefix}.temperatureLimitValue`}
      validate={numberValidator({ min: -273, max: 9999 })}
      min={-273}
      max={9999}
      InputProps={{
        endAdornment: <InputAdornment position="end">°C</InputAdornment>
      }}
    />
  );
};

export default TemperatureLimit;
