import React, { useState, useEffect } from "react";

import { useApi } from "../../../api";
import { setFieldErrorsFromResponse } from "../../../utils/formErrors";
import FormDialog from "./FormDialog";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const EditContainer = ({ contactId, onClose }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();

  const [values, setValues] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/v2/contacts/${contactId}`).then(response => {
      const contact = response.data;

      setValues({
        firstName: contact.firstName,
        lastName: contact.lastName,
        role: contact.role,
        cellNumber: contact.cellNumber,
        email: contact.email,
        language: contact.language,
        comment: contact.comment
      });

      setLoading(false);
    });
  }, [api, contactId]);

  if (isLoading) {
    return null;
  }

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const params = { ...values };

    api
      .put(`/v2/contacts/${contactId}`, params)
      .then(() => {
        enqueueSnackbar("contacts.responses.updated", { variant: "success" });

        onClose(true);
      })
      .catch(e => {
        if (!e.response) {
          return;
        }

        setSubmitting(false);
        setFieldErrorsFromResponse(e.response, setFieldError);
      });
  };

  return (
    <FormDialog values={values} onClose={onClose} onSubmit={handleSubmit} />
  );
};

export default EditContainer;
