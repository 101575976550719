import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { PrimaryButton } from "../../../../elements/Button";
import I18nText from "../../../../elements/I18nText";
import {
  ListActionsWrapper,
  EditAction,
  DeleteAction
} from "../../../../elements/ListAction";
import { useAuth, PERMISSIONS } from "../../../../auth";
import FormattedPermission from "./FormattedPermission";

const useStyles = makeStyles(theme => ({
  borderedLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`
  }
}));

const List = React.memo(
  ({ aces, onEditAce, onCreateAce, onDeleteAce, allowedToGrantDelete }) => {
    const { user } = useAuth();
    const classes = useStyles();

    return (
      <>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right", marginTop: 16 }}>
            <PrimaryButton onClick={onCreateAce} icon={<AddIcon />}>
              <I18nText>devices.settings.access.actions.new</I18nText>
            </PrimaryButton>
          </Grid>
        </Grid>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <I18nText>access.user</I18nText>
              </TableCell>
              <TableCell colSpan={5}>
                <I18nText>access.permissions.aggregated.read</I18nText>
              </TableCell>
              <TableCell colSpan={3}>
                <I18nText>access.permissions.aggregated.write</I18nText>
              </TableCell>
              <TableCell colSpan={3}>
                <I18nText>access.permissions.aggregated.execute</I18nText>
              </TableCell>
              <TableCell style={{ minWidth: 70 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aces.map(ace => (
              <TableRow key={ace.id}>
                <TableCell>{ace.username}</TableCell>

                <TableCell className={classes.borderedLeft}>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.VIEW_STATUS}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.VIEW_PROCESS}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.VIEW_CONSUMPTION}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.VIEW_CONFIGURATION}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.VIEW_MESSAGING}
                  />
                </TableCell>

                <TableCell className={classes.borderedLeft}>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.CONFIGURATION}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.EDIT}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.DELETE}
                  />
                </TableCell>

                <TableCell className={classes.borderedLeft}>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.QUERY}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.REF_DATE_QUERY}
                  />
                </TableCell>
                <TableCell>
                  <FormattedPermission
                    permissions={ace.permissions}
                    name={PERMISSIONS.EXPORT}
                  />
                </TableCell>

                <TableCell align="right" className={classes.borderedLeft}>
                  {user.id !== ace.userId && (
                    <ListActionsWrapper>
                      <EditAction onClick={() => onEditAce(ace)} />
                      <DeleteAction onClick={() => onDeleteAce(ace)} />
                    </ListActionsWrapper>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default List;
