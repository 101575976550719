import React from "react";
import { Grid } from "@material-ui/core";

import { useAuth, ROLES } from "../../../../../../auth";
import ThingName from "./ThingName";
import MessageBox from "./MessageBox";
import FtpUpdateEnabled from "./FtpUpdateEnabled";
import IotType from "./IotType";

const IotDevice = () => {
  const { user, hasRole } = useAuth();

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        {hasRole(user, ROLES.MASTER) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ThingName />
            </Grid>
            <Grid item xs={12}>
              <MessageBox />
            </Grid>
            <Grid item xs={12}>
              <IotType />
            </Grid>
            <Grid item xs={12}>
              <FtpUpdateEnabled />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default IotDevice;
