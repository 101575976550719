import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useApi } from "../../../../api";
import Alarm from "./Alarm";
import useI18nSnackbar from "../../../../hooks/useI18nSnackbar";

const AlarmContainer = ({ alarm }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const { t } = useTranslation();

  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(alarm.confirmationStatus ? alarm.confirmationStatus : "revoked");
  }, [alarm.confirmationStatus, setStatus]);

  const updateStatus = newStatus => {
    switch (newStatus) {
      case "disregard":
        disregard();

        break;

      case "seen":
        seen();

        break;

      default:
        revoke();
    }
  };

  const disregard = () => {
    api
      .put(`/v2/alarms/${alarm.id}/confirmation`, { status: "disregard" })
      .then(() => {
        setStatus("disregard");
        enqueueSnackbar("devices.status.alarms.responses.disregard", {
          variant: "success"
        });
      });
  };

  const seen = () => {
    api
      .put(`/v2/alarms/${alarm.id}/confirmation`, { status: "seen" })
      .then(() => {
        setStatus("seen");
        enqueueSnackbar("devices.status.alarms.responses.seen", {
          variant: "success"
        });
      });
  };

  const revoke = () => {
    api.delete(`/v2/alarms/${alarm.id}/confirmation`).then(() => {
      setStatus("revoked");
      enqueueSnackbar("devices.status.alarms.responses.revoked", {
        variant: "success"
      });
    });
  };

  const getAlarmTitle = () => {
    let title = "";
    if (alarm.sequence) {
      title = `${t(`alarms.scopes.${alarm.scope}`)} ${alarm.sequence}`;
    } else {
      title = t("alarms.title");
    }

    return `${title}: ${alarm.value}`;
  };

  return (
    <Alarm
      status={status}
      onUpdateStatus={updateStatus}
      title={getAlarmTitle()}
    />
  );
};

export default AlarmContainer;
