import React from "react";
import { TableRow, TableCell, makeStyles, useTheme } from "@material-ui/core";
import I18nText from "../../../../elements/I18nText";
import FormattedNumber from "../../../../elements/FormattedNumber";

const useCellStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: 32
  }
}));

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.common.white,
    justifyContent: "center",
    alignItems: "center"
  },
  colorBox: {
    width: 30,
    height: 30,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const ProbeRow = ({ sensor, showTemperatureColumn }) => {
  const classes = useStyles();
  const cellClasses = useCellStyles();
  const theme = useTheme();

  const translateContentType = contentType => {
    if (!contentType) {
      return "";
    }

    return <I18nText>{`sensors.contentTypes.${contentType}`}</I18nText>;
  };

  return (
    <TableRow>
      <TableCell classes={cellClasses}>
        <div
          className={classes.colorBox}
          style={{
            backgroundColor: theme.custom.colors.sensors[sensor.sequence]
          }}
        >
          {sensor.sequence}
        </div>
      </TableCell>

      <TableCell>
        <I18nText>{`sensors.subTypes.${sensor.subType}`}</I18nText>
      </TableCell>

      <TableCell>{sensor.name}</TableCell>

      <TableCell>{translateContentType(sensor.contentType)}</TableCell>

      <TableCell>
        <FormattedNumber
          unit={
            sensor.unit ? (
              <I18nText>{`sensors.units.${sensor.unit}`}</I18nText>
            ) : null
          }
        >
          {sensor.capacity}
        </FormattedNumber>
      </TableCell>

      <TableCell>
        <FormattedNumber unit="%">{sensor.fillLimit}</FormattedNumber>
      </TableCell>

      <TableCell>
        <FormattedNumber unit="%">{sensor.threshold}</FormattedNumber>
      </TableCell>

      {showTemperatureColumn && (
        <TableCell>
          {sensor.temperatureLimitType !== "none" && (
            <FormattedNumber
              unit={
                sensor.temperatureUnit ? (
                  <I18nText>{`sensors.units.${sensor.temperatureUnit}`}</I18nText>
                ) : null
              }
            >
              {sensor.temperatureLimitValue}
            </FormattedNumber>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProbeRow;
