import React from "react";
import { makeStyles } from "@material-ui/core";

import Menu from "./Menu";
import InfoBar from "./InfoBar";

const Layout = ({ children, user, subPage }) => {
  const classes = makeStyles(theme => ({
    container: {
      display: "flex"
    },
    sideMenuWrapper: {
      flex: "0 0 210px",
      backgroundColor: theme.palette.common.white,
      paddingTop: 2,
      minHeight: "calc(100vh - 48px)",

      [theme.breakpoints.down("sm")]: {
        flex: "0 0 54px"
      }
    },
    sideMenu: {
      backgroundColor: "#d3d3d3",
      height: "100%"
    },
    contentWrapper: {
      flex: 1,
      minWidth: 800
    },
    content: {
      flexGrow: 1,
      padding: 20
    }
  }))();

  return (
    <div className={classes.container}>
      <div className={classes.sideMenuWrapper}>
        <div className={classes.sideMenu}>
          <Menu user={user} subPage={subPage} />
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <InfoBar user={user} />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
