import React from "react";

import I18nText from "../../../../../elements/I18nText";

import { Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import {
  ButtonsWrapper,
  CancelButton,
  PrimaryButton
} from "../../../../../elements/Button";
import { UserSelect } from "../../../../../elements/FormField";

const UserForm = ({
  user,
  excludeList = [],
  onUserSelected,
  onClose,
  onSubmit
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserSelect
            name="user"
            excludeList={excludeList}
            value={user}
            required
            onUpdate={value => onUserSelected(value)}
            label={
              <I18nText>devices.settings.access.create.selectUser</I18nText>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonsWrapper
            leftButton={<CancelButton onClick={onClose} />}
            rightButton={
              <PrimaryButton
                disabled={!user}
                icon={<ArrowForwardIosIcon />}
                onClick={onSubmit}
              >
                <I18nText>actions.continue</I18nText>
              </PrimaryButton>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
