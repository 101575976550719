import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";

import { useApi } from "../../api";

const severityMapping = {
  info: "info",
  minor: "info",
  significant: "warning",
  critical: "error"
};

const SystemNotifications = () => {
  const api = useApi();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    api.get("/v2/system-notifications").then(response => {
      setNotifications(response.data);
    });
  }, [api]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Box padding={1}>
      {notifications.map(notification => {
        return (
          <Box style={{ paddingTop: 4 }} key={notification.id}>
            <Alert severity={severityMapping[notification.severity]}>
              {notification.message}
            </Alert>
          </Box>
        );
      })}
    </Box>
  );
};

export default SystemNotifications;
