import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useApi } from "../../../api";
import FormContainer from "./FormContainer";
import useI18nSnackbar from "../../../hooks/useI18nSnackbar";

const EditContainer = ({ refDateQueryId, onClose }) => {
  const api = useApi();
  const history = useHistory();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    isActive: true,
    intervalType: "monthly",
    monthlyInterval: "monthly_first",
    dailyIntervals: [],
    intradayInterval: "intraday_12",
    queryAt: new Date(Math.round(new Date().getTime() / 300000) * 300000), // rounded to nearest 5 mins
    olderThan: 1800
  });

  useEffect(() => {
    setLoading(true);
    api.get(`/v2/ref-date-queries/${refDateQueryId}`).then(response => {
      const refDateQuery = response.data;

      const hoursAndMinutes = refDateQuery.queryAt.split(":");
      const queryAt = new Date();
      queryAt.setHours(hoursAndMinutes[0]);
      queryAt.setMinutes(hoursAndMinutes[1]);
      queryAt.setSeconds(0);

      const newState = {
        id: refDateQuery.id,
        isActive: refDateQuery.isActive,
        intervalType: refDateQuery.intervalType,
        olderThan: refDateQuery.olderThan,
        queryAt,
        monthlyInterval: "",
        dailyIntervals: [],
        intradayInterval: ""
      };

      switch (refDateQuery.intervalType) {
        case "monthly":
          newState.monthlyInterval =
            refDateQuery.intervals.length > 0
              ? refDateQuery.intervals[0]
              : "monthly_first";

          break;

        case "daily":
          newState.dailyIntervals = refDateQuery.intervals;

          break;

        case "intraday_":
          newState.intradayInterval =
            refDateQuery.intervals.length > 0
              ? refDateQuery.intervals[0]
              : "intraday_12";

          break;
        default:
      }

      setInitialValues(newState);

      setSelectedDeviceIds(refDateQuery.deviceIds);

      setLoading(false);
    });
  }, [api, refDateQueryId]);

  const handleSubmit = values => {
    setSubmitting(true);

    api
      .put(`/v2/ref-date-queries/${values.id}`, values)
      .then(() => {
        enqueueSnackbar("refDateQueries.responses.updated", {
          variant: "success"
        });

        setSubmitting(false);
        history.push({ pathname: "/devices/ref-date-queries", reload: true });
      })
      .catch(() => setSubmitting(false));
  };

  if (isLoading) {
    return null;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit}
      onClose={onClose}
      preSelectedDeviceIds={selectedDeviceIds}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
    />
  );
};

export default EditContainer;
