import React from "react";
import { parsePhoneNumber } from "libphonenumber-js/min";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  span: {
    whiteSpace: "nowrap"
  }
});

const FormattedPhoneNumber = ({ children, fallback = "-" }) => {
  const classes = useStyles();

  if (!children) {
    return fallback;
  }

  let value = children;

  try {
    const number = parsePhoneNumber(children);

    if (number) {
      value = number.formatInternational().replace(/ /g, "-");
    }
  } catch (error) {}

  return <span className={classes.span}>{value}</span>;
};

export default FormattedPhoneNumber;
