import React, { useState } from "react";

import { useApi } from "../../api";
import { DeleteButton } from "../../elements/Button";
import ConfirmationDialog from "../../elements/ConfirmationDialog";
import useI18nSnackbar from "../../hooks/useI18nSnackbar";
import { useTranslation } from "../../i18n";

const DeleteContainer = ({ contactId, deviceCount, onCancel, onDeleted }) => {
  const api = useApi();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const deleteContact = () => {
    setDeleting(true);
    api.delete(`/v2/contacts/${contactId}`).then(() => {
      setDeleting(false);
      onDeleted();
    });

    enqueueSnackbar("contacts.responses.deleted", {
      variant: "success"
    });
  };

  const content = deviceCount
    ? t("contacts.delete.confirmationWithDevices", { deviceCount })
    : t("contacts.delete.confirmation");

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton disabled={isDeleting} onClick={deleteContact} />
      }
    >
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </ConfirmationDialog>
  );
};

export default DeleteContainer;
