import { useLocale } from "../i18n";
import {
  formatUnit,
  formatNumber,
  formatDate,
  formatFlag
} from "../utils/formatting";

const useFormat = () => {
  const { locale } = useLocale();

  return {
    formatUnit: formatUnit(locale),
    formatNumber: formatNumber(locale),
    formatDate: formatDate(locale),
    formatFlag
  };
};

export default useFormat;
