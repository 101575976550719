import React from "react";
import { DialogContent } from "@material-ui/core";

import DialogTitleWithCloseIcon from "../../elements/DialogTitleWithCloseIcon";
import FixedPositionDialog from "../../elements/FixedPositionDialog";
import { Trans } from "../../i18n";

const Dialog = ({ children, onClose }) => {
  return (
    <FixedPositionDialog
      transitionDuration={0}
      open={true}
      scroll="body"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitleWithCloseIcon onClose={onClose}>
        <Trans i18nKey="refDateQueries.title" />
      </DialogTitleWithCloseIcon>
      <DialogContent>{children}</DialogContent>
    </FixedPositionDialog>
  );
};

export default Dialog;
