import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Input,
  Checkbox,
  ListItemText,
  Switch,
  MenuItem,
  Grid,
  makeStyles
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import I18nText from "../../../elements/I18nText";

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    maxWidth: "100%"
  }
});

const DailyIntervals = ({ value, error, setValue, dailyIntervalOptions }) => {
  const classes = useStyles();

  return (
    <FormControl classes={classes} error={!!error} variant="outlined">
      <InputLabel id="daily-interval-label">
        <I18nText>refDateQueries.dailyIntervalLabel</I18nText>
      </InputLabel>
      <Select
        style={{ minWidth: 300 }}
        labelId="daily-interval-label"
        multiple
        value={value}
        onChange={e => setValue("dailyIntervals", e.target.value)}
        input={<Input />}
        renderValue={selected => {
          return selected
            .map(
              value => dailyIntervalOptions.find(o => o.value === value).name
            )
            .join(", ");
        }}
      >
        {dailyIntervalOptions.map(interval => (
          <MenuItem key={interval.value} value={interval.value}>
            <Checkbox checked={value.indexOf(interval.value) !== -1} />
            <ListItemText primary={interval.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MetadataForm = ({ values, errors, setValue, dailyIntervalOptions }) => {
  const classes = useStyles();

  const MonthlyIntervals = () => (
    <FormControl classes={classes} error={!!errors.monthlyInterval}>
      <InputLabel id="monthly-interval-label">
        <I18nText>refDateQueries.monthlyIntervalLabel</I18nText>
      </InputLabel>
      <Select
        labelId="monthly-interval-label"
        value={values.monthlyInterval}
        onChange={e => setValue("monthlyInterval", e.target.value)}
      >
        <MenuItem value="monthly_first">
          <I18nText>refDateQueries.intervals.monthly_first</I18nText>
        </MenuItem>
        <MenuItem value="monthly_mid">
          <I18nText>refDateQueries.intervals.monthly_mid</I18nText>
        </MenuItem>
        <MenuItem value="monthly_last">
          <I18nText>refDateQueries.intervals.monthly_last</I18nText>
        </MenuItem>
      </Select>
    </FormControl>
  );

  const IntradayIntervals = () => (
    <FormControl classes={classes} error={!!errors.intradayInterval}>
      <InputLabel id="intraday-interval-label">
        <I18nText>refDateQueries.interval</I18nText>
      </InputLabel>
      <Select
        labelId="intraday-interval-label"
        value={values.intradayInterval}
        onChange={e => setValue("intradayInterval", e.target.value)}
      >
        <MenuItem value="intraday_1">
          <I18nText>refDateQueries.intervals.intraday_1</I18nText>
        </MenuItem>
        <MenuItem value="intraday_3">
          <I18nText>refDateQueries.intervals.intraday_3</I18nText>
        </MenuItem>
        <MenuItem value="intraday_6">
          <I18nText>refDateQueries.intervals.intraday_6</I18nText>
        </MenuItem>
        <MenuItem value="intraday_12">
          <I18nText>refDateQueries.intervals.intraday_12</I18nText>
        </MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={values.isActive}
              onChange={e => setValue("isActive", e.target.checked)}
              value="isActive"
              color="primary"
            />
          }
          label={<I18nText>refDateQueries.isActive</I18nText>}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl classes={classes}>
              <InputLabel id="interval-type-label">
                <I18nText>refDateQueries.intervalType</I18nText>
              </InputLabel>
              <Select
                labelId="interval-type-label"
                value={values.intervalType}
                onChange={e => setValue("intervalType", e.target.value)}
              >
                <MenuItem value="monthly">
                  <I18nText>refDateQueries.intervalTypes.monthly</I18nText>
                </MenuItem>
                <MenuItem value="daily">
                  <I18nText>refDateQueries.intervalTypes.daily</I18nText>
                </MenuItem>
                <MenuItem value="intraday">
                  <I18nText>refDateQueries.intervalTypes.intraday</I18nText>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {values.intervalType === "monthly" && <MonthlyIntervals />}
            {values.intervalType === "daily" && (
              <DailyIntervals
                value={values.dailyIntervals}
                setValue={setValue}
                dailyIntervalOptions={dailyIntervalOptions}
                error={errors.dailyIntervals}
              />
            )}
            {values.intervalType === "intraday" && <IntradayIntervals />}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <KeyboardTimePicker
              classes={classes}
              variant="dialog"
              ampm={false}
              label={<I18nText>refDateQueries.queryAt</I18nText>}
              value={values.queryAt}
              minutesStep={5}
              onChange={value => setValue("queryAt", value)}
              format="HH:mm"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl classes={classes}>
              <InputLabel id="older-than-label">
                <I18nText>refDateQueries.olderThanLabel</I18nText>
              </InputLabel>
              <Select
                style={{ minWidth: 300 }}
                labelId="older-than-label"
                value={values.olderThan}
                onChange={e => setValue("olderThan", e.target.value)}
              >
                <MenuItem value="1800">
                  <I18nText>refDateQueries.olderThan.1800</I18nText>
                </MenuItem>
                <MenuItem value="3600">
                  <I18nText>refDateQueries.olderThan.3600</I18nText>
                </MenuItem>
                <MenuItem value="7200">
                  <I18nText>refDateQueries.olderThan.7200</I18nText>
                </MenuItem>
                <MenuItem value="21600">
                  <I18nText>refDateQueries.olderThan.21600</I18nText>
                </MenuItem>
                <MenuItem value="43200">
                  <I18nText>refDateQueries.olderThan.43200</I18nText>
                </MenuItem>
                <MenuItem value="86400">
                  <I18nText>refDateQueries.olderThan.86400</I18nText>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetadataForm;
